import { useMemo } from "react";
import buildApi from "../lib/api";

function usePublicApi() {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const api = useMemo(() => buildApi(), [])
    return api;
}

export default usePublicApi;
