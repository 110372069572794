import { Button } from "../components/BaseComponents";

function Separator() {
    return (
        <div className="my-20 h-2 w-full bg-charcoal-100"></div>
    )
}

function Design() {
    return (
        <div>
            <h1>Line</h1>
            <div>
                <Button type="Primary" text="Button sm" />
            </div>

        
            <Separator />
            <h1>Buttons</h1>
            <div>
                <div>xs</div>
                <Button size="xs" type="Primary" text="Primary" />
                <Button size="xs" type="Green-Secondary" text="Green-secondary" />
                <Button size="xs" type="Link" text="Link" />
                <Button size="xs" type="Outlined" text="Outlined" />
                <Button size="xs" type="Pagination" text="Pagination" />
                <Button size="xs" type="Secondary" text="Secondary" />
                <Button size="xs" type="White" text="White" />
                <Button size="xs" type="on dark" text="on dark" />
            </div>
            <div>
                <div>sm</div>
                <Button size="sm" type="Primary" text="Primary" />
                <Button size="sm" type="Green-Secondary" text="Green-secondary" />
                <Button size="sm" type="Link" text="Link" />
                <Button size="sm" type="Outlined" text="Outlined" />
                <Button size="sm" type="Pagination" text="Pagination" />
                <Button size="sm" type="Secondary" text="Secondary" />
                <Button size="sm" type="White" text="White" />
                <Button size="sm" type="on dark" text="on dark" />
            </div>
            <div>
                <div>md</div>
                <Button size="md" type="Primary" text="Primary" />
                <Button size="md" type="Green-Secondary" text="Green-secondary" />
                <Button size="md" type="Link" text="Link" />
                <Button size="md" type="Outlined" text="Outlined" />
                <Button size="md" type="Pagination" text="Pagination" />
                <Button size="md" type="Secondary" text="Secondary" />
                <Button size="md" type="White" text="White" />
                <Button size="md" type="on dark" text="on dark" />
            </div>
            <div>
                <div>lg</div>
                <Button size="lg" type="Primary" text="Primary" />
                <Button size="lg" type="Green-Secondary" text="Green-secondary" />
                <Button size="lg" type="Link" text="Link" />
                <Button size="lg" type="Outlined" text="Outlined" />
                <Button size="lg" type="Pagination" text="Pagination" />
                <Button size="lg" type="Secondary" text="Secondary" />
                <Button size="lg" type="White" text="White" />
                <Button size="lg" type="on dark" text="on dark" />
            </div>
            <Separator />
        </div>
    );
}

export default Design;
