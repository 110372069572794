import { useState } from "react";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";
import { Button, LoadingSpinner } from "../BaseComponents"
import Label from "../BaseComponents/Label";
import Modal from "../BaseComponents/Modal/Modal"
import PageHeadings from "../BaseComponents/PageHeadings/PageHeadings"

type AddToPortfolioModalProps = {
    open?: boolean,
    onClose: () => void,
    companyId: string,
    companyName: string,
    existingAttachments?: any[],
}

function AddToPortfolioModal({open=false, onClose, companyId, companyName, existingAttachments=[]}: AddToPortfolioModalProps) {
    const [loading, setLoading] = useState(false);

    const [portfolioId, setPortfolioId] = useState('');
    const [portfolios, setPortfolios] = useState<any[]>([]);

    const api = useApi();

    const loadPortfolios = async () => {
        setLoading(true);
        const run = async () => {
            const result = await api.get('/portfolios/');
            setPortfolios(result.data.portfolios);
            setLoading(false);
        }
        run();
    }

    const onSave = async () => {
        setLoading(true);
        await api.post(`/portfolios/${portfolioId}/companies/`, {
            _csrf: await getCSRFToken(),
            companyId: companyId,
        })
        setLoading(false);
        onClose();
    }

    return (
        <Modal open={open} onClose={onClose} size="small" onOpen={loadPortfolios}>
            <PageHeadings title="Add to portfolio" />
            { (loading) ? (
                <LoadingSpinner />
            ) : (
                <>
                    <p className="text-white mt-8">Add { companyName} to a portfolio</p>

                    { existingAttachments.length ? (
                        <div className="mt-4">
                            <Label>Currently in portfolios:</Label>
                            { existingAttachments.map(attached => {
                                return (
                                    <div className="text-white">
                                        { attached.portfolio.name }
                                    </div>
                                )
                            })}
                        </div>
                    ): (
                        null
                    )}

                    <Label className="block mt-4">Select a portfolio</Label>
                    <select className="rounded" onChange={e => setPortfolioId(e.target.value)} value={portfolioId}>
                        <option value="">Select a portfolio</option>
                        { portfolios.filter(portfolio => {
                            return !existingAttachments.some(attached => {
                                return attached.portfolio.id === portfolio.id
                            })
                        }).map(portfolio => {
                            return (
                                <option value={portfolio.id}>
                                    { portfolio.name}
                                </option>
                            )
                        })}
                    </select>
                    <div className="flex space-x-2 mt-4">
                        <Button
                            disabled={!portfolioId}
                            type="Primary"
                            text="Add organisation to portfolio"
                            onClick={onSave}
                        />
                        <Button type="Outlined" text="Cancel" onClick={onClose} />
                    </div>
                </>
            )}
        </Modal>
    )
}

export default AddToPortfolioModal