import Button from '../Button/Button'

type HeadingProps = {
	title: string
	subTitle?: string
	subSubTitle?: string
	routeBack?: string
	routeState?: string
}

function PageHeadings({ title, subTitle, subSubTitle, routeBack, routeState }: HeadingProps) {
	return (
		<>
			<div className='flex gap-3xl items-baseline block'>
				<h1 className='font-extrabold text-2xl text-white flex'>{title}</h1>
				{subTitle && <h2 className='text-xl leading-7 font-normal text-white'>{subTitle}</h2>}
				{subSubTitle && (
					<h3 className='text-base leading-7 font-normal text-white'>{subSubTitle}</h3>
				)}
			</div>
			{routeBack && (
				<Button
					url={routeBack}
					routeState={routeState}
					isLink={true}
					type='Link'
					hasLeadingIcon={true}
					icon='fa-regular fa-chevron-left'
					text='Back'
				/>
			)}
		</>
	)
}
export default PageHeadings
