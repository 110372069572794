import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button, Input } from '../components/BaseComponents'
import { SortConfig } from '../components/BaseComponents/Datagrid/useSortableData'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import Pagination from '../components/BaseComponents/Pagination/Pagination'
import SimpleSortTable, {
	TableColumn,
} from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import routes from '../components/Router/routes'
import { useEffectWithoutInitialRender } from '../hooks/useEffectWithoutInitialRender'
import { getSuppliers, GET_SUPPLIERS_LIMIT } from '../redux/actions/suppliersActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectAllSuppliers,
	selectSuppliersPagination,
	selectSuppliersStatus,
	SUPPLIERS_RESET,
} from '../redux/slices/suppliersSlice'
import { FetchStatus } from '../types/LoadingStates'
import { Supplier } from '../types/Supplier'
import useApi from '../hooks/useApi'

const CompanySuppliers = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	const suppliersStatus = useAppSelector(selectSuppliersStatus)
	const pagination = useAppSelector(selectSuppliersPagination)
	const allSuppliers = useAppSelector(selectAllSuppliers)

	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	const [orderBy, setOrderBy] = useState<string>('name')
	const [direction, setDirection] = useState<'asc' | 'desc' | undefined>('asc')

	// Data loading
	useEffect(() => {
		dispatch({
			type: SUPPLIERS_RESET,
		})
		dispatch(getSuppliers(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{ id: 'name', label: 'Supplier', sortable: true },
		{
			id: 'isicCode',
			label: 'Sector Code (ISIC)',
			sortable: false,
			columnClass: 'col-span-2',
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{rowData.isicCode}
							{rowData.isicCodeName && (
								<span className='text-charcoal-050'> - {rowData.isicCodeName}</span>
							)}
						</p>
					</>
				)
			},
		},
		{
			id: 'jurisdiction',
			label: 'Jurisdiction',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>{rowData.jurisdiction_code}</p>
					</>
				)
			},
		},
		{
			id: 'edit',
			label: '',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<Button
						type='Primary'
						size='sm'
						text='Edit supplier'
						hasLeadingIcon
						icon='fa-regular fa-pen-to-square'
						accessibilityLabel={rowData.itemName}
						additionalClass='float-right'
						onClick={() => {
							navigate(generatePath(routes.companySupplierEdit.path, { supplierId: rowData._id}))
						}}
					/>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'name',
		direction: 'ascending',
	}

	// DataGrid data constructor
	const constructData = (dataSet: Array<Supplier>) => [
		...dataSet.map((supplier) => {
			return {
				_id: supplier.id,
				name: supplier.name,
				isicCode: supplier.supplierIndustryCode?.code || '-',
				isicCodeName: supplier.supplierIndustryCode?.name || undefined,
				jurisdiction_code: supplier.jurisdiction_code || '-',
				edit: '',
			}
		}),
	]

	// Timeout for search
	useEffectWithoutInitialRender(() => {
		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			dispatch(
				getSuppliers(api, {
					page: pagination.first_page,
					limit: GET_SUPPLIERS_LIMIT,
					orderBy: orderBy,
					direction: 'asc',
					q: searchKeyword,
				})
			)

			setSearchInProgress(false)
		}, 500)

		if (searchKeyword !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchKeyword])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companySuppliers.name}</title>
			</Helmet>
			<PageHeadings
				title='Purchases'
				subTitle='Suppliers'
				subSubTitle='Overview'
				routeBack={routes.companyPurchases.path}
			/>

			{suppliersStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching suppliers associated with this organisation. If this problem
						persists, please contact the Neoni team.
					</p>
				</div>
			)}

			<>
				<div className='items-start'>
					<div className='relative max-w-2xl'>
						<label htmlFor='filterItems' className='sr-only'>
							Filter suppliers
						</label>
						<Input
							type={'text'}
							name={'filterSuppliers'}
							placeholder='Search for a supplier'
							value={searchKeyword}
							onChange={(event) => {
								setSearchKeyword(event.target.value)
							}}
						/>
						<label htmlFor='filterPurchases' className='sr-only'>
							Filter suppliers
						</label>
						<Button
							hasLeadingIcon
							type='White'
							size='sm'
							icon={` ${
								searchInProgress
									? 'fas fa-loader fa-spin'
									: searchKeyword.length > 0
									? 'fa-solid fa-times'
									: 'fa-solid fa-search'
							}`}
							additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
							onClick={() => {
								setSearchKeyword('')
								dispatch(
									getSuppliers(api, {
										page: pagination.first_page,
										limit: GET_SUPPLIERS_LIMIT,
										orderBy: orderBy,
										direction: direction,
										q: '',
									})
								)
							}}
						/>
					</div>
				</div>

				<SimpleSortTable
					isLoading={suppliersStatus === FetchStatus.loading}
					additionalClass='mt-4'
					data={constructData(allSuppliers)}
					columns={columns}
					defaultSorting={defaultSorting}
					onSortPress={(key, direction) => {
						// Store current sorting values for pagination to reference in its API calls
						setOrderBy(key)
						setDirection(direction === 'ascending' ? 'asc' : 'desc')
						dispatch(
							getSuppliers(api, {
								page: pagination.current_page,
								limit: GET_SUPPLIERS_LIMIT,
								orderBy: key,
								direction: direction === 'ascending' ? 'asc' : 'desc',
								q: searchKeyword,
							})
						)
					}}
				/>

				<Pagination
					isLoading={suppliersStatus === FetchStatus.loading}
					pagination={pagination}
					onPageChange={(newPageNumber) => {
						dispatch(
							getSuppliers(api, {
								page: newPageNumber,
								limit: GET_SUPPLIERS_LIMIT,
								orderBy: orderBy,
								direction: direction,
								q: searchKeyword,
							})
						)
					}}
				/>
			</>
		</PageTemplate>
	)
}

export default CompanySuppliers
