import { Dispatch } from 'redux'
import { getParams } from '../../lib/utils'
import {
	suppliersCarbonData_ERROR,
	suppliersCarbonData_LOADED,
	suppliersCarbonData_LOADING,
} from '../slices/carbonAccountsSupplier'
import { NeoniApi } from '../../lib/api'

export const GET_PURCHASES_SUPPLIERS_LIMIT = 20
export const GET_PURCHASES_SUPPLIERS_LIMIT_SCOPE3 = 10

type IgetPurchasesSuppliers = {
	companyId: string
	accountingPeriodId: string
	page?: number
	limit?: number
	orderBy?: string
	direction?: string
	scope?: string
	q?: string
	excludeScope1?: boolean | string
	excludeScope2?: boolean | string
	excludeNullScope?: boolean | string
}

export const getPurchasesSuppliers =
	(api: NeoniApi, {
		companyId,
		accountingPeriodId,
		page = 1,
		limit = GET_PURCHASES_SUPPLIERS_LIMIT,
		orderBy = '',
		direction = '',
		scope = '',
		q = '',
		excludeScope1 = '',
		excludeScope2 = '',
		excludeNullScope = '',
	}: IgetPurchasesSuppliers) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: suppliersCarbonData_LOADING,
		})

		try {
			const paramVals = {
				scope,
				page,
				itemsPerPage: limit,
				orderBy,
				direction,
				q,
			}
			let params = getParams(paramVals)
			const body = await api.get(`accountingPeriod/${accountingPeriodId}/carbonAccounts/suppliers?${params}`)

			body.data.per_page = limit
			if (body.success === true) {
				// Successfully fetched purchases
				dispatch({
					type: suppliersCarbonData_LOADED,
					payload: body.data,
				})
			} else {
				// Error
				dispatch({
					type: suppliersCarbonData_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: suppliersCarbonData_ERROR,
				payload: error,
			})
		}
	}
