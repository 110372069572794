import { Dispatch } from 'redux'
import {
	PERIODSTATS_ERROR,
	PERIODSTATS_LOADED,
	PERIODSTATS_LOADING,
} from '../slices/periodStatisticsSlice'
import { NeoniApi } from '../../lib/api'

export const getPeriodStatistics = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: PERIODSTATS_LOADING,
	})

	try {
		const body = await api.get(`accountingPeriods/statistics`);

		if (body.success === true) {
			// Successfully fetched period statistics
			dispatch({
				type: PERIODSTATS_LOADED,
				payload: body.summaries,
			})
		} else {
			// Error
			dispatch({
				type: PERIODSTATS_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: PERIODSTATS_ERROR,
			payload: error,
		})
	}
}
