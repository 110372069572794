import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostStatus } from '../../types/LoadingStates'
import { Supplier } from '../../types/Supplier'
import type { RootState } from '../store'

type UpdateSuppliersSlice = {
	status: PostStatus
	error?: string
	supplier?: Supplier
}

const UpdateSuppliersInitialState: UpdateSuppliersSlice = {
	status: PostStatus.initial,
	error: undefined,
	supplier: undefined,
}

export const updateSuppliersSlice = createSlice({
	name: 'Update Suppliers',
	initialState: UpdateSuppliersInitialState,
	reducers: {
		UPDATESUPPLIERS_SENDING: (state) => {
			return {
				...state,
				status: PostStatus.sending,
				message: undefined,
			}
		},
		UPDATESUPPLIERS_SENT: (state, action: PayloadAction<Supplier>) => {
			return {
				...state,
				status: PostStatus.sent,
				error: undefined,
				supplier: action.payload,
			}
		},
		UPDATESUPPLIERS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.error,
				error: action.payload,
			}
		},
		UPDATESUPPLIERS_RESET: () => {
			return UpdateSuppliersInitialState
		},
	},
})

// Action types
export const {
	UPDATESUPPLIERS_SENDING,
	UPDATESUPPLIERS_SENT,
	UPDATESUPPLIERS_ERROR,
	UPDATESUPPLIERS_RESET,
} = updateSuppliersSlice.actions

// Selectors
export const selectUpdateSuppliersStatus = (state: RootState) => state.updateSuppliers.status
export const selectUpdateSuppliersError = (state: RootState) => state.updateSuppliers.error
export const selectUpdateSuppliersUpdatedSupplier = (state: RootState) =>
	state.updateSuppliers.supplier

export default updateSuppliersSlice.reducer
