import { Link } from 'react-router-dom'
import { Button } from '../components/BaseComponents'
import { FormEvent, useState } from 'react';
import useApi from '../hooks/useApi';
import { getCSRFToken } from '../lib/utils';

function ForgotPassword() {
    const api = useApi();

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const onFormSubmit = (e: FormEvent) => {
       e.preventDefault();
       onSubmit();
       return false; 
    }

    const onSubmit = async () => {
        setSubmitting(true)
        setError('');
        setMessage('');

        try {
            const result = await api.post('/user/sendResetEmail', {
                email,
                _csrf: await getCSRFToken(),
            });

            setSubmitting(false);

            setMessage(result.message)
            
        } catch(error: any) {
            setSubmitting(false);
            if(error.body?.error) {
                setError(error.body.error)
            } else {
                setError('An unknown error occured');
            }
        }
    }

    return (
        <div className="content w-[32rem] mx-auto mt-16">
            <div className="text-charcoal-100 font-bold text-2xl text-center">Reset Password</div>
            <form onSubmit={onFormSubmit}>
                <label className="block mt-8 text-sm">
                    <span className="text-charcoal-100">Enter your email address</span>
                    <br />
                    <input
                        disabled={submitting}
                        name="email"
                        className="rounded w-full mt-1 text-sm"
                        type="text"
                        placeholder="you@example.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </label>
                <Button
                    disabled={submitting}
                    onClick={onSubmit}
                    type="Primary"
                    text="Forgot Password"
                    size="sm"
                    additionalClass='mt-4 w-full'
                />
                <button type="submit" value="Submit" className="hidden" />

                <div className="mt-4">
                    <Link
                        className="underline text-algae-500"
                        to="/login"
                    >
                        Log in
                    </Link>
                </div>
            </form>
            { error && (
                <div className='bg-error-400 rounded p-4 mt-6 text-sm'>
                    <span className='fa-regular fa-circle-exclamation pr-4' />
                    { error }
                </div>
            )}
            { message && (
                <div className='bg-algae-500 rounded p-4 mt-6 text-sm'>
                    { message }
                </div>
            )}
        </div>
    );
}

export default ForgotPassword
