import classNames from 'classnames'
import { FunctionComponent, useEffect, useState } from 'react'

export type Tab = {
	label: string
	component: (active?: boolean) => React.ReactNode
	onChange?: (newTabIndex: number) => void
}

type ITabList = {
	tabDescription?: string
	tabs: Array<Tab>
	additionalContainerClass?: string
	additionalPanelClass?: string
	initialActiveTab?: number
}

const TabList: FunctionComponent<ITabList> = ({
	tabDescription = '',
	tabs,
	additionalContainerClass,
	additionalPanelClass,
	initialActiveTab = 0,
}) => {
	const [activeTab, setActiveTab] = useState<number>(initialActiveTab)

	useEffect(() => {}, [tabs])

	return (
		<>
			<div
				role='tablist'
				aria-label={tabDescription}
				className={classNames(additionalContainerClass)}
			>
				<div className='flex w-full border-t-4 border-charcoal-600'>
					{tabs.map((tab, index) => {
						return (
							<button
								className={classNames('w-full text-sm text-white text-left px-4 py-3', {
									'bg-charcoal-800': index === activeTab,
									'border-r-4 border-charcoal-400': index !== tabs.length - 1,
								})}
								key={tab.label}
								role='tab'
								aria-selected={index === activeTab}
								aria-controls={`tabpanel-${index}`}
								id={`tab-${index}`}
								tabIndex={0}
								onClick={() => {
									setActiveTab(index)
									tab.onChange && tab.onChange(index)
								}}
							>
								{tab.label}
							</button>
						)
					})}
				</div>
				{tabs.map((tab, index) => {
					return (
						<div
							key={`${tab.label}-panel`}
							id={`tabpanel-${index}`}
							role='tabpanel'
							className={classNames('bg-charcoal-800', additionalPanelClass)}
							aria-labelledby={`tab-${index}`}
							hidden={activeTab !== index}
						>
							{tab.component(activeTab === index)}
						</div>
					)
				})}
			</div>
		</>
	)
}

export default TabList
