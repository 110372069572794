type ElementProps<T extends keyof JSX.IntrinsicElements> = JSX.IntrinsicElements[T];

function Label(props: ElementProps<"label">) {
    let classes = 'text-sm font-medium text-charcoal-100 my-1'
    if(props.className) {
        classes += ' ' + props.className;
    }

    return <label {...props} className={classes} />
}

export default Label;
