import { Helmet } from "react-helmet";
import PageHeadings from "../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../components/Router/routes";
import { useContext, useEffect, useState } from "react";
import { formatDate } from "../lib/utils";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "../components/BaseComponents";
import { userContext } from "../context/UserContext";
import AddToPortfolioModal from "../components/Portfolios/AddToPortfolioModal";
import RequestAccessModal from "../components/RequestAccessModal";
import useApi from "../hooks/useApi";
import useCleanup from "../hooks/useCleanup";

function SearchResult({row, reload}: any) {
    const { checkAccess } = useContext(userContext);

    const [portfolioModalOpen, setPortfolioModalOpen] = useState(false);
    const companyKey = row.keyName || row.id;

    const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);

    return (
        <div className="bg-charcoal-400 flex rounded-sm py-2 px-4 mt-4">
            <div className="flex grow">
                <div className="w-64 flex flex-col ">
                    <Link className="underline text-white" to={`/public/profiles/${companyKey}`}>{ row.name }</Link>
                    <div>
                        <span className="text-charcoal-100">Organisation number{' '}</span>
                        <span className="text-white">{ row.companyNumber}</span>
                    </div>
                </div>
                <div className="w-24">
                    <span className="text-charcoal-100">Reports:{' '}</span>
                    <span className="text-white">{ row.reports.length}</span>
                </div>
                <div>
                    <span className="text-charcoal-100">Latest report:{' '}</span>
                    <span className="text-white">
                        { row.reports[0]?.end_date ? formatDate(row.reports[0]?.end_date) : '-' }
                    </span>
                </div>
            </div>

            <div className="flex space-x-2">
                { checkAccess('company.portfolios') && (
                    <>
                        <div>
                            <Button
                                size="sm"
                                type="Primary"
                                text={row.portfolioCompanies.length ? `In ${row.portfolioCompanies.length} portfolio(s)` : `Add to portfolio`}
                                hasTrailingIcon
                                icon="fa-regular fa-plus"
                                onClick={() => setPortfolioModalOpen(true)}
                            />
                        </div>

                        <div>
                            { row.permissionStatus ? (
                                <Button size="sm" type="Primary" disabled text={`Access status: ${row.permissionStatus}`} />
                            ): (
                                <>
                                    <Button
                                        size="sm"
                                        type="Primary"
                                        text="Request access"
                                        onClick={() => setRequestAccessModalOpen(true)}
                                    />
                                    <RequestAccessModal
                                        companyName={row.name}
                                        companyId={row.id}
                                        onClose={() => {
                                            setRequestAccessModalOpen(false)
                                        }}
                                        onDone={() => {
                                            reload();
                                            setRequestAccessModalOpen(false)
                                        }}
                                        open={requestAccessModalOpen}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
            <AddToPortfolioModal
                open={portfolioModalOpen}
                companyId={row.id}
                companyName={row.name}
                existingAttachments={row.portfolioCompanies}
                onClose={() => setPortfolioModalOpen(false)}
            />
        </div>
    )
}

function PublicProfiles() {
    const [data, setData] = useState<any>([]);
    const [qs] = useSearchParams();
    const api = useApi();

    const getData = useCleanup(() => {
        let cancelled = false;
        const run = async () => {
            const result = await api.get(`/public/secr/search?queryString=${encodeURIComponent(searchString)}`);
            if(cancelled) {
                return;
            }
            setData(result.data);
        }
        run()
        return () => {
            cancelled = true;
        }
    })

    const searchString = qs.get('s') || '';

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString])

    return(
        <PageTemplate testid='company-dashboard-main'>
            <Helmet>
                <title>Emission Report Search</title>
            </Helmet>
            <PageHeadings title='Emission Report Search' />

            <div className="mt-8">

                <div
                    role='table'
                    className='table border-collapse w-full'
                >
                    { data.map((row: any) => {
                        return (
                            <SearchResult key={row.id} row={row}  reload={getData}/>
                        )
                    })}
                </div>
                { !data.length && (
                    <div className="text-white">
                        <p className="my-4"><strong>No results found</strong></p>
                        <p className="my-4">
                            Do you know the carbon emissions of this organisation, or another organisation?
                            <br />
                            You can contribute to our public database after creating a Neoni account.
                        </p>
                        <Link className="my-4" to={routes.publicSignUp.path}>
                            <Button text="Sign up" size="sm" type="Primary" />
                        </Link>
                    </div>
                )}
            </div>
        </PageTemplate>
    )
}

export default PublicProfiles;
