import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { LoadingSpinner } from "../components/BaseComponents";
import routes from "../components/Router/routes";
import { userContext } from "../context/UserContext";

function LandingPage() {
    const { fetchUserData,  userData } = useContext(userContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        const run = async () => {
            await fetchUserData()
            setLoading(false);
        };

        run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(loading) {
        return <LoadingSpinner />
    }

    if(!userData) {
        console.log('No user data');
        return <Navigate to={routes.publicLogin.path} />
    }

    const role = userData.role;

    if(role === 'admin') {
        console.log('Admin role');
        return <Navigate to={routes.adminDashboard.path} />
    } else {
        console.log('Other role')
        return <Navigate to={routes.companyDashboard.path} />
    }
}

export default LandingPage;
