type Props = {
	message: string
}

function ContactMessage({ message }: Props) {
	return (
		<div className='p-3 leading-6 font-semibold text-base text-charcoal-900 rounded-lg bg-sun-500'>
			<p className='mb-2'>{message}</p>
			<p className=''>
				<a className='underline font-bold mr-1' href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}>
					Contact us
				</a>
				if you have a problem.
			</p>
		</div>
	)
}
export default ContactMessage
