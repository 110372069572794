// This file stores single source of truth route information as constants, for use elsewhere

import { AppRoute } from '../../types/AppRoute'
import AdminDashboard from '../../pages/AdminDashboard'
import AdminInviteCompany from '../../pages/AdminInviteCompany'
import CompanyDashboard from '../../pages/CompanyDashboard'
import CompanySetup from '../../pages/CompanySetup'
import Purchases from '../../pages/CompanyPurchases'
import AddAccountingPeriods from '../../pages/AddAccountingPeriods'
import AddPurchasesUpload from '../../pages/AddPurchasesUpload'
import CompanyRefineEmissions from '../../pages/CompanyRefineEmissions'
import CompanyRefineEmissionsType from '../../pages/CompanyRefineEmissionsType'
import CompanyRefineElectricity from '../../pages/CompanyRefineElectricity'
import CompanyRefineSuppliers from '../../pages/CompanyRefineSuppliers'
import CompanyItems from '../../pages/CompanyItems'
import CompanyEditItem from '../../pages/CompanyEditItem'
import CompanySuppliers from '../../pages/CompanySuppliers'
import CompanySupplyChain from '../../pages/CompanySupplyChain'
import CompanyEditSupplier from '../../pages/CompanyEditSupplier'
import CompanyInsightsTrends from '../../pages/CompanyInsights/CarbonAccountTrends/CompanyTrends'
import CompanySupport from '../../pages/CompanySupport'
import AdminCompanies from '../../pages/AdminCompanies'
import CompanyInviteSupplierList from '../../pages/CompanyInviteSupplierList'
import CompanyInviteSupplier from '../../pages/CompanyInviteSupplier'
import AdminInviteUser from '../../pages/AdminInviteUser'
import CarbonAccountsPage from '../../pages/CompanyCarbonAccounts/CarbonAccounts'
import CompanyInsights from '../../pages/CompanyInsights/CompanyInsights'
import CompanyInsightsSuppliers from '../../pages/CompanyInsights/Suppliers/Suppliers'
import CompanyInsightsSupplierTrends from '../../pages/CompanyInsights/Suppliers/Trends/SupplierTrends'
import AccountingPeriodCsvs from '../../pages/AcountingPeriodList'
import CompanyInsightsSupplyChainAnalysisPage from '../../pages/CompanyInsights/SupplyChainAnalysis/SupplyChainAnalysis'
import CompanyInsightsPeriodicReportsPage from '../../pages/CompanyInsights/PeriodicReports/PeriodicReports'
import MyReports from '../../pages/SECR/MyReports'
import SECRSearch from '../../pages/SECRSearch'
import Portfolios from '../../pages/Portfolios'
import CompanySettings from '../../pages/CompanySettings'
import SecrCreate from '../../pages/SECR/create2'
import AdminCompany from '../../pages/AdminCompany/AdminCompany'
import AssumeCompany from '../../pages/AssumeCompany'
import ClearAssumedCompany from '../../pages/ClearAssumedCompany'
import AdminCurrencies from '../../pages/AdminCurrencies'
import AdminSettings from '../../pages/AdminSettings/AdminSettings'
import PublicDashboard from '../../pages/PublicDashboard'
import PublicProfiles from '../../pages/PublicProfiles'
import PublicProfile from '../../pages/PublicProfile'
import PublicReport from '../../pages/PublicReport'
import Login from '../../pages/Login'
import Design from '../../pages/Design'
import SignUp from '../../pages/SignUp'
import AdminUsers from '../../pages/AdminUsers/AdminUsers'
import AccountingClients from '../../pages/AccountingClients'
import Logout from '../../pages/Logout'
import ForgotPassword from '../../pages/ForgotPassword'
import AdminReports from '../../pages/AdminReports/AdminReports'
import ChildCompanies from '../../pages/ChildCompanies'
import CompanyProjects from '../../pages/CompanyProjects/CompanyProjects'
import PurchaseList from '../../pages/CompanyPurchaseList'
import LandingPage from '../../pages/LandingPage'
import PortfolioList from '../../pages/Portfolios/PortfolioList'
import MyReport from '../../pages/SECR/MyReport'
import ClientCompanies from '../../pages/ClientCompanies'
import ResetPassword from '../../pages/ResetPassword'
import { Navigate } from 'react-router-dom'

function makeRoutes<T extends string>(routes: Record<T, AppRoute>) {
	return routes as Record<T, AppRoute>;
}

const routes = makeRoutes({
	// Admin routes
	adminDashboard: {
		name: 'Neoni - Admin Dashboard',
		menuName: 'Admin Dashboard',
		path: '/admin/dashboard',
		icon: 'fa-light fa-house',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['admin'],
		component: () => <AdminDashboard />,
	},
	adminInviteCompany: {
		name: 'Neoni - Invite Companies',
		menuName: 'Invite Companies',
		path: '/admin/invite',
		icon: 'fa-light fa-send mr-md',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminInviteCompany />,
	},
	adminCompanies: {
		name: 'Neoni - Companies',
		menuName: 'Companies',
		path: '/admin/companies',
		icon: 'fa-light fa-users-line',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminCompanies />,
	},
	adminCompany: {
		name: 'Neoni - Company',
		menuName: 'Companies',
		path: '/admin/companies/:companyId?',
		sidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminCompany />,
	},
	adminUsers: {
		name: 'Neoni - Users',
		menuName: 'Users',
		path: '/admin/users',
		icon: 'fa-light fa-user',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminUsers />,
	},
	adminInviteUser: {
		name: 'Neoni - Invite Users',
		menuName: 'Invite Users',
		path: '/admin/invite-users',
		icon: 'fa-light fa-user-plus',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminInviteUser />,
	},
	adminSettings: {
		name: 'Admin - Settings',
		menuName: 'Admin Settings',
		path: '/admin/settings',
		icon: 'fa-light fa-screwdriver-wrench',
		sidebar: true,
		showInSidebar: true,
		showAtBottom: true,
		accessRequired: ['company.admin'],
		component: () => <AdminSettings />
	},
	adminCurrencies: {
		name: 'Admin Currencies',
		menuName: 'Currencies',
		path: '/admin/currencies',
		icon: 'fa-light fa-coin-vertical',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminCurrencies />
	},
	adminReports: {
		name: 'Admin Reports',
		menuName: 'Reports',
		path: '/admin/reports',
		icon: 'fa-light fa-memo',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.admin'],
		component: () => <AdminReports />
	},

	landingPage: {
		name: 'Landing Page',
		path: '/',
		component: () => <LandingPage />
	},

	// Company routes
	companyDashboard: {
		name: 'Neoni - Dashboard',
		menuName: 'Dashboard',
		path: '/dashboard',
		icon: 'fa-light fa-house',
		sidebar: true,
		showInSidebar: true,

		rolesAllowed: ['company'],
		component: () => <CompanyDashboard />,
	},
	companySetup: {
		name: 'Neoni - Welcome',
		path: '/welcome',
		// rolesAllowed: ['company'],
		component: () => <CompanySetup />,
	},
	companyPurchases: {
		id: 'companyPurchases',
		name: 'Neoni - Purchases',
		menuName: 'Accounts',
		path: '/purchases',
		icon: 'fa-light fa-basket-shopping',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		accessRequired: ['company.accounting'],
		component: () => <Purchases />,
	},
	companyProjects: {
		name: 'Neoni - Projects',
		menuName: 'Projects',
		path: '/purchases/projects/:projectId?',
		icon: 'fa-light fa-basket-shopping',
		parentId: 'companyPurchases',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		accessRequired: ['company.projects'],
		component: () => <CompanyProjects />,
	},
	companyPurchaseList: {
		name: 'Neoni - List of Purchases',
		menuName: 'Purchases',
		path: '/purchases/purchaseList',
		icon: 'fa-light fa-basket-shopping',
		parentId: 'companyPurchases',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		accessRequired: ['company.accounting'],
		component: () => <PurchaseList />,
	},
	companyCarbonAccounts: {
		name: 'Neoni - Carbon Accounts',
		menuName: 'Carbon Accounts',
		path: '/purchases/carbon-accounts/:accountingPeriodId?',
		sidebar: true,
		showInSidebar: true,
		parentId: 'companyPurchases',
		rolesAllowed: ['company'],
		component: () => <CarbonAccountsPage />,
	},
	companySuppliers: {
		name: 'Neoni - All Suppliers',
		menuName: 'Suppliers',
		path: '/purchases/suppliers',
		sidebar: true,
		showInSidebar: true,
		parentId: 'companyPurchases',
		rolesAllowed: ['company'],
		component: () => <CompanySuppliers />,
	},
	companySupplierEdit: {
		name: 'Neoni - Edit Supplier',
		path: '/purchases/suppliers/:supplierId',
		sidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyEditSupplier />,
	},
	companyItems: {
		name: 'Neoni - All Items',
		menuName: 'Items',
		path: '/purchases/items',
		sidebar: true,
		showInSidebar: true,
		parentId: 'companyPurchases',
		rolesAllowed: ['company'],
		accessRequired: ['company.accounting'],
		component: () => <CompanyItems />,
	},
	companyItemEdit: {
		name: 'Neoni - Edit Item',
		path: '/purchases/items/:itemId',
		sidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyEditItem />,
	},
	companyAddAccountingPeriods: {
		name: 'Neoni - Accounting Periods',
		menuName: 'Accounting Periods',
		path: '/purchases/accounting-periods',
		sidebar: true,
		showInSidebar: true,
		parentId: 'companyPurchases',
		rolesAllowed: ['company'],
		component: () => <AddAccountingPeriods />,
	},
	companyViewAccountingPeriodDetails: {
		name: 'Neoni - Accounting Periods Detail View',
		path: '/purchases/accounting-periods/detail/:accountingPeriodIdVal?',
		sidebar: true,
		rolesAllowed: ['company'],
		showInSidebar: false,
		component: () => <AccountingPeriodCsvs />,
	},
	companyAddPurchasesUpload: {
		name: 'Neoni - Upload Purchases',
		path: '/purchases/upload',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <AddPurchasesUpload />,
	},
	companyRefineEmissionsGenerated: {
		name: 'Neoni - Refine Data - Emissions Generated',
		path: '/purchases/refine/emissions-generated/:accountingPeriodId',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <CompanyRefineEmissions />,
	},
	companyRefineEmissionsGeneratedType: {
		name: 'Neoni - Refine Data - Emissions Generated Type',
		path: '/purchases/refine/emissions-generated-type/:accountingPeriodId',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <CompanyRefineEmissionsType />,
	},
	companyRefineElectricity: {
		name: 'Neoni - Refine Data - Electricity',
		path: '/purchases/refine/electricity/:accountingPeriodId',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <CompanyRefineElectricity />,
	},
	companyRefineSuppliers: {
		name: 'Neoni - Refine Data - Suppliers',
		path: '/purchases/refine/suppliers/:accountingPeriodId',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <CompanyRefineSuppliers />,
	},
	companySupplyChainRequests: {
		name: 'Neoni - External Network',
		menuName: 'External Network',
		path: '/supply-chain-requests',
		icon: 'fa-light fa-link',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		accessRequired: ['company.invite'],
		id: 'companySupplyChain',
		component: () => <CompanySupplyChain />,
	},
	companyInviteSupplierList: {
		name: 'Neoni - Invite Supplier',
		menuName: 'Invite Supplier',
		path: '/supply-chain-requests/suppliers',
		icon: 'fa-light fa-link',
		sidebar: true,
		showInSidebar: true,
		parentId: 'companySupplyChain',
		rolesAllowed: ['company'],
		component: () => <CompanyInviteSupplierList />,
	},
	companyInviteSupplier: {
		name: 'Neoni - Invite Supplier',
		path: '/supply-chain-requests/suppliers/:supplierId',
		sidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyInviteSupplier />,
	},
	clientCompanies: {
		name: 'Neoni - Client Organisations',
		menuName: 'Client Organisations',
		parentId: 'companySupplyChain',
		path: '/supply-chain-requests/client-organisations',
		showInSidebar: true,
		sidebar: true,
		accessRequired: ['company.clientAccounting'],
		component: () => <ClientCompanies />
	},
	companyInsights: {
		name: 'Neoni - Insights',
		menuName: 'Insights',
		path: '/insights',
		icon: 'fa-regular fa-chart-mixed',
		id: 'insights',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		accessRequired: ['company.insights'],
		component: () => <CompanyInsights />,
	},
	companyInsightsTrends: {
		name: 'Neoni - Insights - Carbon Account Trends',
		menuName: 'Carbon Account Trends',
		path: '/insights/carbon-account-trends',
		parentId: 'insights',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyInsightsTrends />,
	},
	companyInsightsSupplyChainAnalysis: {
		name: 'Neoni - Insights - Supply Chain Analysis',
		menuName: 'Supply Chain Analysis',
		path: '/insights/supply-chain-analysis/:graphId?',
		parentId: 'insights',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyInsightsSupplyChainAnalysisPage />,
	},
	// secr: {
	// 	name: 'Neoni - Reports',
	// 	menuName: 'Reports',
	// 	path: '/secr',
	// 	icon: 'fa-regular fa-bars-progress',
	// 	id: 'secr',
	// 	sidebar: true,
	// 	showInSidebar: true,
	// 	rolesAllowed: ['company'],
	// 	component: () => <SECRMenu />,
	// },
	secrSearch: {
		name: 'Neoni - Search Public Reports',
		menuName: 'Search Public Reports',
		path: '/portfolios/search/',
		parentId: 'portfolios',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		component: () => <SECRSearch />,
	},
	myReportsEdit: {
		name: 'Neoni - SECR - Edit',
		menuName: 'Edit SECR Report',
		path: '/my-reports/:reportId/edit',
		// parentId: 'secr',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <SecrCreate />,
	},
	myReportsCreate: {
		name: 'Neoni - SECR - Create',
		menuName: 'Create Report',
		path: '/my-reports/create',
		parentId: 'secr',
		sidebar: true,
		showInSidebar: false,
		rolesAllowed: ['company'],
		component: () => <SecrCreate />,
	},
	myReport: {
		name: 'Neoni - My Report',
		path: '/my-reports/:reportId',
		sidebar: true,
		rolesAllowed: ['company'],
		component: () => <MyReport />,
	},
	myReports: {
		name: 'Neoni - My Reports',
		menuName: 'My Reports',
		path: '/my-reports',
		icon: 'fa-regular fa-bars-progress',
		id: 'secr',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		component: () => <MyReports />,
	},
	portfolio: {
		name: 'Neoni - Data Hub',
		menuName: 'Data Hub',
		id: 'data-hub',
		path: '/data-hub',
		icon: 'fa-regular fa-folder-bookmark',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.portfolios'],
		rolesAllowed: ['company'],
		component: () => <Navigate to={routes.companiesPortfolio.path} />
		// component: () => <SECRSearch />,
	},
	companiesPortfolio: {
		name: 'Neoni - Portfolios',
		menuName: 'Portfolios',
		parentId: 'data-hub',
		path: '/data-hub/portfolios',
		icon: 'fa-regular fa-folder-bookmark',
		sidebar: true,
		showInSidebar: true,
		accessRequired: ['company.portfolios'],
		rolesAllowed: ['company'],
		component: () => <PortfolioList />,
	},
	companyPortfolio: {
		name: 'Neoni - Portfolios',
		path: '/data-hub/portfolios/:portfolioId',
		sidebar: true,
		accessRequired: ['company.portfolios'],
		rolesAllowed: ['company'],
		component: () => <Portfolios />,
	},
	companyInsightsPeriodicReports: {
		name: 'Neoni - Insights - Detailed Insights',
		menuName: 'Detailed Insights',
		path: '/insights/periodic-reports/:graphId?',
		parentId: 'insights',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyInsightsPeriodicReportsPage />,
	},
	companyInsightsSuppliers: {
		name: 'Neoni - Insights - Suppliers',
		menuName: 'Suppliers',
		path: '/insights/suppliers',
		parentId: 'insights',
		sidebar: true,
		showInSidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyInsightsSuppliers />,
	},
	companyInsightsSupplierTrends: {
		name: 'Neoni - Insights - Supplier Carbon Account Trends',
		path: '/insights/suppliers/:supplierId',
		sidebar: true,
		rolesAllowed: ['company'],
		component: () => <CompanyInsightsSupplierTrends />,
	},
	companySupport: {
		name: 'Neoni - Support',
		menuName: 'Help & Support',
		path: '/support',
		icon: 'fa-light fa-comments-question',
		sidebar: true,
		showInSidebar: true,
		showAtBottom: true,
		rolesAllowed: ['company'],
		component: () => <CompanySupport />,
	},
	companySettings: {
		name: 'Neoni - Settings',
		menuName: 'Settings',
		path: '/settings',
		icon: 'fa-light fa-gear',
		sidebar: true,
		showInSidebar: true,
		showAtBottom: true,
		rolesAllowed: ['company'],
		component: () => <CompanySettings />,
	},
	assumeCompany: {
		name: 'Neoni - Assume Company',
		path: '/assumeCompany/:assumptionType/:companyId',
		component: () => <AssumeCompany />,
	},
	clearAssumedCompany: {
		name: 'Neoni - Assume Company',
		path: '/clearAssumedCompany/',
		component: () => <ClearAssumedCompany />,
	},
	publicDashboard: {
		name: 'Dashboard',
		path: '/public/',
		public: true,
		sidebar: true,
		component: () => <PublicDashboard />,
	},
	publicProfiles: {
		name: 'Company Profiles',
		path: '/public/profiles',
		public: true,
		sidebar: true,
		component: () => <PublicProfiles />,
	},
	publicProfile: {
		name: 'Neoni - Company Profile',
		path: '/public/profiles/:companyId',
		public: true,
		sidebar: true,
		component: () => <PublicProfile />,
	},
	publicReport: {
		name: 'Neoni - Public Report',
		path: '/public/reports/:reportId',
		public: true,
		sidebar: true,
		component: () => <PublicReport />,
	},
	publicLogin: {
		name: 'Neoni - Login',
		path: '/login',
		public: true,
		component: () => <Login />,
	},
	publicSignUp: {
		name: 'Neoni - Create Account',
		path: '/signup',
		public: true,
		component: () => <SignUp />,
	},
	publicLogout: {
		name: 'Neoni - Logout',
		path: '/logout',
		public: true,
		component: () => <Logout />,
	},
	forgotPassword: {
		name: 'Neoni - Forgot Password',
		path: '/forgotPassword',
		public: true,
		component: () => <ForgotPassword />,
	},
	resetPassword: {
		name: 'Neoni - ResetPassword',
		path: '/resetPassword',
		public: true,
		component: () => <ResetPassword />,
	},
	design: {
		name: 'Neoni - Design',
		path: '/design',
		rolesAllowed: ['admin'],
		component: () => <Design />,
	},
	// accountingClients: {
	// 	name: 'Neoni - Accounting Clients',
	// 	menuName: 'Accounting Clients',
	// 	path: '/accountingClients',
	// 	id: 'accountingClients',
	// 	icon: 'fa-light fa-people-line',
	// 	sidebar: true,
	// 	showInSidebar: true,
	// 	accessRequired: ['company.clientAccounting'],
	// 	component: () => <AccountingClients />
	// },
});

// Ordered version of company routes for mapping
const orderedRoutes: AppRoute[] = [
	// Admin routes
	routes.adminDashboard,
	routes.adminInviteCompany,
	routes.adminCompanies,
	routes.adminCompany,
	routes.adminUsers,
	routes.adminInviteUser,
	routes.adminSettings,
	routes.adminCurrencies,
	routes.adminReports,

	// Company routes
	routes.companyDashboard,
	routes.companySetup,
	routes.companyPurchases,
	routes.companyAddAccountingPeriods,
	routes.companyViewAccountingPeriodDetails,
	routes.companyItems,
	routes.companyItemEdit,
	routes.companySuppliers,
	routes.companySupplierEdit,
	routes.companyCarbonAccounts,
	routes.companyAddPurchasesUpload,
	routes.companyRefineEmissionsGenerated,
	routes.companyRefineEmissionsGeneratedType,
	routes.companyRefineElectricity,
	routes.companyRefineSuppliers,
	routes.companySettings,
	routes.companySupport,
	routes.companySupplyChainRequests,
	routes.companyInviteSupplierList,
	routes.companyInviteSupplier,
	routes.companyInsights,
	routes.companyInsightsTrends,
	routes.companyInsightsSupplyChainAnalysis,
	routes.myReportsCreate,
	routes.myReportsEdit,
	routes.myReport,
	routes.myReports,
	routes.secrSearch,
	// routes.secr,
	routes.companiesPortfolio,
	routes.companyPortfolio,
	routes.companyInsightsPeriodicReports,
	routes.companyInsightsSuppliers,
	routes.companyInsightsSupplierTrends,
	routes.assumeCompany,
	routes.clearAssumedCompany,
	// routes.accountingClients,
	routes.companyProjects,
	routes.companyPurchaseList,
	routes.clientCompanies,
	
	/** Public routes */
	routes.publicDashboard,
	routes.publicProfile,
	routes.publicProfiles,
	routes.publicReport,
	routes.publicLogin,
	routes.publicSignUp,
	routes.design,
	routes.publicLogout,
	routes.forgotPassword,
	routes.resetPassword,

	routes.landingPage,
	routes.portfolio
]

export { routes, orderedRoutes }
export default routes
