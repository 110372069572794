import classNames from 'classnames'
import { FunctionComponent } from 'react'

type ISkeleton = {
	children?: React.ReactNode
	additionalClass?: string
}

const Skeleton: FunctionComponent<ISkeleton> = ({ additionalClass, children }) => (
	<div
		className={classNames('block animate-pulse bg-charcoal-600 rounded-lg h-12', additionalClass)}
	>
		{children}
	</div>
)

export default Skeleton
