const LoadingSpinner = ({ loadingText = 'Loading...', inline = false, small = false }) => (
	<div
		data-testid='loading-spinner'
		className={`flex ${!inline && 'h-64'} justify-center items-center text-white ${
			small ? 'text-sm' : 'text-2xl'
		}`}
	>
		<span className='fa-solid fa-spinner fa-spin mr-md'></span>
		{loadingText}
	</div>
)

export default LoadingSpinner
