//DEPRECATED
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type PurchaseSliceTypeDeprecated = {
	status: FetchStatus
	error?: string
	purchases: Array<any>
}

const PurchasesInitialState: PurchaseSliceTypeDeprecated = {
	status: FetchStatus.initial,
	error: undefined,
	purchases: [],
}

export const PurchaseSliceDeprecated = createSlice({
	name: 'Purchases',
	initialState: PurchasesInitialState,
	reducers: {
		PURCHASES_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		PURCHASES_LOADED: (state, action: PayloadAction<Array<any>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				purchases: action.payload,
			}
		},
		PURCHASES_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		PURCHASES_RESET: () => {
			return PurchasesInitialState
		},
	},
})

// Action types
export const { PURCHASES_LOADING, PURCHASES_LOADED, PURCHASES_ERROR, PURCHASES_RESET } =
	PurchaseSliceDeprecated.actions

// Selectors
export const selectPurchasesStatus = (state: RootState) => state.purchases.status
export const selectPurchasesError = (state: RootState) => state.purchases.error
export const selectAllPurchases = (state: RootState) => state.purchases.purchases
export const selectAllScope1Purchases = (state: RootState) =>
	state.purchases.purchases.filter((purchase) => Number(purchase.item.scope) === 1)
export const selectAllScope2Purchases = (state: RootState) =>
	state.purchases.purchases.filter((purchase) => Number(purchase.item.scope) === 2)

export default PurchaseSliceDeprecated.reducer
