import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invitation } from '../../types/Invitation'
import { PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type CreateSupplyChainInvitationsState = {
	status: PostStatus
	error?: string
	invite?: Invitation
	email?: string
	companyId?: string
	sendEmail?: boolean
}
type invitationToSend = {
	email?: string
	companyId?: string
	sendEmail?: boolean
}
const createSupplyChainInvitationsState: CreateSupplyChainInvitationsState = {
	status: PostStatus.initial,
	error: undefined,
	invite: undefined,
	email: '',
	companyId: '',
	sendEmail: true,
}

export const createSupplyChainInvitationsSlice = createSlice({
	name: 'createSupplyChainInvitations',
	initialState: createSupplyChainInvitationsState,
	reducers: {
		SUPPLYCHAIN_INVITATIONS_CREATE_SENDING: (state) => {
			return {
				...state,
				status: PostStatus.sending,
			}
		},
		SUPPLYCHAIN_INVITATIONS_CREATE_SENT: (state, action: PayloadAction<Invitation>) => {
			return {
				...state,
				status: PostStatus.sent,
				invite: action.payload,
			}
		},
		SUPPLYCHAIN_INVITATIONS_CREATE_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.error,
				error: action.payload,
			}
		},
		SUPPLYCHAIN_INVITATIONS_CREATE_RESET: () => {
			return createSupplyChainInvitationsState
		},
		SUPPLYCHAIN_INVITATION_SETEMAIL: (state, action: PayloadAction<invitationToSend>) => {
			return {
				...state,
				email: action.payload.email,
				companyId: action.payload.companyId,
				sendEmail: action.payload.sendEmail,
			}
		},
		SUPPLYCHAIN_INVITATION_RESETEMAIL: (state) => {
			return {
				...state,
				email: undefined,
				companyId: undefined,
				sendEmail: true,
			}
		},
	},
})

// Action types
export const {
	SUPPLYCHAIN_INVITATIONS_CREATE_SENDING,
	SUPPLYCHAIN_INVITATIONS_CREATE_SENT,
	SUPPLYCHAIN_INVITATIONS_CREATE_ERROR,
	SUPPLYCHAIN_INVITATIONS_CREATE_RESET,
	SUPPLYCHAIN_INVITATION_SETEMAIL,
	SUPPLYCHAIN_INVITATION_RESETEMAIL,
} = createSupplyChainInvitationsSlice.actions

// Selectors
export const selectSupplyChainInvitationsStatus = (state: RootState) =>
	state.createSupplyChainInvitations.status
export const selectSupplyChainInvicationsError = (state: RootState) =>
	state.createSupplyChainInvitations.error
export const selectSupplyChainInvitation = (state: RootState) =>
	state.createSupplyChainInvitations.invite
export const selectSupplyChainInviteEmail = (state: RootState) =>
	state.createSupplyChainInvitations.email
export const selectSupplyChainInviteCompanyID = (state: RootState) =>
	state.createSupplyChainInvitations.companyId
export const selectSupplyChainInviteSendEmail = (state: RootState) =>
	state.createSupplyChainInvitations.sendEmail

export default createSupplyChainInvitationsSlice.reducer
