import classNames from 'classnames'
import { FunctionComponent, useState } from 'react'
import { useEffectWithoutInitialRender } from '../../../hooks/useEffectWithoutInitialRender'
import { SortDirection } from '../../../types/SortDirection'

interface TableData {
	[key: string]: any
	_id: string
	_group?: TableData[]
}

export interface TableColumn {
	id: string
	label?: string
	sortable?: boolean
	columnClass?: string
	textAlign?: 'right' | 'center' | 'left'
	renderer?(columnKey: string, data: TableData): JSX.Element
}

interface ISimpleSortTable {
	data: Array<TableData>
	columns: Array<TableColumn>
	defaultSorting?: {
		key: string
		direction: SortDirection
	}
	additionalClass?: string
	onSortPress?: (key: string, direction: string) => void
	isLoading?: boolean
}

const SimpleSortTable: FunctionComponent<ISimpleSortTable> = ({
	data,
	columns,
	defaultSorting,
	additionalClass,
	onSortPress,
	isLoading,
}) => {
	const defaultSortBy = defaultSorting?.key || columns[0].id
	const defaultDirection = defaultSorting?.direction || ('descending' as SortDirection)
	const [sortingBy, setSortingBy] = useState<string>(defaultSortBy)
	const [direction, setDirection] = useState<SortDirection>(defaultDirection)

	const oppositeDirection = (direction: SortDirection) => {
		return direction === 'ascending' ? 'descending' : 'ascending'
	}

	const renderSortIcon = (key: string, activeKey: string, direction: SortDirection) => {
		if (activeKey === key) {
			// We are sorting by this key so should show an arrow
			if (direction === 'ascending') {
				// Ascending
				return <span className='fa-regular fa-arrow-up-short-wide text-charcoal-100 mr-2' />
			} else {
				// Descending
				return <span className='fa-regular fa-arrow-down-wide-short text-charcoal-100 mr-2' />
			}
		}
		// Otherwise show generic sorting icon
		return <span className='fa-regular fa-bars-sort text-charcoal-300 mr-2' />
	}

	// Custom hook to skip the first render using the callback
	useEffectWithoutInitialRender(() => {
		onSortPress && onSortPress(sortingBy, direction)
	}, [sortingBy, direction])

	// Loading skeleton
	if (isLoading) {
		return (
			<div className={classNames(additionalClass, 'animate-pulse bg-charcoal-800')}>
				{/* Header skeleton */}
				<div
					className={`grid grid-cols-${columns.length} w-full bg-charcoal-800 text-charcoal-050`}
				>
					{columns.map((col, index) => (
						<div
							key={index}
							className={classNames(col.columnClass, 'my-3 mx-2 bg-charcoal-700 rounded-lg h-5')}
						></div>
					))}
				</div>
				{/* Row skeleton */}
				<div className={`grid grid-cols-${columns.length} w-full bg-charcoal-700 h-96`} />
			</div>
		)
	}

	return (
		<div className={classNames('table border-collapse w-full', additionalClass)} role='table'>
			{/* Header row */}
			<div
				role='row'
				className='table-row w-full bg-charcoal-800 text-charcoal-050 border-b border-charcoal-050'
			>
				{columns.map((column, index) => (
					<div
						key={index}
						role='columnheader'
						className={classNames('p-3 table-cell', column.columnClass)}
					>
						{column.sortable ? (
							<button
								className={classNames('flex', {
									'justify-start': column.textAlign === 'left',
									'justify-end': column.textAlign === 'right',
									'justify-center': column.textAlign === 'center',
									'w-full': column.textAlign !== void 0,
								})}
								onClick={() => {
									if (sortingBy === column.id) {
										// Key is active, cycle sorting order
										if (oppositeDirection(direction) === 'ascending') {
											setDirection('ascending')
										} else {
											// Key has been through both directions, set to default column and order
											if (sortingBy === defaultSortBy) {
												// Exception to rule as we're already sorting default sort column so just flip direction
												setDirection(oppositeDirection(direction))
											} else {
												// Otherwise set sort to starting defaults
												setDirection(defaultDirection)
												setSortingBy(defaultSortBy)
											}
										}
									} else {
										// Key not active yet, so let's set it to the first sort direction and activate it
										setDirection('descending')
										setSortingBy(column.id)
									}
								}}
							>
								{renderSortIcon(column.id, sortingBy, direction)}
								<span className='text-xs'>{column.label}</span>
							</button>
						) : (
							<span
								className={classNames('flex text-xs', {
									'justify-start': column.textAlign === 'left',
									'justify-end': column.textAlign === 'right',
									'justify-center': column.textAlign === 'center',
									'w-full': column.textAlign !== void 0,
								})}
							>
								{column.label}
							</span>
						)}
					</div>
				))}
			</div>

			{/* Data rows */}
			{data.length > 0 && (
				<>
					{/* For every row of data... */}
					{data.map((row, index) => {
						return (
							<div
								key={index}
								role='row'
								className='table-row w-full bg-charcoal-700 text-white border-b border-charcoal-050'
							>
								{/* And every column in the row... */}
								{columns.map((col, index2) => {
									return (
										<div
											key={index2}
											role='cell'
											className={classNames(
												'table-cell align-middle text-sm p-3',
												col.columnClass,
												{
													'text-right': col.textAlign === 'right',
													'text-center': col.textAlign === 'center',
													'text-left': col.textAlign === 'left',
												}
											)}
										>
											{col.renderer ? col.renderer(col.id, row) : row[col.id]}
										</div>
									)
								})}
							</div>
						)
					})}
				</>
			)}
		</div>
	)
}

export default SimpleSortTable
