import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { Invitation } from '../../types/Invitation'
import {
	SENT_INVITATIONS_ERROR,
	SENT_INVITATIONS_FAILURE_UPDATE,
	SENT_INVITATIONS_FETCHING,
	SENT_INVITATIONS_INSERT,
	SENT_INVITATIONS_SUCCESS,
	SENT_INVITATIONS_UPDATED_RESEND,
	SENT_INVITATIONS_UPDATED_REVOKED,
	SENT_INVITATIONS_UPDATING,
} from '../slices/companySentInvitationsSlice'
import {
	SUPPLYCHAIN_INVITATIONS_CREATE_ERROR,
	SUPPLYCHAIN_INVITATIONS_CREATE_SENDING,
	SUPPLYCHAIN_INVITATIONS_CREATE_SENT,
	SUPPLYCHAIN_INVITATION_RESETEMAIL,
	SUPPLYCHAIN_INVITATION_SETEMAIL,
} from '../slices/createSupplyChainInvitation'
import { NeoniApi } from '../../lib/api'

export const getSentInvitations = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: SENT_INVITATIONS_FETCHING,
	})

	try {
		const body = await api.get(`/supplyChainInvitations/sent`)
		body.invitations.sort((a: Invitation, b: Invitation) => (a.createdAt > b.createdAt ? -1 : 1))

		dispatch({
			type: SENT_INVITATIONS_SUCCESS,
			payload: body.invitations,
		})
	} catch (error) {
		dispatch({
			type: SENT_INVITATIONS_ERROR,
			payload: error,
		})
	}
}

export const revokeInvitationCompany =
	(api: NeoniApi, invitationId: string, status: string) => async (dispatch: Dispatch) => {
		// Mark invitation as update in progress
		dispatch({
			type: SENT_INVITATIONS_UPDATING,
			payload: invitationId,
		})

		try {
			const csrf = await getCSRFToken()
			const body = await api.put(`supplyChainInvitations/${invitationId}`, {
				status, _csrf: csrf
			});

			if (body.success === true) {
				// Successfully revoked
				dispatch({
					type: SENT_INVITATIONS_UPDATED_REVOKED,
					payload: body.invitation,
				})
			} else {
				// Error
				dispatch({
					type: SENT_INVITATIONS_FAILURE_UPDATE,
					payload: { id: invitationId, error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: SENT_INVITATIONS_FAILURE_UPDATE,
				payload: { id: invitationId, error: error },
			})
		}
	}

export const resendInvitationCompany = (api: NeoniApi, invitationId: string) => async (dispatch: Dispatch) => {
	// Mark invitation as update in progress
	dispatch({
		type: SENT_INVITATIONS_UPDATING,
		payload: invitationId,
	})

	try {
		const csrf = await getCSRFToken()
		const body = await api.post(`supplyChainInvitations/resend`, {
			inviteId: invitationId, _csrf: csrf
		});

		if (body.success === true) {
			// Successfully revoked
			dispatch({
				type: SENT_INVITATIONS_UPDATED_RESEND,
				payload: { revokedInvite: body.revokedInvite, invite: body.invitation },
			})
		} else {
			// Error
			dispatch({
				type: SENT_INVITATIONS_FAILURE_UPDATE,
				payload: { id: invitationId, error: body.message },
			})
		}
	} catch (error) {
		dispatch({
			type: SENT_INVITATIONS_FAILURE_UPDATE,
			payload: { id: invitationId, error: error },
		})
	}
}

export const createInvitationCompany =
	(api: NeoniApi, companyId: string, email: string, sendEmail: boolean, supplierId?: string) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: SUPPLYCHAIN_INVITATIONS_CREATE_SENDING,
			})
			dispatch({
				type: SUPPLYCHAIN_INVITATION_SETEMAIL,
				payload: { companyId, email, supplierId, sendEmail },
			})

			const csrf = await getCSRFToken()

			const body = await api.post(`supplyChainInvitations`, {
				companyId, email, supplierId, _csrf: csrf, sendEmail
			});

			if (body.success === true) {
				// Successfully invited
				dispatch({
					type: SUPPLYCHAIN_INVITATIONS_CREATE_SENT,
					payload: body.invitation,
				})
				dispatch({
					type: SENT_INVITATIONS_INSERT,
					payload: body.invitation,
				})
				dispatch({
					type: SUPPLYCHAIN_INVITATION_RESETEMAIL,
				})
			} else {
				// Error
				dispatch({
					type: SUPPLYCHAIN_INVITATIONS_CREATE_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: SUPPLYCHAIN_INVITATIONS_CREATE_ERROR,
				payload: error,
			})
		}
	}
