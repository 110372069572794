import { FunctionComponent } from 'react'
import { NumericFormat } from 'react-number-format'
import { centsToCurrency, getCurrencySymbol } from '../../../lib/utils'
import useCurrency from '../../../hooks/useCurrency'
import { CurrencyCode } from '../../../types/currency'

type ICurrencyInput = {
	errors?: any
	name: string
	defaultValue?: number
	placeholder?: string
	disabled?: boolean
	onChange?: (value: string) => void
	currencyCode?: CurrencyCode,
}

const CurrencyInput: FunctionComponent<ICurrencyInput> = ({
	errors,
	defaultValue,
	placeholder,
	disabled,
	onChange,
	currencyCode,
}) => {
	const companyCurrency = useCurrency();
	currencyCode = currencyCode || companyCurrency;

	return (
		<>
			<div className='relative'>
				<div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
					<span className='text-charcoal-500 sm:text-sm fa-light'>
						{ getCurrencySymbol(currencyCode) }
					</span>
				</div>
				<div>
					<NumericFormat
						required
						aria-required
						disabled={disabled}
						className={`pl-7 pr-12 block w-full rounded focus:outline-none border-charcoal-200 focus:border-charcoal-200 focus:ring-0 ${errors && errors.turnover
							? 'border-error-500 bg-error-050 text-error-800 focus:border-error-500'
							: 'border-charcoal-200'
							} ${disabled
								? 'border-charcoal-500 bg-charcoal-050/75 text-charcoal-800 focus:border-charcoal-500'
								: ''
							}`}
						allowLeadingZeros
						thousandSeparator=','
						placeholder={placeholder}
						defaultValue={centsToCurrency(defaultValue)}
						onChange={(newValue) => onChange && onChange(newValue.target.value)}
					/>
				</div>
			</div>
			<div>
				{errors && errors.turnover && (
					<p className='mt-2 text-sm text-error-700'>
						{errors.turnover.type === 'required' && 'This field is required'}
					</p>
				)}
			</div>
		</>
	)
}

export default CurrencyInput
