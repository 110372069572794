import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../../../components/BaseComponents'
import PageHeadings from '../../../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../../../components/BaseComponents/PageTemplate/PageTemplate'
import TabList from '../../../../components/BaseComponents/TabList/TabList'
import routes from '../../../../components/Router/routes'
import { getSupplier } from '../../../../redux/actions/suppliersActions'
import { getSupplierPeriodStatistics } from '../../../../redux/actions/supplierStatisticsActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/reduxHooks'
import {
	selectIndividualSupplier,
	selectSuppliersStatus,
	SUPPLIERS_RESET,
} from '../../../../redux/slices/suppliersSlice'
import {
	selectAllSupplierStatistics,
	selectSupplierStatisticsStatus,
	SUPPLIERSTATS_RESET,
} from '../../../../redux/slices/supplierStatisticsSlice'
import { FetchStatus } from '../../../../types/LoadingStates'
import SupplierInsightsOverviewGraph from './Graphs/Overview'
import SupplierInsightsEmissionQualityGraph from './Graphs/QualityRating'
import SupplierInsightsScope1Graph from './Graphs/Scope1'
import SupplierInsightsScope2Graph from './Graphs/Scope2'
import SupplierInsightsScope3Graph from './Graphs/Scope3'
import useApi from '../../../../hooks/useApi'

// STILL TODO:
// 	- Convert data to intensity based percentages (even though the raw kgc02e data will be in the frontend too lol)

const CompanyInsightsSupplierTrends = () => {
	const { supplierId } = useParams()
	const dispatch = useAppDispatch()
	const api = useApi();

	const supplierEndpointStatus = useAppSelector(selectSuppliersStatus)
	const endpointStatus = useAppSelector(selectSupplierStatisticsStatus)
	const allSupplierStatistics = useAppSelector(selectAllSupplierStatistics)
	const supplierData = useAppSelector(selectIndividualSupplier)

	// Reset data
	useEffect(() => {
		dispatch({
			type: SUPPLIERS_RESET,
		})
		dispatch({
			type: SUPPLIERSTATS_RESET,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Data loading - Single Supplier and Statistics
	useEffect(() => {
		if (supplierEndpointStatus === FetchStatus.initial) {
			dispatch(getSupplier(api, supplierId))
		}
		if (endpointStatus === FetchStatus.initial) {
			if (supplierId) {
				dispatch(getSupplierPeriodStatistics(api, supplierId))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplierEndpointStatus, endpointStatus])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyInsightsSupplierTrends.name}</title>
			</Helmet>

			{supplierEndpointStatus === FetchStatus.loaded && (
				<PageHeadings title='Insights' subTitle='Supplier' subSubTitle={supplierData?.name}
					routeBack={routes.companyInsightsSuppliers.path} />
			)}

			{endpointStatus === FetchStatus.loading ||
				(supplierEndpointStatus === FetchStatus.loading && (
					<LoadingSpinner loadingText='Loading supplier statistics...' />
				))}

			{endpointStatus === FetchStatus.loaded && supplierEndpointStatus === FetchStatus.loaded && (
				<>
					{allSupplierStatistics.length > 0 ? (
						<TabList
							tabDescription='Emission breakdowns by scope'
							additionalPanelClass='p-4'
							tabs={[
								{
									label: 'Total Emissions by Scopes',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>
												Total Emissions by Scopes
											</h2>
											<div className='p-4 max-w-6xl'>
												{active && (
													<SupplierInsightsOverviewGraph chartData={allSupplierStatistics} />
												)}
											</div>
										</>
									),
								},
								{
									label: 'Scope 1',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>Scope 1 Breakdown</h2>
											<div className='p-4 max-w-6xl'>
												{active && (
													<SupplierInsightsScope1Graph chartData={allSupplierStatistics} />
												)}
											</div>
										</>
									),
								},
								{
									label: 'Scope 2',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>Scope 2 Breakdown</h2>
											<div className='p-4 max-w-6xl'>
												{active && (
													<SupplierInsightsScope2Graph chartData={allSupplierStatistics} />
												)}
											</div>
										</>
									),
								},
								{
									label: 'Scope 3 by Sector',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>
												Scope 3 Breakdown by Sector
											</h2>
											<div className='p-4 max-w-6xl'>
												{active && (
													<SupplierInsightsScope3Graph chartData={allSupplierStatistics} />
												)}
											</div>
										</>
									),
								},
								{
									label: 'Data Quality',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>
												Data Quality
											</h2>
											<div className='p-4 max-w-6xl'>
												{active && <SupplierInsightsEmissionQualityGraph chartData={allSupplierStatistics} />}
											</div>
										</>
									),
								},
							]}
							additionalContainerClass='mt-8'
						/>
					) : (
						<div className='rounded-lg bg-charcoal-600 p-4 mt-8'>
							<p className='text-white'>
								Sorry! We couldn't find any completed accounting periods to compare
							</p>
						</div>
					)}
				</>
			)}

			{endpointStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching accounting periods associated with this organisation. If this
						problem persists, please contact the Neoni team.
					</p>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyInsightsSupplierTrends
