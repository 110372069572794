import { useState } from "react";
import useApi from "../hooks/useApi";
import { getCSRFToken } from "../lib/utils";
import { LoadingSpinner } from "./BaseComponents";
import ConfirmationModal from "./BaseComponents/ConfirmationModal";
import PageHeadings from "./BaseComponents/PageHeadings/PageHeadings";

type RequestAccessModalProps = {
    companyName: string;
    companyId: string;
    onClose: () => void,
    open?: boolean
    onDone: () => void;
}

function RequestAccessModal({companyName, companyId, onClose, onDone, open}: RequestAccessModalProps) {
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const onConfirm = async () => {
        setLoading(true);
        await api.post('/accountingClients/', {
            _csrf: await getCSRFToken(),
            companyId,
        })
        onDone()
        setLoading(false);
    }


    return (
        <ConfirmationModal onCancel={onClose} onConfirm={onConfirm} open={!!open}>
            <PageHeadings title="Request access" />
            { loading ? (
                <LoadingSpinner />
            ) : (
                <p className="text-white">Request permission to manage organisation "{companyName}"?</p>
            )}
        </ConfirmationModal>
    )
}

export default RequestAccessModal;
