import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../components/BaseComponents'
import { FormEvent, useState } from 'react';
import usePublicApi from '../hooks/usePublicApi';
import { getCSRFToken } from '../lib/utils';
import routes from '../components/Router/routes';

function Login() {
    const publicApi = usePublicApi();
    const navigate = useNavigate();

    const {state: navState } = useLocation();

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(navState ? navState.error : '');
    const message = navState?.message;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onFormSubmit = (e: FormEvent) => {
       e.preventDefault();
       onSubmit();
       return false; 
    }

    const onSubmit = async () => {
        setSubmitting(true)
        setError('');

        try {
            await publicApi.post('/user/login', {
                email, password,
                _csrf: await getCSRFToken(),
            });
            navigate(routes.landingPage.path)
        } catch(error: any) {
            console.warn(error)
            setSubmitting(false);
			const { loginError } = error.body || {};
            setError(loginError)
        }
    }
    return (
        <div className="content w-[32rem] mx-auto mt-16">
            <div className="text-charcoal-100 font-bold text-2xl text-center">Welcome</div>
            <div className="flex text-center mt-8 text-sm">
                <Link className="w-1/2 text-charcoal-100 p-2" to={routes.publicSignUp.path}>Create an account</Link>
                <Link className="w-1/2 border-algae-500 p-2 border-b-2 text-white" to={routes.publicLogin.path}>Log in</Link>
            </div>
            { message && (
                <div className='bg-algae-500 rounded p-4 mt-6 text-sm'>
                    <span className='fa-regular fa-circle-exclamation pr-4' />
                    { message }
                </div>
            )}
            <form onSubmit={onFormSubmit}>
                <label className="block mt-8 text-sm">
                    <span className="text-charcoal-100">Organisation email address</span>
                    <br />
                    <input
                        disabled={submitting}
                        name="email"
                        className="rounded w-full mt-1 text-sm"
                        type="text"
                        placeholder="you@example.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </label>
                <label className="block mt-8 text-sm">
                    <span className="text-charcoal-100">Password</span>
                    <br />
                    <input
                        disabled={submitting}
                        name="password"
                        className="rounded w-full mt-1 text-sm"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </label>
                { error && (
                    <div className='bg-error-400 rounded p-4 mt-6 text-sm'>
                        <span className='fa-regular fa-circle-exclamation pr-4' />
                        { error }
                    </div>
                )}

                <div className="mt-4">
                    <Link
                        className="underline text-algae-500"
                        to={routes.forgotPassword.path}
                    >
                        Forgot password?
                    </Link>
                </div>
                <Button
                    disabled={submitting}
                    onClick={onSubmit}
                    type="Primary"
                    text="Login"
                    size="sm"
                    additionalClass='mt-2 w-full'
                />
                <button type="submit" value="Submit" className="hidden" />
            </form>
        </div>
    );
}

export default Login
