import { useState } from "react";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

type DeletePortfolioEntryModalProps = {
    onClose: () => void,
    onDeleted: () => void
    isOpen?: boolean,
    portfolioId: string,
    portfolioName: string,
    companyId: string,
    companyName: string,
};

function DeletePortfolioEntryModal({onClose, onDeleted, isOpen, portfolioId, portfolioName, companyId, companyName}: DeletePortfolioEntryModalProps) {
    const api = useApi();
    const [loading, setLoading] = useState(false);

    const deletePortfolio = async () => {
        setLoading(true);
        await api.post(`/portfolios/${portfolioId}/remove/${companyId}`, {
            _csrf: await getCSRFToken(),
        })
        setLoading(false);
        onDeleted();
    }


    return (
        <Modal open={!!isOpen} onClose={onClose} size="small">
            <div className="flex flex-col">
                <PageHeadings title={`Remove Organisation from portfolio ${portfolioName}?`} />
                { loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <p className="text-white mt-8">
                            Remove organisation {companyName} from this portfolio?
                        </p>
                        <div className="mt-8 grow">
                            <Button onClick={deletePortfolio} additionalClass="mr-5" type="Primary" text="Delete Portfolio" />
                            <Button onClick={onClose} type="Outlined" text="Cancel" />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default DeletePortfolioEntryModal;
