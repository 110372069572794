import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'

const CompanySupport = () => (
	<PageTemplate>
		<PageHeadings title='Help & Support' />

		<div className='mt-4 p-4 bg-charcoal-600 rounded'>
			<p className='text-white mb-4'>
				Please start by visiting our <a className="text-algae-300" href="http://help.neoni-app.com/">Help Centre</a> for guidance.
			</p>

			<p className='text-white'>
				If this has not solved your issue, you can contact someone in our Support Team by emailing <a className="text-algae-300" href="mailto:support@neoni-app.com">support@neoni-app.com.</a> This will automatically raise a support ticket in our system and we will get back to you as soon as possible.
			</p>
		</div>
	</PageTemplate>
)

export default CompanySupport
