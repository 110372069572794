type CompanyInviteCardProps = {
	activeResult?: object
	result: {
		name: String
		company_number: String
		address: String
	}
	isInteractable: Boolean
	setActiveResult?: Function
	clickHandler?: Function
	additionalClasses?: string
}

function CompanyInviteCard(props: CompanyInviteCardProps) {
	return (
		<div
			className={`p-xl ${
				props.activeResult === props.result
					? 'bg-algae-100 ring ring-algae-500 '
					: 'bg-white  mt-md'
			} ${props.additionalClasses && props.additionalClasses} `}
			role={props.isInteractable ? 'button' : ''}
			onClick={() => {
				if (props.isInteractable) {
					if (props.clickHandler) props.clickHandler()
				}
			}}
		>
			<p className='text-charcoal-900 leading-5 text-sm font-medium'>{props.result.name}</p>
			<p className='text-charcoal-900 leading-5 text-sm font-medium mb-3'>
				{props.result.company_number}
			</p>
			<p className='text-charcoal-400 leading-5 text-sm'>{props.result.address}</p>
		</div>
	)
}

export default CompanyInviteCard
