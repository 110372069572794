import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type fileToUpload = {
	file: string
	accountingPeriodId: string
	filename: string
}

type UploadError = {
	reference: string
	text: string
}

type AddPurchasesUploadSlice = {
	status: PostStatus
	error?: string
	file?: fileToUpload
	fileErrors?: Array<UploadError>
	fileAccountingPeriodId?: string
	fileAttempts?: number
	fileRecords?: number
}
const AddPurchasesUploadInitialState: AddPurchasesUploadSlice = {
	status: PostStatus.initial,
	error: undefined,
	file: undefined,
	fileErrors: undefined,
	fileAccountingPeriodId: undefined,
	fileAttempts: undefined,
	fileRecords: undefined,
}

export const addPurchasesUploadSlice = createSlice({
	name: 'addPurchasesUpload',
	initialState: AddPurchasesUploadInitialState,
	reducers: {
		ADDPURCHASESUPLOAD_SENDING: (state) => {
			return {
				...state,
				status: PostStatus.sending,
			}
		},
		ADDPURCHASESUPLOAD_SENT: (
			state,
			action: PayloadAction<{
				accountingPeriodId: string
				attempts: number
				errors: Array<UploadError>
				numberOfRecords: number
			}>
		) => {
			return {
				...state,
				status: PostStatus.sent,
				error: undefined,
				fileErrors: action.payload.errors,
				fileAccountingPeriodId: action.payload.accountingPeriodId,
				fileAttempts: action.payload.attempts,
				fileRecords: action.payload.numberOfRecords,
			}
		},
		ADDPURCHASESUPLOAD_FAILURE: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.error,
				error: action.payload,
			}
		},
		ADDPURCHASESUPLOAD_RESET: () => {
			return AddPurchasesUploadInitialState
		},
		ADDPURCHASESUPLOAD_SETFILE: (state, action: PayloadAction<fileToUpload>) => {
			return {
				...state,
				file: action.payload,
			}
		},
		ADDPURCHASESUPLOAD_RESETFILE: (state) => {
			return {
				...state,
				file: undefined,
			}
		},
	},
})

// Action types
export const {
	ADDPURCHASESUPLOAD_SENDING,
	ADDPURCHASESUPLOAD_SENT,
	ADDPURCHASESUPLOAD_FAILURE,
	ADDPURCHASESUPLOAD_RESET,
	ADDPURCHASESUPLOAD_SETFILE,
	ADDPURCHASESUPLOAD_RESETFILE,
} = addPurchasesUploadSlice.actions

// Selectors
export const selectAddPurchasesUploadStatus = (state: RootState) => state.addPurchasesUpload.status
export const selectAddPurchasesUploadError = (state: RootState) => state.addPurchasesUpload.error
export const selectAddPurchasesUploadFile = (state: RootState) =>
	state.addPurchasesUpload.file?.file
export const selectAddPurchasesUploadId = (state: RootState) =>
	state.addPurchasesUpload.file?.accountingPeriodId

export const selectAddPurchasesUploadName = (state: RootState) =>
	state.addPurchasesUpload.file?.filename
export const selectAddPurchasesFileErrors = (state: RootState) =>
	state.addPurchasesUpload.fileErrors
export const selectAddPurchasesFileErrorCount = (state: RootState) =>
	state.addPurchasesUpload.fileErrors?.length || 0
export const selectAddPurchasesFileRecords = (state: RootState) =>
	state.addPurchasesUpload.fileRecords
export const selectAddPurchasesAccountingId = (state: RootState) =>
	state.addPurchasesUpload.fileAccountingPeriodId

// Selector for detecting successful vs error filled upload
export const selectAddPurchasesUploadSuccessful = (state: RootState) => {
	if (state.addPurchasesUpload.fileErrors) {
		if (state.addPurchasesUpload.fileErrors.length > 0) return false
	}
	return true
}

// Selector for targeting the accounting period of the uploaded file
export const selectAddPurchasesFileAccountingPeriod = (state: RootState) => {
	return state.accountingPeriods.periods.filter((period) => {
		if (period.id === state.addPurchasesUpload.fileAccountingPeriodId) {
			return true
		}
		return false
	})[0]
}

export default addPurchasesUploadSlice.reducer
