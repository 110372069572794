import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, Input, LoadingSpinner } from '../components/BaseComponents'
import routes from '../components/Router/routes'
import { centsToCurrency, currencyToCents } from '../lib/utils'
import { Period } from '../types/AccountingPeriods'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	createAccountingPeriod,
	deleteAccountingPeriod,
	getAccountingPeriods,
	updateAccountingPeriods,
} from '../redux/actions/accountingPeriodsActions'
import {
	ACCOUNTINGPERIODS_CREATE_RESET,
	ACCOUNTINGPERIODS_EDITING_RESET,
	ACCOUNTINGPERIODS_SET_NAME,
	selectAccountingPeriods,
	selectAccountingPeriodsCreateStatus,
	selectAccountingPeriodsEditStatus,
	selectAccountingPeriodsError,
	selectAccountingPeriodsLastCreated,
	selectAccountingPeriodsStatus,
} from '../redux/slices/accountingPeriodsSlice'
import Modal from '../components/BaseComponents/Modal/Modal'
import CurrencyInput from '../components/BaseComponents/Forms/CurrencyInput'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import { ADDPURCHASESUPLOAD_RESET } from '../redux/slices/addPurchasesUploadSlice'
import SimpleSortTable, { TableColumn } from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import { PurchaseUploadState, PurchaseUploadStateDisplay } from '../enums/PurchaseUploadState'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import useCurrencyFormatter from '../hooks/useCurrencyFormatter'
import useApi from '../hooks/useApi'
import useCurrency from '../hooks/useCurrency'

export enum accountingPeriodStatusEnum {
	WAITING_FOR_UPLOAD = 'WAITING_FOR_UPLOAD',
	UPLOADED = 'UPLOADED',
	WIP = 'WIP',
	PROCESSING_CSV = 'PROCESSING_CSV ',
	UPLOAD_ERROR = 'UPLOAD_ERROR ',
	FINALIZED = 'FINALIZED',
	READY_FOR_AUDIT = 'READY_FOR_AUDIT',
	AUDIT_IN_PROGRESS = 'AUDIT_IN_PROGRESS',
	AUDITED = 'AUDITED',
	AUDITED_AND_COMPLETED = 'AUDITED_AND_COMPLETED'
}

function AddAccountingPeriods() {
	const dayjs = require('dayjs')
	const today = new Date()

	const formatCurrency = useCurrencyFormatter();
	const currency = useCurrency()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	useEffect(() => {
		dispatch(getAccountingPeriods(api))
		dispatch({
			type: ADDPURCHASESUPLOAD_RESET,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const accountingPeriodsData = useAppSelector(selectAccountingPeriods)
	const accountingPeriodsStatus = useAppSelector(selectAccountingPeriodsStatus)
	const accountingPeriodsLoadingError = useAppSelector(selectAccountingPeriodsError)
	const accountingPeriodsCreateStatus = useAppSelector(selectAccountingPeriodsCreateStatus)
	const accountingPeriodsLastCreated = useAppSelector(selectAccountingPeriodsLastCreated)

	const [newPeriodStartDate, setNewPeriodStartDate] = useState(dayjs(today).format('YYYY-MM-DD'))
	const [newPeriodEndDate, setNewPeriodEndDate] = useState(
		dayjs(today).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
	)

	const [newPeriodTurnover, setNewPeriodTurnover] = useState(0)
	const [newPeriodPurchaseTarget, setNewPeriodPurchaseTarget] = useState(0)
	const [newPeriodValid, setNewPeriodValid] = useState(true)
	const [newPeriodTurnoverValid, setNewPeriodTurnoverValid] = useState(true)
	const [newPeriodEndDateTouched, setNewPeriodEndDateTouched] = useState(false)

	const [addAccountingPeriodModalOpen, setAddAccountingPeriodModalOpen] = useState<boolean>(false)
	const [editAccountingPeriodModalOpen, setEditAccountingPeriodModalOpen] = useState<boolean>(false)
	const [updateAccountingPeriod, setUpdateAccountingPeriod] = useState<Period | undefined>(undefined)
	const [editPeriodStartDate, setEditPeriodStartDate] = useState(dayjs(today).format('YYYY-MM-DD'))
	const [editPeriodValid, setEditPeriodValid] = useState(true)
	const [editPeriodEndDate, setEditPeriodEndDate] = useState(
		dayjs(today).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
	)
	const [editPeriodTurnover, setEditPeriodTurnover] = useState<number | undefined>(0)
	const [editPeriodPurchaseTarget, setEditPeriodPurchaseTarget] = useState<number | undefined>(0)
	const [editPeriodTurnoverValid, setEditPeriodTurnoverValid] = useState(true)
	const [editConfirmationModalOpen, setEditConfirmationModalOpen] = useState<boolean>(false)

	const accountingPeriodsEditStatus = useAppSelector(selectAccountingPeriodsEditStatus)



	const resetAccountingPeriodsCreation = () => {
		dispatch({
			type: ACCOUNTINGPERIODS_CREATE_RESET,
		})
		// Reset local state stores used in form
		setNewPeriodValid(true)
		setNewPeriodTurnoverValid(true)
		setNewPeriodStartDate(dayjs(today).format('YYYY-MM-DD'))
		setNewPeriodEndDate(dayjs(today).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD'))
		setNewPeriodEndDateTouched(false)
		setNewPeriodTurnover(0)
	}

	const checkNewAccountingPeriodDatesValid = (startDate: string, endDate: string) => {
		console.log(startDate, endDate);
		if (dayjs(startDate).isAfter(dayjs(endDate))) {
			setNewPeriodValid(false)
		} else {
			setNewPeriodValid(true)
		}
	}
	const checkEditAccountingPeriodDatesValid = (startDate: string, endDate: string) => {
		if (dayjs(startDate).isAfter(dayjs(endDate))) {
			setEditPeriodValid(false)
		} else {
			setEditPeriodValid(true)
		}
	}
	const checkNewPeriodTuronverValidity = (value: string) => {
		if (!value.length || value === null || value === undefined) {
			setNewPeriodTurnoverValid(false)
		} else {
			setNewPeriodTurnoverValid(true)
		}
	}
	const checkEditPeriodTuronverValidity = (value: string) => {
		if (value.length < 6 || value === null || value === undefined) {
			setEditPeriodTurnoverValid(false)
		} else {
			setEditPeriodTurnoverValid(true)
		}
	}
	const editAccountingPeriod = (period: Period) => {
		setEditPeriodStartDate(dayjs(period.start_date, 'DD/MM/YY').format('YYYY-MM-DD'))
		setEditPeriodEndDate(dayjs(period.end_date, 'DD/MM/YY').format('YYYY-MM-DD'))
		setEditPeriodTurnover(period.total_turnover_amount !== undefined ? period.total_turnover_amount : 0)
		setEditPeriodPurchaseTarget(period.total_purchase_spent_target !== undefined ? period.total_purchase_spent_target : 0)
		setUpdateAccountingPeriod(period)
		setEditAccountingPeriodModalOpen(true)
		console.log('editPeriodStartDate', editPeriodStartDate)
	}
	// DataGrid Columns
	const columns: TableColumn[] = [
		{
			id: 'name', label: 'Accounting Period', sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{rowData.startDate} - {rowData.endDate}
						</p>
					</>
				)
			},
		},
		{
			id: 'turnover',
			label: 'Turnover',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{formatCurrency(Number(centsToCurrency(rowData.turnover)))}
						</p>
					</>
				)
			},
		},
		{
			id: 'purchaseSpentTarget',
			label: 'Purchases in Period',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{formatCurrency(Number(centsToCurrency(rowData.purchaseSpentTarget)))}
						</p>
					</>
				)
			},
		},
		{
			id: 'spend',
			label: 'Purchases Uploaded',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{formatCurrency(Number(centsToCurrency(rowData.spend)))}
						</p>
					</>
				)
			},
		},

		{
			id: 'upload_state',
			label: 'Status',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						{typeof rowData.upload_state === 'number' ? (
							<>{PurchaseUploadStateDisplay[rowData.upload_state]}</>
						) : (
							<>Ready for file upload</>
						)}
					</>
				)
			},
		},
		{
			id: 'csvFiles',
			label: 'Upload / View CSVs',
			sortable: false,
			textAlign: 'center',
			renderer: (columnKey, rowData) => {
				return (
					<>
						<Button
							type='Primary'
							size='sm'
							hasLeadingIcon={true}
							icon='fa-regular fa-file-csv'
							additionalClass='ml-2'
							onClick={() => {
								dispatch({ type: ACCOUNTINGPERIODS_SET_NAME, payload: rowData.startDate + '-' + rowData.endDate })
								navigate(generatePath(routes.companyViewAccountingPeriodDetails.path, {accountingPeriodIdVal: rowData._id}))
							}}
						/>
					</>
				)
			},

		},
		{
			id: '_id',
			label: 'Actions',
			sortable: false,
			textAlign: 'center',
			renderer: (columnKey, rowData) => {
				return (
					<>
						<span
							id={rowData._id}
							data-tooltip-content='There are csv files attached to this accounting period. Please delete the csv files to enable the delete button.'
						>
							<Button
								type='Primary'
								size='sm'
								disabled={rowData.csvFiles?.length > 0}
								hasTrailingIcon={true}
								icon='fa-regular fa-trash'
								additionalClass='ml-2'
								onClick={() => dispatch(deleteAccountingPeriod(api, rowData._id))}
							/>
						</span>
						{rowData.csvFiles?.length > 0 &&
							<ReactTooltip variant='light' anchorId={rowData._id} />}
						<Button
							type='Primary'
							size='sm'
							hasTrailingIcon={true}
							icon='fa-regular fa-edit'
							additionalClass='ml-2'
							onClick={() => editAccountingPeriod(rowData.period)}
						/>
					</>
				)
			},
		},
		{
			id: 'period',
			label: 'View / Refine Data',
			textAlign: 'center',
			renderer: (columnKey, rowData) => {
				return (
					<>
						{typeof rowData.upload_state === 'number' ? (
							// There's an upload_state registered, send them to the correct pick-up point
							<Button
								type='Primary'
								size='sm'
								text={
									rowData.upload_state !== PurchaseUploadState.scope3Refined ? 'Refine data' : 'View period'
								}
								onClick={() => {
									// Choose route based on state
									switch (rowData.upload_state) {
										case PurchaseUploadState.fileUploaded:
											navigate(generatePath(routes.companyRefineEmissionsGenerated.path, {accountingPeriodId: rowData._id}))
											break
										case PurchaseUploadState.scope1Selected:
											navigate(generatePath(routes.companyRefineEmissionsGeneratedType.path, {accountingPeriodId: rowData._id}))
											break
										case PurchaseUploadState.scope1Refined:
											navigate(generatePath(routes.companyRefineElectricity.path, {accountingPeriodId: rowData._id}))
											break
										case PurchaseUploadState.scope2Refined:
											navigate(generatePath(routes.companyRefineSuppliers.path, {accountingPeriodId: rowData._id}))
											break
										case PurchaseUploadState.scope3Refined:
											navigate(generatePath(routes.companyCarbonAccounts.path, {accountingPeriodId: rowData._id}))
											break
										default:
											navigate(generatePath(routes.companyRefineEmissionsGenerated.path, {accountingPeriodId: rowData._id}))
									}
								}}
							/>
						) : (
							null
						)}
					</>
				)
			},
		},
		// {
		// 	id: 'secr',
		// 	label: 'Public Reports',
		// 	textAlign: 'center',
		// 	renderer: (columnKey, rowData) => {
		// 		if(rowData.period.secrReport) {
		// 			return (
		// 				<Button
		// 					type='Outlined'
		// 					size='sm'
		// 					text="Review Report"
		// 					isLink
		// 					url={routes.secrList.path + rowData.period.secrReport.id }
		// 				/>
		// 			);
		// 		}
		// 		return (
		// 			<Button
		// 				type='Primary'
		// 				size='sm'
		// 				text="Publish Report"
		// 				onClick={
		// 					() => navigate('/secr/create', { state: { periodId: rowData._id}})
		// 				}
		// 			/>
		// 		)
		// 	},
		// }
	]
	// DataGrid data constructor
	const constructData = (dataSet: Array<Period>) => [
		...dataSet.map((period) => {
			return {
				_id: period.id,
				startDate: period.start_date,
				endDate: period.end_date,
				createdAt: period.created_at,
				turnover: period.total_turnover_amount,
				purchaseSpentTarget: period.total_purchase_spent_target,
				upload_state: period.upload_state,
				csvFiles: period.csvFiles,
				spend: period.total_spend_amount,
				period: period
			}
		}),
	]

	return (
		<PageTemplate testid='add-accounting-periods-main'>
			<Helmet>
				<title>{routes.companyAddAccountingPeriods.name}</title>
			</Helmet>
			<PageHeadings
				title='Add Purchases'
				subTitle='Upload CSV'
				subSubTitle='Accounting Period'
				routeBack={routes.companyPurchases.path}
			/>


			<div className='block'>
				<Button
					type='Primary'
					text='Add Accounting Period'
					size='sm'
					hasTrailingIcon
					icon='fa-regular fa-plus'
					onClick={() => {
						setAddAccountingPeriodModalOpen(!addAccountingPeriodModalOpen)
					}}
					additionalClass='mt-4 mb-2'
				/>
				<Modal
					open={addAccountingPeriodModalOpen}
					onOpen={() => resetAccountingPeriodsCreation()}
					onClose={() => setAddAccountingPeriodModalOpen(false)}
					size='small'
				>
					{accountingPeriodsCreateStatus === PostStatus.initial && (
						<>
							<PageHeadings title='Add a new accounting period' />

							<div className='max-w-2xl'>
								<label className='block text-white text-sm pt-6 pb-4'>
									What is the accounting period for the csv
								</label>

								<Input
									label='From'
									value={newPeriodStartDate}
									type='date'
									name='startDate'
									additionalClass='mt-2'
									onChange={(e) => {
										setNewPeriodStartDate(e.target.value)
										let endDate = newPeriodEndDate;
										if (!newPeriodEndDateTouched) {
											endDate = dayjs(e.target.value).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
											setNewPeriodEndDate(endDate)
										}
										checkNewAccountingPeriodDatesValid(e.target.value, endDate)
									}}
								/>

								<div
									aria-errormessage={`${!newPeriodValid && 'The end date must be after the start date'
										}`}
									aria-invalid={!newPeriodValid}
								>
									<Input
										label='To'
										value={newPeriodEndDate}
										type='date'
										name='endDate'
										additionalClass='mt-2 mb-6'
										onChange={(e) => {
											setNewPeriodEndDate(e.target.value)
											setNewPeriodEndDateTouched(true)
											checkNewAccountingPeriodDatesValid(newPeriodStartDate, e.target.value)
										}}
									/>
								</div>

								{!newPeriodValid && (
									<div className='rounded-lg bg-error-500 p-4 mb-4'>
										<p className='text-white'>
											The end date of an accounting period must be after the start date
										</p>
									</div>
								)}

								<label
									htmlFor='turnover'
									className='block text-sm font-medium text-charcoal-100 my-1'
								>
									Turnover <span className='text-algae-500'>*</span>
								</label>

								<CurrencyInput
									name='turnover'
									placeholder={'0,000,000'}
									onChange={(value) => {
										setNewPeriodTurnover(currencyToCents(value))
										checkNewPeriodTuronverValidity(value)
									}}
								/>

								{!newPeriodTurnoverValid && (
									<div className='rounded-lg bg-error-500 p-4 mb-4 mt-2'>
										<p className='text-white'>Turnover required.</p>
									</div>
								)}

								<label
									htmlFor='purchaseSpentTarget'
									className='block text-sm font-medium text-charcoal-100 my-1 mt-6'
								>
									Total Purchase Spent Target
								</label>

								<CurrencyInput
									name='purchaseSpentTarget'
									placeholder={'0,000,000'}
									onChange={(value) => {
										setNewPeriodPurchaseTarget(currencyToCents(value))
									}}
								/>
							</div>

							<Button
								onClick={() => {
									dispatch(
										createAccountingPeriod(api, {
											startDate: newPeriodStartDate,
											endDate: newPeriodEndDate,
											totalTurnoverCurrency: currency,
											totalTurnoverAmount: newPeriodTurnover,
											totalPurchaseSpentTarget: newPeriodPurchaseTarget
										})
									)
								}}
								type='Primary'
								hasTrailingIcon={true}
								icon='fa-solid fa-plus'
								text='Add reporting period'
								additionalClass='mt-9 mr-5'
								disabled={!newPeriodValid || !newPeriodTurnoverValid || newPeriodTurnover === 0}
							/>
							<Button
								type='Outlined'
								text='Cancel'
								onClick={() => {
									setAddAccountingPeriodModalOpen(!addAccountingPeriodModalOpen)
								}}
							/>
						</>
					)}

					{accountingPeriodsCreateStatus === PostStatus.sending && (
						<LoadingSpinner loadingText='Creating new accounting period...' />
					)}

					{accountingPeriodsCreateStatus === PostStatus.sent && (
						<>
							<PageHeadings title='Successfully created new accounting period' />
							<p className='text-white mt-6'>Your new accounting period has been created:</p>

							<div className='mt-4 bg-white rounded-lg p-4'>
								<p className='text-lg'>
									<strong>Accounting Period:</strong>
								</p>
								<p>
									{accountingPeriodsLastCreated.start_date.toString()} to{' '}
									{accountingPeriodsLastCreated.end_date.toString()}
								</p>
								<p className='mt-4 text-lg'>
									<strong>Turnover for the period:</strong>
								</p>
								<p>
									{centsToCurrency(accountingPeriodsLastCreated.total_turnover_amount)} (
									{accountingPeriodsLastCreated.total_turnover_currency})
								</p>
							</div>

							<Button
								type='Primary'
								text='Back to accounting periods'
								onClick={() => {
									setAddAccountingPeriodModalOpen(false)
								}}
								additionalClass='mt-6 mr-5'
							/>

							<Button
								type='Outlined'
								text='Create another period'
								icon='fa-regular fa-plus'
								hasTrailingIcon
								onClick={() => {
									resetAccountingPeriodsCreation()
								}}
								additionalClass='mt-6'
							/>
						</>
					)}

					{accountingPeriodsCreateStatus === PostStatus.error && (
						<>
							<PageHeadings title='Error' />
							<div className='rounded-lg bg-error-500 p-4 mt-4'>
								<p className='text-white'>
									There was an error whilst trying to create the new accounting period. If this
									problem persists, please contact the Neoni team.
								</p>
							</div>
							<Button
								onClick={() => {
									dispatch(
										createAccountingPeriod(api, {
											startDate: newPeriodStartDate,
											endDate: newPeriodEndDate,
											totalTurnoverCurrency: currency,
											totalTurnoverAmount: newPeriodTurnover,
											totalPurchaseSpentTarget: newPeriodPurchaseTarget
										})
									)
								}}
								type='Primary'
								hasTrailingIcon={true}
								icon='fa-solid fa-arrow-right'
								text='Retry'
								additionalClass='mt-9 mr-5'
							/>
							<Button
								type='Outlined'
								text='Cancel'
								onClick={() => {
									setAddAccountingPeriodModalOpen(false)
								}}
								additionalClass='mt-6 mr-5'
							/>
						</>
					)}
				</Modal>
				<Modal
					open={editAccountingPeriodModalOpen}
					onClose={() => {
						setEditAccountingPeriodModalOpen(false)
						dispatch({ type: ACCOUNTINGPERIODS_EDITING_RESET })
					}}
					size='small'
				>
					<>
						<PageHeadings title='Update accounting period' />

						{accountingPeriodsEditStatus === PostStatus.initial && <>
							<div className='max-w-2xl'>
								<label className='block text-white text-sm pt-6 pb-4'>
									What is the accounting period for the csv
								</label>

								<Input
									label='From'
									disabled={updateAccountingPeriod?.status !== accountingPeriodStatusEnum.UPLOAD_ERROR && updateAccountingPeriod?.status !== accountingPeriodStatusEnum.PROCESSING_CSV && updateAccountingPeriod?.status !== accountingPeriodStatusEnum.WAITING_FOR_UPLOAD}
									value={editPeriodStartDate}
									type='date'
									name='startDate'
									additionalClass='mt-2'
									onChange={(e) => {
										setEditPeriodStartDate(e.target.value)
										checkEditAccountingPeriodDatesValid(e.target.value, editPeriodEndDate)
									}}
								/>

								<div
									aria-errormessage={`${!editPeriodValid && 'The end date must be after the start date'
										}`}
									aria-invalid={!editPeriodValid}
								>
									<Input
										label='To'
										value={editPeriodEndDate}
										type='date'
										name='endDate'
										additionalClass='mt-2 mb-6'
										onChange={(e) => {
											setEditPeriodEndDate(e.target.value)
											checkEditAccountingPeriodDatesValid(editPeriodStartDate, e.target.value)
										}}
									/>
								</div>

								{!editPeriodValid && (
									<div className='rounded-lg bg-error-500 p-4 mb-4'>
										<p className='text-white'>
											The end date of an accounting period must be after the start date
										</p>
									</div>
								)}

								<label
									htmlFor='turnover'
									className='block text-sm font-medium text-charcoal-100 my-1'
								>
									Turnover <span className='text-algae-500'>*</span>
								</label>

								<CurrencyInput
									disabled={updateAccountingPeriod?.status === accountingPeriodStatusEnum.AUDIT_IN_PROGRESS || updateAccountingPeriod?.status === accountingPeriodStatusEnum.AUDITED || updateAccountingPeriod?.status === accountingPeriodStatusEnum.AUDITED_AND_COMPLETED}
									name='turnover'
									placeholder={'0,000,000'}
									defaultValue={editPeriodTurnover}
									onChange={(value) => {
										setEditPeriodTurnover(currencyToCents(value))
										checkEditPeriodTuronverValidity(value)
									}}
								/>

								{!editPeriodTurnoverValid && (
									<div className='rounded-lg bg-error-500 p-4 mb-4 mt-2'>
										<p className='text-white'>Turnover should have atleast 5 digits.</p>
									</div>
								)}

								<label
									htmlFor='purchaseSpentTarget'
									className='block text-sm font-medium text-charcoal-100 my-1 mt-6'
								>
									Total Purchase Spent Target
								</label>

								<CurrencyInput
									disabled={updateAccountingPeriod?.status !== accountingPeriodStatusEnum.UPLOAD_ERROR && updateAccountingPeriod?.status !== accountingPeriodStatusEnum.PROCESSING_CSV && updateAccountingPeriod?.status !== accountingPeriodStatusEnum.WAITING_FOR_UPLOAD && updateAccountingPeriod?.status !== accountingPeriodStatusEnum.UPLOADED}
									name='purchaseSpentTarget'
									defaultValue={editPeriodPurchaseTarget}
									placeholder={'0,000,000'}
									onChange={(value) => {
										setEditPeriodPurchaseTarget(currencyToCents(value))
									}}
								/>
							</div>

							<Button
								onClick={() => {
									if (updateAccountingPeriod?.status === accountingPeriodStatusEnum.FINALIZED && editPeriodTurnover !== updateAccountingPeriod?.total_turnover_amount) {
										setEditConfirmationModalOpen(true)
									}
									else {
										dispatch(
											updateAccountingPeriods(api, {
												startDate: editPeriodStartDate,
												endDate: editPeriodEndDate,
												totalTurnoverCurrency: currency,
												totalTurnoverAmount: editPeriodTurnover,
												totalPurchaseSpentTarget: editPeriodPurchaseTarget,
												id: updateAccountingPeriod?.id
											})
										)
									}
								}}
								type='Primary'
								hasTrailingIcon={true}
								icon='fa-solid fa-edit'
								text='Update reporting period'
								additionalClass='mt-9 mr-5'
								disabled={!editPeriodValid || !editPeriodTurnoverValid || editPeriodTurnover === 0}
							/>
							<Button
								type='Outlined'
								text='Cancel'
								onClick={() => {
									setEditAccountingPeriodModalOpen(false)
								}}
							/>
						</>}
						{accountingPeriodsEditStatus === PostStatus.sending &&
							<LoadingSpinner loadingText='Updating Accounting Period' />}
						{accountingPeriodsEditStatus === PostStatus.error &&
							<>
								<div className='rounded-lg bg-error-500 p-4 mt-4'>
									<p className='text-white'>
										There was an error whilst updating this accounting period. If this problem persists,
										please contact the Neoni team.
									</p>
								</div>
								<Button
									onClick={() => {
										dispatch(
											updateAccountingPeriods(api, {
												startDate: editPeriodStartDate,
												endDate: editPeriodEndDate,
												totalTurnoverCurrency: currency,
												totalTurnoverAmount: editPeriodTurnover,
												totalPurchaseSpentTarget: editPeriodPurchaseTarget,
												id: updateAccountingPeriod?.id
											})
										)
									}}
									type='Primary'
									hasTrailingIcon={true}
									icon='fa-solid fa-arrow-right'
									text='Retry'
									additionalClass='mt-9 mr-5'
								/>
								<Button
									type='Outlined'
									text='Cancel'
									onClick={() => {
										setEditAccountingPeriodModalOpen(false)
									}}
									additionalClass='mt-6 mr-5'
								/>
							</>}
						{accountingPeriodsEditStatus === PostStatus.sent &&
							<>
								<p className='text-white mt-6'>Your accounting period has been updated successfully.</p>

								<Button
									type='Primary'
									text='Back to accounting periods'
									onClick={() => {
										dispatch({ type: ACCOUNTINGPERIODS_EDITING_RESET })
										setEditAccountingPeriodModalOpen(false)
									}}
									additionalClass='mt-6 mr-5'
								/>
							</>
						}

					</>
				</Modal>
				<Modal
					open={editConfirmationModalOpen}
					onClose={() => setEditConfirmationModalOpen(false)}
					size='small'
				>
					<PageHeadings title='Update Accounting Period' />
					<label className='block text-white pt-6 pb-4'>
						Are you sure, you want to update Turnover at this stage of Accounting Period?
					</label>
					<Button
						type='Outlined'
						text='Cancel Update'
						additionalClass=' mr-5'
						onClick={() => {
							setEditConfirmationModalOpen(false)
						}}
					/>
					<Button
						onClick={() => {
							dispatch(
								updateAccountingPeriods(api, {
									startDate: editPeriodStartDate,
									endDate: editPeriodEndDate,
									totalTurnoverCurrency: currency,
									totalTurnoverAmount: editPeriodTurnover,
									totalPurchaseSpentTarget: editPeriodPurchaseTarget,
									id: updateAccountingPeriod?.id
								})
							)

							setEditConfirmationModalOpen(false)
						}}
						type='Primary'
						text='Confirm Update'
						additionalClass='mt-9'
					/>
				</Modal>
				<Button
					type='Outlined'
					text='Download CSV template'
					size='sm'
					hasTrailingIcon
					icon='fa-regular fa-download'
					onClick={() => {
						window.location.href = `${process.env.REACT_APP_BASE_API}/csv/purchases-template`
					}}
					additionalClass='mt-4 mb-2 ml-4'
				/>
			</div>

			<div className='flex-grow mt-4 '>
				<div className='col-span-2 w-full'>
					{/* <div className='block rounded-lg bg-charcoal-600'>
						<div className='flex flex-col pt-xl pb-2'> */}
					{accountingPeriodsStatus === FetchStatus.loading && <LoadingSpinner />}

					{accountingPeriodsStatus === FetchStatus.loaded &&
						accountingPeriodsData.length > 0 && (
							<SimpleSortTable
								additionalClass='mt-4'
								data={constructData(accountingPeriodsData)}
								columns={columns}
							/>
						)}

					{accountingPeriodsStatus === FetchStatus.loaded &&
						accountingPeriodsData.length === 0 && (
							<>
								<p className='text-white px-4 py-2'>
									We did not find any accounting periods for your organisation
								</p>
							</>
						)}

					{accountingPeriodsStatus === FetchStatus.error && (
						<>
							<p className='my-lg text-white'>
								Oops! There was an error fetching the accounting periods data.
							</p>
							<p className='my-lg mt-4 text-white'>{accountingPeriodsLoadingError}</p>
						</>
					)}
					{/* </div>
					</div> */}
				</div>
			</div>
		</PageTemplate >
	)
}
export default AddAccountingPeriods
