import { FunctionComponent, useEffect, useState } from 'react'
import { Button } from '../../../../components/BaseComponents'
import SimpleSortTable, {
	TableColumn,
} from '../../../../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import { getSectors } from '../../../../redux/actions/sectorActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/reduxHooks'
import {
	SECTOR_RESET,
	selectAllSector,
	selectSectorStatus,
} from '../../../../redux/slices/sectorSlice'
import { FetchStatus } from '../../../../types/LoadingStates'
import { Sector } from '../../../../types/Sector'
import { WORST_SECTOR_LIMIT_QUANTITY } from '../Graphs/ECO2Sector'
import useApi from '../../../../hooks/useApi'

// This component should only refresh/be doing stuff when marked as 'active' as it lives inside
// a modal and might otherwise be rendered inivisbly and cause interference

// Sector endpoint does not have pagination

type IModalSectorSearch = {
	onChange?: (newValue?: Sector[]) => void
	active?: boolean
}

export const ModalSectorSearch: FunctionComponent<IModalSectorSearch> = ({
	onChange,
	active = true,
}) => {
	const dispatch = useAppDispatch()

	const sectorsStatus = useAppSelector(selectSectorStatus)
	const allSectors = useAppSelector(selectAllSector)

	const [selection, setSelection] = useState<Sector[]>([])
	const api = useApi();

	// Data loading (refire every time marked active)
	useEffect(() => {
		if (active) {
			dispatch({
				type: SECTOR_RESET,
			})
			dispatch(getSectors(api))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{
			id: 'selected',
			label: '',
			columnClass: 'w-3', // narrow fixed column width
			renderer: (columnKey, rowData) => {
				return (
					<input
						type='checkbox'
						className='rounded border-charcoal-300 text-algae-600 focus:ring-algae-500'
						value={rowData._id}
						checked={rowData.selected}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							event.stopPropagation()

							if (selection.filter((sector) => sector.id === rowData._id).length > 0) {
								// Remove from selection
								setSelection([...selection.filter((sector) => sector.id !== rowData._id)])
							} else {
								// Check there's still room to add another
								if (selection.length !== WORST_SECTOR_LIMIT_QUANTITY) {
									// Add to selected
									setSelection([...selection, rowData.sectorObject])
								}
							}
						}}
					/>
				)
			},
		},
		{
			id: 'isicCode',
			label: 'Code',
			renderer: (columnKey, rowData) => {
				return <p className='text-white'>{rowData._id}</p>
			},
		},
		{ id: 'name', label: 'Description' },
	]

	// DataGrid data constructor
	const constructData = (dataSet: Array<any>) => [
		...dataSet.map((sector) => {
			return {
				_id: sector.code,
				name: sector.name,
				selected:
					selection.filter((selectedSupplier) => selectedSupplier.code === sector.code).length > 0,
				sectorObject: sector,
			}
		}),
	]

	return (
		<>
			{sectorsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching sectors associated with this organisation. If this problem
						persists, please contact the Neoni team.
					</p>
				</div>
			)}

			<h3 className='font-bold text-xl text-white mb-4'>Select Sectors to Compare</h3>

			<p className='text-white font-bold mb-4'>
				Please select up to {WORST_SECTOR_LIMIT_QUANTITY} sectors to compare their embedded CO₂
				emissions:
			</p>

			{selection !== void 0 && selection.length > 0 && (
				<>
					{selection.map((sector) => {
						return (
							<Button
								key={sector.code}
								type='Outlined'
								size='sm'
								additionalClass='mr-2 mb-2'
								text={`${sector.code} - ${sector.name}`}
								hasTrailingIcon
								icon='fa-regular fa-times'
								onClick={() => {
									// Remove from selection
									setSelection([
										...selection.filter((selectedSector) => selectedSector.code !== sector.code),
									])
								}}
							/>
						)
					})}
					<div className='mt-4 mb-8'>
						<Button
							type='Primary'
							text='Save Selection'
							onClick={() => {
								onChange && onChange(selection)
							}}
						/>
					</div>
				</>
			)}

			<SimpleSortTable
				isLoading={sectorsStatus === FetchStatus.loading}
				additionalClass='mt-4'
				data={constructData(allSectors)}
				columns={columns}
			/>

			{selection !== void 0 && selection.length > 0 && (
				<div className='mt-4 flex-row-reverse w-full flex'>
					<Button
						type='Primary'
						text='Save Selection'
						onClick={() => {
							onChange && onChange(selection)
						}}
					/>
				</div>
			)}
		</>
	)
}
