import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PaginationMeta } from '../../types/PaginationMeta'
import { SupplierCarbonData } from '../../types/SupplierCarbonData'
import type { RootState } from '../store'

type SupplierCarbonAccountsType = {
	status: FetchStatus
	error?: string
	suppliersData: Array<SupplierCarbonData>
	pagination: PaginationMeta
}

const supplierCarbonAccountsInitialState: SupplierCarbonAccountsType = {
	status: FetchStatus.initial,
	error: undefined,
	suppliersData: [],
	pagination: {
		total: 0,
		per_page: 0,
		current_page: 1,
		last_page: 1,
		first_page: 1,
	},
}

export const SupplierCarbonAccounts = createSlice({
	name: 'suppliersData',
	initialState: supplierCarbonAccountsInitialState,
	reducers: {
		suppliersCarbonData_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		suppliersCarbonData_LOADED: (state, action: PayloadAction<any>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				suppliersData: action.payload.suppliers,
				pagination: {
					total: action.payload.totalItems,
					per_page: action.payload.per_page,
					current_page: action.payload.currentPage,
					last_page: action.payload.lastPage,
					first_page: action.payload.first_page,
				},
			}
		},
		suppliersCarbonData_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		suppliersCarbonData_RESET: () => {
			return supplierCarbonAccountsInitialState
		},
	},
})

// Action types
export const {
	suppliersCarbonData_LOADING,
	suppliersCarbonData_LOADED,
	suppliersCarbonData_ERROR,
	suppliersCarbonData_RESET,
} = SupplierCarbonAccounts.actions

// Selectors
export const selectsuppliersCarbonDataStatus = (state: RootState) =>
	state.suppliersCarbonAccounts.status
export const selectsuppliersCarbonDataError = (state: RootState) =>
	state.suppliersCarbonAccounts.error
export const selectAllsuppliersCarbonData = (state: RootState) =>
	state.suppliersCarbonAccounts.suppliersData
export const selectsuppliersCarbonDataPagination = (state: RootState) =>
	state.suppliersCarbonAccounts.pagination
export const selectsuppliersCarbonDataNumberOfResults = (state: RootState) =>
	state.suppliersCarbonAccounts.suppliersData.length

export default SupplierCarbonAccounts.reducer
