import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { Invitation } from '../../types/Invitation'
import {
	INVITATIONS_CREATE_ERROR,
	INVITATIONS_CREATE_SENDING,
	INVITATIONS_CREATE_SENT,
	INVITATION_RESETEMAIL,
	INVITATION_SETEMAIL,
} from '../slices/createInvitationsSlice'
import {
	INVITATIONS_ERROR,
	INVITATIONS_FAILURE_UPDATE,
	INVITATIONS_FETCHING,
	INVITATIONS_SUCCESS,
	INVITATIONS_UPDATED_REVOKED,
	INVITATIONS_UPDATED_RESEND,
	INVITATIONS_UPDATING,
	INVITATIONS_INSERT,
} from '../slices/invitationsSlice'
import { NeoniApi } from '../../lib/api'

export const getInvitations = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: INVITATIONS_FETCHING,
	})

	try {
		const body = await api.get(`adminInvitations/sent`);
		body.invitations.sort((a: Invitation, b: Invitation) => (a.createdAt > b.createdAt ? -1 : 1))

		dispatch({
			type: INVITATIONS_SUCCESS,
			payload: body.invitations,
		})
	} catch (error) {
		dispatch({
			type: INVITATIONS_ERROR,
			payload: error,
		})
	}
}

export const createInvitation =
	(api: NeoniApi, companyId: string, email: string, sendEmail: boolean, roleId?: string) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: INVITATIONS_CREATE_SENDING,
			})
			dispatch({
				type: INVITATION_SETEMAIL,
				payload: { companyId, email, sendEmail },
			})

			const csrf = await getCSRFToken()

			const body = await api.post(`adminInvitations`, {
				companyId, email, _csrf: csrf, sendEmail, roleId
			});

			if (body.success === true) {
				// Successfully invited
				dispatch({
					type: INVITATIONS_CREATE_SENT,
					payload: body.invite,
				})
				dispatch({
					type: INVITATIONS_INSERT,
					payload: body.invite,
				})
				dispatch({
					type: INVITATION_RESETEMAIL,
				})
			} else {
				// Error
				dispatch({
					type: INVITATIONS_CREATE_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: INVITATIONS_CREATE_ERROR,
				payload: error,
			})
		}
	}

export const revokeInvitation =
	(api: NeoniApi, invitationId: string, status: string) => async (dispatch: Dispatch) => {
		// Mark invitation as update in progress
		dispatch({
			type: INVITATIONS_UPDATING,
			payload: invitationId,
		})

		try {
			const csrf = await getCSRFToken()
			const body = await api.put(`adminInvitations/${invitationId}`,
				{ status, _csrf: csrf}
			)

			if (body.success === true) {
				// Successfully revoked
				dispatch({
					type: INVITATIONS_UPDATED_REVOKED,
					payload: body.invite,
				})
			} else {
				// Error
				dispatch({
					type: INVITATIONS_FAILURE_UPDATE,
					payload: { id: invitationId, error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: INVITATIONS_FAILURE_UPDATE,
				payload: { id: invitationId, error: error },
			})
		}
	}

export const resendInvitation = (api: NeoniApi, invitationId: string) => async (dispatch: Dispatch) => {
	// Mark invitation as update in progress
	dispatch({
		type: INVITATIONS_UPDATING,
		payload: invitationId,
	})

	try {
		const csrf = await getCSRFToken()
		const body = await api.post(`adminInvitations/resend`,
			{inviteId: invitationId, _csrf: csrf }
		);

		if (body.success === true) {
			// Successfully revoked
			dispatch({
				type: INVITATIONS_UPDATED_RESEND,
				payload: { revokedInvite: body.revokedInvite, invite: body.invite },
			})
		} else {
			// Error
			dispatch({
				type: INVITATIONS_FAILURE_UPDATE,
				payload: { id: invitationId, error: body.message },
			})
		}
	} catch (error) {
		dispatch({
			type: INVITATIONS_FAILURE_UPDATE,
			payload: { id: invitationId, error: error },
		})
	}
}
