/* eslint-disable */
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Input, LoadingSpinner } from '../components/BaseComponents'
import routes from '../components/Router/routes'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import Modal from '../components/BaseComponents/Modal/Modal'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import Select from 'react-select'
import { getCompanyList } from '../redux/actions/getCompanyListActions'
import { selectCompanyList, selectCompanyListStatus } from '../redux/slices/getCompanyListSlice'
import { createInvitation, getInvitations } from '../redux/actions/invitationsActions'
import {
	INVITATIONS_CREATE_RESET,
	selectCreateInvitationsStatus,
} from '../redux/slices/createInvitationsSlice'
import {
	selectInvicationsError,
	selectInvitations,
	selectInvitationsStatus,
} from '../redux/slices/invitationsSlice'
import InvitationsTable from '../components/InvitationsTable/InvitationsTable'
import { getRoles } from '../redux/actions/rolesActions'
import { selectRoles, selectRolesStatus } from '../redux/slices/rolesSlice'
import { validateEmail } from '../lib/utils'
import { SelectDropdown } from '../types/SelectDropdown'
import useApi from '../hooks/useApi'

function AdminInviteUser() {
	const [email, setEmail] = useState('')
	const [companyId, setCompanyId] = useState('')
	const [roleId, setRoleId] = useState<string | undefined>('')
	const [sendEmail, setsendEmail] = useState<boolean | undefined>(false)
	const [companyList, setcompanyList] = useState<Array<SelectDropdown | undefined>>([])
	const [rolelist, setRolesList] = useState<Array<SelectDropdown | undefined>>([])
	const [valid, setValid] = useState<boolean>(true)
	const [validCompany, setValidcompany] = useState<boolean>(true)

	const dispatch = useAppDispatch()
	const api = useApi();

	const createInvitationStatus = useAppSelector(selectCreateInvitationsStatus)
	const allInvitations = useAppSelector(selectInvitations)
	const invitationsStatus = useAppSelector(selectInvitationsStatus)
	const invitationsError = useAppSelector(selectInvicationsError)

	const companyListStatus = useAppSelector(selectCompanyListStatus)
	const companylistsData = useAppSelector(selectCompanyList)

	const roleStatus = useAppSelector(selectRolesStatus)
	const rolesData = useAppSelector(selectRoles)

	const [inviteUserModalOpen, setInviteUserModalOpen] = useState<boolean>(false)
	useEffect(() => {
		dispatch(getCompanyList(api, { limit: 1000 }))
		dispatch(getInvitations(api))
		dispatch(getRoles(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (companyListStatus === FetchStatus.loaded) {
			let buildList = []
			for (let i = 0; i < companylistsData.length; i++) {
				buildList.push({ label: companylistsData[i].name, value: companylistsData[i].id })
			}
			setcompanyList(buildList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyListStatus])

	useEffect(() => {
		if (roleStatus === FetchStatus.loaded) {
			let buildList = []
			for (let i = 0; i < rolesData.length; i++) {
				if (rolesData[i].name !== 'admin') {
					buildList.push({
						label: rolesData[i].name.charAt(0).toUpperCase() + rolesData[i].name.slice(1),
						value: rolesData[i].id,
					})
				}
			}
			setRolesList(buildList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleStatus])

	const resetInvitation = () => {
		dispatch({
			type: INVITATIONS_CREATE_RESET,
		})
		// Reset local state stores used in form
		setEmail('')
		setCompanyId('')
		setRoleId('')
		setsendEmail(false)
		setValid(true)
	}

	const isCompanyUser = () => {
		let user = rolelist.filter((item: any) => item.value === roleId)
		return user[0]?.label === 'Company'
	}

	return (
		<PageTemplate testid='add-accounting-periods-main'>
			<Helmet>
				<title>{routes.adminInviteUser.name}</title>
			</Helmet>
			<PageHeadings title='Invite User' />

			<div className='block'>
				<Button
					type='Primary'
					text='Invite a New User'
					size='sm'
					hasTrailingIcon
					icon='fa-regular fa-plus'
					onClick={() => {
						setInviteUserModalOpen(true)
					}}
					additionalClass='mt-4 mb-2'
				/>
				<Modal
					open={inviteUserModalOpen}
					onOpen={() => resetInvitation()}
					onClose={() => setInviteUserModalOpen(false)}
					size='small'
				>
					{createInvitationStatus === PostStatus.initial && (
						<>
							<PageHeadings title='Invite a new User' />

							<div className='max-w-2xl'>
								<Input
									label='Email'
									type='text'
									name='email'
									value={email}
									onChange={(e) => {
										setEmail(e.target.value)
									}}
									additionalClass='mt-2'
								/>
								{!valid && (
									<div className='rounded-lg bg-error-500 p-4 mb-4 mt-4'>
										<p className='text-white'>You must enter a valid email address</p>
									</div>
								)}
								{roleStatus === FetchStatus.loaded && (
									<>
										<label
											htmlFor='Role'
											className='block text-sm font-medium text-charcoal-100 mt-2'
										>
											Role
										</label>
										<Select
											name='Role'
											options={rolelist}
											isMulti={false}
											isSearchable={true}
											isClearable={false}
											className='w-full'
											onChange={(value) => {
												setRoleId(value?.value)
											}}
											value={rolelist.filter(function (option: any) {
												return option.value === roleId
											})}
										></Select>
									</>
								)}

								{roleId !== undefined && isCompanyUser() && (
									<>
										<label
											htmlFor='Company'
											className='block text-sm font-medium text-charcoal-100 mt-2'
										>
											Organisation
										</label>
										<Select
											name='Company'
											options={companyList}
											isMulti={false}
											isSearchable={true}
											isClearable={false}
											className='w-full'
											onChange={(value) => {
												if (value?.value !== undefined) {
													setCompanyId(value?.value)
												}
											}}
											value={companyList.filter(function (option: any) {
												return option.value === companyId
											})}
										></Select>
										{!validCompany && (
											<div className='rounded-lg bg-error-500 p-4 mb-4 mt-4'>
												<p className='text-white'>You must select a organisation.</p>
											</div>
										)}
									</>
								)}
								<div className='text-charcoal-100 text-sm mt-4'>
									<label>
										Send Email
										<input
											className='mx-4'
											type='checkbox'
											checked={sendEmail}
											onChange={(e) => {
												setsendEmail(!sendEmail)
											}}
										></input>
									</label>
								</div>
							</div>

							<Button
								onClick={() => {
									if (
										sendEmail !== undefined &&
										validateEmail(email) &&
										((isCompanyUser() && companyId !== '') || !isCompanyUser())
									) {
										dispatch(createInvitation(api, companyId, email, sendEmail, roleId))
									} else if (!validateEmail(email)) {
										setValid(false)
									} else if (
										isCompanyUser() &&
										(companyId === '' || companyId === null || companyId === undefined)
									) {
										setValidcompany(false)
									}
								}}
								type='Primary'
								hasTrailingIcon={true}
								icon='fa-solid fa-plus'
								text='Invite User'
								additionalClass='mt-9 mr-5'
							/>
							<Button
								type='Outlined'
								text='Cancel'
								onClick={() => {
									setInviteUserModalOpen(!inviteUserModalOpen)
								}}
							/>
						</>
					)}
					{createInvitationStatus === PostStatus.sending && (
						<LoadingSpinner loadingText='Inviting User...' />
					)}

					{createInvitationStatus === PostStatus.sent && (
						<>
							<PageHeadings title='Successfully invited the user' />
							<p className='text-white mt-6'>Your new user has been invited successfully:</p>

							<div className='mt-4 bg-white rounded-lg p-4'>
								<p className='text-lg'>
									<strong>Email:</strong>
								</p>
								<p>{email}</p>
								<p className='mt-4 text-lg'>
									<strong>Role:</strong>
								</p>
								<p>{rolelist.filter((item: any) => item.value === roleId)[0]?.label}</p>

								{isCompanyUser() && (
									<>
										<p className='mt-4 text-lg'>
											<strong>Organisation:</strong>
										</p>
										<p>{companyList.filter((item: any) => item.value === companyId)[0]?.label}</p>
									</>
								)}

								<p className='mt-4 text-lg'>
									<strong>Send Email?:</strong>
								</p>
								<p>{sendEmail === true ? 'Yes' : 'No'}</p>
							</div>

							<Button
								type='Primary'
								text='Back to user list'
								onClick={() => {
									resetInvitation()
									setInviteUserModalOpen(false)
								}}
								additionalClass='mt-6 mr-5'
							/>

							<Button
								type='Outlined'
								text='Invite another user'
								icon='fa-regular fa-plus'
								hasTrailingIcon
								onClick={() => {
									resetInvitation()
								}}
								additionalClass='mt-6'
							/>
						</>
					)}

					{createInvitationStatus === PostStatus.error && (
						<>
							<PageHeadings title='Error' />
							<div className='rounded-lg bg-error-500 p-4 mt-4'>
								<p className='text-white'>
									There was an error whilst trying to invite the user. If this problem persists,
									please contact the Neoni team.
								</p>
							</div>
							<Button
								onClick={() => {
									if (
										sendEmail !== undefined &&
										validateEmail(email) &&
										((isCompanyUser() && companyId !== '') || !isCompanyUser())
									) {
										dispatch(createInvitation(api, companyId, email, sendEmail, roleId))
									} else if (!validateEmail(email)) {
										setValid(false)
									} else if (
										isCompanyUser() &&
										(companyId === '' || companyId === null || companyId === undefined)
									) {
										setValidcompany(false)
									}
								}}
								type='Primary'
								hasTrailingIcon={true}
								icon='fa-solid fa-arrow-right'
								text='Retry'
								additionalClass='mt-9 mr-5'
							/>
							<Button
								type='Outlined'
								text='Cancel'
								onClick={() => {
									setInviteUserModalOpen(false)
								}}
								additionalClass='mt-6 mr-5'
							/>
						</>
					)}
				</Modal>
			</div>

			<div className='flex-grow mt-4 '>
				<div className='col-span-2 w-full'>
					<div className='block rounded-lg bg-charcoal-600'>
						<div className='flex flex-col pt-xl pb-2'>
							{invitationsStatus === FetchStatus.loading && <LoadingSpinner />}

							{invitationsStatus === FetchStatus.loaded && allInvitations.length > 0 && (
								<InvitationsTable invitations={allInvitations} type='Admin' />
							)}

							{invitationsStatus === FetchStatus.loaded && allInvitations.length === 0 && (
								<>
									<p className='text-white px-4 py-2'>We did not find any invitations.</p>
								</>
							)}

							{invitationsStatus === FetchStatus.error && (
								<>
									<p className='my-lg text-white'>
										Oops! There was an error fetching the invitations data.
									</p>
									<p className='my-lg mt-4 text-white'>{invitationsError}</p>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</PageTemplate>
	)
}
export default AdminInviteUser
