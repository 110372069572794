import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISICItem } from '../../types/ISICItem'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type IsicSlice = {
	status: FetchStatus
	error?: string
	isicCodes: Array<ISICItem>
}

const isicInitialState: IsicSlice = {
	status: FetchStatus.initial,
	error: undefined,
	isicCodes: [],
}

export const isicSlice = createSlice({
	name: 'isicCodes',
	initialState: isicInitialState,
	reducers: {
		ISIC_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		ISIC_LOADED: (state, action: PayloadAction<Array<ISICItem>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				isicCodes: action.payload,
			}
		},
		ISIC_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		ISIC_RESET: () => {
			return isicInitialState
		},
	},
})

// Action types
export const { ISIC_LOADING, ISIC_LOADED, ISIC_ERROR, ISIC_RESET } = isicSlice.actions

// Selectors
export const selectIsicStatus = (state: RootState) => state.isic.status
export const selectIsicError = (state: RootState) => state.isic.error
export const selectAllIsic = (state: RootState) => state.isic.isicCodes

export default isicSlice.reducer
