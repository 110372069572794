import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import PageHeadings from '../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../../components/Router/routes'
import CompanyCarbonAccountsPurchases from './Purchases'
import CompanyCarbonAccountsSuppliers from './Suppliers'

const CarbonAccountsPage = () => {
	const { state } = useLocation()
	const [pageMode, setPageMode] = useState<'purchases' | 'suppliers'>('suppliers')
	const [suppliersTab, setSuppliersTab] = useState<string>('')
	const [purchasesTab, setPurchasesTab] = useState<string>('')

	useEffect(() => {
		if (state === 'suppliers') {
			setPageMode('suppliers')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyCarbonAccounts.name}</title>
			</Helmet>
			<PageHeadings title='Purchases' subTitle='Carbon Accounts' />

			{pageMode === 'purchases' ? (
				<CompanyCarbonAccountsPurchases purchasesTab={purchasesTab} setPurchasesTab={(tab: any) => setPurchasesTab(tab)} setPageMode={(newMode: any) => setPageMode(newMode)} />
			) : (
				<CompanyCarbonAccountsSuppliers suppliersTab={suppliersTab} setSuppliersTab={(tab: any) => setSuppliersTab(tab)} setPageMode={(newMode: any) => setPageMode(newMode)} />
			)}
		</PageTemplate>
	)
}

export default CarbonAccountsPage
