import { useRef } from "react";

type DebounceCallback = (...args: any[]) => any;

function useDebounce(delayMs: number) {
    const ref = useRef((callback: DebounceCallback) => {
        let lastTimeout: any = 0;
        return () => {
            clearTimeout(lastTimeout);
            lastTimeout = setTimeout(callback, delayMs);
        }
    });

    const caller = ref.current;
    return caller;
}

export default useDebounce;
