import { Dispatch } from 'redux'
import { ROLES_ERROR, ROLES_FETCHING, ROLES_SUCCESS } from '../slices/rolesSlice'
import { NeoniApi } from '../../lib/api'

export const getRoles = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: ROLES_FETCHING,
	})

	try {
		const body = await api.get(`roles`);

		dispatch({
			type: ROLES_SUCCESS,
			payload: body.data.roles,
		})
	} catch (error) {
		dispatch({
			type: ROLES_ERROR,
			payload: error,
		})
	}
}
