import { useContext, useMemo } from "react";
import { userContext } from "../context/UserContext";
import buildApi from "../lib/api";

function useApi() {
    const { userData } = useContext(userContext);

    const api = useMemo(
        () => buildApi(userData),
		// eslint-disable-next-line react-hooks/exhaustive-deps
        [userData]
    )

    return api;
}

export default useApi;
