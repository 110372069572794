import { useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { Button } from "../components/BaseComponents";
import PageHeadings from "../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate";
import SimpleSortTable, { TableColumn } from "../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import routes from "../components/Router/routes";
import useApi from "../hooks/useApi";
import { formatControlRequestStatus, formatDate } from "../lib/utils";

function ClientCompanies() {

    const [loading, setLoading] = useState(true);
    const api = useApi();
    const [data, setData] = useState<any[]>([])

    useEffect(() => {
        setLoading(true);
        const run = async () =>{ 
            const result = await api.get('/accountingClients/')
            setData(result.data)
            setLoading(false);
        }

        run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns: TableColumn[] = [
        {
            id: 'companyName',
            label: 'Organisation name',
            renderer: (key, data) => {
                const controlPath = generatePath(
                    routes.assumeCompany.path,
                    {assumptionType: 'client', companyId: data.companyId}
                );
                
                const hasAccess = data.status === 'full_access'
                return hasAccess ? (
                    <Link
                        className="underline"
                        to={controlPath}
                    >
                        { data.companyName }
                    </Link>
                ) : (
                    <span>{ data.companyName }</span>
                );
            }
        },
        {
            id: 'companyNumber',
            label: 'Registered #'
        },
        {
            id: 'status',
            label: 'Status',
            renderer: (key, data) => <span>{ formatControlRequestStatus(data[key]) }</span>
        },
        {
            id: 'jurisdiction',
            label: 'Jurisdiction',
        },
        {
            id: 'address',
            label: 'Address',
        },
        {
            id: 'latestReport',
            label: 'Latest Report',
            renderer: (key, data) =>{ 
                const value = data[key];
                return <span>{ value ? formatDate(data[key]) : null }</span>
            }
        },
    ]

    return (
        <PageTemplate>
            <PageHeadings title="Client Organisations"/>
            <div className="mt-8">
                <Button
                    type="Primary"
                    text="Invite organisation"
                    hasLeadingIcon
                    icon="fa-regular fa-user-plus"
                    size="sm"
                />
            </div>
            <SimpleSortTable
                additionalClass="mt-8"
                data={data}
                columns={columns}
                isLoading={loading}
            />
        </PageTemplate>
    )
}

export default ClientCompanies;