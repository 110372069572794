import { PropsWithChildren, createContext, useState } from "react";

type AuthContext = {
    auth?: Auth;
    setAuth: (auth: Auth) => void,
}

const authContext = createContext<AuthContext>({
    setAuth: (_auth: Auth) => {},
});

type Auth = {
    access: {
        companyId: string;
        companyPermissions: {
            admin: boolean,
            manage: boolean,
        publish: boolean,
            secrReport: boolean,
            invite: boolean,
        },
        userPermissions: {
            read: boolean,
            write: boolean,
            manage: boolean,
        }
    }
}

function AuthProvider({children}: PropsWithChildren) {
    const [auth, setAuth] = useState<Auth>();
    const value = {auth, setAuth};

    return (
        <authContext.Provider value={value}>{ children }</authContext.Provider>
    )
}

export {
    AuthProvider,
    authContext,
};
