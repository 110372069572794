import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PaginationMeta } from '../../types/PaginationMeta'
import { Supplier } from '../../types/Supplier'
import type { RootState } from '../store'

type SuppliersSlice = {
	status: FetchStatus
	error?: string
	suppliers: Array<Supplier>
	pagination: PaginationMeta
	supplier?: Supplier
}

const suppliersInitialState: SuppliersSlice = {
	status: FetchStatus.initial,
	error: undefined,
	suppliers: [],
	pagination: {
		total: 0,
		per_page: 0,
		current_page: 1,
		last_page: 1,
		first_page: 1,
	},
	supplier: undefined,
}

export const suppliersSlice = createSlice({
	name: 'Suppliers',
	initialState: suppliersInitialState,
	reducers: {
		SUPPLIERS_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		SUPPLIERS_LOADED: (
			state,
			action: PayloadAction<{
				data: Array<Supplier>
				meta: any
			}>
		) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				suppliers: action.payload.data,
				pagination: {
					total: action.payload.meta.total,
					per_page: action.payload.meta.per_page,
					current_page: action.payload.meta.current_page,
					last_page: action.payload.meta.last_page,
					first_page: action.payload.meta.first_page,
				},
			}
		},
		SUPPLIERS_SINGLELOADED: (state, action: PayloadAction<Supplier>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				supplier: action.payload,
			}
		},
		SUPPLIERS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		SUPPLIERS_RESET: () => {
			return suppliersInitialState
		},
	},
})

// Action types
export const {
	SUPPLIERS_LOADING,
	SUPPLIERS_LOADED,
	SUPPLIERS_SINGLELOADED,
	SUPPLIERS_ERROR,
	SUPPLIERS_RESET,
} = suppliersSlice.actions

// Selectors
export const selectSuppliersStatus = (state: RootState) => state.suppliers.status
export const selectSuppliersError = (state: RootState) => state.suppliers.error
export const selectAllSuppliers = (state: RootState) => state.suppliers.suppliers
export const selectSuppliersPagination = (state: RootState) => state.suppliers.pagination
export const selectSuppliersNumberOfResults = (state: RootState) => state.suppliers.suppliers.length
export const selectIndividualSupplier = (state: RootState) => state.suppliers.supplier

export default suppliersSlice.reducer
