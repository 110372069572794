import { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, ContactMessage, LoadingSpinner } from '../components/BaseComponents'
import CsvResult from '../components/BaseComponents/CsvResult/CsvResult'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import { addPurchasesUploadFile } from '../redux/actions/addPurchasesUploadActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectAddPurchasesAccountingId,
	selectAddPurchasesFileAccountingPeriod,
	selectAddPurchasesFileErrorCount,
	selectAddPurchasesFileErrors,
	selectAddPurchasesFileRecords,
	selectAddPurchasesUploadError,
	selectAddPurchasesUploadFile,
	selectAddPurchasesUploadId,
	selectAddPurchasesUploadName,
	selectAddPurchasesUploadStatus,
	selectAddPurchasesUploadSuccessful,
} from '../redux/slices/addPurchasesUploadSlice'
import { PostStatus } from '../types/LoadingStates'
import { updateAccountingPeriodStage } from '../redux/actions/accountingPeriodsActions'
import useApi from '../hooks/useApi'

const AddPurchasesUpload = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	const fileToUpload = useAppSelector(selectAddPurchasesUploadFile)
	const fileToUploadAccountingPeriodId = useAppSelector(selectAddPurchasesUploadId)

	const fileToUploadAccountingPeriodName = useAppSelector(selectAddPurchasesUploadName)

	const uploaderStatus = useAppSelector(selectAddPurchasesUploadStatus)
	const uploaderError = useAppSelector(selectAddPurchasesUploadError)
	const uploadIsValid = useAppSelector(selectAddPurchasesUploadSuccessful)

	const uploadedFilePeriodDetails = useAppSelector(selectAddPurchasesFileAccountingPeriod)
	const uploadedFileErrors = useAppSelector(selectAddPurchasesFileErrors)
	const uploadedFileRecords = useAppSelector(selectAddPurchasesFileRecords)
	const uploadedFileErrorCount = useAppSelector(selectAddPurchasesFileErrorCount)
	const uploadedFileAccountingPeriod = useAppSelector(selectAddPurchasesAccountingId)

	useEffect(() => {
		if (
			typeof fileToUpload === 'undefined' ||
			typeof fileToUploadAccountingPeriodId === 'undefined'
		) {
			// No file defined, we can't upload anything, send them back to pick a period and file
			navigate(routes.companyAddAccountingPeriods.path, { replace: true })
		} else {
			// Let's start the upload process
			dispatch(
				addPurchasesUploadFile(api, {
					file: fileToUpload,
					accountingPeriodId: fileToUploadAccountingPeriodId,
					filename: fileToUploadAccountingPeriodName !== undefined ? fileToUploadAccountingPeriodName : '-'
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		// Flag the accounting period as having a successful file upload
		if (uploaderStatus === PostStatus.sent) {
			if (uploadIsValid) {
				if (fileToUploadAccountingPeriodId) {
					dispatch(
						updateAccountingPeriodStage(api, {
							accountingPeriodId: fileToUploadAccountingPeriodId,
							uploadState: 0,
						})
					)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploaderStatus, uploadIsValid])

	return (
		<PageTemplate testid='add-purchases-main'>
			<Helmet>
				<title>{routes.companyAddPurchasesUpload.name}</title>
			</Helmet>
			{uploaderStatus === PostStatus.sending && (
				<>
					<PageHeadings
						title='Add Purchases'
						subTitle='Upload CSV'
						routeBack={generatePath(routes.companyViewAccountingPeriodDetails.path, { accountingPeriodIdVal: uploadedFileAccountingPeriod})}
					/>
					<LoadingSpinner loadingText='Uploading file...' />
				</>
			)}

			{uploaderStatus === PostStatus.error && (
				<>
					<PageHeadings
						title='Add Purchases'
						subTitle='Upload CSV'
						routeBack={generatePath(routes.companyViewAccountingPeriodDetails.path, { accountingPeriodIdVal: uploadedFileAccountingPeriod})}
					/>

					<div className='rounded-lg bg-error-500 p-4 mb-5 mt-4'>
						<p className='text-white'>
							There was an error sending your file. If this problem persists. please contact the
							Neoni team.
						</p>
						<p className='text-white mt-4'>{uploaderError}</p>
					</div>

					<div>
						<Button
							onClick={() => {
								navigate(generatePath(routes.companyViewAccountingPeriodDetails.path, { accountingPeriodIdVal: uploadedFileAccountingPeriod}))
							}}
							type='Outlined'
							text='Cancel'
							additionalClass='mr-5'
						/>
						<Button
							onClick={() => {
								if (
									typeof fileToUpload !== 'undefined' &&
									typeof fileToUploadAccountingPeriodId !== 'undefined'
								) {
									dispatch(
										addPurchasesUploadFile(api, {
											file: fileToUpload,
											accountingPeriodId: fileToUploadAccountingPeriodId,
											filename: fileToUploadAccountingPeriodName !== undefined ? fileToUploadAccountingPeriodName : '-'
										})
									)
								}
							}}
							type='Primary'
							hasTrailingIcon={true}
							icon='fa-regular fa-upload'
							text='Retry upload with same file'
						/>
					</div>
				</>
			)}

			{uploaderStatus === PostStatus.sent && (
				<>
					<PageHeadings
						title='Add Purchases'
						subTitle='Upload CSV'
						subSubTitle='Check Data'
						routeBack={generatePath(routes.companyViewAccountingPeriodDetails.path, {accountingPeriodIdVal: uploadedFileAccountingPeriod})}
					/>

					{uploadIsValid ? (
						<>
							{/* Success */}

							<div className='rounded-lg bg-charcoal-600 p-4 mt-5 mb-2'>
								<p className='text-2xl text-white mb-5'>Success!</p>
								<p className='text-white mb-7'>
									Your data has been imported. The next steps will be categorising your expenses to
									better estimate emissions.
								</p>

								{uploadedFilePeriodDetails && (
									<div className='p-4 py-2 rounded-lg bg-white'>
										<CsvResult
											startDate={uploadedFilePeriodDetails.start_date}
											endDate={uploadedFilePeriodDetails.end_date}
											errors={uploadedFileErrorCount}
											purchases={uploadedFileRecords}
										/>
									</div>
								)}
							</div>

							<div className='mt-4 mb-4'>
								<Button
									onClick={() => {
										navigate(
											generatePath(
												routes.companyRefineEmissionsGenerated.path,
												{ accountingPeriodId: uploadedFileAccountingPeriod},
											),
											{
												replace: true,
											}
										)
									}}
									type='Primary'
									hasTrailingIcon={true}
									icon='fa-regular fa-angle-right'
									text='Refine data'
								/>
							</div>
						</>
					) : (
						<>
							{/* Fail */}

							<ContactMessage message='We found some errors in that file. Please fix any errors in your csv file and reupload.' />

							{uploadedFilePeriodDetails && (
								<div className='p-4 py-2 rounded-lg bg-charcoal-600 mb-5 mt-5'>
									<CsvResult
										headingTextClass='text-white'
										dataTextClass='text-charcoal-100'
										startDate={uploadedFilePeriodDetails.start_date}
										endDate={uploadedFilePeriodDetails.end_date}
										errors={uploadedFileErrorCount}
										purchases={uploadedFileRecords}
									/>
								</div>
							)}

							<div className='col-span-2 w-full'>
								<div className='flex flex-col rounded-lg shadow-lg bg-charcoal-600 h-full'>
									<div className='mt-8 flex flex-col'>
										<div className='-my-2 overflow-x-auto'>
											<div className='inline-block min-w-full py-2 align-middle'>
												<div className='overflow-hidden shadow-sm'>
													<table className='table-auto min-w-full divide-y divide-charcoal-300'>
														<tbody className=''>
															{uploadedFileErrors?.map((error, index) => {
																return (
																	<tr
																		key={error.text + error.reference}
																		className='border-t-2 border-b-2 border-white'
																	>
																		<td className='p-lg text-left text-sm font-medium text-white leading-5'>
																			{index + 1}
																		</td>
																		<td className='p-lg text-left text-sm font-medium text-white leading-5'>
																			{error.reference}
																		</td>
																		<td className='p-lg text-left text-sm font-medium leading-5'>
																			<p className='text-charcoal-100'>Reason for error</p>
																			<p className='text-white'>{error.text}</p>
																		</td>
																	</tr>
																)
															})}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className='bg-charcoal-700 mt-8 p-4 rounded'>
										<Button
											onClick={() => {
												navigate(
													generatePath(routes.companyViewAccountingPeriodDetails.path, {accountingPeriodIdVal: uploadedFileAccountingPeriod}),
													{ replace: true })
											}}
											type='Outlined'
											size='sm'
											hasLeadingIcon={true}
											icon='fa-regular fa-arrow-left'
											text='Back to accounting periods details'
											additionalClass='float-right'
										/>
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</PageTemplate>
	)
}
export default AddPurchasesUpload
