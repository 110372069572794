export enum PurchaseUploadState {
	fileUploaded = 0,
	scope1Selected = 1,
	scope1Refined = 2,
	scope2Refined = 3,
	scope3Refined = 4,
}

export const PurchaseUploadStateDisplay = [
	'File Uploaded',
	'Scope 1 Incomplete',
	'Scope 2 Incomplete',
	'Scope 3 Incomplete',
	'Complete',
]
