import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import { Helmet } from 'react-helmet'
import routes from '../components/Router/routes'

function AdminDashboard() {
	return (
		<PageTemplate testid='admin-dashboard-main'>
			<Helmet>
				<title>{routes.adminDashboard.name}</title>
			</Helmet>
			<p className='text-white'>Admin Dashboard</p>
		</PageTemplate>
	)
}
export default AdminDashboard
