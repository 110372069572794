/* eslint-disable indent */
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, LoadingSpinner, CompanyInviteCard } from '../components/BaseComponents'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import { getSupplier } from '../redux/actions/suppliersActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectIndividualSupplier,
	selectSuppliersError,
	selectSuppliersStatus,
	SUPPLIERS_RESET,
} from '../redux/slices/suppliersSlice'
import { UPDATESUPPLIERS_RESET } from '../redux/slices/updateSuppliersSlice'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import { createInvitationCompany } from '../redux/actions/companySentInvitationsActions'
import { createCompany } from '../redux/actions/createCompanyActions'
import CreateInviteForm from '../components/BaseComponents/Forms/CreateInviteForm'
import Modal from '../components/BaseComponents/Modal/Modal'
import OpenCoSearchResults from '../components/OpenCoSearchResults/OpenCoSearchResults'
import {
	CREATECOMPANY_RESET,
	selectCreateCompanyStatus,
	selectCreatedCompany,
} from '../redux/slices/createCompanySlice'
import {
	OPENCORPORATES_RESET,
	selectOpenCorporates,
	selectOpenCorporatesStatus,
} from '../redux/slices/openCorporatesSlice'
import { OpenSearchCompany } from '../types/OpenSearchCompany'
import {
	SUPPLYCHAIN_INVITATIONS_CREATE_RESET,
	selectSupplyChainInvitationsStatus,
	selectSupplyChainInviteCompanyID,
	selectSupplyChainInviteEmail,
	selectSupplyChainInviteSendEmail,
} from '../redux/slices/createSupplyChainInvitation'
import { getOpenCorporatesData } from '../redux/actions/openCorporatesActions'
import { NewCompany } from '../types/Company'
import useApi from '../hooks/useApi'

const CompanyInviteSupplier = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	const { supplierId } = useParams()

	const suppliersStatus = useAppSelector(selectSuppliersStatus)
	const suppliersError = useAppSelector(selectSuppliersError)
	const supplierData = useAppSelector(selectIndividualSupplier)
	const createInvitationStatus = useAppSelector(selectSupplyChainInvitationsStatus)
	const createCompanyStatus = useAppSelector(selectCreateCompanyStatus)
	const createdCompany = useAppSelector(selectCreatedCompany)
	const openCorporatesData = useAppSelector(selectOpenCorporates)
	const openCorporatesStatus = useAppSelector(selectOpenCorporatesStatus)

	const [inviteModalOpen, setinviteModalOpen] = useState<boolean>(false)
	const [activeResult, setActiveResult] = useState<OpenSearchCompany>()
	const [searchValueOpenCorporates, setsearchValueOpenCorporates] = useState('')
	const [searchValueTypeOpenCorporates, setsearchValueTypeOpenCorporates] = useState(false)
	const email = useAppSelector(selectSupplyChainInviteEmail)
	const sendEmail = useAppSelector(selectSupplyChainInviteSendEmail)
	const companyIdToInvite = useAppSelector(selectSupplyChainInviteCompanyID)

	// Reset supplier data
	useEffect(() => {
		dispatch({
			type: SUPPLIERS_RESET,
		})
		dispatch({
			type: UPDATESUPPLIERS_RESET,
		})
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Data loading - Supplier
	useEffect(() => {
		if (suppliersStatus === FetchStatus.initial) {
			// No supplier data, attempt to load supplier
			dispatch(getSupplier(api, supplierId))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [suppliersStatus, supplierData])

	const resetModal = () => {
		setActiveResult(undefined)
		dispatch({
			type: CREATECOMPANY_RESET,
		})
		dispatch({
			type: SUPPLYCHAIN_INVITATIONS_CREATE_RESET,
		})
		dispatch({
			type: OPENCORPORATES_RESET,
		})
	}
	const dispatchSearchOpenCorporates = () => {
		dispatch(getOpenCorporatesData(api, searchValueOpenCorporates, searchValueTypeOpenCorporates))
	}
	const convertToNeoniCompany = (company: OpenSearchCompany) => {
		let industryCodesFiltered: any[] = []
		company.industryCodes.forEach((industryCodes: any) => {
			industryCodesFiltered.push({
				code: industryCodes['industry_code']['code'],
				codeType: industryCodes['industry_code']['code_scheme_name'],
			})
		})
		return {
			name: company.name,
			type: company.type,
			number: company.company_number,
			jurisdiction_code: company.jurisdictionCode,
			industry_codes: industryCodesFiltered,
			address: company.address,
		} as NewCompany
	}

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyInviteSupplier.name}</title>
			</Helmet>
			<PageHeadings
				title='External Network'
				subTitle='Invite Supplier'
				subSubTitle='Supplier'
			/>

			{suppliersStatus === FetchStatus.loaded && (
				<>
					<dl className='mt-8 grid grid-cols-3 text-white'>
						<dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Supplier name</dt>
						<dd className='col-span-2 p-4 border-y border-charcoal-200'>
							{supplierData?.name || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Organisation number</dt>
						<dd className='col-span-2 p-4 border-y border-charcoal-200'>
							{supplierData?.company_number || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Jurisdiction</dt>
						<dd className='col-span-2 p-4 border-b border-charcoal-200'>
							{supplierData?.jurisdiction_code || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Sector Code (ISIC)</dt>
						<dd className='col-span-2 p-4 border-b border-charcoal-200'>
							{supplierData?.supplierIndustryCode?.code || '-'}
							{supplierData?.supplierIndustryCode?.name &&
								` - ${supplierData?.supplierIndustryCode?.name}`}
						</dd>
					</dl>

					<div className='mt-8'>
						<Button
							type='Primary'
							text='Create invite'
							icon='fa-regular fa-user-plus'
							hasTrailingIcon
							onClick={() => setinviteModalOpen(true)}
						/>
					</div>

					<Modal
						open={inviteModalOpen}
						onOpen={() => {
							resetModal()
						}}
						onClose={() => {
							setinviteModalOpen(false)
						}}
						size='small'
					>
						{createInvitationStatus === PostStatus.initial && (
							<>
								{createCompanyStatus === PostStatus.initial && (
									<>
										<PageHeadings title={`Search for your Supplier's organisation details`} />
										<div className='mb-5 mt-5'>
											<form
												className='flex flex-col'
												onSubmit={(e) => {
													e.preventDefault()
													dispatchSearchOpenCorporates()
												}}
											>
												<label className='text-charcoal-100 text-sm'>
													Find organisation by name or organisation number
												</label>
												<div className='flex mt-sm'>
													<input
														className='text-charcoal-500 rounded-l-sm w-full p-md border-charcoal-200 focus:border-charcoal-200 focus:ring-0'
														id='company_search'
														type='text'
														onChange={(e) => {
															setsearchValueOpenCorporates(e.target.value)
														}}
														placeholder='Search for organisation name or number'
													/>
													<button className='flex items-center rounded-r-sm bg-algae-100 border border-algae-500 px-md text-charcoal-500'>
														Search<span className='ml-md fa-regular fa-search'></span>
													</button>
												</div>
												<label className='text-charcoal-100 text-sm mt-4 ml-4'>
													Search by organisation <nav></nav>umber
													<input
														className='mx-4'
														type='checkbox'
														checked={searchValueTypeOpenCorporates}
														onChange={(e) => {
															setsearchValueTypeOpenCorporates(!searchValueTypeOpenCorporates)
														}}
													></input>
												</label>
											</form>
											{openCorporatesStatus === FetchStatus.loading && (
												<LoadingSpinner loadingText='Loading Results...' />
											)}
											{openCorporatesStatus === FetchStatus.loaded && (
												<OpenCoSearchResults
													results={openCorporatesData}
													setActiveResult={setActiveResult}
													activeResult={activeResult as any}
												/>
											)}
											{openCorporatesStatus === FetchStatus.error && (
												<>
													<div className='rounded-lg bg-error-500 p-4 mt-4'>
														<p className='text-white'>
															There was an error whilst fetching the organisation data. If this problem
															please contact the Neoni team.
														</p>
													</div>
													<Button
														onClick={dispatchSearchOpenCorporates}
														type='Primary'
														hasTrailingIcon={true}
														icon='fa-solid fa-arrow-right'
														text='Retry'
														additionalClass='mt-9 mr-5'
													/>
													<Button
														type='Outlined'
														text='Cancel'
														onClick={() => {
															setinviteModalOpen(false)
														}}
														additionalClass='mt-6 mr-5'
													/>
												</>
											)}
										</div>
									</>
								)}
								{createCompanyStatus === PostStatus.sending && (
									<LoadingSpinner loadingText='Creating Organisation...' />
								)}
								{createCompanyStatus === PostStatus.sent && createdCompany && (
									<>
										<PageHeadings title='Send Invitation' />
										<div className='flex flex-col mt-5'>
											{activeResult && (
												<CompanyInviteCard
													isInteractable={false}
													activeResult={activeResult}
													result={activeResult}
												/>
											)}
											<CreateInviteForm
												companyId={createdCompany.id}
												type='Company'
												supplierId={supplierId}
											/>
										</div>
									</>
								)}
								{createCompanyStatus === PostStatus.error && (
									<>
										<PageHeadings title='Error' />
										<div className='rounded-lg bg-error-500 p-4 mt-4'>
											<p className='text-white'>
												There was an error whilst creating the organisation. If this problem please
												contact the Neoni team.
											</p>
										</div>
										<Button
											onClick={() => {
												dispatch(createCompany(api, convertToNeoniCompany(activeResult as any)))
											}}
											type='Primary'
											hasTrailingIcon={true}
											icon='fa-solid fa-arrow-right'
											text='Retry'
											additionalClass='mt-9 mr-5'
										/>
										<Button
											type='Outlined'
											text='Cancel'
											onClick={() => {
												setinviteModalOpen(false)
											}}
											additionalClass='mt-6 mr-5'
										/>
									</>
								)}
							</>
						)}

						{createInvitationStatus === PostStatus.sending && (
							<LoadingSpinner loadingText='Creating invitation...' />
						)}

						{createInvitationStatus === PostStatus.sent && (
							<>
								<PageHeadings title='Invitation sent successfully' />
								<p className='text-white mt-6'>Your invitation has been sent successfully</p>
								<Button
									type='Primary'
									text='Back to invitations'
									onClick={() => {
										setinviteModalOpen(false)
										navigate(routes.companySupplyChainRequests.path)
									}}
									additionalClass='mt-6 mr-5'
								/>

								<Button
									type='Outlined'
									text='Invite another supplier'
									icon='fa-regular fa-plus'
									hasTrailingIcon
									onClick={() => {
										resetModal()
										navigate(routes.companyInviteSupplierList.path)
									}}
									additionalClass='mt-6'
								/>
							</>
						)}

						{createInvitationStatus === PostStatus.error && (
							<>
								<PageHeadings title='Error' />
								<div className='rounded-lg bg-error-500 p-4 mt-4'>
									<p className='text-white'>
										There was an error whilst trying to send the invitation. If this problem
										persists, please contact the Neoni team.
									</p>
								</div>
								<Button
									onClick={() => {
										if (
											companyIdToInvite !== undefined &&
											email !== undefined &&
											sendEmail !== undefined
										) {
											dispatch(
												createInvitationCompany(api, companyIdToInvite, email, sendEmail, supplierId)
											)
										}
									}}
									type='Primary'
									hasTrailingIcon={true}
									icon='fa-solid fa-arrow-right'
									text='Retry'
									additionalClass='mt-9 mr-5'
								/>
								<Button
									type='Outlined'
									text='Cancel'
									onClick={() => {
										setinviteModalOpen(false)
									}}
									additionalClass='mt-6 mr-5'
								/>
							</>
						)}
					</Modal>
				</>
			)}

			{suppliersStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading supplier...' />
			)}

			{suppliersStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white mb-4'>
						There was an error whilst trying to fetch this supplier. If this problem persists,
						please contact the Neoni team.
					</p>
					{suppliersError && (
						<p className='text-white mb-4'>Error message: {suppliersError.toString()}</p>
					)}
					<Button
						type='Primary'
						hasLeadingIcon
						additionalClass='mt-4'
						icon='fa-regular fa-arrow-left'
						text='Return to suppliers overview'
						onClick={() => {
							navigate(routes.companySuppliers.path)
						}}
					/>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyInviteSupplier
