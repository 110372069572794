import { LineChart, Line, XAxis, YAxis, Legend } from "recharts";


type ILineGraphData = {
	data: any
	heading?: string
	xAxisValue?: string
}

let getBlueColor = (index: number, totalCount: number) => {
	const colorOffset = 140 + index * 420 / totalCount
	const green = Math.min(colorOffset, 255)
	const red = Math.max(Math.min(9 + colorOffset - 255, 255), 0)
	return `rgb(${red}, ${green}, 255`
}

export default function LineChartComponent(props: ILineGraphData) {
	const { data, heading, xAxisValue } = props
	const names = new Set<string>()
	data.forEach( (sum: any) => {
		Object.keys(sum).slice(1).forEach( label => names.add(label))
	})
	return (
		<>
			<h2 className='text-white text-xl font-bold mb-2 mt-2'>
				{heading}
			</h2>
			<LineChart data={data} width={900} height={400}>
				<XAxis
					dataKey={xAxisValue}  
					style={{ fontSize: '12px' }}
					tick={{ fill: '#FFF' }}
				/>
				<YAxis/>
				{ [...names.values()].map((name, index) => {
					const color = getBlueColor(index, names.size)
					return (
						<Line
							key={name}
							dataKey={name}
							fill={color}
							name={name}
							connectNulls
							type='monotone'
							stroke={color}
						/>
					)
				})}
				<Legend
					width={320}
					align="right"
					verticalAlign="middle"
					iconType="square"
					layout="vertical"
					wrapperStyle={{left: 600, top: 0}}
				/>
			</LineChart>
		</>
	);
}
