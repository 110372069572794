import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invitation } from '../../types/Invitation'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type InvitationsState = {
	status: FetchStatus
	error?: string
	invitations: Array<Invitation>
}
const invitationsInitialState: InvitationsState = {
	status: FetchStatus.initial,
	error: undefined,
	invitations: [],
}

export const invitationsSlice = createSlice({
	name: 'invitations',
	initialState: invitationsInitialState,
	reducers: {
		INVITATIONS_FETCHING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		INVITATIONS_SUCCESS: (state, action: PayloadAction<Array<Invitation>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				invitations: action.payload,
			}
		},
		INVITATIONS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		INVITATIONS_RESET: () => {
			return invitationsInitialState
		},
		INVITATIONS_INSERT: (state, action: PayloadAction<Invitation>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				invitations: [action.payload, ...state.invitations],
			}
		},
		INVITATIONS_UPDATING: (state, action: PayloadAction<string>) => {
			// Flag invitation as updating, mark updated as incomplete
			return {
				...state,
				invitations: state.invitations.map((invitation) => ({
					...invitation,
					updating: action.payload === invitation.id ? true : invitation.updating,
					updated: action.payload === invitation.id ? false : invitation.updated,
				})),
			}
		},
		INVITATIONS_UPDATED_REVOKED: (state, action: PayloadAction<Invitation>) => {
			// Update invitation status, remove updating flag, add updated flag
			return {
				...state,
				invitations: state.invitations.map((invitation) => ({
					...invitation,
					updating: action.payload.id === invitation.id ? false : invitation.updating,
					updated: action.payload.id === invitation.id ? true : invitation.updated,
					status: action.payload.id === invitation.id ? action.payload.status : invitation.status,
				})),
			}
		},
		INVITATIONS_UPDATED_RESEND: (
			state,
			action: PayloadAction<{
				revokedInvite: string
				invite: Invitation
			}>
		) => {
			// Insert new invitation
			// Set old invitation as updated, updating false, and status 'revoked'
			return {
				...state,
				invitations: [
					action.payload.invite,
					...state.invitations.map((invitation) => ({
						...invitation,
						updating: action.payload.revokedInvite === invitation.id ? false : invitation.updating,
						updated: action.payload.revokedInvite === invitation.id ? true : invitation.updated,
						status: action.payload.revokedInvite === invitation.id ? 'revoked' : invitation.status,
					})),
				],
			}
		},
		INVITATIONS_FAILURE_UPDATE: (state, action: PayloadAction<{ id: string; error: string }>) => {
			// Remove updating flag, populate error, add updated flag
			return {
				...state,
				invitations: state.invitations.map((invitation) => ({
					...invitation,
					updating: action.payload.id === invitation.id ? false : invitation.updating,
					updated: action.payload.id === invitation.id ? false : invitation.updated,
					error: action.payload.id === invitation.id ? action.payload.error : invitation.error,
				})),
			}
		},
	},
})

// Action types
export const {
	INVITATIONS_FETCHING,
	INVITATIONS_SUCCESS,
	INVITATIONS_ERROR,
	INVITATIONS_RESET,
	INVITATIONS_UPDATING,
	INVITATIONS_UPDATED_REVOKED,
	INVITATIONS_UPDATED_RESEND,
	INVITATIONS_FAILURE_UPDATE,
	INVITATIONS_INSERT,
} = invitationsSlice.actions

// Selectors
export const selectInvitationsStatus = (state: RootState) => state.invitations.status
export const selectInvicationsError = (state: RootState) => state.invitations.error
export const selectInvitations = (state: RootState) => state.invitations.invitations

export default invitationsSlice.reducer
