import { useState } from "react";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

type EditPortfolioModalProps = {
    onClose: () => void,
    onEdited: (newId: string) => void
    isOpen?: boolean,
    portfolioId: string,
    portfolioName: string;
};

function EditPortfolioModal({onClose, onEdited, isOpen, portfolioId, portfolioName}: EditPortfolioModalProps) {
    const [label, setLabel] = useState(portfolioName);
    const api = useApi();

    const [loading, setLoading] = useState(false)

    const edit = async () => {
        setLoading(true)
        const result = await api.put(`/portfolios/${portfolioId}`, {
            name: label,
            _csrf: await getCSRFToken(),
        })
        console.log(result);
        onEdited(result.data.id);
        setLoading(false);
    }

    return (
        <Modal open={!!isOpen} onClose={onClose} size="small">
            <div className="flex flex-col">
                <PageHeadings title={`Edit portfolio ${portfolioName}`} />
                { loading  ? (
                    <LoadingSpinner />
                ): (
                    <>
                        <label className="mt-8 text-charcoal-100">Portfolio Name</label>
                        <input
                            className="rounded"
                            value={label}
                            onChange={e => setLabel(e.target.value)} type="text"
                            placeholder="Portfolio name..."
                        />
                        <div className="mt-8 grow">
                            <Button onClick={edit} additionalClass="mr-5" disabled={!label} type="Primary" text="Save Portfolio" />
                            <Button onClick={onClose} type="Outlined" text="Cancel" />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default EditPortfolioModal;
