/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, LoadingSpinner } from '../../../components/BaseComponents'
import PageHeadings from '../../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../../components/BaseComponents/PageTemplate/PageTemplate'
import Pagination from '../../../components/BaseComponents/Pagination/Pagination'
import { routes } from '../../../components/Router/routes'
import { getSuppliers, GET_SUPPLIERS_LIMIT } from '../../../redux/actions/suppliersActions'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/reduxHooks'
import {
	selectAllSuppliers,
	selectSuppliersPagination,
	selectSuppliersStatus,
	SUPPLIERS_RESET,
} from '../../../redux/slices/suppliersSlice'
import { FetchStatus } from '../../../types/LoadingStates'
import useApi from '../../../hooks/useApi'
import { generatePath } from 'react-router-dom'

const CompanyInsightsSuppliers = () => {
	const dispatch = useAppDispatch()
	const api = useApi();
	const pagination = useAppSelector(selectSuppliersPagination)
	const endpointStatus = useAppSelector(selectSuppliersStatus)
	const allSuppliers = useAppSelector(selectAllSuppliers)

	useEffect(() => {
		dispatch({ type: SUPPLIERS_RESET })
		dispatch(getSuppliers(api, { connectedSuppliers: true }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<PageTemplate testid='company-dashboard-main'>
				<Helmet>
					<title>{routes.companyInsightsSuppliers.name}</title>
				</Helmet>
				<PageHeadings title='Insights' subTitle='Suppliers' />

				{endpointStatus === FetchStatus.loading && (
					<LoadingSpinner loadingText='Loading connected suppliers...' />
				)}

				{endpointStatus === FetchStatus.loaded && (
					<>
						{allSuppliers.length > 0 ? (
							<div className='mt-8'>
								<div className='grid grid-cols-3 gap-4'>
									{allSuppliers.map((supplier) => (
										<div
											className='bg-charcoal-800 rounded-lg p-4 flex flex-col justify-between'
											key={supplier.id}
										>
											<h2 className='text-lg font-semibold text-white mb-4'>{supplier.name}</h2>
											<Button
												isLink={true}
												url={generatePath(routes.companyInsightsSupplierTrends.path, { supplierId: supplier.id })}
												type='Outlined'
												text='View Performance'
												hasLeadingIcon
												fullWidth
												icon='fa-regular fa-arrow-trend-up'
											/>
										</div>
									))}
								</div>
								<Pagination
									pagination={pagination}
									onPageChange={(newPageNumber) => {
										dispatch(
											getSuppliers(api, {
												page: newPageNumber,
												limit: GET_SUPPLIERS_LIMIT,
												orderBy: 'name',
												direction: 'asc',
												connectedSuppliers: true,
											})
										)
									}}
								/>
							</div>
						) : (
							<div className='rounded-lg bg-charcoal-600 p-4 mt-8'>
								<p className='text-white'>
									Sorry! We couldn't find any connected suppliers for your accounts.
								</p>
							</div>
						)}
					</>
				)}

				{endpointStatus === FetchStatus.error && (
					<div className='rounded-lg bg-error-500 p-4 mt-4'>
						<p className='text-white'>
							There was an error fetching connected suppliers associated with this organisation. If this
							problem persists, please contact the Neoni team.
						</p>
					</div>
				)}
			</PageTemplate>
		</>
	)
}
export default CompanyInsightsSuppliers
