import classNames from 'classnames'
import { useContext } from 'react'
import { generatePath, Link, NavLink, useMatch } from 'react-router-dom'
import { AppRoute } from '../../types/AppRoute'
import routes from '../Router/routes'
import { userContext } from '../../context/UserContext'

type MenuItemProps = Pick<AppRoute, 'name'|'path'|'icon'|'menuName'|'id'> & {
	childPages: AppRoute[],
};

const MenuItem = ({ name, path, icon, menuName, childPages }: MenuItemProps) => {
	let expanded: boolean = false

	// If the route is parent path matched and there are children, we should show the parent as expanded
	const inParentPath = useMatch(path + '/*')
	if (inParentPath !== null) {
		expanded = true
	}

	return (
		<>
			<NavLink
				end
				to={ generatePath(path) }
				className={({ isActive }) =>
					`${
						isActive ? 'text-charcoal-900 bg-algae-200' : 'text-charcoal-050 hover:text-white'
					} flex items-center p-md text-sm font-semibold rounded mb-md`
				}
			>
				{({ isActive }) => (
					<>
						<span
							className={`${icon} mr-md ${isActive ? 'text-charcoal-500' : 'text-algae-200'}`}
						></span>
						<div className='flex justify-between items-center w-full'>
							<span>{menuName ? menuName : name}</span>

							{/* Show a chevron if there's children */}
							{childPages.length > 0 && (
								<>
									<span
										className={classNames('fa-regular pr-1', {
											'fa-chevron-down': expanded,
											'fa-chevron-right': !expanded,
											'text-charcoal-500': isActive,
											'text-white': !isActive,
										})}
									></span>
								</>
							)}
						</div>
					</>
				)}
			</NavLink>

			{/* Render any children pages for this ID */}
			{expanded &&
				childPages.map((menuItem) => (
					<NavLink
						key={menuItem.path}
						to={ generatePath(menuItem.path) }
						className={({ isActive }) =>
							`${
								isActive ? 'text-charcoal-900 bg-algae-200' : 'text-algae-500 hover:text-white'
							} flex items-center p-md text-sm font-semibold rounded mb-md pl-10`
						}
					>
						<span>{menuItem.menuName ? menuItem.menuName : menuItem.name}</span>
					</NavLink>
				))}
		</>
	)
}

type SidebarProps = {
	validRoutes: AppRoute[];
}

const Sidebar = ({validRoutes}: SidebarProps) => {
	// const userData = useUserData();
	const { userData } = useContext(userContext);

	if(!userData) {
		return null;
	}

	const { companyName, fullName, access } = userData;

	// const permittedroutes = orderedRoutes.filter(({rolesAllowed, accessRequired, parentId, showInSidebar}) => {
	// 	const hasRole = !rolesAllowed || rolesAllowed.includes(role) && typeof parentId === 'undefined';
	// 	const hasPermission = !accessRequired || accessRequired.every(accessString => accessStrings[accessString])
	// 	return hasRole && hasPermission && showInSidebar;
	// })

	const shownRoutes = validRoutes.filter(route => route.showInSidebar && !route.parentId);

	const topRoutes = shownRoutes.filter((route) => !route.showAtBottom && route.showInSidebar)
	const bottomRoutes = shownRoutes.filter((route) => route.showAtBottom && route.showInSidebar)

	return (
		<>
			<div
				data-testid='sidebar'
				className='w-1/6 md:w-1/5 p-sm bg-charcoal-700 flex flex-col shrink-0'
			>
				<h1 className='text-white mt-1 ml-4 font-bold mb-2'>
					{userData.original.companyName}
					<div className="text-xs">
						Logged in as { fullName }
					</div>
				</h1>
				{access?.assumedCompanyType && (
					<div
						className='bg-sun-500 mt-1 font-bold mx-2 mb-2 p-md rounded'
						
					>
						<div className="text-xs flex">
							<div className="grow">Accessing</div>
							<Link to={routes.clearAssumedCompany.path}>
								<span className="fa-regular fa-xmark" />
							</Link>
						</div>
						<div>{companyName}</div>
					</div>
				)}
				<nav className='p-md h-full flex flex-col justify-between' aria-label='Sidebar'>
					<div>
						{topRoutes.map(
							({ path, name, icon, menuName, id }: AppRoute) => {
								// Show as top level menu item if:
								//	- Enabled in side bar
								// 	- Is not a child page (has a parentId)
								const childPages = validRoutes.filter(route => {
									return id && route.parentId === id
								});
								return (
									<MenuItem
										key={path}
										name={name}
										icon={icon}
										path={path}
										menuName={menuName}
										childPages={childPages}
									/>
								)
							}
						)}
					</div>

					{/* Bottom aligned menu items */}
					<div>
						{bottomRoutes.map(
							({ path, name, icon, menuName, id}: AppRoute) => {
								const childPages = topRoutes.filter(route => {
									return id && route.parentId === id
								});
								return (
									<MenuItem
										key={path}
										name={name}
										icon={icon}
										path={path}
										menuName={menuName}
										childPages={childPages}
									/>
								)
							}
						)}
						<Link
							className='text-charcoal-050 hover:text-white flex items-center p-md text-sm font-semibold rounded'
							to={routes.publicLogout.path}
						>
							<span className='fa-light fa-arrow-right-from-bracket mr-md'></span>
							<span>Logout</span>
						</Link>
					</div>
				</nav>
			</div>
		</>
	)
}

export default Sidebar
