import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button, Input, LoadingSpinner } from '../components/BaseComponents'
import { DataGridColumn } from '../components/BaseComponents/Datagrid/Datagrid'
import { SortConfig } from '../components/BaseComponents/Datagrid/useSortableData'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import Pagination from '../components/BaseComponents/Pagination/Pagination'
import SimpleSortTable from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import routes from '../components/Router/routes'
import { useEffectWithoutInitialRender } from '../hooks/useEffectWithoutInitialRender'
import { getSuppliers, GET_SUPPLIERS_LIMIT } from '../redux/actions/suppliersActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectAllSuppliers,
	selectSuppliersPagination,
	selectSuppliersStatus,
} from '../redux/slices/suppliersSlice'
import { FetchStatus } from '../types/LoadingStates'
import { Supplier } from '../types/Supplier'
import useApi from '../hooks/useApi'

const CompanyInviteSupplierList = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	const suppliersStatus = useAppSelector(selectSuppliersStatus)
	const pagination = useAppSelector(selectSuppliersPagination)
	const allSuppliers = useAppSelector(selectAllSuppliers)

	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	const orderBy = 'name'
	const direction = 'asc'

	// Data loading
	useEffect(() => {
		dispatch(getSuppliers(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// DataGrid Columns
	const columns: DataGridColumn[] = [
		{ id: 'supplierName', label: 'Supplier', sortable: false },
		{ id: 'isicCodes', label: 'Sector Code (ISIC)', sortable: false },
		{
			id: 'jurisdiction',
			label: 'Jurisdiction',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>{rowData.jurisdiction_code}</p>
					</>
				)
			},
		},
		{
			id: 'invite',
			label: '',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<Button
						type='Primary'
						size='sm'
						text='Invite supplier'
						hasLeadingIcon
						icon='fa-regular fa-user-plus'
						accessibilityLabel={rowData.itemName}
						onClick={() => {
							navigate(generatePath(
								routes.companyInviteSupplier.path,
								{ supplierId: rowData._id }
							))
						}}
					/>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'supplierName',
		direction: 'descending',
	}

	// DataGrid data constructor
	const constructData = (dataSet: Array<Supplier>) => [
		...dataSet.map((supplier) => {
			return {
				_id: supplier.id,
				supplierName: supplier.name,
				isicCodes: supplier.supplierIndustryCode?.code || '-',
				jurisdiction_code: supplier.jurisdiction_code || '-',
				invite: '',
			}
		}),
	]

	// Timeout for search
	useEffectWithoutInitialRender(() => {
		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			dispatch(
				getSuppliers(api, {
					page: pagination.first_page,
					limit: GET_SUPPLIERS_LIMIT,
					orderBy: orderBy,
					direction: 'asc',
					q: searchKeyword,
				})
			)

			setSearchInProgress(false)
		}, 500)

		if (searchKeyword !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchKeyword])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyInviteSupplierList.name}</title>
			</Helmet>
			<PageHeadings
				title='External Network'
				subTitle='Invite Supplier'
				routeBack={routes.companySupplyChainRequests.path}
			/>

			{suppliersStatus === FetchStatus.loaded && (
				<>
					<div className='items-start'>
						<div className='relative max-w-2xl'>
							<label htmlFor='filterItems' className='sr-only'>
								Filter suppliers
							</label>
							<Input
								type={'text'}
								name={'filterSuppliers'}
								placeholder='Search supplier name or Sector Code (ISIC)'
								value={searchKeyword}
								onChange={(event) => {
									setSearchKeyword(event.target.value)
								}}
							/>
							<label htmlFor='filterPurchases' className='sr-only'>
								Filter suppliers
							</label>
							<Button
								hasLeadingIcon
								type='White'
								size='sm'
								icon={` ${
									searchInProgress
										? 'fas fa-loader fa-spin'
										: searchKeyword.length > 0
										? 'fa-solid fa-times'
										: 'fa-solid fa-search'
								}`}
								additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
								onClick={() => {
									setSearchKeyword('')
									dispatch(
										getSuppliers(api, {
											page: pagination.first_page,
											limit: GET_SUPPLIERS_LIMIT,
											orderBy: orderBy,
											direction: direction,
											q: '',
										})
									)
								}}
							/>
						</div>
					</div>

					<SimpleSortTable
						additionalClass='mt-4'
						data={constructData(allSuppliers)}
						columns={columns}
						defaultSorting={defaultSorting}
					/>

					<Pagination
						pagination={pagination}
						onPageChange={(newPageNumber) => {
							dispatch(
								getSuppliers(api, {
									page: newPageNumber,
									limit: GET_SUPPLIERS_LIMIT,
									orderBy: orderBy,
									direction: direction,
									q: searchKeyword,
								})
							)
						}}
					/>
				</>
			)}

			{suppliersStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading suppliers...' />
			)}

			{suppliersStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching suppliers associated with this organisation. If this problem
						persists, please contact the Neoni team.
					</p>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyInviteSupplierList
