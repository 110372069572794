import { Dispatch } from 'redux'
import {
	PERIODICREPORTS_ERROR,
	PERIODICREPORTS_LOADED,
	PERIODICREPORTS_LOADING,
	PERIODICREPORTS_SUPPLYCHAIN_ERROR,
	PERIODICREPORTS_SUPPLYCHAIN_LOADED,
	PERIODICREPORTS_SUPPLYCHAIN_LOADING,
} from '../slices/periodicReportingSlice'
import { NeoniApi } from '../../lib/api'

export const getPeriodicReports = (api: NeoniApi, accountingPeriodId: string) => async (dispatch: Dispatch) => {
	dispatch({
		type: PERIODICREPORTS_LOADING,
	})

	try {
		const body = await api.get(`reports/${accountingPeriodId}/report`);

		if (body.success === true) {
			// Successfully fetched period statistics
			dispatch({
				type: PERIODICREPORTS_LOADED,
				payload: body.data,
			})
		} else {
			// Error
			dispatch({
				type: PERIODICREPORTS_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: PERIODICREPORTS_ERROR,
			payload: error,
		})
	}
}

//TODO: replace with proper url of the endpoint
export const getPeriodicReportsSupplyChain =
	(api: NeoniApi, accountingPeriodId: string) => async (dispatch: Dispatch) => {
		dispatch({
			type: PERIODICREPORTS_SUPPLYCHAIN_LOADING,
		})

		try {
			const body = await api.get(`reports/${accountingPeriodId}/decarbonisationReport`)

			if (body.success === true) {
				// Successfully fetched period statistics
				dispatch({
					type: PERIODICREPORTS_SUPPLYCHAIN_LOADED,
					payload: body.data,
				})
			} else {
				// Error
				dispatch({
					type: PERIODICREPORTS_SUPPLYCHAIN_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: PERIODICREPORTS_SUPPLYCHAIN_ERROR,
				payload: error,
			})
		}
	}
