import { Helmet } from 'react-helmet'
import { Button } from '../../components/BaseComponents'
import PageHeadings from '../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../components/BaseComponents/PageTemplate/PageTemplate'
import { routes } from '../../components/Router/routes'

const CompanyInsights = () => {
	return (
		<>
			<PageTemplate testid='company-dashboard-main'>
				<Helmet>
					<title>{routes.companyInsights.name}</title>
				</Helmet>
				<PageHeadings title='Insights' subTitle='Overview' />
				<div className='mt-8'>
					<div className='bg-charcoal-700 rounded-lg p-4'>
						<h2 className='text-lg font-semibold text-white'>Carbon Account Trends</h2>
						<p className='mt-4 mb-8 text-white'>
							See how your purchases affect your Carbon Accounts from year to year.
						</p>

						<Button
							isLink={true}
							url={routes.companyInsightsTrends.path}
							type='Outlined'
							text='Carbon Account Trends'
							hasTrailingIcon
							icon='fa-regular fa-chevron-right'
						/>
					</div>
				</div>
			</PageTemplate>
		</>
	)
}
export default CompanyInsights
