import Select from 'react-select'
import { Button, LoadingSpinner } from "../../../components/BaseComponents"
import { useEffect, useState } from "react"
import { SelectTypeOption } from "../../../types/SelectTypeOptions"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/reduxHooks"
import { getAccountingPeriods } from "../../../redux/actions/accountingPeriodsActions"
import { selectAccountingPeriods, selectAccountingPeriodsStatus } from "../../../redux/slices/accountingPeriodsSlice"
import { FetchStatus } from "../../../types/LoadingStates"
import { getPeriodicReportsSupplyChain } from "../../../redux/actions/periodicReportsActions"
import { selectAllPeriodicSupplyChainReports, selectPeriodicReportsSupplyChainError, selectPeriodicReportsSupplyChainStatus } from "../../../redux/slices/periodicReportingSlice"
import { Insight, InsightGroup } from './Insights'
import useApi from '../../../hooks/useApi'


const CompanyInsightsCarbonAccounts = () => {
	const dispatch = useAppDispatch()
	const api = useApi();

	const [accountingTypeOptions, setaccountingTypeOptions] = useState<Array<SelectTypeOption>>([])

	const accountingPeriodsStatus = useAppSelector(selectAccountingPeriodsStatus)
	const allAccountingPeriodData = useAppSelector(selectAccountingPeriods).filter(
		(period) => period.status !== 'WAITING_FOR_UPLOAD' && period.upload_state !== null
	)

	const [thisAccountingPeriod, setThisAccountingPeriod] = useState(allAccountingPeriodData.slice().reverse()[0])

	const [errorNoPeriod, setErrorNoPeriod] = useState(false)
	const periodicReportsStatus = useAppSelector(selectPeriodicReportsSupplyChainStatus)
	const periodicReportsError = useAppSelector(selectPeriodicReportsSupplyChainError)
	const periodicReportsData = useAppSelector(selectAllPeriodicSupplyChainReports)

	useEffect(() => {
		// Fetch accounting periods if they aren't in the store, we need to compare against the IDs
		if (accountingPeriodsStatus === FetchStatus.initial) {
			dispatch(getAccountingPeriods(api))
		}
		if (accountingPeriodsStatus === FetchStatus.loaded) {
			setaccountingTypeOptions(
				allAccountingPeriodData
					.filter((period) => period.status !== 'WAITING_FOR_UPLOAD')
					.map((period) => {
						return {
							value: period.id,
							label: `${period.start_date} - ${period.end_date}`,
							companyid: period.company_id,
						}
					})
			)
			const accountingPeriod = allAccountingPeriodData.slice().reverse()[0];
			setThisAccountingPeriod(accountingPeriod)
			if (accountingPeriod) {
			} else {
				setErrorNoPeriod(true)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountingPeriodsStatus])

	useEffect(() => {
		if(thisAccountingPeriod) {
			dispatch(getPeriodicReportsSupplyChain(api, thisAccountingPeriod.id))
		}
	}, [thisAccountingPeriod, dispatch])
	// Change selected accounting period
	const onSelectAccountingPeriod = (event: any) => {
		const id = event.value
		const accountingPeriod = allAccountingPeriodData.find(period => period.id === id)
		if(accountingPeriod) {
			setThisAccountingPeriod(accountingPeriod)
		}
	}

	console.log(periodicReportsData)
	if(periodicReportsError) {
		return (
			<div className='rounded-lg bg-error-500 p-4 mt-4'>
				<p className='text-white'>
					There was an error whilst trying to generate reports. If this problem persists, please
					contact the Neoni team.
				</p>
			</div>
		)
	}

	if (accountingPeriodsStatus !== FetchStatus.loaded || periodicReportsStatus !== FetchStatus.loaded) {
		return <LoadingSpinner loadingText='Loading accounting periods...' />
	}

	if(errorNoPeriod) {
		return (
			<div className='rounded-lg bg-error-500 p-4 mt-4'>
				<p className='text-white'>
					There are no completed accounting periods to view.
				</p>
			</div>
		)
	}

	const sectorGraphData = periodicReportsData && periodicReportsData.report.find(insight => insight.content.graphKey === 'emissions_by_sectors') 
	const sectorEmissionsGraphData = periodicReportsData && periodicReportsData.report.find(insight => insight.content.graphKey === 'embedded_improvements_sectors') 
	const supplierEmissionGraphData = periodicReportsData && periodicReportsData.report.find(insight => insight.content.graphKey === 'embedded_improvements_suppliers') 
	const companyName = periodicReportsData && periodicReportsData.companyName
	const totalEmissions = periodicReportsData && periodicReportsData.totalEmissions
	return (
		<>
			<div className="rounded bg-charcoal-500">
				<div className="flex justify-between">

					<div className="mt-6 flex space-x-2">
						<span className='text-charcoal-100 mt-2'>Select Reporting Period</span>
						<Select
							className="w-72"
							options={accountingTypeOptions}
							placeholder='DD/MM/YY - DD/MM/YY'
							onChange={(option) => {
								onSelectAccountingPeriod(option)
							}}
							value={thisAccountingPeriod ? accountingTypeOptions.find( ({value}) => value === thisAccountingPeriod.id ) : null}
						/>
					</div>
					<div className="mt-6 flex space-x-2">
						<Button
							type='Primary'
							size='sm'
							onClick={() => { }}
							text='Export as PDF'
							icon='fa-regular fa-file-export'
							hasTrailingIcon
						/>
					</div>
				</div>
				<div className="mt-4 bg-charcoal-800 p-4">
					<div className="flex justify-between text-white">
						<div>
							<p className="text-2xl">{ companyName }</p>
							<p className="mt-2 text-sm text-charcoal-100">
								{ thisAccountingPeriod.start_date } - { thisAccountingPeriod.end_date }
							</p>
						</div>
						<div><p className="text-sm text-charcoal-100">Total Emissions</p><p className="space-x-2"><span className="mt-2 text-2xl">{ totalEmissions }</span><span className="text-sm text-charcoal-100">Kg CO₂e</span></p></div>
					</div>

					{/* { periodicReportsData && periodicReportsData.report.map(insight => {
						return insight.groupKey ? null : <Insight insight={insight} />
					})} */}
					{ periodicReportsData && (
						<>
							{ sectorGraphData && <Insight insight={sectorGraphData} /> }
							<InsightGroup groupKey="total_embedded_emissions" heading="Total Embedded Emissions" insights={periodicReportsData.report} />
							{ sectorEmissionsGraphData && <Insight insight={sectorEmissionsGraphData} /> }
							{ supplierEmissionGraphData && <Insight insight={supplierEmissionGraphData} /> }
						</>
					)}
				</div>
			</div>
		</>
	)
}
export default CompanyInsightsCarbonAccounts
