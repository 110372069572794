import { Helmet } from 'react-helmet'
import PageHeadings from '../../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../../components/BaseComponents/PageTemplate/PageTemplate'
import TabList from '../../../components/BaseComponents/TabList/TabList'
import { routes } from '../../../components/Router/routes'
import CompanyInsightsCarbonAccounts from './CarbonAccounts'
import CompanyInsightsSupplyChain from './SupplyChainDecarbonisation'

const CompanyInsightsPeriodicReportsPage = () => {
	return (
		<>
			<PageTemplate testid='company-dashboard-main'>
				<Helmet>
					<title>{routes.companyInsightsPeriodicReports.name}</title>
				</Helmet>
				<PageHeadings title='Insights' subTitle='Periodic Reports' />

				<TabList
					additionalContainerClass='mt-8'
					tabs={[
						{
							label: 'Carbon Accounts',
							component: (activeTab) => (
								<CompanyInsightsCarbonAccounts />
							),
						},
						{
							label: 'Supply Chain Decarbonisation',
							component: (activeTab) => (
								<>
									{/* <p className='text-white p-4'>
										<span className='text-algae-300'>Coming soon:</span> compare sectors and suppliers
										for carbon intensity ratio
									</p> */}
									<CompanyInsightsSupplyChain />
								</>
							),
						},
					]}
				/>
			</PageTemplate>
		</>
	)
}
export default CompanyInsightsPeriodicReportsPage
