import { useEffect, useState } from "react";
import { PaginationMeta } from "../types/PaginationMeta";

function usePagination(page?: number, perPage?: number) {
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [currentPerPage] = useState(perPage || 10)

    const [params, setParams] = useState({
        page: currentPage.toString(),
        perPage: currentPerPage.toString(),
    });

    useEffect(() => {
        setParams({
            page: currentPage.toString(),
            perPage: currentPerPage.toString(),
        })
    }, [currentPage, currentPerPage])

    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [firstPage, setFirstPage] = useState(1);

    return {
        readMeta: (meta: PaginationMeta) => {
            setTotal(meta.total);
            setLastPage(meta.last_page);
            setFirstPage(meta.first_page);
        },
        onPageChange: (newPage: number) => {
            setCurrentPage(newPage);
        },
        params,
        pagination: {
            total,
            per_page: currentPerPage,
            current_page: currentPage,
            last_page: lastPage,
            first_page: firstPage,
        }
    }
}

export default usePagination;
