import { Helmet } from "react-helmet";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../../components/Router/routes";
import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCSRFToken, getCurrencySymbol } from "../../lib/utils";
import useCurrency from "../../hooks/useCurrency";
import useApi from "../../hooks/useApi";
import useUserData from "../../hooks/useUserData";
import useAddNotification from "../../hooks/useAddNotification";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import SecrPublishConfirmation from "./SecrPublishConfirmation";
import Label from "../../components/BaseComponents/Label";
import useCompanyData from "../../hooks/useCompanyData";
import dayjs from "dayjs";

const LINE_ITEM_LIMIT = 10;
const NUMBER_PLACEHOLDER = '#';
const TEXT_PLACEHOLDER = 'Text field';

type HeaderData = {
    organisation: string;
    address: string;
    registrationNumber: string;
    legalForm: string;
    jurisdiction: string;
}

function Row({children}: PropsWithChildren) {
    return (
        <div
            role='row'
            className='table-row w-full first:border-b last:pb-4 border-charcoal-050'
        >
            { children }
        </div>
    );
}

type IColProps = PropsWithChildren<{
    indent?: number
    header?: boolean
    alignRight?: boolean,
    className?: string,
}>

function Col({children, indent, header, alignRight, className}: IColProps) {
    let classNames = 'p-1 table-cell '
    classNames += indent ? ' pl-' + indent * 4 : '';
    classNames += alignRight ? ' text-right' : '';
    classNames += ' ' + (className || '');
    return (
        <div
            className={classNames}
        >
            { header 
                ? <span className="text-xs">children</span>
                : children
            }
        </div>
    );
}

function SecrCreate() {
    const { state } = useLocation();
    const periodId = state?.periodId;
    const prefilled = !!periodId;
    const api = useApi();
    const [data, setData] = useState<any>({});
    const { reportId } = useParams();

    const [defaults, setDefaults] = useState<any>();

    const [loadingPrefilled, setLoadingPrefilled] = useState(prefilled || !!reportId);
    const [loadingDefaults, setLoadingDefaults] = useState(true);
    const userData = useUserData();
    const { companyData } = useCompanyData(userData.companyId);

    const accountingPeriodId = periodId || data.accountingPeriodId;

    useEffect(() => {
        if(!periodId) {
            return;
        }

        let cancelled = false;
        const run = async () => {
            const result = await api.get(`/secr/fromAccountingPeriod/${periodId}`);
            if(cancelled) {
                return;
            }
            setData(result.data);
            setLoadingPrefilled(false);
        }

        run();

        return () => {
            cancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodId])
    
    useEffect(() => {
        if(!reportId) {
            return;
        }

        let cancelled = false;
        const run = async () => {
            const result = await api.get(`/secr/${reportId}`);
            if(cancelled) {
                return;
            }
            setData(result.data.report);
            setLoadingPrefilled(false);
        }

        run();

        return () => {
            cancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        let cancelled = false;
        const run = async () => {
            const result = await api.get(`/secr/defaults`);
            if(cancelled) {
                return;
            }
            setDefaults(result.data);
            setLoadingDefaults(false);
        }

        run();

        return () => {
            cancelled = true;
        };
    }, [])

    if(loadingPrefilled || loadingDefaults) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <SecrReportInput
            key={periodId}
            prefilledData={data}
            prefilled={prefilled}
            overwriteReportId={reportId}
            accountingPeriodId={accountingPeriodId}
            prefilledHeaderData={{
                organisation: companyData?.name || '',
                legalForm: companyData?.type || '',
                address: companyData?.address || '',
                jurisdiction: companyData?.jurisdiction_code || '',
                registrationNumber: String(companyData?.number || ''),
            }}
            defaults={defaults}
        />
    )
}

type NumberInputProps = {
    value: string;
    onChange: (value: string) => void
    fullWidth?: boolean
    placeholder?: string;
    readonly?: boolean;
}

function NumberInput({value, onChange, fullWidth=false, placeholder, readonly=false}: NumberInputProps) {
    return (
        <input
            disabled={readonly}
            className={`text-charcoal-900 rounded ${fullWidth ? 'w-full': 'w-36'} text-right disabled:bg-charcoal-100`}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            onBlur={e => {
                const asNumber = parseFloat(e.target.value);
                if(Number.isNaN(asNumber)) {
                    onChange('')
                } else {
                    onChange(asNumber.toString())
                }
            }}
        />
    )
}

type SecrReportInputProps = {
    prefilledData?: any
    prefilledHeaderData?: HeaderData
    prefilled?: boolean
    defaults: {
        methodologyText: string;
        disclosureText: string;
    }
    accountingPeriodId?: string;
    overwriteReportId?: string;
}

function SecrReportInput({
    prefilledData = {}, 
    prefilledHeaderData,
    prefilled=false,
    defaults,
    accountingPeriodId,
    overwriteReportId,
}: SecrReportInputProps) {
    const addNotification = useAddNotification();
    const navigate = useNavigate();
    const api = useApi();

    // Header data
    const [organisation, setOrganisation] = useState(prefilledHeaderData?.organisation);
    const [registrationNumber, setRegistrationNumber] = useState(prefilledHeaderData?.registrationNumber);
    const [legalForm, setLegalForm] = useState(prefilledHeaderData?.legalForm);
    const [address, setAddress] = useState(prefilledHeaderData?.address);
    const [jurisdiction, setJurisdiction] = useState(prefilledHeaderData?.jurisdiction);

    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    const { fullName } = useUserData();
    const [signeeName, setSigneeName] = useState(prefilledData.signeeName || fullName);
    const [signeeTitle, setSigneeTitle] = useState(prefilledData.signeeTitle || fullName);

    const [scope1TotalEmissions, setScope1TotalEmissions] = useState(prefilledData.scope1Total);
    const [scope1TotalEnergy, setScope1TotalEnergy] = useState(prefilledData.scope1TotalEnergy);
    const [scope2TotalEmissions, setScope2TotalEmissions] = useState(prefilledData.scope2Total);
    const [scope2TotalEnergy, setScope2TotalEnergy] = useState(prefilledData.scope2TotalEnergy);
    const [scope3TotalEmissions, setScope3TotalEmissions] = useState(prefilledData.scope3Total);

    const [scope1LineItems, setScope1LineItems] = useState<any[]>(prefilledData.scope1LineItems || []);
    const [scope2LineItems, setScope2LineItems] = useState<any[]>(prefilledData.scope2LineItems || []);
    const [scope3LineItems, setScope3LineItems] = useState<any[]>(prefilledData.scope3LineItems || []);

    const userCurrency = useCurrency();
    const currency = prefilledData.currency || userCurrency;
    const currencySymbol = getCurrencySymbol(currency)

    const [informationText, setInformationText] = useState(prefilledData.informationText);

    const [startDate, setStartdate] = useState(prefilledData.start_date ? prefilledData.start_date.slice(0, 10) : undefined);
    const [endDate, setEndDate] = useState(prefilledData.end_date ? prefilledData.end_date.slice(0, 10) : undefined);
    const [isReference, setIsReference] = useState(prefilledData.isBaseline);
    // const [intensityRatio, setIntensityRatio] = useState(prefilledData.localIntensityRatio);
    

    const [turnover, setTurnover] = useState(prefilledData.turnover);
    const [totalEmissions, setTotalEmissions] = useState(prefilledData.totalEmissions);
    const [totalEnergy, setTotalEnergy] = useState(prefilledData.totalEnergy);

    let intensityRatio: string | number = 0;
    if(turnover) {
        intensityRatio = (totalEmissions / turnover).toFixed(6);
    }

    const [publishTotalEmissions, setPublishTotalEmissions] = useState(prefilledData.publishTotalEmissions);
    const [publishTotalEnergy, setPublishTotalEnergy] = useState(prefilledData.publishTotalEnergy);
    const [showValidation, setShowValidation] = useState(false);

    const [saving, setSaving] = useState(false);
    const reportType = prefilled ? 'Accounts summary' : 'Manual report';

    const validations: Record<string, string> = {};
    if(!startDate) {
        validations['startDate'] = 'Missing from date';
    }
    if(!endDate) {
        validations['endDate'] = 'Missing to date';
    }
    if(startDate && endDate) {
        if(!dayjs(endDate).isAfter(startDate)) {
            validations['endDate'] = 'To date must be after from date';
        }
    }
    if(!intensityRatio) {
        validations['intensityRatio'] = 'Missing intensity ratio';
    }
    
    if(!signeeName) {
        validations['signeeName'] = 'Missing signee name';
    }
    const hasErrors = !!Object.keys(validations).length;
    const [showErrors, setShowErrors] = useState(false);

    const onSave = async (publish: boolean) => {
        const saveData = {
            intensityRatio,
            startDate,
            endDate,
            turnover,
            totalEmissions,
            totalEnergy,
            methodologyText: defaults.methodologyText,
            disclosureText: defaults.disclosureText,
            informationText,
            currency,
            scope1TotalEmissions,
            scope1TotalEnergy,
            scope2TotalEmissions,
            scope2TotalEnergy,
            scope3TotalEmissions,
            scope1LineItems,
            scope2LineItems,
            scope3LineItems,
            type: reportType,
            accountingPeriodId,

            organisation,
            jurisdiction,
            registrationNumber,
            legalForm,
            address,

            signeeName,
            signeeTitle,
        }

        const saveOptions = {
            publishTotalEmissions,
            publishTotalEnergy,
            isReference,
            publish,
            overwriteId: overwriteReportId,
        }

        setSaving(true);
        await api.post('secr/save', {
            secrReport: saveData,
            options: saveOptions,
            _csrf: await getCSRFToken()
        });
        setSaving(false);
        navigate(routes.myReports.path)
        addNotification({body: 'SECR Report Saved'});
    }

    
    // const dataInput = <T extends keyof typeof data>(dataKey: T, type?: 'text'|'number') => {
    //     const value = data[dataKey] ?? '';

    //     if(prefilled) {
    //         return <span>{ value }</span>
    //     }

    //     const onChange = (newValue: typeof value) => {
    //         if(type === 'number') {
    //             // @ts-ignore
    //             newValue = Number(newValue) || 0;
    //         }
    //         setData({...data, [dataKey]: newValue});
    //     }

    //     return (
    //         <input
    //             className={'text-charcoal-900 rounded' + (type === 'number' ? ' w-36 text-right' : '')}
    //             type="text"
    //             value={value.toString()}
    //             onChange={e => onChange(e.target.value as typeof value)}
    //         />
    //     )
    // }


    return(
        <PageTemplate testid='company-dashboard-main'>
            <Helmet>
                <title>{routes.myReportsCreate.name}</title>
            </Helmet>
            <PageHeadings title="My Reports" subTitle={prefilled ? 'Create accounts summary' : 'Manually create report'} />

            <div className="[& input]:text-white">
                <h2 className="text-white">{ organisation }</h2>
                <table className="border-separate border-spacing-1 mt-8">
                    <tr>
                        <td><Label>Jurisdiction</Label></td>
                        <td className="text-white">{ jurisdiction }</td>
                    </tr>
                    <tr>
                        <td><Label>Registration number</Label></td>
                        <td className="text-white">{ registrationNumber }</td>
                    </tr>
                    <tr>
                        <td><Label>Legal Form</Label></td>
                        <td className="text-white">{ legalForm }</td>
                    </tr>
                    <tr>
                        <td><Label>Registered address</Label></td>
                        <td className="text-white">{ address }</td>
                    </tr>
                </table>

                <div className="rounded-lg bg-charcoal-600 p-4 mt-4">
                    <h2 className="text-white">Add summary data</h2>
                    <div className="flex space-x-4">
                        <div className="basis-1/3">
                            <Label className="block">From</Label>
                            <input
                                disabled={prefilled}
                                type="date"
                                className="rounded text-black w-full disabled:bg-charcoal-100"
                                value={startDate}
                                onChange={e => setStartdate(e.target.value)}
                            />
                            { showErrors && validations['startDate'] && (
                                <div className="mt-1 bg-error-050 text-error-500 p-2 rounded-sm text-sm inline-block">{ validations['startDate'] }</div>
                            )}
                            <Label className="block mt-2">To</Label>
                            <input
                                disabled={prefilled}
                                type="date"
                                className="rounded text-black w-full disabled:bg-charcoal-100"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                            />
                            { showErrors && validations['endDate'] && (
                                <div className="mt-1 bg-error-050 text-error-500 p-2 rounded-sm text-sm inline-block">{ validations['endDate'] }</div>
                            )}
                        </div>
                        <div>
                            <Label>
                                <input
                                    type="checkbox"
                                    className="rounded mr-2"
                                    checked={isReference}
                                    onChange={e => setIsReference(e.target.checked)}
                                />
                                Reference period
                            </Label>
                        </div>
                    </div>

                    <div className="w-2/3 flex space-x-4 mt-4">
                        <div className="basis-1/2">
                            <Label className="w-28 inline-block">First published</Label>
                            <span className="text-white">{ prefilledData.firstPublishDate || '---'}</span>
                            <br />
                            <Label className="w-28 inline-block">Last updated</Label>
                            <span className="text-white">{ prefilledData.publishDate || '---'}</span>
                            <br />
                            <Label className="w-28 inline-block">Type</Label>
                            <span className="text-white">{ reportType }</span>
                            <br />
                        </div>
                        <div>
                            <Label className="block">Intensity ratio</Label>
                            <span className="text-white font-bold">
                                {/* <NumberInput
                                    value={intensityRatio}
                                    onChange={setIntensityRatio}
                                    readonly={prefilled}
                                    placeholder={NUMBER_PLACEHOLDER}
                                    fullWidth
                                /> */}
                                { intensityRatio}
                            </span>
                            <Label className="block">
                                kgCO₂e / {currencySymbol} turnover 
                            </Label>
                            { showErrors && validations['intensityRatio'] && (
                                <div className="bg-error-050 text-error-500 p-2 rounded-sm text-sm inline-block">{ validations['intensityRatio'] }</div>
                            )}
                        </div>
                    </div>

                    <div className="w-2/3 flex space-x-4 mt-4">
                        <div className="basis-1/2 shrink-0">
                            <Label className="block">Turnover ( { currencySymbol } )</Label>
                            <NumberInput
                                readonly={prefilled}
                                fullWidth
                                value={turnover}
                                onChange={setTurnover}
                                placeholder={NUMBER_PLACEHOLDER}
                            />
                            <Label>(Never published in public report)</Label>
                        </div>
                        <div className="flex space-x-2">
                            <div>
                                <Label className="block">Total (kgCO₂e)</Label>
                                <span className="text-white">
                                    <NumberInput
                                        fullWidth
                                        placeholder={NUMBER_PLACEHOLDER}
                                        readonly={prefilled}
                                        value={totalEmissions}
                                        onChange={setTotalEmissions}
                                    />
                                </span>
                                <br />
                                <Label>
                                    <input
                                        type="checkbox"
                                        className="rounded mr-2" 
                                        checked={publishTotalEmissions}
                                        onChange={e => setPublishTotalEmissions(e.target.checked)}
                                    />
                                    Publish kgCO₂e in public report
                                </Label>
                            </div>
                            <div className="ml-2">
                                <Label className="block" >Total (kWh)</Label>
                                <span className="text-white">

                                    <NumberInput
                                        fullWidth
                                        placeholder={NUMBER_PLACEHOLDER}
                                        value={totalEnergy}
                                        onChange={setTotalEnergy}
                                        readonly={prefilled}
                                    />
                                </span>
                                <br />
                                <Label>
                                    <input
                                        type="checkbox"
                                        className="rounded mr-2"
                                        checked={publishTotalEnergy}
                                        onChange={e => setPublishTotalEnergy(e.target.checked)}
                                    />
                                    Publish kWh in public report
                                </Label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rounded-lg bg-charcoal-600 p-4 mt-4">
                    <div className="flex cursor-pointer" onClick={() => setShowDetail(!showDetail)}>
                        <div className="grow text-white">
                            { showDetail ? (
                                <>Hide Detail <Label>(This section is optional)</Label></>
                            ) : (
                                <>Show Detail <Label>(This section is optional)</Label></>
                            )}
                        </div>

					    <i className={`fa ${showDetail ? 'fa-minus' : 'fa-plus'} text-algae-300`} />
                    </div>
                    <div className="mt-4">
                        <hr className="mb-2 text-charcoal-400"/>
                        { showDetail && (
                    <div
                        role='table'
                        className='table border-collapse w-full bg-charcoal-700 [&>div>div:nth-child(n+2)]:w-1/6'
                    >
                        <div
                            role='row'
                            className='table-row text-xs w-full bg-charcoal-800 text-charcoal-050 border-b border-charcoal-050'
                        >
                            <Col></Col>
                            <Col alignRight><div className="mr-4">kgCO₂e</div></Col>
                            <Col alignRight><div className="mr-4">kWh</div></Col>
                            <Col />
                            <Col />
                        </div>
                        <Row>
                            <Col><Label>Direct emissions</Label></Col>
                        </Row>
                        <Row>
                            <Col indent={1} className="text-white">
                                Scope 1 subtotal
                            </Col>
                            <Col alignRight>
                                <NumberInput
                                    readonly={prefilled}
                                    value={scope1TotalEmissions}
                                    onChange={setScope1TotalEmissions}
                                    placeholder={NUMBER_PLACEHOLDER}
                                    fullWidth
                                /> 
                            </Col>
                            <Col alignRight>
                                <NumberInput
                                    readonly={prefilled}
                                    value={scope1TotalEnergy}
                                    onChange={setScope1TotalEnergy}
                                    placeholder={NUMBER_PLACEHOLDER}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        { !!scope1LineItems.length && (
                            <Row><Col indent={1} ><Label>Line items</Label></Col></Row>
                        )}
                        { scope1LineItems.map(({name, total, energy}, index) => {
                            return (
                                <Row>
                                    <Col indent={1}>
                                        <input
                                            className='text-charcoal-900 rounded disabled:bg-charcoal-100 w-full'
                                            type="text"
                                            placeholder={TEXT_PLACEHOLDER}
                                            value={name}
                                            disabled={prefilled}
                                            onChange={((e) => {
                                                setScope1LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].name = e.target.value;
                                                    return changed;
                                                })
                                            })}
                                        />
                                    </Col>
                                    <Col>
                                        <NumberInput
                                            value={total}
                                            fullWidth
                                            onChange={value => {
                                                setScope1LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].total = value;
                                                    return changed;
                                                })
                                            }}
                                            placeholder={NUMBER_PLACEHOLDER}
                                            readonly={prefilled}
                                        />
                                    </Col>
                                    <Col>
                                        <NumberInput
                                            value={energy}
                                            fullWidth
                                            onChange={value => {
                                                setScope1LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].energy = value;
                                                    return changed;
                                                })
                                            }}
                                            placeholder={NUMBER_PLACEHOLDER}
                                            readonly={prefilled}
                                        />
                                    </Col>
                                    { !prefilled && (
                                        <Col>
                                            <Button
                                                type="Primary" hasLeadingIcon icon="fa-regular fa-trash" size="sm"
                                                onClick={() => setScope1LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed.splice(index, 1)
                                                    return changed;
                                                })}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            )
                        })}

                        { scope1LineItems.length < LINE_ITEM_LIMIT && !prefilled && (
                            <Row>
                                <Col indent={1}>
                                    <Button
                                        type="Outlined"
                                        text="Add line item"
                                        size="sm"
                                        additionalClass="mt-2 mb-4"
                                        hasTrailingIcon icon='fa-regular fa-plus'
                                        onClick={() => setScope1LineItems(prev => [...prev, {name: '', total: '', energy: ''}])}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                <Label>Indirect emissions</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col indent={1} className="text-white">Scope 2 subtotal</Col>
                            <Col alignRight>
                                <NumberInput
                                    readonly={prefilled}
                                    value={scope2TotalEmissions}
                                    placeholder={NUMBER_PLACEHOLDER}
                                    onChange={setScope2TotalEmissions}
                                    fullWidth
                                />
                            </Col>
                            <Col alignRight>
                                <NumberInput
                                    readonly={prefilled}
                                    value={scope2TotalEnergy}
                                    placeholder={NUMBER_PLACEHOLDER}
                                    onChange={setScope2TotalEnergy}
                                    fullWidth
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        { !!scope2LineItems.length && (
                            <Row><Col indent={1}><Label>Line items</Label></Col></Row>
                        )}
                        { scope2LineItems.map(({name, total, energy}, index) => {
                            return (
                                <Row>
                                    <Col indent={1}>
                                        <input
                                            disabled={prefilled}
                                            className='text-charcoal-900 rounded disabled:bg-charcoal-100 w-full'
                                            type="text"
                                            value={name}
                                            placeholder={TEXT_PLACEHOLDER}
                                            onChange={((e) => {
                                                setScope2LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].name = e.target.value;
                                                    return changed;
                                                })
                                            })}
                                        />
                                    </Col>
                                    <Col>
                                        <NumberInput
                                            value={total}
                                            fullWidth
                                            onChange={value => {
                                                setScope2LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].total = value;
                                                    return changed;
                                                })
                                            }}
                                            placeholder={NUMBER_PLACEHOLDER}
                                            readonly={prefilled}
                                        />
                                    </Col>
                                    <Col>
                                        <NumberInput
                                            value={energy}
                                            fullWidth
                                            onChange={value => {
                                                setScope2LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].energy = value;
                                                    return changed;
                                                })
                                            }}
                                            placeholder={NUMBER_PLACEHOLDER}
                                            readonly={prefilled}
                                        />
                                    </Col>
                                    { !prefilled && (
                                        <Col>
                                            <Button
                                                type="Primary" hasLeadingIcon icon="fa-regular fa-trash" size="sm"
                                                onClick={() => setScope2LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed.splice(index, 1)
                                                    return changed;
                                                })}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            )
                        })}

                        { scope2LineItems.length < LINE_ITEM_LIMIT && !prefilled && (
                            <Row>
                                <Col indent={1}>
                                    <Button
                                        type="Outlined"
                                        text="Add line item"
                                        size="sm"
                                        additionalClass="mt-2"
                                        hasTrailingIcon icon='fa-regular fa-plus'
                                        onClick={() => setScope2LineItems(prev => [...prev, {name: '', total: '', energy: ''}])}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col indent={1} className="mt-4 text-white">Scope 3 subtotal</Col>
                            <Col alignRight>
                                <NumberInput
                                    value={scope3TotalEmissions}
                                    onChange={setScope3TotalEmissions}
                                    placeholder={NUMBER_PLACEHOLDER}
                                    fullWidth
                                    readonly={prefilled}
                                />
                            </Col>
                        </Row>

                        { !!scope3LineItems.length && (
                            <Row><Col indent={1}><Label>Line items</Label></Col></Row>
                        )}
                        { scope3LineItems.map(({name, total}, index) => {
                            return (
                                <Row>
                                    <Col indent={1}>
                                        <input
                                            className='text-charcoal-900 rounded disabled:bg-charcoal-100 w-full'
                                            type="text"
                                            value={name}
                                            placeholder={TEXT_PLACEHOLDER}
                                            onChange={((e) => {
                                                setScope3LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].name = e.target.value;
                                                    return changed;
                                                })
                                            })}
                                            disabled={prefilled}
                                        />
                                    </Col>
                                    <Col>
                                        <NumberInput
                                            value={total}
                                            fullWidth
                                            onChange={value => {
                                                setScope3LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed[index].total = value;
                                                    return changed;
                                                })
                                            }}
                                            placeholder={NUMBER_PLACEHOLDER}
                                            readonly={prefilled}
                                        />
                                    </Col>
                                    <Col />
                                    { !prefilled && (
                                        <Col>
                                            <Button
                                                type="Primary" hasLeadingIcon icon="fa-regular fa-trash" size="sm"
                                                onClick={() => setScope3LineItems(prev => {
                                                    const changed = prev.slice();
                                                    changed.splice(index, 1)
                                                    return changed;
                                                })}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            )
                        })}

                        { scope3LineItems.length < LINE_ITEM_LIMIT && !prefilled && (
                            <Row>
                                <Col indent={1}>
                                    <Button
                                        type="Outlined"
                                        text="Add line item"
                                        additionalClass="mt-2"
                                        size="sm"
                                        hasTrailingIcon icon='fa-regular fa-plus'
                                        onClick={() => setScope3LineItems(prev => [...prev, {name: '', total: ''}])}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row><Col /></Row>
                    </div>

                        )}
                        { showDetail && (<hr className="mt-2 text-charcoal-400"/>)}
                    </div>
                </div>
                <div className="mt-8">

                </div>

                <div className='rounded-lg bg-charcoal-600 p-4 mt-4'>
                    <Label>Methodology</Label>
                    <p
                        className="w-full rounded mt-1 whitespace-pre-line text-white"
                    >
                        {defaults.methodologyText}
                    </p>
                    <div className="mt-4">
                        <Label>Confirmation</Label>
                        <p
                            className="w-full rounded mt-1 whitespace-pre-line text-white"
                        >
                            {defaults.disclosureText}
                        </p>
                    </div>

                    <div className="mt-4">
                        <Label>Report authorised by</Label>
                        <div className="mt-1">
                            <input
                                type="text"
                                placeholder="First name"
                                className="rounded"
                                value={signeeName}
                                onChange={e => setSigneeName(e.target.value)}
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                placeholder="Role (e.g. Director)"
                                className="rounded"
                                value={signeeTitle}
                                onChange={e => setSigneeTitle(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                { !prefilled && (
                    <div className='rounded-lg bg-charcoal-600 p-4 mt-4'>
                        <Label>Information sources</Label>
                        <textarea
                            className="w-full rounded text-charcoal-900 h-40 mt-1"
                            value={informationText}
                            placeholder="Include URL to the online document or website you obtained this information from. Alternatively, describe the source of this information if not online."
                            onChange={e => setInformationText(e.target.value)}
                        />
                    </div>
                )}

                { showErrors && hasErrors && (
                    <div className="text-right mt-8">
                        <div className="rounded p-4 inline-block bg-error-500 text-error-800 border-error-500">Your report contains errors</div>
                    </div>
                )}
                <div className="my-4 flex space-x-4 align-right justify-end">
                    { !prefilled && (
                        <Button
                            type="Outlined"
                            text="Save as Draft"
                            onClick={() => {
                                if(hasErrors) {
                                    setShowErrors(true);
                                } else {
                                    onSave(false)
                                }
                            }}
                        />
                    )}
                    <Button
                        type="Primary"
                        text="Save and Publish"
                        onClick={() => {
                            if(hasErrors) {
                                setShowErrors(true)
                            } else {
                                setPublishModalOpen(true)
                            }
                    }}/>
                </div>
            </div>
            <SecrPublishConfirmation
                open={publishModalOpen}
                companyName={ organisation || '' }
                signeeName={ signeeName }
                signeeTitle={ signeeTitle }
                onClose={() => {
                    setPublishModalOpen(false)
                }}
                onConfirm={() => {
                    onSave(true);
                }}
                userFullName={fullName}
                loading={saving}
            />
        </PageTemplate>
    )
}

export default SecrCreate;
