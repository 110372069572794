import { useState } from "react";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import CurrencyInput from "../../components/BaseComponents/Forms/CurrencyInput";
import Label from "../../components/BaseComponents/Label";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import useApi from "../../hooks/useApi";
import { currencyToCents, getCSRFToken } from "../../lib/utils";

type CompanyProjectCreateModalProps = {
    open: boolean;
    onClose: () => void;
}

function CompanyProjectCreateModal({open, onClose}: CompanyProjectCreateModalProps) {
    const [loading, setLoading] = useState(false);
    const api = useApi();

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [turnover, setTurnover] = useState(0);

    const onCreate = async () => {
        setLoading(true);
        await api.post('/projects', {
            name,
            code,
            totalTurnoverAmount: turnover,
            _csrf: await getCSRFToken(),
        });
        setLoading(false);
        onClose();
    }

    return (
        <Modal open={open} onClose={onClose} size="small" >
            <PageHeadings title="Create Project" />
            { loading ? <LoadingSpinner /> : (
                <form>
                    <div>
                        <Label className="block">Project name</Label>
                        <input
                            className="rounded w-full"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div>
                        <Label className="block">Unique project code</Label>
                        <input
                            className="rounded w-full"
                            type="text"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                    </div>
                    <div>
                        <Label className="block">Project value (total sales/revenues)</Label>
                        <CurrencyInput name="turnover" onChange={value => setTurnover(currencyToCents(value))} />
                    </div>
                    <div className="flex mt-4 space-x-2">
                        <Button text="Create" type="Primary" onClick={onCreate} />
                        <Button text="Cancel" onClick={onClose} type="Outlined" />
                    </div>
                </form>
            )}
        </Modal>
    )
}

export default CompanyProjectCreateModal;
