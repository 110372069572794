import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../../components/Router/routes";
import SecrDisplay from "../../components/SECR/SecrDisplay";

function MyReport() {
    const { reportId } = useParams();

    if(!reportId) {
        return <Navigate to={routes.myReports.path} />
    }

    return(
        <PageTemplate testid='company-dashboard-main'>
            <Helmet>
                <title>{routes.myReports.name}</title>
            </Helmet>
            <PageHeadings title='Report' routeBack={routes.myReports.path} />
            
            <SecrDisplay reportId={reportId} />
        </PageTemplate>
    )
}

export default MyReport;
