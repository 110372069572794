import { countries } from '../../assets/countryList'

type JurisdictionSelectProps = {
    value: string,
    onChange: (value: string) => void;
}

function JurisdictionSelect({value, onChange}: JurisdictionSelectProps) {
    return (
        <select className="rounded" value={value} onChange={e => onChange(e.target.value)}>
            <option value="" >Any</option>
            { countries.map(country => {
                return (
                    <option key={country['alpha-3']}  value={country['alpha-3']}>
                        { country['label']}
                    </option>
                )
            })}
        </select>
    )
}

export default JurisdictionSelect;
