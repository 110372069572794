import { FunctionComponent } from 'react'
import { PaginationMeta } from '../../../types/PaginationMeta'
import Button from '../Button/Button'

interface IPagination {
	pagination: PaginationMeta
	onPageChange: (newPageNumber: number) => void
	isLoading?: boolean
}

const Pagination: FunctionComponent<IPagination> = ({ pagination, onPageChange, isLoading }) => {
	const loop = new Array(pagination.last_page).fill(' ')

	const currentStartRange = pagination.current_page * pagination.per_page - pagination.per_page + 1
	const currentEndRange =
		pagination.current_page * pagination.per_page >= pagination.total
			? pagination.total
			: pagination.current_page * pagination.per_page

	if (isLoading) {
		return (
			<div className='animate-pulse rounded-lg bg-charcoal-600 p-2 mt-5 flex justify-between items-center w-full'>
				<div className='rounded-full bg-charcoal-500 h-4 w-48 mx-3 my-2' />
				<div className='flex mx-3'>
					<div className='rounded-lg bg-charcoal-500 h-6 w-6 mx-1 my-2' />
					<div className='rounded-lg bg-charcoal-500 h-6 w-6 mx-1 my-2' />
					<div className='rounded-lg bg-charcoal-500 h-6 w-6 mx-1 my-2' />
				</div>
			</div>
		)
	}

	return (
		<div className='rounded-lg bg-charcoal-600 p-2 mt-5 flex justify-between items-center w-full'>
			{pagination.total !== 0 ? (
				<p className='text-white px-4'>
					Showing {currentStartRange} to {currentEndRange} of {pagination.total} results
				</p>
			) : (
				<p className='text-white px-4'>No results found</p>
			)}
			<div>
				<Button
					type='Pagination'
					hasLeadingIcon
					icon='fa-regular fa-chevron-left'
					disabled={pagination.current_page === 1}
					onClick={() => {
						// Previous
						if (pagination.current_page - 1 >= pagination.first_page) {
							onPageChange && onPageChange(pagination.current_page - 1)
						}
					}}
				/>

				{loop.map((page, index) => {
					let renderPage = false
					let renderElipsis = false

					// First page number (always render)
					if (index + 1 === pagination.first_page) {
						renderPage = true
					}

					// The elipsis before
					if (index + 1 === pagination.current_page - 3) {
						// Check if this is the only hidden item, if it is, lets render a number instead
						if (pagination.current_page - 4 === pagination.first_page) {
							renderPage = true
						} else {
							renderElipsis = true
						}
					}

					// The 2nd page before
					if (index + 1 === pagination.current_page - 2) {
						renderPage = true
					}

					// The page before
					if (index + 1 === pagination.current_page - 1) {
						renderPage = true
					}

					// The current page (always render)
					if (index + 1 === pagination.current_page) {
						renderPage = true
					}

					// The page after
					if (index + 1 === pagination.current_page + 1) {
						renderPage = true
					}

					// The 2nd page after
					if (index + 1 === pagination.current_page + 2) {
						renderPage = true
					}

					// The elipsis after
					if (index + 1 === pagination.current_page + 3) {
						// Check if this is the only hidden item, if it is, lets render a number instead
						if (pagination.current_page + 4 === pagination.last_page) {
							renderPage = true
						} else {
							renderElipsis = true
						}
					}

					// Last page - 2 (If we're on the first page, we can show an extra one towards the end as we have spare slots)
					if (pagination.current_page === 1 && index + 1 === pagination.last_page - 2) {
						renderPage = true
					}

					// Last page - 1 (If we're on the first two pages, we can show an extra page towards the end of the list as we have spare slots)
					if (
						(pagination.current_page === 1 || pagination.current_page === 2) &&
						index + 1 === pagination.last_page - 1
					) {
						renderPage = true
					}

					// Last page number
					if (index + 1 === pagination.last_page) {
						renderPage = true
					}

					// Renders a page number button and also prevents an elipsis render by order of execution
					if (renderPage) {
						return (
							<Button
								key={index}
								type={pagination.current_page - 1 === index ? 'Primary' : 'Pagination'}
								text={(index + 1).toString()}
								onClick={() => {
									onPageChange && onPageChange(index + 1)
								}}
							/>
						)
					}

					// Renders an elipsis button
					if (renderElipsis) {
						return (
							<Button
								key={index}
								type='Pagination'
								text={'...'}
								onClick={() => {
									onPageChange && onPageChange(index + 1)
								}}
							/>
						)
					}

					return null
				})}

				<Button
					type='Pagination'
					hasLeadingIcon
					icon='fa-regular fa-chevron-right'
					additionalClass=''
					disabled={pagination.current_page === pagination.last_page}
					onClick={() => {
						// Next
						if (pagination.current_page + 1 <= pagination.last_page) {
							onPageChange && onPageChange(pagination.current_page + 1)
						}
					}}
				/>
			</div>
		</div>
	)
}

export default Pagination
