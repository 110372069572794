import { Fragment, useContext } from 'react'
import { createCompany } from '../../redux/actions/createCompanyActions'
import { useAppDispatch } from '../../redux/hooks/reduxHooks'
import { NewCompany } from '../../types/Company'
import { OpenSearchCompany } from '../../types/OpenSearchCompany'
import { Button, CompanyInviteCard } from '../BaseComponents'
import useApi from '../../hooks/useApi'
import useUserData from '../../hooks/useUserData'
import { userContext } from '../../context/UserContext'

type Props = {
	results: Array<OpenSearchCompany>
	activeResult: OpenSearchCompany
	setActiveResult: (newCompany: OpenSearchCompany) => void
}

function OpenCoSearchResults({ results, activeResult, setActiveResult }: Props) {
	const dispatch = useAppDispatch()
	const api = useApi();
	const { checkAccess } = useContext(userContext);
	const convertToNeoniCompany = (company: OpenSearchCompany) => {
		let industryCodesFiltered: any[] = []
		company.industryCodes.forEach((industryCodes: any) => {
			industryCodesFiltered.push({
				code: industryCodes['industry_code']['code'],
				codeType: industryCodes['industry_code']['code_scheme_name'],
			})
		})
		return {
			name: company.name,
			type: company.type,
			number: company.company_number,
			jurisdiction_code: company.jurisdictionCode,
			industry_codes: industryCodesFiltered,
			address: company.address,
			tier: checkAccess('company.admin') ? 'standard' : undefined,
		} as NewCompany
	}
	return (
		<div className='h-5/6'>
			<h3 className='text-charcoal-100 text-sm mt-lg'>
				Search result{results.length > 1 ? 's' : ''} {results.length}
			</h3>
			<div className='h-full overflow-auto'>
				{results.map((result: any, index: number) => {
					return (
						<Fragment key={index}>
							<CompanyInviteCard
								key={`${index}-${result?.company_number}-${result?.name}`}
								isInteractable={true}
								activeResult={activeResult}
								setActiveResult={setActiveResult}
								result={result}
								clickHandler={() => {
									setActiveResult(results[index])
								}}
								additionalClasses={`${activeResult === result && 'mt-5'}`}
							/>
							{activeResult === result && (
								<Button
									type='Primary'
									text='Create Invite'
									onClick={() => {
										dispatch(createCompany(api, convertToNeoniCompany(activeResult)))
									}}
									additionalClass='mt-3 mb-3'
								/>
							)}
						</Fragment>
					)
				})}
			</div>
		</div>
	)
}
export default OpenCoSearchResults
