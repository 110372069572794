import classNames from 'classnames'
import { FunctionComponent, useEffect, useState } from 'react'
import { Tab } from './TabList'

// TabList but vertically orientated tab buttons
// Separate component because Tailwind couldn't resolve styling classnames dynamically

type IVerticalTabList = {
	tabDescription?: string
	tabs: Array<Tab>
	additionalContainerClass?: string
	additionalTabButtonClass?: string
	additionalPanelClass?: string
	initialActiveTab?: number
	updateInitialTab?: boolean
}

const VerticalTabList: FunctionComponent<IVerticalTabList> = ({
	tabDescription = '',
	tabs,
	additionalContainerClass,
	additionalTabButtonClass,
	additionalPanelClass,
	initialActiveTab = 0,
	updateInitialTab = false
}) => {
	const [activeTab, setActiveTab] = useState<number>(initialActiveTab)

	useEffect(() => { }, [tabs])
	useEffect(() => {
		if (updateInitialTab) {
			setActiveTab(1)
		}
	}, [updateInitialTab])

	return (
		<>
			<div
				role='tablist'
				aria-label={tabDescription}
				className={classNames('flex', additionalContainerClass)}
			>
				<div className='flex flex-col w-1/6 md:w-1/5 bg-charcoal-600 border-charcoal-300'>
					{tabs.map((tab, index) => {
						return (
							<button
								className={classNames('w-full text-sm text-white text-left px-4 py-3', {
									'bg-charcoal-800': index === activeTab,
									'border-r-4 border-charcoal-400': index !== activeTab,
								})}
								key={tab.label}
								role='tab'
								aria-selected={index === activeTab}
								aria-controls={`verticaltabpanel-${index}`}
								id={`verticaltab-${index}`}
								tabIndex={0}
								onClick={() => {
									setActiveTab(index)
									tab.onChange && tab.onChange(index)
								}}
							>
								{tab.label}
							</button>
						)
					})}
				</div>
				<div className='w-full'>
					{tabs.map((tab, index) => {
						return (
							<div
								key={`${tab.label}-verticalpanel`}
								id={`verticaltabpanel-${index}`}
								role='tabpanel'
								className={classNames('bg-charcoal-800', additionalPanelClass)}
								aria-labelledby={`verticaltab-${index}`}
								hidden={activeTab !== index}
							>
								{tab.component(activeTab === index)}
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default VerticalTabList
