// These enums can be used to represent API state in the UI, allowing us to display in-progress screens, completion or errors

export enum FetchStatus {
	initial = 'initial',
	loading = 'loading',
	loaded = 'loaded',
	error = 'error',
}

export enum PostStatus {
	initial = 'initial',
	sending = 'sending',
	sent = 'sent',
	error = 'error',
}
