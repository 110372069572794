/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../components/BaseComponents'
import routes from '../../components/Router/routes'
import PageHeadings from '../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../components/BaseComponents/PageTemplate/PageTemplate'
import { countries } from '../../assets/countryList'
import Pagination from '../../components/BaseComponents/Pagination/Pagination'
import useApi from '../../hooks/useApi'
import SimpleSortTable, { TableColumn } from '../../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import AdminCreateCompany from '../../components/Admin/CreateCompany/CreateCompany'
import { Link } from 'react-router-dom'
import Label from '../../components/BaseComponents/Label'
import useCleanup from '../../hooks/useCleanup'
import useDebounce from '../../hooks/useDebounce'
import usePagination from '../../hooks/usePagination'
import useDataSort from '../../hooks/useDataSort'

const columns: TableColumn[] = [
	{
		id: 'full_name',
		label: 'Name',
		sortable: true,
	},
	{
		id: 'email',
		label: 'Email',
		sortable: true,
	},
	{
		id: 'name',
		label: 'Organisation Name',
		sortable: true,
		renderer: (key, row) => (
			<Link
				className="underline"
				to={`/admin/companies/${row.company.id}`}
			>
				{ row.company[key] }
			</Link>
		)
	},
	{
		id: 'number',
		label: 'Company #',
		sortable: true,
		renderer: (key, row) => row.company[key],
	},
	{
		id: 'jurisdiction_code',
		label: 'Jurisdiction',
		sortable: true,
		renderer: (key, row) => row.company[key],
	},
	{
		id: 'is_public_body',
		label: 'Public Body',
		sortable: true,
		renderer: (key, data) => <span>{ data.company[key] ? 'Yes' : 'No' } </span>
	},
	{
		id: 'currency_code',
		label: 'Currency',
		sortable: true,
		renderer: (key, data) => <span>{ data.company[key] || '[unset]'}</span>
	},
	{
		id: 'tier',
		label: 'Tier',
		sortable: true,
		renderer: (key, row) => row.company[key],
	},
	{
		id: 'admin_locked',
		label: 'Locked by admin',
		sortable: true,
		renderer: (key, data) => <span>{ data.company[key] ? 'Yes' : 'No' }</span>
	},
	{
		id: 'user_locked',
		label: 'Locked by organisation',
		sortable: true,
		renderer: (key, data) => <span>{ data.company[key] ? 'Yes' : 'No' }</span>
	},
	{
		id: 'admin_locked',
		label: 'Organisation Locked',
		sortable: true,
		renderer: (key, data) => <span>{ data.company[key] ? 'Yes' : 'No' }</span>
	}
];

function AdminUsers() {
	const countryList = countries

	const [loading, setLoading] = useState(true);

	const pagination = usePagination(1, 20);
	const dataSort = useDataSort('full_name', 'asc');

	const api = useApi();

	const [filters, setFilters] = useState({
		name: '',
		email: '',
		companyName: '',
		number: '',
		type: 'any',
		jurisdiction: 'any',
		publicBody: 'any',
		setupStatus: 'any',
		invitationStatus: 'any',
		tier: 'any',
		currencyCode: 'any',
	})

	const [results, setResults] = useState<any>({data: [], meta: {}});

	const [dynamicFacets, setDynamicFacets] = useState({
		currencyCodes: [] as string[],
		jurisdictions: [] as string[],
		companyTypes: [] as string[],
	});

	useEffect(() => {
		let cancelled = false;
		const run = async () => {
			const apiResult = await api.get('admin/companiesSearchFacets');
			if(!cancelled) {
				setDynamicFacets(apiResult.data);
			}
		}
		run();

		return () => { cancelled = true }
	}, []);


	const searchDebounce = useDebounce(250);

	const onSearch = useCleanup(() => {
		let cancelled = false;
		setLoading(true);
		const run = async () => {
			const qs = new URLSearchParams({
				...filters,
				...dataSort.params,
				...pagination.params,
			});

			const apiResult = await api.get('admin/users?' + qs.toString());
			console.log('cancelled', cancelled)
			if(cancelled) {
				return;
			}
			setLoading(false);
			setResults(apiResult.data);
		}
		searchDebounce(run)();
		return () => {
			cancelled = true;
		}
	})

	useEffect(() => {
		onSearch();
	}, [filters, pagination.params, dataSort.params])

	const [addCompanyModalOpen, setAddCompanyModalOpen] = useState<boolean>(false)

	return (
		<PageTemplate testid='add-accounting-periods-main'>
			<Helmet>
				<title>{routes.adminCompanies.name}</title>
			</Helmet>
			<PageHeadings title='Create Organisation' />

			<div className='block'>
				<Button
					type='Primary'
					text='Create a New Organisation'
					size='sm'
					hasTrailingIcon
					icon='fa-regular fa-plus'
					onClick={() => {
						setAddCompanyModalOpen(true)
					}}
					additionalClass='mt-4 mb-2'
				/>
				<AdminCreateCompany
					open={addCompanyModalOpen}
					onClose={() => setAddCompanyModalOpen(false)}
				/>
			</div>

			<div className="my-8">
				<div className="flex space-x-3">
					<label>
						<Label className="block">Name</Label>
						<input
							type="text"
							placeholder="Jo Bloggs..."
							className="rounded"
							value={filters.name}
							onChange={e => setFilters({...filters, name: e.target.value})}
						/>
					</label>
					<label>
						<Label className="block">Email</Label>
						<input
							type="text"
							placeholder="name@example.com..."
							className="rounded"
							value={filters.email}
							onChange={e => setFilters({...filters, email: e.target.value})}
						/>
					</label>
				</div>
				<div className="text-white mt-8 mb-2">
					Organisation filters
				</div>
				<div className="flex space-x-3 mb-3">
					<label>
						<Label className="block">Organisation Name</Label>
						<input
							type="text"
							placeholder="iSumio..."
							className="rounded"
							value={filters.companyName}
							onChange={e => setFilters({...filters, companyName: e.target.value})}
						/>
					</label>	
					<label>
						<Label className="block">Organisation Number</Label>
						<input
							type="text"
							placeholder="123..."
							className="rounded"
							value={filters.number}
							onChange={e => setFilters({...filters, number: e.target.value})}
						/>
					</label>
					<label>
						<Label className="block">Organisation Type</Label>
						<select
							className="rounded"
							value={filters.type}
							onChange={e => setFilters({...filters, type: e.target.value})}
						>
							<option value="any">Any</option>
							{ dynamicFacets.companyTypes.map( companyType => (
								<option
									key={companyType}
									value={companyType}
								>
									{ companyType || '[unset]'}
								</option>
							))}
						</select>
					</label>	
					<label>
						<Label className="block">Tier</Label>
						<select
							className="rounded"
							value={filters.tier}
							onChange={e => setFilters({...filters, tier: e.target.value})}
						>
							<option value="any">Any</option>
							<option value="micro">Micro</option>
							<option value="standard">Standard</option>
							<option value="premium">Premium</option>
						</select>
					</label>
				</div>
				<div className="flex space-x-3 my-3">
					<label>
						<Label className="block">Jurisdiction</Label>
						<select
							className="rounded inline-block"
							value={filters.jurisdiction}
							onChange={e => setFilters({...filters, jurisdiction: e.target.value})}
						>
							<option value="any">Any</option>
							{ countryList.map( country => {
								if(!dynamicFacets.jurisdictions.includes(country['value'])) {
									return null;
								}
								return (
									<option
										key={country['iso_3166-2']}
										value={country.value}
									>
										{ country.label }
									</option>
								);
							})}
						</select>
					</label>
					<label>
						<Label className="block">Public Body</Label>
						<select
							className="rounded"
							value={filters.publicBody}
							onChange={e => setFilters({...filters, publicBody: e.target.value})}
						>
							<option value="any">Any</option>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</label>
					<label>
						<Label className="block">Set Up</Label>
						<select
							className="rounded"
							value={filters.setupStatus}
							onChange={e => setFilters({...filters, setupStatus: e.currentTarget.value})}
						>
							<option value="any">Any</option>
							<option value="no">Not set up</option>
							<option value="yes">Set up</option>
						</select>
					</label>

					<label>
						<Label className="block">Invitation Status</Label>
						<select
							className="rounded bg-charcoal-200"
							value={filters.invitationStatus}
							onChange={e => setFilters({...filters, invitationStatus: e.target.value})}
							disabled
						>
							<option value="any">Any</option>
							<option>None</option>
							<option>Sent</option>
							<option>Accepted</option>
						</select>
					</label>

					<label>
						<Label className="block">Currency</Label>
						<select
							className="rounded"
							value={filters.currencyCode}
							onChange={e => setFilters({...filters, currencyCode: e.target.value})}
						>
							<option value="any">Any</option>
							{ dynamicFacets.currencyCodes.map( currencyCode => (
								<option
									key={currencyCode}
									value={currencyCode || '[unset]'}
								>
									{ currencyCode || '[unset]'}
								</option>
							))}
						</select>
					</label>

				</div>

				<Button
					type='Primary'
					text='Search'
					size='sm'
					hasTrailingIcon
					onClick={onSearch}
					additionalClass='mt-4 mb-2'
				/>
			</div>
			<SimpleSortTable
				isLoading={loading}
				columns={columns}
				data={results.data}
				defaultSorting={dataSort.defaultSorting}
				onSortPress={dataSort.onChange}
			/>
			<Pagination 
				pagination={pagination.pagination}
				onPageChange={pagination.onPageChange}
			/>

		</PageTemplate>
	)
}
export default AdminUsers
