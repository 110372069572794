import { Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button } from '../components/BaseComponents'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import { routes } from '../components/Router/routes'
import useUserData from '../hooks/useUserData'
import { useContext } from 'react'
import { userContext } from '../context/UserContext'

function CompanyDashboard() {
	const userData = useUserData();

	const { checkAccess } = useContext(userContext);

	if(userData?.hasCompletedSetup === false) {
		return <Navigate to={routes.companySetup.path} replace />
	}


	return (
		<>
			<PageTemplate testid='company-dashboard-main'>
				<Helmet>
					<title>{routes.companyDashboard.name}</title>
				</Helmet>
					<div className='mt-3xl p-lg bg-charcoal-600 rounded'>
						<h2 className='mt-3 text-4xl font-semibold text-white'>
							Welcome to neoni ® beta testing!
						</h2>
						<p className='mt-5 text-white text-lg'>
							You are a leader in emissions reduction and we are excited to have you with us.
						</p>
						<p className='my-lg text-white text-lg'>
							Neoni enables you to decarbonise your supply chain in three steps:
						</p>
						<p className='my-lg text-white pl-5'>
							1. Get real-world, reliable, data from your supply chain - in Beta
						</p>
						<p className='my-lg text-white pl-5'>
							2. Use Neoni insights to progressively reduce supply chain emissions - coming soon
						</p>
						<p className='my-lg text-white pl-5'>
							3. Use Neoni reporting to evidence the results and enhance reputation - coming soon
						</p>
						<p className='my-lg text-white text-lg'>Let’s get started by:</p>
						<ul className='my-lg text-white list-disc pl-9'>
							<li>
								Adding purchase data to calculate direct emissions and estimate supply chain
								emissions
							</li>
							<li>
								Then invite suppliers to use Neoni and share their summary emissions data with you
							</li>
						</ul>
						<div className='mt-5 mb-2'>
							<Button
								isLink={true}
								url={checkAccess('company.accounting') ? routes.companyPurchases.path : routes.myReports.path }
								type='Primary'
								text='Get Started!'
							/>
						</div>
					</div>
			</PageTemplate>
		</>
	)
}
export default CompanyDashboard
