import { Dispatch } from 'redux'
import { SECTOR_ERROR, SECTOR_LOADED, SECTOR_LOADING } from '../slices/sectorSlice'
import { NeoniApi } from '../../lib/api'

export const getSectors = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: SECTOR_LOADING,
	})

	try {
		const sectors = await api.get(`sectors/`);
		const body = await sectors.json()

		if (body.success === true) {
			// Successfully fetched purchases
			dispatch({
				type: SECTOR_LOADED,
				payload: body.sectors,
			})
		} else {
			// Error
			dispatch({
				type: SECTOR_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: SECTOR_ERROR,
			payload: error,
		})
	}
}
