import { Dispatch } from 'redux'
import {
	SUPPLYCHAINGRAPHDATA_ERROR,
	SUPPLYCHAINGRAPHDATA_LOADED,
	SUPPLYCHAINGRAPHDATA_LOADING,
} from '../slices/supplyChainGraphDataSlice'
import { NeoniApi } from '../../lib/api'

export const getSupplierIntensityChangeData =
	(api: NeoniApi, supplierIds: string = '') =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: SUPPLYCHAINGRAPHDATA_LOADING,
		})
		try {
			const body = await api.get(`reports/intensityRatioChange?` +
				new URLSearchParams({ supplierIds: supplierIds })
			)

			if (body.success === true) {
				// Successfully fetched suppliers graph data
				dispatch({
					type: SUPPLYCHAINGRAPHDATA_LOADED,
					payload: body.data.data,
				})
			} else {
				// Error
				dispatch({
					type: SUPPLYCHAINGRAPHDATA_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: SUPPLYCHAINGRAPHDATA_ERROR,
				payload: error,
			})
		}
	}
