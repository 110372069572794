import { useEffect, useState } from "react";
import { Button } from "../../components/BaseComponents";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

type AdminCompanyActionsProps = {
    companyId: string;
}

function AdminCompanyActions({companyId}: AdminCompanyActionsProps) {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const api = useApi();
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        const result = await api.get(`/admin/company/${companyId}/summary`);
        setData(result.data);
    }

    useEffect(() => {
        setLoading(true);
        getData();
        setLoading(false);
    }, [companyId])

    const onLockChange = async (locked: boolean) => {
        setLoading(true);
        await api.put(`/admin/company/${companyId}`, {
            company: {
                adminLocked: locked,
            },
            _csrf: await getCSRFToken(),
        })
        await getData()
        setLoading(false);
    }

    const onDelete = async () => {
        setLoading(true);
        await api.post(`/admin/company/${companyId}/deleteData`, {});
        await getData();
        setLoading(false);
    }

    const isLocked = data?.company?.admin_locked;

    return (
        <div>
            <h3 className="text-white mb-3 mt-8">
                Organisation actions
            </h3>
            <div className="flex space-x-8">
                <div>
                    <Button
                        text={`${isLocked ? 'Unlock' : 'Lock'} organisation`}
                        type="Primary"
                        onClick={() => onLockChange(!isLocked)}
                        disabled={loading}
                    />
                </div>
                {/* <div>
                    <Button
                        text="Delete all data"
                        type="Primary"
                        hasLeadingIcon
                        icon="fa-regular fa-triangle-exclamation"
                        onClick={() => setIsDeleteModalOpen(true)}
                        disabled={loading}
                    />
                </div> */}
            </div>
            <Modal
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                size="small"
            >
                <div className="flex flex-col">
                    <PageHeadings title="Delete all data for [organisation name]?" />
                    <div className="grow text-white mt-4">
                        This will delete the following:
                        <ul className="list-disc pl-6">
                            <li>Suppliers: { data.supplierCount }</li>
                            <li>Items: { data.itemCount }</li>
                            <li>Purchases: { data.purchaseCount } </li>
                            <li>Accounting periods { data.periodCount }</li>
                        </ul>
                    </div>
                    <div className="flex space-x-4 mt-8">
                        <Button type="Primary" text="Confirm" fullWidth />
                        <Button type="Outlined" text="Cancel" fullWidth onClick={() => setIsDeleteModalOpen(false)} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AdminCompanyActions;
