import { useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import useCleanup from "../hooks/useCleanup";
import { formatControlRequestStatus, getCSRFToken } from "../lib/utils";
import { Button, LoadingSpinner } from "./BaseComponents";
import ConfirmationModal from "./BaseComponents/ConfirmationModal";
import PageHeadings from "./BaseComponents/PageHeadings/PageHeadings";
import SimpleSortTable, { TableColumn } from "./BaseComponents/SimpleSortTable/SimpleSortTable";

function AdvisorsTable() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const api = useApi();

    const loadData = async () => {
        setLoading(true);
        const result = await api.get('/accountingClients/requests')
        setData(result.data);
        setLoading(false);
    }
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [acceptanceCandidate, setAcceptanceCandidate] = useState<any>(null);
    const [acceptanceLoading, setAcceptanceLoading] = useState(false);
    const [rejectionCandidate, setRejectionCandidate] = useState<any>(null);
    const [rejectionLoading, setRejectionLoading] = useState<any>(null);

    const acceptRequest = async (request: any) => {
        setAcceptanceLoading(true);
        await api.put(`accountingClients/requests/${request.id}`, {
            status: 'full_access',
            _csrf: await getCSRFToken(),
        })
        setAcceptanceLoading(false);
        setAcceptanceCandidate(null);
        loadData();
    }

    const rejectRequest = async (request: any) => {
        setRejectionLoading(true);
        await api.put(`accountingClients/requests/${request.id}`, {
            status: 'rejected',
            _csrf: await getCSRFToken(),
        })
        setRejectionLoading(false);
        setRejectionCandidate(null);
        loadData();
    }

    if(loading) {
        return <LoadingSpinner />
    }

    const columns: TableColumn[] = [
        {
            id: 'name',
            label: 'Organisation name',
            renderer: (key, data) => {
                return <span>{data.parent.name}</span>
            }
        },
        {
            id: 'status',
            label: 'Status',
            renderer: (key, data) => {
                const status = data[key];

                return <span>{ formatControlRequestStatus(status) }</span>
            }
        },
        {
            id: 'actions',
            label: 'Actions',
            textAlign: 'center',
            renderer: (key, data) => {
                const status = data.status;

                return (
                    <div className="flex space-x-2 justify-center">
                        <Button
                            disabled={status === 'full_access'}
                            type="Primary"
                            size="sm"
                            text="Grant full access"
                            onClick={() => setAcceptanceCandidate(data)}
                        />
                        <Button
                            type="Primary"
                            disabled={status === 'rejected'}
                            size="sm"
                            text="Decline request"
                            onClick={() => setRejectionCandidate(data)}
                        />
                    </div>
                )
            }
        }
    ]

    if(!data.length) {
        return <div className="text-white px-4 py-2 mt-2">We did not find any sent invitations</div>
    }

    return (
        <>
            <SimpleSortTable
                data={data}
                columns={columns}
            />
            { acceptanceCandidate && (
                <ConfirmationModal
                    onCancel={() => setAcceptanceCandidate(null)}
                    onConfirm={() => acceptRequest(acceptanceCandidate)}
                    open
                >
                    { acceptanceLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <PageHeadings title="ACcept Request" />
                            <div className="text-white my-8">
                                Grant access to { acceptanceCandidate.parent.name }
                            </div>
                        </>
                    )}
                </ConfirmationModal>
            )}
            { rejectionCandidate && (
                <ConfirmationModal
                    onCancel={() => setRejectionCandidate(null)}
                    onConfirm={() => rejectRequest(rejectionCandidate)}
                    open
                >
                    { rejectionLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <PageHeadings title="Reject Request" />
                            <div className="text-white my-8">
                                Reject access request from { rejectionCandidate.parent.name }
                            </div>
                        </>
                    )}
                </ConfirmationModal>
            )}


        </>
    )
}

export default AdvisorsTable;
