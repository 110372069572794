import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";

type IStackedBarchartData = {
	data: any[]
	heading?: string
}

export function StackedBarChart({ data, heading }: IStackedBarchartData) {
  const sectors = new Set<string>()
  // data.forEach( period => {
  //   period.
  // })
  const content = data.map( period => {
    const output: Record<string, any> = {
      name: period.label,
    }

    period.values.forEach( (sectorValues: any) => {
      output[sectorValues.label] = sectorValues.value
      sectors.add(sectorValues.label)
    })

    return output
  })
  return (
    <>
      <h2 className='text-white text-xl font-bold mb-2 mt-2'>
        {heading}
      </h2>
      <BarChart
        width={900}
        height={300}
        data={content}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey='name'
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px' }}
          tick={{ fill: '#FFF' }}
        />
        <YAxis
          label={{ value: 'kgC02e', angle: -90, position: 'left', fill: '#FFF' }}
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px' }}
          tick={{ fill: '#FFF' }}
        />
        <Tooltip
          contentStyle={{ backgroundColor: '#2E3A46', borderColor: '#252F38', borderWidth: 2 }}
          labelStyle={{ color: 'white' }}
          cursor={{ fill: '#12171C' }}
        />

          { [...sectors.values()].map( (label, index) => {
            const colorOffset = 140 + index * 420 / sectors.size
            const green = Math.min(colorOffset, 255)
            const red = Math.max(Math.min(9 + colorOffset - 255, 255), 0)
            return (
              <Bar dataKey={label} stackId='a' fill={`rgb(${red}, ${green}, 255)`} name={label} barSize={48} unit="kg CO₂e" />
            )
          })}
        <Legend
          align='right'
          verticalAlign='top'
          layout='vertical'
          formatter={(value) => <span className='text-white ml-2 text-sm'>{value}</span>}
          width={300}
        />
      </BarChart>
    </>
  )
}