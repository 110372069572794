import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { LoadingSpinner } from "../../components/BaseComponents";
import Label from "../../components/BaseComponents/Label";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import routes from "../../components/Router/routes";
import useApi from "../../hooks/useApi";
import useCurrency from "../../hooks/useCurrency";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";
import useDataSort from "../../hooks/useDataSort";
import usePagination from "../../hooks/usePagination";
import { centsToCurrency, getCSRFToken, getCurrencySymbol } from "../../lib/utils";

const formatNumber = Intl.NumberFormat('en-GB').format;

function CompanyProject() {
    const { projectId } = useParams();

	const formatCurrency = useCurrencyFormatter();
    const [listData, setListData ] = useState<any[]>([])
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingSummary, setLoadingSummary] = useState(true);
    const currency = useCurrency();

    const [summary, setSummary] = useState<any>({});

    const [grouping, setGrouping] = useState<'purchases'|'suppliers'>('suppliers');

    const [scope, setScope] = useState('');
    const dataSort = useDataSort('supplierName', 'desc');
    const pagination = usePagination(1, 20);

    useEffect(() => {
        const loadListdata = async () => {
            const result = await api.get('/projects?sortKey=name&sortDirection=asc');
            setListData(result.data.rows);
        }
        loadListdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoadingSummary(true);
        const loadSummary = async () => {
            const result = await api.get(`/projects/${projectId}`);
            setSummary(result.projectSummary)
            setLoadingSummary(false);
        }

        loadSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    const [loadingPurchaseIds, setLoadingPurchaseIds] = useState<string[]>([]);

    const updatePurchaseProject = async (purchaseId: string, projectId: string) => {
        setLoadingPurchaseIds(prev => {
            const changed = prev.slice();
            changed.push(purchaseId)
            return changed;
        });

        await api.put('/singlePurchase/${purchaseId}', {
            _csrf: await getCSRFToken(),
            purchase: {
                projectId,
            }
        });

        setLoadingPurchaseIds(prev => {
            const changed = new Set(prev);
            changed.delete(purchaseId);
            return [...changed.values()];
        })
    }

    const columns: TableColumn[] = grouping === 'purchases'
        ? [
            {
                id:'itemName',
                label: 'Item Name',
                sortable: true,
            },
            {
                id:'level1',
                label: 'Emission Type',
                sortable: true,
            },
            {
                id:'totalSpend',
                label: 'Total Spend',
                sortable: true,
                renderer: (key, data) => <span>{ formatCurrency(Number(centsToCurrency(data[key]))) }</span>,
                textAlign: 'right',
            },
            {
                id:'totalEmissions',
                label: 'CO₂e Emissions',
                sortable: true,
                renderer: (key, data) => <span>{ (data[key]) } kgC02e</span>,
                textAlign: 'right',
            }
        ]
        : [
            {
                id:'supplierName',
                label: 'Supplier Name',
                sortable: true,
            },
            {
                id:'totalSpend',
                label: 'Total Spend',
                sortable: true,
                renderer: (key, data) => <span>{ formatCurrency(Number(centsToCurrency(data[key]))) }</span>,
                textAlign: 'right',
            },
            {
                id:'totalEmissions',
                label: 'CO₂e Emissions',
                sortable: true,
                renderer: (key, data) => <span>{ (data[key]) } kgC02e</span>,
                textAlign: 'right',
            },
            {
                id:'invite',
                label: '',
            }
        ]

    const api = useApi();

    useEffect(() => {
        let cancelled = false;
        setLoading(true);
        const run = async () => {
            const qs = new URLSearchParams({
                grouping,
                searchText,
                ...dataSort.params,
                ...pagination.params,
                scope,
            })
            const results = await api.get(`/projects/${projectId}/entries?` + qs)
            if(cancelled) {
                return;
            }
            setLoading(false);
            setData(results.data.rows);
            pagination.readMeta(results.data.meta);
        }
        run();
        return () => {
            cancelled = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.params, grouping, projectId, dataSort.params, scope, searchText])

    const navigate = useNavigate();

    if(loadingSummary) {
        return <LoadingSpinner />
    }

    return (
        <PageTemplate>
            <PageHeadings title="Purchases" subTitle="Projects" />
            <div className="flex space-x-2 items-end mt-5">
                <div>
                    <Label className="block">
                        Select Project
                    </Label>
                    <select
                        className="rounded"
                        value={projectId}
                        onChange={(e) => {
                            navigate(generatePath(routes.companyProjects.path, { projectId:  e.target.value }))
                        }}
                    >
                        { listData.map( project => {
                            return (
                                <option value={project.id}>{ project.name }</option>
                            )
                        })}
                    </select>
                </div>
                <div>
                    <input
                        type="text"
                        className="rounded"
                        placeholder="Search"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                </div>
                <div className="mb-3 flex ml-3">
                    <input
                        id='suppliers'
                        name='notification-method'
                        type='radio'
                        onChange={() => { setGrouping('suppliers'); dataSort.onChange('supplierName', 'ascending') }}
                        defaultChecked={grouping === 'suppliers'}
                        className='h-4 w-4 border-charcoal-300 text-algae-600 focus:ring-algae-600'
                    />
                    <label htmlFor='suppliers' className='ml-3 block text-sm font-medium text-white'>
                        Suppliers
                    </label>
                </div>
                <div className="mb-3 flex ml-3">
                    <input
                        id='purchases'
                        name='notification-method'
                        type='radio'
                        onChange={() => { setGrouping('purchases'); dataSort.onChange('itemName', 'ascending') }}
                        defaultChecked={grouping === 'purchases'}
                        className='h-4 w-4 border-charcoal-300 text-algae-600 focus:ring-algae-600'
                    />
                    <label htmlFor='purchases' className='ml-3 block text-sm font-medium text-white'>
                        Items
                    </label>
                </div>
            </div>
            <div className="flex rounded-lg overflow-hidden mt-5">
                <div className="flex flex-col p-4 grow bg-white">
                    <div className="text-sm text-charcoal-400 font-medium">Total Emissions</div>
                    <div className="text-2xl text-charcoal-900 font-semibold">{ formatNumber(summary.totalEmissions) }</div>
                    <div className="text-sm text-charcoal-400 font-medium">kgC02e</div>
                </div>
                <div className="flex flex-col p-4 grow bg-ozone-050">
                    <div className="text-sm text-charcoal-400 font-medium">Intensity Ratio</div>
                    <div className="text-2xl text-charcoal-900 font-semibold">{ summary.intensity || '-' }</div>
                    <div className="text-sm text-charcoal-400 font-medium">kgC02e / { getCurrencySymbol(currency)} unit project value</div>
                </div>
                { (!scope || scope === '1') && (
                    <div className="flex flex-col p-4 grow bg-sun-100">
                        <div className="text-sm text-charcoal-400 font-medium">Scope 1 Total</div>
                    <div className="text-2xl text-charcoal-900 font-semibold">{ formatNumber(summary.scope1Total) }</div>
                        <div className="text-sm text-charcoal-400 font-medium">kgC02e</div>
                    </div>
                )}
                { (!scope || scope === '2') && (
                    <div className="flex flex-col p-4 grow bg-sun-300">
                        <div className="text-sm text-charcoal-400 font-medium">Scope 2 Total</div>
                    <div className="text-2xl text-charcoal-900 font-semibold">{ formatNumber(summary.scope2Total) }</div>
                        <div className="text-sm text-charcoal-400 font-medium">kgC02e</div>
                    </div>
                )}
                { (!scope || scope === '3') && (
                    <div className="flex flex-col p-4 grow bg-sun-500">
                        <div className="text-sm text-charcoal-400 font-medium">Scope 3 Total</div>
                    <div className="text-2xl text-charcoal-900 font-semibold">{ formatNumber(summary.scope3Total) }</div>
                        <div className="text-sm text-charcoal-400 font-medium">kgC02e</div>
                    </div>
                )}
            </div>

            <div className="flex text-center font-medium text-sm">
                <div
                    onClick={() => setScope('')}
                    className={`border-transparent cursor-pointer w-1/4 py-4 px-1 ` + (!scope ? 'text-white border-algae-200 border-b-2' : 'text-charcoal-200')}
                >
                    Overview
                </div>
                <div
                    onClick={() => setScope('1')}
                    className={`border-transparent cursor-pointer w-1/4 py-4 px-1 ` + (scope === '1' ? 'text-white border-algae-200 border-b-2' : 'text-charcoal-200')}
                >
                    Scope 1 - Direct emissions
                </div>
                <div
                    onClick={() => setScope('2')}
                    className={`border-transparent cursor-pointer w-1/4 py-4 px-1 ` + (scope === '2' ? 'text-white border-algae-200 border-b-2' : 'text-charcoal-200')}
                >
                    Scope 2 - Indirect emissions
                </div>
                <div
                    onClick={() => setScope('3')}
                    className={`border-transparent cursor-pointer w-1/4 py-4 px-1 ` + (scope === '3' ? 'text-white border-algae-200 border-b-2' : 'text-charcoal-200')}
                >
                    Scope 3 - Indirect emissions
                </div>
            </div>

            <SimpleSortTable
                additionalClass="mt-4"
                columns={columns}
                data={data}
                isLoading={loading}
                defaultSorting={dataSort.defaultSorting}
                onSortPress={dataSort.onChange}
            />
            <Pagination
                pagination={pagination.pagination}
                onPageChange={pagination.onPageChange}
                isLoading={loading}
            />
        </PageTemplate>
    )
}

export default CompanyProject;
