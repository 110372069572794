import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Sector } from 'recharts'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type SectorSlice = {
	status: FetchStatus
	error?: string
	sectorCodes: Array<typeof Sector>
}

const sectorInitialState: SectorSlice = {
	status: FetchStatus.initial,
	error: undefined,
	sectorCodes: [],
}

export const sectorSlice = createSlice({
	name: 'isicCodes',
	initialState: sectorInitialState,
	reducers: {
		SECTOR_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		SECTOR_LOADED: (state, action: PayloadAction<Array<typeof Sector>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				sectorCodes: action.payload,
			}
		},
		SECTOR_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		SECTOR_RESET: () => {
			return sectorInitialState
		},
	},
})

// Action types
export const { SECTOR_LOADING, SECTOR_LOADED, SECTOR_ERROR, SECTOR_RESET } = sectorSlice.actions

// Selectors
export const selectSectorStatus = (state: RootState) => state.sector.status
export const selectSectorError = (state: RootState) => state.sector.error
export const selectAllSector = (state: RootState) => state.sector.sectorCodes

export default sectorSlice.reducer
