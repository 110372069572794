import { NavigateFunction } from "react-router-dom";
import { UserData } from "../context/UserContext";

const API_ROOT = process.env.REACT_APP_BASE_API + '/';

function buildApi(userData?: UserData) {
    const defaultHeaders = () => {

        const headers: Record<string, string> = {};
        const assumedCompanyType = userData?.access?.assumedCompanyType;
        if(assumedCompanyType) {
            headers[`neoni-${assumedCompanyType}-assumed-company-id`]  = userData.companyId;
        }

        return headers;
    }
    const request = async (path: string, method: string, body?: any) => {

        // remove leading slashes on path
        path = path.replace(/^\/+/, '');
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            ...defaultHeaders(),
        };

        const response = await fetch(
            API_ROOT + path,
            {
                method,
                body: JSON.stringify(body),
                headers,
            }
        );

        if(!response.ok) {
            console.log('Response not OK')
            const error = new Error('HTTP error');

            try {
                body = await response.json();
                if(body.redirectTo) {
                    console.log('Redirecting')
                    window.location = body.redirectTo;
                }
                // @ts-ignore
                error.body = body;
            } catch(_) {
            }
            throw error;
        }
        const result = await response.json();
        return result;
    };

    const get = async (path: string) => {
        return request(path, 'GET');
    };

    const post = async (path: string, body: any) => {
        return request(path, 'POST', body);
    };

    const put = async (path: string, body: any) => {
        return request(path, 'PUT', body)
    };

    const del = async(path: string, body: any) => {
        return request(path, 'DELETE', body)
    };

    const form = async(path: string, rawBody: any) => {

        const response = await fetch(
            API_ROOT + path,
            {
                method: 'POST',
                body: rawBody,
                headers: defaultHeaders(),
            }
        );

        const result = await response.json();
        return result;
    }

    return {
        request,
        get,
        post,
        put,
        delete: del,
        form,
    };
}

export type NeoniApi = ReturnType<typeof buildApi>;

export default buildApi;
