/* eslint-disable indent */
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { countries } from '../assets/countryList'
import { Button, LoadingSpinner } from '../components/BaseComponents'
import ISICSelector from '../components/BaseComponents/ISICSelector/ISICSelector'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import { getSupplier } from '../redux/actions/suppliersActions'
import { updateSupplier } from '../redux/actions/updateSuppliersActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectIndividualSupplier,
	selectSuppliersError,
	selectSuppliersStatus,
	SUPPLIERS_RESET,
} from '../redux/slices/suppliersSlice'
import {
	selectUpdateSuppliersStatus,
	UPDATESUPPLIERS_RESET,
} from '../redux/slices/updateSuppliersSlice'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import useApi from '../hooks/useApi'

const CompanyEditSupplier = () => {
	const api = useApi();
	const { state } = useLocation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { supplierId } = useParams()

	const suppliersStatus = useAppSelector(selectSuppliersStatus)
	const supplierUpdateStatus = useAppSelector(selectUpdateSuppliersStatus)
	const suppliersError = useAppSelector(selectSuppliersError)
	const supplierData = useAppSelector(selectIndividualSupplier)

	const [newCode, setNewCode] = useState<Array<string> | undefined>(undefined)
	const [newJurisdiction, setNewJurisdiction] = useState<string | undefined | null>(undefined)

	const countriesList = countries

	const customFilter = (
		option: { data: { value: string; region: string; label: string } },
		searchText: string
	) => {
		return (
			option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.region.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.label.toLowerCase().includes(searchText.toLowerCase())
		)
	}

	// Reset supplier data
	useEffect(() => {
		dispatch({
			type: SUPPLIERS_RESET,
		})
		dispatch({
			type: UPDATESUPPLIERS_RESET,
		})
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Data loading - Supplier
	useEffect(() => {
		if (suppliersStatus === FetchStatus.initial) {
			// No supplier data, attempt to load supplier
			dispatch(getSupplier(api, supplierId))
		}

		if (suppliersStatus === FetchStatus.loaded) {
			if (supplierData?.supplierIndustryCode?.code) {
				setNewCode([supplierData?.supplierIndustryCode.code])
			} else {
				setNewCode(undefined)
			}

			if (supplierData?.jurisdiction_code) {
				setNewJurisdiction(supplierData.jurisdiction_code)
			} else {
				setNewJurisdiction(undefined)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [suppliersStatus, supplierData])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companySupplierEdit.name}</title>
			</Helmet>
			{state === 'carbon' && (
				<PageHeadings
					title='Carbon Accounts'
					subTitle='Suppliers'
					subSubTitle='Edit supplier'
					routeBack={generatePath(routes.companyCarbonAccounts.path)}
					routeState='suppliers'
				/>
			)}
			{state === null && (
				<PageHeadings
					title='Purchases'
					subTitle='Suppliers'
					subSubTitle='Edit supplier'
					routeBack={routes.companySuppliers.path}
				/>
			)}

			{suppliersStatus === FetchStatus.loaded && (
				<>
					<dl className='mt-3 grid grid-cols-3 text-white'>
						<dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Supplier name</dt>
						<dd className='col-span-2 p-4 border-y border-charcoal-200'>
							{supplierData?.name || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Organisation number</dt>
						<dd className='col-span-2 p-4 border-y border-charcoal-200'>
							{supplierData?.company_number || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Jurisdiction</dt>
						<dd className='col-span-2 p-4 border-b border-charcoal-200'>
							{supplierData?.jurisdiction_code || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Sector Code (ISIC)</dt>
						<dd className='col-span-2 p-4 border-b border-charcoal-200'>
							{supplierData?.supplierIndustryCode?.code || '-'}
							{supplierData?.supplierIndustryCode?.name &&
								` - ${supplierData?.supplierIndustryCode?.name}`}
						</dd>
					</dl>

					<form
						onSubmit={(e) => {
							e.preventDefault()
						}}
					>
						{supplierUpdateStatus !== PostStatus.sent &&
							supplierUpdateStatus !== PostStatus.sending && (
								<>
									<div className='my-4 p-4 bg-charcoal-600 rounded-lg max-w-5xl'>
										<p className='text-white mb-4'>Add/Change the jurisdiction of this supplier:</p>
										<Select
											id='countrylist'
											options={countriesList}
											isMulti={false}
											isSearchable={true}
											isClearable={true}
											className='w-full'
											filterOption={customFilter}
											onChange={(value) => {
												setNewJurisdiction(value?.value)
											}}
											value={countriesList.filter(function (option) {
												return option.value === newJurisdiction
											})}
										></Select>
									</div>

									<div className='my-4 p-4 bg-charcoal-600 rounded-lg max-w-5xl'>
										<p className='text-white mb-4'>
											Add/Change the sector code (ISIC) to represent this supplier:
										</p>

										<ISICSelector
											key={supplierId}
											darkMode={true}
											value={newCode}
											min={1}
											max={1}
											updateHandler={(value) => {
												// TODO: ISIC Selector needs refactor to avoid this horrible logic
												if (value && value.length > 0) {
													if (typeof newCode === 'undefined') {
														setNewCode(value)
													}
													if (newCode && newCode[0] !== value[0]) {
														setNewCode(value)
													}
												} else {
													setNewCode(undefined)
												}
											}}
										/>
									</div>
								</>
							)}

						{supplierUpdateStatus === PostStatus.sending && (
							<div className='my-4 p-4 bg-charcoal-600 rounded-lg'>
								<LoadingSpinner inline loadingText='Updating supplier...' />
							</div>
						)}

						{supplierUpdateStatus === PostStatus.error && (
							<div className='rounded-lg bg-error-500 p-4 mt-4'>
								<p className='text-white'>
									There was an error attempting to update your supplier. Please try again. If the
									problem persists, please contact the Neoni team for support.
								</p>
							</div>
						)}

						{supplierUpdateStatus === PostStatus.sent && (
							<>
								<div className='mt-8 mb-4 bg-white rounded-lg p-4'>
									<p>
										<span className='fa-regular fa-check mr-2 text-algae-700' />
										Your supplier was successfully updated
									</p>
								</div>

								<Button
									type='Primary'
									onClick={() => {
										navigate(routes.companySuppliers.path)
									}}
									text='Return to suppliers overview'
									hasLeadingIcon
									additionalClass='mt-4'
									icon='fa-regular fa-arrow-left'
								/>
								<Button
									type='Outlined'
									onClick={() => {
										navigate(0)
									}}
									text='Edit'
									hasLeadingIcon
									additionalClass='mt-4 ml-4'
									icon='fa-regular fa-pen-to-square'
								/>
							</>
						)}

						{supplierUpdateStatus === PostStatus.initial && (
							<Button
								type='Primary'
								text='Update supplier'
								hasTrailingIcon
								icon='fa-regular fa-pen-to-square'
								onClick={() => {
									let jurisdictionValue
									let codeValue

									// Jurisdiction
									if (supplierData?.jurisdiction_code !== newJurisdiction) {
										if (
											typeof newJurisdiction === 'undefined' &&
											supplierData?.jurisdiction_code !== null
										) {
											jurisdictionValue = null
										} else {
											jurisdictionValue = newJurisdiction
										}
									}

									// ISIC Code
									if (supplierData?.supplierIndustryCode?.code !== (newCode && newCode[0])) {
										if (typeof newCode === 'undefined') {
											if (supplierData?.supplierIndustryCode !== null) {
												codeValue = null
											}
										} else {
											codeValue = newCode[0]
										}
									}

									// Send off the API call if there's a detected change
									if (jurisdictionValue !== undefined || codeValue !== undefined) {
										dispatch(
											updateSupplier(api, {
												supplierId: supplierId,
												jurisdication: jurisdictionValue,
												code: codeValue,
											})
										)
									}
								}}
							/>
						)}
					</form>
				</>
			)}

			{suppliersStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading supplier...' />
			)}

			{suppliersStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white mb-4'>
						There was an error whilst trying to fetch this supplier. If this problem persists,
						please contact the Neoni team.
					</p>
					{suppliersError && (
						<p className='text-white mb-4'>Error message: {suppliersError.toString()}</p>
					)}
					<Button
						type='Primary'
						hasLeadingIcon
						additionalClass='mt-4'
						icon='fa-regular fa-arrow-left'
						text='Return to suppliers overview'
						onClick={() => {
							navigate(routes.companySuppliers.path)
						}}
					/>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyEditSupplier
