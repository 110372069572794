import React from "react";
import { Tooltip, PieChart, Pie, Cell, Legend } from "recharts";

const COLORS = ["#FF7426", "#FFA675", "#FFE0CE"];
type IPieGraphData = {
	data: any[]
	heading?: string
	blue?: boolean
}
let renderLabel = function (entry: any) {
	return Math.round(entry.value) + 'kg CO₂e';
}

let getBlueColor = (index: number, totalCount: number) => {
	const colorOffset = 140 + index * 420 / totalCount
	const green = Math.min(colorOffset, 255)
	const red = Math.max(Math.min(9 + colorOffset - 255, 255), 0)
	return `rgb(${red}, ${green}, 255`
}

export default function CAScopePieChart({ data, heading, blue }: IPieGraphData) {
	const content = data.map(a => {
		return {
			name: a.label,
			value: a.value,
		}
	})
	return (
		<>
			<h2 className='text-white text-xl font-bold mb-2 mt-2'>
				{heading}
			</h2>
			<PieChart width={900} height={400}>
				<Pie
					data={content}
					cx={290}
					cy={180}
					innerRadius={90}
					outerRadius={120}
					fill="#8884d8"
					paddingAngle={5}
					dataKey="value"
					label={renderLabel}
				>
					{content.map((_entry, index) => (
						<Cell key={`cell-${index}`} fill={ blue ? getBlueColor(index, content.length) : COLORS[index % COLORS.length]}/>
					))}
				</Pie>
				<Tooltip/>
				<Legend
					wrapperStyle={{top: 100}}
					width={300}
					style={{left: '500px'}}
					align='right'
					verticalAlign='middle'
					layout='vertical'
					formatter={(value) => <span className='text-white ml-2 text-sm'>{value}</span>}
				/>
			</PieChart>
		</>
	);
}
