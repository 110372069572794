import { Dispatch } from 'redux'
import {
	EMISSIONTYPES_ERROR,
	EMISSIONTYPES_LOADED,
	EMISSIONTYPES_LOADING,
} from '../slices/emissionTypesSlice'
import { NeoniApi } from '../../lib/api'

export const getScope1EmissionTypes = (api: NeoniApi, year?: string) => async (dispatch: Dispatch) => {
	dispatch({
		type: EMISSIONTYPES_LOADING,
	})
	try {
		const body = await api.get(`scope_1_emission_types/`)

		if (body.success === true) {
			// Successfully fetched emission types
			dispatch({
				type: EMISSIONTYPES_LOADED,
				payload: {
					scope1: body.emissionFactors,
				},
			})
		} else {
			// Error
			dispatch({
				type: EMISSIONTYPES_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: EMISSIONTYPES_ERROR,
			payload: error,
		})
	}
}
