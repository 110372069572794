import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, ContactMessage, LoadingSpinner } from '../components/BaseComponents'
import routes from '../components/Router/routes'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import { setAddPurchaseUploadFile } from '../redux/actions/addPurchasesUploadActions'
import { deleteCsvFile, getCsvFiles } from '../redux/actions/csvFilesActions'
import { deleteCsvFileError, deleteCsvFileStatus, selectCsvFileError, selectCsvFiles, selectCsvFileStatus } from '../redux/slices/csvFilesSlice'
import SimpleSortTable, { TableColumn } from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import { CsvType } from '../types/CsvFile'
import { ADDPURCHASESUPLOAD_RESET, ADDPURCHASESUPLOAD_RESETFILE, selectAddPurchasesFileErrors, selectAddPurchasesUploadStatus, selectAddPurchasesUploadSuccessful } from '../redux/slices/addPurchasesUploadSlice'
import { updateAccountingPeriodStage } from '../redux/actions/accountingPeriodsActions'
import Modal from '../components/BaseComponents/Modal/Modal'
import { selectAccountingPeriodsName } from '../redux/slices/accountingPeriodsSlice'
import useApi from '../hooks/useApi'

function AccountingPeriodCsvs() {

	const { accountingPeriodIdVal } = useParams()

	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	useEffect(() => {
		dispatch(getCsvFiles(api, accountingPeriodIdVal))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const csvData = useAppSelector(selectCsvFiles)
	const csvStatus = useAppSelector(selectCsvFileStatus)
	const csvLoadingError = useAppSelector(selectCsvFileError)


	const [addPurchaseUploadId, setAddPurchaseUploadId] = useState('')

	const uploaderStatus = useAppSelector(selectAddPurchasesUploadStatus)
	const uploadIsValid = useAppSelector(selectAddPurchasesUploadSuccessful)
	const uploadedFileErrors = useAppSelector(selectAddPurchasesFileErrors)

	const deleteCsvStatus = useAppSelector(deleteCsvFileStatus)
	const deleteCsvError = useAppSelector(deleteCsvFileError)

	const [addCompanyModalOpen, setAddCompanyModalOpen] = useState<boolean>(false)
	const accountingPeriodName = useAppSelector(selectAccountingPeriodsName)

	// Reference to the invisible file upload input
	const inputFile = useRef<HTMLInputElement>(null)

	useEffect(() => {
		// Flag the accounting period as having a successful file upload
		if (uploaderStatus === PostStatus.sent) {
			if (uploadIsValid) {
				if (addPurchaseUploadId) {
					dispatch(
						updateAccountingPeriodStage(api, {
							accountingPeriodId: addPurchaseUploadId,
							uploadState: 0,
						})
					)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploaderStatus, uploadIsValid])

	// DataGrid data constructor
	const constructData = (dataSet: Array<CsvType>) => [
		...dataSet.map((csv) => {
			return {
				_id: csv.id,
				name: csv.filename,
				accountingPeriodId: csv.accountingPeriodId,
				uploadedAt: csv.uploaded_date,
				uploadedBy: csv.uploader,
				no: '',
			}
		}),
	]
	// DataGrid Columns
	const columns: TableColumn[] = [
		{ id: 'no', label: 'No.', sortable: false },
		{
			id: 'name',
			label: 'File Name',
			sortable: false,
		},
		{
			id: 'uploadedAt',
			label: 'Uploaded At',
			sortable: false,
		},
		{
			id: 'uploadedBy',
			label: 'Uploaded By',
			sortable: false,
		},
		{
			id: '',
			label: '',
			sortable: false,
			columnClass: 'text-center',
			renderer: (columnKey, rowData) => {
				return (
					<Button
						type='Primary'
						size='sm'
						text='Delete'
						hasLeadingIcon
						icon='fa-regular fa-trash'
						accessibilityLabel={rowData.name}
						additionalClass='float-right'
						onClick={() => {
							//	navigate(routes.companySupplierEdit.path + rowData._id)
							dispatch(deleteCsvFile(api, accountingPeriodIdVal, rowData._id))
						}}
					/>
				)
			},
		},
	]

	return (
		<PageTemplate testid='add-accounting-periods-main'>
			<Helmet>
				<title>{routes.companyAddAccountingPeriods.name}</title>
			</Helmet>
			<PageHeadings
				title={'Accounting Period ' + (accountingPeriodName || '')}
				subTitle='Csv Files'
				routeBack={routes.companyAddAccountingPeriods.path}
			/>

			<input
				type='file'
				id='file'
				ref={inputFile}
				accept='.csv'
				onChange={(e) => {
					if (e.target.files && e.target.files.length > 0) {
						dispatch(
							setAddPurchaseUploadFile({
								file: URL.createObjectURL(e.target.files[0]),
								accountingPeriodId: addPurchaseUploadId,
								filename: e.target.files[0].name
							})
						)
						navigate(routes.companyAddPurchasesUpload.path)
					}
				}}
				className='hidden'
			/>
			<div className='flex flex-row space-x-2'>
				<div className='block'>
					<Button
						type='Primary'
						text='Upload Csv File'
						size='sm'
						hasTrailingIcon={true}
						icon='fa-regular fa-upload'
						onClick={() => {
							if (accountingPeriodIdVal !== undefined) {

								setAddPurchaseUploadId(accountingPeriodIdVal)
								if (inputFile.current != null) {
									inputFile.current.click()
								}
							}
						}}
						additionalClass='mt-4 mb-2'
					/>

				</div>
			</div>
			{csvStatus === FetchStatus.loading && (
				<LoadingSpinner />
			)}
			{csvStatus === FetchStatus.loaded && csvData.length > 0 && (<SimpleSortTable
				additionalClass='mt-4'
				data={constructData(csvData)}
				columns={columns}
				onSortPress={(key, direction) => {
				}}
			/>)}

			{csvStatus === FetchStatus.loaded &&
				csvData.length === 0 && (
					<>
						<div className='rounded-lg bg-charcoal-700 p-4 mt-4'>
							<p className='text-white'>
								We couldn't find and csv files for this accounting period.
							</p>
						</div>
					</>
				)}
			{csvStatus === FetchStatus.error && (
				<>
					<p className='my-lg text-white'>
						Oops! There was an error fetching the csv data.
					</p>
					<p className='my-lg mt-4 text-white'>{csvLoadingError}</p>
				</>
			)}
			{deleteCsvStatus === PostStatus.sending && (
				<>
					<LoadingSpinner loadingText='Deleting file...' />
				</>
			)}
			{deleteCsvStatus === PostStatus.error && (
				<>
					<p className='my-lg text-white'>
						Oops! There was an error fetching the csv data.
					</p>
					<p className='my-lg mt-4 text-white'>{deleteCsvError}</p>
				</>
			)}
			<Modal
				open={addCompanyModalOpen}
				onClose={() => {
					setAddCompanyModalOpen(false)
					dispatch({ type: ADDPURCHASESUPLOAD_RESET })
					dispatch({ type: ADDPURCHASESUPLOAD_RESETFILE })
				}}
				size='small'
			>
				<>
					{/* Fail */}

					<ContactMessage message='We found some errors in that file. Please fix any errors in your csv file and reupload.' />

					<div className='col-span-2 w-full'>
						<div className='flex flex-col rounded-lg shadow-lg bg-charcoal-600 h-full'>
							<div className='mt-8 flex flex-col'>
								<div className='-my-2 overflow-x-auto'>
									<div className='inline-block min-w-full py-2 align-middle'>
										<div className='overflow-hidden shadow-sm'>
											<table className='table-auto min-w-full divide-y divide-charcoal-300'>
												<tbody className=''>
													{uploadedFileErrors?.map((error, index) => {
														return (
															<tr
																key={error.text + error.reference}
																className='border-t-2 border-b-2 border-white'
															>
																<td className='p-lg text-left text-sm font-medium text-white leading-5'>
																	{index + 1}
																</td>
																<td className='p-lg text-left text-sm font-medium text-white leading-5'>
																	{error.reference}
																</td>
																<td className='p-lg text-left text-sm font-medium leading-5'>
																	<p className='text-charcoal-100'>Reason for error</p>
																	<p className='text-white'>{error.text}</p>
																</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			</Modal>
		</PageTemplate>
	)
}
export default AccountingPeriodCsvs
