type csvResult = {
	startDate: string
	endDate: string
	purchases?: number
	errors?: number
	headingTextClass?: string
	dataTextClass?: string
}
const CsvSummary = ({
	startDate,
	endDate,
	purchases,
	errors,
	headingTextClass,
	dataTextClass,
}: csvResult) => {
	return (
		<table className='max-w-2xl w-full'>
			<tbody>
				<tr className='leading-10 text-sm mt-lg mb-md font-normal'>
					<td className={`${headingTextClass}`}>Accounting Period:</td>
					<td className={`${dataTextClass}`}>
						{startDate} - {endDate}
					</td>
				</tr>
				{typeof purchases !== 'undefined' && (
					<tr className={`${headingTextClass} leading-10 text-sm mt-lg mb-md font-normal`}>
						<td>Number of purchases:</td>
						<td className={`${dataTextClass}`}>{purchases}</td>
					</tr>
				)}
				{typeof errors !== 'undefined' && (
					<tr className={`${headingTextClass} leading-10 text-sm mt-lg mb-md font-normal`}>
						<td>Number of errors:</td>
						<td className={`${dataTextClass}`}>{errors}</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
export default CsvSummary
