import { useEffect, useState } from "react";
import usePublicApi from "../hooks/usePublicApi";
import { useParams } from "react-router-dom";
import PageHeadings from "../components/BaseComponents/PageHeadings/PageHeadings";
import SimpleSortTable, { TableColumn } from "../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import { Button, LoadingSpinner } from "../components/BaseComponents";
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate";
import { formatDate, formatReportStatus } from "../lib/utils";

const columns: TableColumn[] = [
    {
        id: 'type',
        label: 'Type',
    },
    {
        id: 'period',
        label: 'Name',
        renderer: (_key, data) => (
            <span>
                {formatDate(data.startDate)}
                {' - '}
                {formatDate(data.endDate)}
            </span>
        )
    },
    {
        id: 'status',
        label: 'Status',
        renderer: (key, data) => <span>{formatReportStatus(data[key])}</span>
    },
    {
        id: 'firstPublished',
        label: 'First published',
        renderer: (key, data) => <span>{formatDate(data[key])}</span>
    },
    {
        id: 'lastUpdated',
        label: 'lastUpdated',
        renderer: (key, data) => <span>{formatDate(data[key])}</span>
    },
    {
        id: 'view',
        label: 'Actions',
        textAlign: 'center',
        renderer: (key, data) => {
            const reportId = data.id;
            return (
                <Button
                    type="Primary"
                    size="sm"
                    text="View"
                    isLink
                    url={`/public/reports/${reportId}`}
                />
            );
        },
    },
];

function PublicProfile() {
    const publicApi = usePublicApi();
    const { companyId } = useParams();

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancelled = false;

        setLoading(true);
        const run = async () => {
            const result = await publicApi.get(`/public/profiles/${companyId}`);
            if(cancelled) {
                return;
            }

            setData(result.data);
            setLoading(false);
        }

        run();
        return () => {
            cancelled = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId])

    if(loading) {
        return <LoadingSpinner />
    }
    return (
        <PageTemplate>
            <PageHeadings title={data.name} subTitle="Organisation Profile" />
            
            <h2 className="my-8 text-white">Reports</h2>
            <SimpleSortTable data={data.reports} columns={columns} />
        </PageTemplate>
    )
}

export default PublicProfile;
