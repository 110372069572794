import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PaginationMeta } from '../../types/PaginationMeta'
import type { RootState } from '../store'

type FetchCompanySlice = {
	status: FetchStatus
	error?: string
	companies: Array<any>
	pagination: PaginationMeta
}
const fetchCompanyInitialState: FetchCompanySlice = {
	status: FetchStatus.initial,
	error: undefined,
	companies: [],
	pagination: {
		total: 0,
		per_page: 0,
		current_page: 1,
		last_page: 1,
		first_page: 1,
	},
}

export const companyListSlice = createSlice({
	name: 'fetchCompanies',
	initialState: fetchCompanyInitialState,
	reducers: {
		COMPANYLIST_FETCH: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		COMPANYLIST_SUCCESS: (
			state,
			action: PayloadAction<{
				data: Array<any>
				meta: any
			}>
		) => {
			return {
				...state,
				status: FetchStatus.loaded,
				companies: action.payload.data,
				pagination: {
					total: action.payload.meta.total,
					per_page: action.payload.meta.per_page,
					current_page: action.payload.meta.current_page,
					last_page: action.payload.meta.last_page,
					first_page: action.payload.meta.first_page,
				},
			}
		},
		COMPANYLIST_FAILURE: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		COMPANYLIST_RESET: () => {
			return fetchCompanyInitialState
		},
	},
})

// Action types
export const { COMPANYLIST_FETCH, COMPANYLIST_SUCCESS, COMPANYLIST_FAILURE, COMPANYLIST_RESET } =
	companyListSlice.actions

// Selectors
export const selectCompanyListStatus = (state: RootState) => state.companyList.status
export const selectCompanyListError = (state: RootState) => state.companyList.error
export const selectCompanyList = (state: RootState) => state.companyList.companies

export const selectCompanyListPagination = (state: RootState) => state.companyList.pagination
export const selectCompanyListNumberOfResults = (state: RootState) =>
	state.companyList.companies.length

export default companyListSlice.reducer
