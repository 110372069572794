import { Dispatch } from 'redux'
import {
	OPENCORPORATES_ERROR,
	OPENCORPORATES_FETCHING,
	OPENCORPORATES_SUCCESS,
} from '../slices/openCorporatesSlice'
import { NeoniApi } from '../../lib/api'

export const getOpenCorporatesData =
	(api: NeoniApi, searchValue: string, isCompanyNumber: boolean) => async (dispatch: Dispatch) => {
		dispatch({
			type: OPENCORPORATES_FETCHING,
		})

		try {
			let params = new URLSearchParams({
				q: searchValue,
			})
			if (isCompanyNumber) {
				params.append('type', 'company_number')
			}

			const body = await api.get(`corporatesearch?` + params);

			dispatch({
				type: OPENCORPORATES_SUCCESS,
				payload: body.data,
			})
		} catch (error) {
			dispatch({
				type: OPENCORPORATES_ERROR,
				payload: error,
			})
		}
	}
