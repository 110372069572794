import { PropsWithChildren } from "react";
import LineChartComponent from "./Graphs/LineGraph";
import PieChart from "./Graphs/PieChart";
import { StackedBarChart } from "./Graphs/StackedBarChart";

function CO2e() {
  const style = {
    verticalAlign: 'sub',
    fontSize: '0.75em',
  }
  return <span>CO<span style={style}>2</span>e</span>
}

function Highlight({  children }: PropsWithChildren) {
  return (
    <span style={{ color: '#FFE0CE'}}>
      { children }
    </span>
  )
}

type  DirectionValueProps = PropsWithChildren<{
  negative?: boolean,
}>

function DirectionValue({ negative, children }: DirectionValueProps) {
  return (
    <div style={{ color: negative ? '#FF514F' : 'green', display: 'inline'}}>
      { children }
    </div>
  )
}

type InsightData = {
  type: string
  groupKey?: string
  content: any,
}

type InsightProps = {
  insight: InsightData
}

type InsightPropProps = {
  insights: InsightData[],
  groupKey: string,
  heading: string,
}
export function InsightGroup({ insights, groupKey, heading}: InsightPropProps) {
  const groupInsights = insights.filter(insight => {
    return insight.groupKey === groupKey
  })

  if(!groupInsights.length) {
    return null
  }

  return (
		<div className='rounded bg-charcoal-700 p-4 mt-10 mb-10'>
			<div className='rounded bg-charcoal-900 p-4'>
				<h2 className='text-white text-lg font-bold mb-2 mt-2'>
					{heading}
				</h2>
        { groupInsights.map( insight => {
          return (
            <div className='rounded border border-1 border-charcoal-500 p-4 text-white mb-2'>
              <Insight insight={insight}/>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export function Insight({insight}: InsightProps) {
  if(insight.type === 'graph') {
    if(insight.content.graphKey === 'emissions_by_scope') {
      return (
        <PieChart data={insight.content.values} heading={insight.content.label} />
      )
    } else if(insight.content.graphKey === 'emission_by_sectors_years') {
      return (
        <StackedBarChart data={insight.content.values} heading={insight.content.label} />
      )
    } else if(insight.content.graphKey === 'emissions_by_sectors') {
      return (
        <PieChart data={insight.content.values} heading={insight.content.label} blue />
      )
    } else if(insight.content.graphKey === 'embedded_improvements_suppliers') {
      return (
        <LineChartComponent
          data={insight.content.values}
          heading={insight.content.label}
          xAxisValue="periodDateRange"
        />
      )
    } else if(insight.content.graphKey === 'embedded_improvements_sectors') {
      return (
        <LineChartComponent
          data={insight.content.values}
          heading={insight.content.label}
          xAxisValue="periodDateRange"
        />
      )
    }
    
    
  }

  if(insight.type === 'statement_values') {
    if(insight.content.statementKey === 'scope1_change') {
      const negative = insight.content.absoluteChange > 0;
      return (
        <div>
          <Highlight>Scope 1</Highlight>
          {' '}was
          {' '}<DirectionValue negative={negative}>
            { Math.abs(insight.content.percentage) }% { negative ? 'higher' : 'lower' }
          </DirectionValue>
          {' '} than last period, { negative ? 'increased' : 'reduced' }
          {' '}a total of <DirectionValue negative={negative}>{insight.content.absoluteChange} </DirectionValue> kg <CO2e/>
        </div>
      )
    } else if(insight.content.statementKey === 'scope1_largest') {
      return (
        <div>
          The largest contributor was <Highlight>{insight.content.label }</Highlight> at <Highlight>{ insight.content.value}</Highlight> kg <CO2e/>
        </div>
      )
    } else if(insight.content.statementKey === 'scope1_largest_decrease') {
      return (
        <div>
          The biggest decrease was <Highlight>{insight.content.change}</Highlight> kg <CO2e/> in <Highlight>{insight.content.label }</Highlight>
        </div>
      )
    } else if(insight.content.statementKey === 'scope1_largest_increase') {
      return (
        <div>
          The biggest increase was <Highlight>{insight.content.change}</Highlight> kg <CO2e/> in <Highlight>{insight.content.label }</Highlight>
        </div>
      )
    } else if(insight.content.statementKey === 'scope2_change') {
      const negative = insight.content.absoluteChange > 0;
      return (
        <div>
          <Highlight>Scope 2</Highlight> was <DirectionValue negative={negative}>
            {Math.abs(insight.content.percentage)}% { negative ? 'higher' : 'lower'}
          </DirectionValue> than last period, { negative ? 'increased' : 'reduced'} a total of <DirectionValue negative={negative}>{insight.content.absoluteChange}</DirectionValue> kg <CO2e/>
        </div>
      )
    } else if(insight.content.statementKey === 'scope2_change_usage') {
      const negative = insight.content.absoluteChange > 0;
      return (
        <div>
          This was because we bought <DirectionValue negative={negative}>{insight.content.change}% { negative ? 'more' : 'less'} KWh</DirectionValue> than last period.
        </div>
      )
    } else if(insight.content.statementKey === 'scope2_change_percent') {
      const negative = insight.content.percent > 0;
      return (
        <div>
          And the <CO2e/> per KWh purchased was <DirectionValue negative={negative}>{Math.abs(insight.content.percent)}% { negative ? 'higher' : 'lower'}</DirectionValue>.
        </div>
      )
    } else if(insight.content.statementKey === 'scope3_total_embedded') {
      return (
        <div>
          <DirectionValue>{ insight.content.total }</DirectionValue> kg <CO2e/> based on figures provided by suppliers on Neoni
        </div>
      )
    } else if(insight.content.statementKey === 'scope3_total_reference') {
      return (
        <div>
          <Highlight>Scope 3</Highlight> total is made up of <DirectionValue>{ insight.content.total }</DirectionValue> kg <CO2e/>, i.e. averages drawn from  OECD and UN data for companies in the same sector and geography
        </div>
      )
    } else if(insight.content.statementKey === 'scope3_change') {
      const negative = insight.content.absoluteChange > 0
      return (
        <div>
          <Highlight>Scope 3</Highlight> was <DirectionValue negative={negative}>{ Math.abs(insight.content.percentage) }%</DirectionValue> { negative ? 'higher' : 'lower' } than last period, increased a total of <DirectionValue negative={negative}>{ insight.content.absoluteChange}</DirectionValue> kg <CO2e/>
        </div>
      )
    } else if(insight.content.statementKey === 'scope3_change_cost') {
      const negative = insight.content.percentage > 0
      return (
        <div>
          This was because we had <DirectionValue negative={negative}>{ Math.abs(insight.content.percent) }% { negative ? 'more' : 'less' }</DirectionValue> spend than last period
        </div>
      )
    } else if(insight.content.statementKey === 'scope3_change_intensity') {
      const negative = insight.content.percent > 0
      return (
        <div>
          And the <CO2e/> per unit spend purchased was <DirectionValue negative={negative}>{ Math.abs(insight.content.percent) }% { negative ? 'higher' : 'lower'}</DirectionValue> than last period
        </div>
      )
    } else if(insight.content.statementKey === 'embedded_total') {
      return (
        <div>
          Total embedded emissions from supply chain purchases were <Highlight>{insight.content.total}</Highlight> kg <CO2e/>
        </div>
      )
    } else if(insight.content.statementKey === 'embedded_change') {
      const negative = insight.content.absoluteChange > 0
      return (
        <div>
          They have { negative ? 'increased' : 'decreased'} a total
          of <DirectionValue negative={negative}>{insight.content.absoluteChange}</DirectionValue> kg <CO2e/> which
          is <DirectionValue negative={negative}>{Math.abs(insight.content.percentage)}%</DirectionValue> { negative ? 'higher' : 'lower'} than
          last period - {insight.content.previousDateRange}
        </div>
      )
    } else if(insight.content.statementKey === 'embedded_change_reason') {
      const negativeCost = insight.content.percentageCostChange > 0
      const negativeIntensity = insight.content.currentIntensityRatio > insight.content.previousIntensityRatio
      return (
        <div>
          This is because total spend has gone { negativeCost ? 'up' : 'down'} 
          {' '}<DirectionValue negative={negativeCost}>{insight.content.percentageCostChange}%</DirectionValue>
          {' '} and the average Intensity Ratio has gone { negativeIntensity ? 'up' : 'down'} from
          {' '}<DirectionValue negative={negativeIntensity}>{insight.content.previousIntensityRatio}</DirectionValue>
          {' '}to
          {' '}<DirectionValue negative={negativeIntensity}>{insight.content.currentIntensityRatio}</DirectionValue>
        </div>
      )
    }
  }
  return null
}