import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { Button, CompanyInviteCard, LoadingSpinner } from '../components/BaseComponents'
import OpenCoSearchResults from '../components/OpenCoSearchResults/OpenCoSearchResults'
import InvitationsTable from '../components/InvitationsTable/InvitationsTable'
import CreateInviteForm from '../components/BaseComponents/Forms/CreateInviteForm'

import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import { createInvitation, getInvitations } from '../redux/actions/invitationsActions'
import { selectInvitations, selectInvitationsStatus } from '../redux/slices/invitationsSlice'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import {
	CREATECOMPANY_RESET,
	selectCreateCompanyStatus,
	selectCreatedCompany,
} from '../redux/slices/createCompanySlice'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import { OpenSearchCompany } from '../types/OpenSearchCompany'
import {
	INVITATIONS_CREATE_RESET,
	selectCreateInvitationsStatus,
	selectCreateInviteCompanyID,
	selectCreateInviteEmail,
	selectCreateInviteSendEmail,
} from '../redux/slices/createInvitationsSlice'
import Modal from '../components/BaseComponents/Modal/Modal'
import {
	OPENCORPORATES_RESET,
	selectOpenCorporates,
	selectOpenCorporatesStatus,
} from '../redux/slices/openCorporatesSlice'
import { getOpenCorporatesData } from '../redux/actions/openCorporatesActions'
import { createCompany } from '../redux/actions/createCompanyActions'
import { NewCompany } from '../types/Company'
import routes from '../components/Router/routes'
import { getRoles } from '../redux/actions/rolesActions'
import { selectRoles, selectRolesStatus } from '../redux/slices/rolesSlice'
import useApi from '../hooks/useApi'

function AdminInviteCompany() {
	const dispatch = useAppDispatch()
	const api = useApi();

	const allInvitations = useAppSelector(selectInvitations)
	const invitationsStatus = useAppSelector(selectInvitationsStatus)

	const openCorporatesData = useAppSelector(selectOpenCorporates)
	const openCorporatesStatus = useAppSelector(selectOpenCorporatesStatus)

	const createCompanyStatus = useAppSelector(selectCreateCompanyStatus)
	const createdCompany = useAppSelector(selectCreatedCompany)

	const createInvitationStatus = useAppSelector(selectCreateInvitationsStatus)
	const [inviteModalOpen, setinviteModalOpen] = useState<boolean>(false)

	const email = useAppSelector(selectCreateInviteEmail)
	const sendEmail = useAppSelector(selectCreateInviteSendEmail)
	const companyIdToInvite = useAppSelector(selectCreateInviteCompanyID)
	const [searchValueOpenCorporates, setsearchValueOpenCorporates] = useState('')
	const [searchValueTypeOpenCorporates, setsearchValueTypeOpenCorporates] = useState(false)

	const [companyrole, setcompanyrole] = useState<string>('')
	const roleStatus = useAppSelector(selectRolesStatus)
	const rolesData = useAppSelector(selectRoles)

	const convertToNeoniCompany = (company: OpenSearchCompany) => {
		let industryCodesFiltered: any[] = []
		company.industryCodes.forEach((industryCodes: any) => {
			industryCodesFiltered.push({
				code: industryCodes['industry_code']['code'],
				codeType: industryCodes['industry_code']['code_scheme_name'],
			})
		})
		return {
			name: company.name,
			type: company.type,
			number: company.company_number,
			jurisdiction_code: company.jurisdictionCode,
			industry_codes: industryCodesFiltered,
			address: company.address,
			tier: 'standard',
		} as NewCompany
	}

	useEffect(() => {
		dispatch(getInvitations(api))
		dispatch(getRoles(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (roleStatus === FetchStatus.loaded) {
			for (let i = 0; i < rolesData.length; i++) {
				if (rolesData[i].name === 'company') {
					setcompanyrole(rolesData[i].id)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleStatus])

	const [activeResult, setActiveResult] = useState<OpenSearchCompany>()

	const resetModal = () => {
		setActiveResult(undefined)
		dispatch({
			type: CREATECOMPANY_RESET,
		})
		dispatch({
			type: INVITATIONS_CREATE_RESET,
		})
		dispatch({
			type: OPENCORPORATES_RESET,
		})
	}

	const dispatchSearchOpenCorporates = () => {
		dispatch(getOpenCorporatesData(api, searchValueOpenCorporates, searchValueTypeOpenCorporates))
	}

	return (
		<PageTemplate testid='admin-invite-company-main'>
			<Helmet>
				<title>{routes.adminInviteCompany.name}</title>
			</Helmet>
			<PageHeadings title='Invite Organisation' />
			<div>
				<Button
					type='Primary'
					text='Invite a New Organisation'
					size='sm'
					hasTrailingIcon
					icon='fa-regular fa-plus'
					onClick={() => {
						setinviteModalOpen(!inviteModalOpen)
					}}
					additionalClass='mt-5 mb-5'
				/>
				<Modal
					open={inviteModalOpen}
					onOpen={() => {
						resetModal()
					}}
					onClose={() => {
						setinviteModalOpen(false)
					}}
					size='small'
				>
					{createInvitationStatus === PostStatus.initial && (
						<>
							{createCompanyStatus === PostStatus.initial && (
								<>
									<PageHeadings title='Search for a Organisation' />
									<div className='mb-5 mt-5'>
										<form
											className='flex flex-col'
											onSubmit={(e) => {
												e.preventDefault()
												dispatchSearchOpenCorporates()
											}}
										>
											<label className='text-charcoal-100 text-sm'>
												Find an organisation by name or organisation number
											</label>
											<div className='flex mt-sm'>
												<input
													className='text-charcoal-500 rounded-l-sm w-full p-md border-charcoal-200 focus:border-charcoal-200 focus:ring-0'
													id='company_search'
													type='text'
													onChange={(e) => {
														setsearchValueOpenCorporates(e.target.value)
													}}
													placeholder='Search for organisation name or number'
												/>
												<button className='flex items-center rounded-r-sm bg-algae-100 border border-algae-500 px-md text-charcoal-500'>
													Search<span className='ml-md fa-regular fa-search'></span>
												</button>
											</div>
											<label className='text-charcoal-100 text-sm mt-4 ml-4'>
												Organisation Number
												<input
													className='mx-4'
													type='checkbox'
													checked={searchValueTypeOpenCorporates}
													onChange={(e) => {
														setsearchValueTypeOpenCorporates(!searchValueTypeOpenCorporates)
													}}
												></input>
											</label>
										</form>
										{openCorporatesStatus === FetchStatus.loading && (
											<LoadingSpinner loadingText='Loading Results...' />
										)}
										{openCorporatesStatus === FetchStatus.loaded && (
											<OpenCoSearchResults
												results={openCorporatesData}
												setActiveResult={setActiveResult}
												activeResult={activeResult as any}
											/>
										)}
										{openCorporatesStatus === FetchStatus.error && (
											<>
												<div className='rounded-lg bg-error-500 p-4 mt-4'>
													<p className='text-white'>
														There was an error whilst fetching the organisation data. If this problem
														please contact the Neoni team.
													</p>
												</div>
												<Button
													onClick={dispatchSearchOpenCorporates}
													type='Primary'
													hasTrailingIcon={true}
													icon='fa-solid fa-arrow-right'
													text='Retry'
													additionalClass='mt-9 mr-5'
												/>
												<Button
													type='Outlined'
													text='Cancel'
													onClick={() => {
														setinviteModalOpen(false)
													}}
													additionalClass='mt-6 mr-5'
												/>
											</>
										)}
									</div>
								</>
							)}
							{createCompanyStatus === PostStatus.sending && (
								<LoadingSpinner loadingText='Creating organisation...' />
							)}
							{createCompanyStatus === PostStatus.sent && createdCompany && (
								<>
									<PageHeadings title='Send Invitation' />
									<div className='flex flex-col mt-5'>
										{activeResult && (
											<CompanyInviteCard
												isInteractable={false}
												activeResult={activeResult}
												result={activeResult}
											/>
										)}
										<CreateInviteForm
											companyId={createdCompany.id}
											type='Admin'
											roleId={companyrole}
										/>
									</div>
								</>
							)}
							{createCompanyStatus === PostStatus.error && (
								<>
									<PageHeadings title='Error' />
									<div className='rounded-lg bg-error-500 p-4 mt-4'>
										<p className='text-white'>
											There was an error whilst creating the organisation. If this problem please contact
											the Neoni team.
										</p>
									</div>
									<Button
										onClick={() => {
											dispatch(createCompany(api, convertToNeoniCompany(activeResult as any)))
										}}
										type='Primary'
										hasTrailingIcon={true}
										icon='fa-solid fa-arrow-right'
										text='Retry'
										additionalClass='mt-9 mr-5'
									/>
									<Button
										type='Outlined'
										text='Cancel'
										onClick={() => {
											setinviteModalOpen(false)
										}}
										additionalClass='mt-6 mr-5'
									/>
								</>
							)}
						</>
					)}

					{createInvitationStatus === PostStatus.sending && (
						<LoadingSpinner loadingText='Creating invitation...' />
					)}

					{createInvitationStatus === PostStatus.sent && (
						<>
							<PageHeadings title='Invitation sent successfully' />
							<p className='text-white mt-6'>Your invitation has been sent successfully</p>
							<Button
								type='Primary'
								text='Back to invitations'
								onClick={() => {
									setinviteModalOpen(false)
								}}
								additionalClass='mt-6 mr-5'
							/>

							<Button
								type='Outlined'
								text='Create another invitation'
								icon='fa-regular fa-plus'
								hasTrailingIcon
								onClick={() => {
									resetModal()
								}}
								additionalClass='mt-6'
							/>
						</>
					)}

					{createInvitationStatus === PostStatus.error && (
						<>
							<PageHeadings title='Error' />
							<div className='rounded-lg bg-error-500 p-4 mt-4'>
								<p className='text-white'>
									There was an error whilst trying to send the invitation. If this problem persists,
									please contact the Neoni team.
								</p>
							</div>
							<Button
								onClick={() => {
									if (
										companyIdToInvite !== undefined &&
										email !== undefined &&
										sendEmail !== undefined
									) {
										dispatch(createInvitation(api, companyIdToInvite, email, sendEmail))
									}
								}}
								type='Primary'
								hasTrailingIcon={true}
								icon='fa-solid fa-arrow-right'
								text='Retry'
								additionalClass='mt-9 mr-5'
							/>
							<Button
								type='Outlined'
								text='Cancel'
								onClick={() => {
									setinviteModalOpen(false)
								}}
								additionalClass='mt-6 mr-5'
							/>
						</>
					)}
				</Modal>

				<div className='col-span-2'>
					<div className='rounded-lg h-full'>
						{/* Loading */}
						{invitationsStatus === FetchStatus.loading && <LoadingSpinner />}

						{/* Loaded */}
						{invitationsStatus === FetchStatus.loaded && allInvitations.length > 0 && (
							<InvitationsTable invitations={allInvitations} type='Admin' />
						)}

						{/* Error */}
						{invitationsStatus === FetchStatus.error && (
							<p className='my-lg text-white'>
								Oops! There was an error fetching the invitations data.
							</p>
						)}
					</div>
				</div>
			</div>
		</PageTemplate>
	)
}

export default AdminInviteCompany
