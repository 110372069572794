import { useState } from "react";
import { Button } from "../../components/BaseComponents";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

type CreatePortfolioModalProps = {
    onClose: () => void,
    onCreated: (newId: string) => void
    isOpen?: boolean,
};

function CreatePortfolioModal({onClose, onCreated, isOpen}: CreatePortfolioModalProps) {
    const [label, setLabel] = useState('');
    const api = useApi();

    const create = async () => {
        const result = await api.post('/portfolios', {
            name: label,
            _csrf: await getCSRFToken(),
        })
        console.log(result);
        onCreated(result.data.id);
    }

    return (
        <Modal open={!!isOpen} onClose={onClose} size="small">
            <div className="flex flex-col">
                <PageHeadings title='Create a new portfolio' />
                <label className="mt-8 text-charcoal-100">Portfolio Name</label>
                <input
                    className="rounded"
                    value={label}
                    onChange={e => setLabel(e.target.value)} type="text"
                    placeholder="Portfolio name..."
                />
                <div className="mt-8 grow">
                    <Button onClick={create} additionalClass="mr-5" disabled={!label} type="Primary" text="Create Portfolio" />
                    <Button onClick={onClose} type="Outlined" text="Cancel" />
                </div>

            </div>
        </Modal>
    )
}

export default CreatePortfolioModal;
