/* eslint-disable @typescript-eslint/no-unused-vars */
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate';
import routes from '../components/Router/routes';
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings';
import SimpleSortTable from '../components/BaseComponents/SimpleSortTable/SimpleSortTable';
import useApi from '../hooks/useApi';
import Label from '../components/BaseComponents/Label';

function AdminCurrencies() {
    const api = useApi();

    const earliest = 1999;
    const latest = new Date().getFullYear();
    const years = new Array(latest - earliest + 1).fill(0);
    console.log({years})
    const [year, setYear] = useState(latest);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        let cancelled = true;
        setLoading(true);
        const run = async () => {
            const result = await api.get(`admin/currencies?year=${year}`)
            setData(result.data);
            setLoading(false);
        };
        run();
        return () => {
            cancelled = false;
        }
    }, [year])

    const columns = [
        {
            label: 'year',
            id: 'year',
        },
        {
            label: 'month',
            id: 'month',
        },
        {
            label: 'EUR',
            id: 'eur',
        },
        {
            label: 'GBP',
            id: 'gbp',
        },
        {
            label: 'USD',
            id: 'usd',
        }
    ]
	return (
		<PageTemplate testid='admin-company'>
			<Helmet>
				<title>{routes.adminCompany.name}</title>
			</Helmet>
			<PageHeadings title='Currencies' subTitle=''/>
		
            <div className="flex flex-col my-8 space-x-4">
                <div>
                    <Label className="block">
                        Year
                    </Label>
                    <select
                        value={year}
                        onChange={e => setYear(Number(e.target.value))}
                        className="rounded"
                    >
                        { years.map((_, i) => {
                            const year = latest - i;
                            return (
                                <option value={year} key={year}>
                                    { year }
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <SimpleSortTable
                columns={columns}
                data={data}
                isLoading={loading}
            />
		</PageTemplate>
	)
}
export default AdminCurrencies
