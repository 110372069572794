import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { Invitation } from '../../types/Invitation'
import {
	RECEIVED_INVITATIONS_ERROR,
	RECEIVED_INVITATIONS_FAILURE_UPDATE,
	RECEIVED_INVITATIONS_FETCHING,
	RECEIVED_INVITATIONS_SUCCESS,
	RECEIVED_INVITATIONS_UPDATED,
	RECEIVED_INVITATIONS_UPDATING,
} from '../slices/companyReceivedInvitationsSlice'
import { NeoniApi } from '../../lib/api'

export const getReceivedInvitations = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: RECEIVED_INVITATIONS_FETCHING,
	})

	try {
		const body = await api.get(`supplyChainInvitations/received`)
		body.invitations.sort((a: Invitation, b: Invitation) => (a.createdAt > b.createdAt ? -1 : 1))

		dispatch({
			type: RECEIVED_INVITATIONS_SUCCESS,
			payload: body.invitations,
		})
	} catch (error) {
		dispatch({
			type: RECEIVED_INVITATIONS_ERROR,
			payload: error,
		})
	}
}

export const acceptRejectInvitationCompany =
	(api: NeoniApi, invitationId: string, isAccepted: boolean) => async (dispatch: Dispatch) => {
		// Mark invitation as update in progress
		dispatch({
			type: RECEIVED_INVITATIONS_UPDATING,
			payload: invitationId,
		})

		try {
			const csrf = await getCSRFToken()
			const body = await api.post(`supplyChainInvitations/respond`, {
				invitationId, isAccepted, _csrf: csrf
			});

			if (body.success === true) {
				// Successfully revoked
				dispatch({
					type: RECEIVED_INVITATIONS_UPDATED,
					payload: body.data.invite,
				})
			} else {
				// Error
				dispatch({
					type: RECEIVED_INVITATIONS_FAILURE_UPDATE,
					payload: { id: invitationId, error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: RECEIVED_INVITATIONS_FAILURE_UPDATE,
				payload: { id: invitationId, error: error },
			})
		}
	}
