import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PeriodicReportResponse } from '../../types/PeriodicReports'
import type { RootState } from '../store'

type periodReportingsState = {
	status: FetchStatus
	error?: string
	data: null | PeriodicReportResponse
	statusSupplyChain: FetchStatus
	errorSupplyChain?: string
	supplyChainData: null | PeriodicReportResponse
}

const periodicReportsInitialState: periodReportingsState = {
	status: FetchStatus.initial,
	error: undefined,
	data: null,
	statusSupplyChain: FetchStatus.initial,
	errorSupplyChain: undefined,
	supplyChainData: null,
}

export const periodicReportsSlice = createSlice({
	name: 'periodicReports',
	initialState: periodicReportsInitialState,
	reducers: {
		PERIODICREPORTS_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
				data: null,
			}
		},
		PERIODICREPORTS_LOADED: (state, action: PayloadAction<PeriodicReportResponse>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				data: action.payload,
			}
		},
		PERIODICREPORTS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
				data: null,
			}
		},
		PERIODICREPORTS_SUPPLYCHAIN_LOADING: (state) => {
			return {
				...state,
				statusSupplyChain: FetchStatus.loading,
			}
		},
		PERIODICREPORTS_SUPPLYCHAIN_LOADED: (state, action: PayloadAction<PeriodicReportResponse>) => {
			return {
				...state,
				statusSupplyChain: FetchStatus.loaded,
				errorSupplyChain: undefined,
				supplyChainData: action.payload,
			}
		},
		PERIODICREPORTS_SUPPLYCHAIN_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				statusSupplyChain: FetchStatus.error,
				errorSupplyChain: action.payload,
			}
		},
		PERIODICREPORTS_RESET: () => {
			return periodicReportsInitialState
		},
	},
})

// Action types
export const {
	PERIODICREPORTS_LOADING,
	PERIODICREPORTS_LOADED,
	PERIODICREPORTS_ERROR,
	PERIODICREPORTS_RESET,
	PERIODICREPORTS_SUPPLYCHAIN_LOADING,
	PERIODICREPORTS_SUPPLYCHAIN_LOADED,
	PERIODICREPORTS_SUPPLYCHAIN_ERROR,
} = periodicReportsSlice.actions

// Selectors
export const selectPeriodicReportsStatus = (state: RootState) => state.periodicReports.status
export const selectPeriodicReportsError = (state: RootState) => state.periodicReports.error
export const selectAllPeriodicReports = (state: RootState) => state.periodicReports.data

export const selectPeriodicReportsSupplyChainStatus = (state: RootState) =>
	state.periodicReports.statusSupplyChain
export const selectPeriodicReportsSupplyChainError = (state: RootState) =>
	state.periodicReports.errorSupplyChain
export const selectAllPeriodicSupplyChainReports = (state: RootState) =>
	state.periodicReports.supplyChainData

export default periodicReportsSlice.reducer
