/* eslint-disable @typescript-eslint/no-unused-vars */
import { Helmet } from 'react-helmet'
import routes from '../../components/Router/routes'
import PageHeadings from '../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../components/BaseComponents/PageTemplate/PageTemplate'
import { useParams } from 'react-router-dom'
import AdminCompanySummary from './AdminCompanySummary'
import AdminCompanyUsers from './AdminCompanyUsers'
import AdminCompanyTier from './AdminCompanyTier'
import AdminCompanyBoltOns from './AdminCompanyBoltOns'
import AdminCompanyActions from './AdminCompanyActions'

function AdminCompany() {
	const { companyId } = useParams();

	if(!companyId) {
		throw new Error(('No organisation Id'))
	}

	return (
		<PageTemplate testid='admin-company'>
			<Helmet>
				<title>{routes.adminCompany.name}</title>
			</Helmet>
			<PageHeadings title='Organisation Details' subTitle=''/>
		
			<AdminCompanySummary companyId={companyId} />
			<AdminCompanyActions companyId={companyId} />
			<AdminCompanyUsers companyId={companyId} />
			<AdminCompanyTier companyId={companyId} />
			<AdminCompanyBoltOns companyId={companyId} />

		</PageTemplate>
	)
}
export default AdminCompany
