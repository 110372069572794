import { formatDate, formatReportStatus, getCurrencySymbol } from "../../lib/utils";
import { Button } from "../BaseComponents";
import { PropsWithChildren, useContext, useState } from "react";
import Label from "../BaseComponents/Label";
import { userContext } from "../../context/UserContext";
import AddToPortfolioModal from "../Portfolios/AddToPortfolioModal";
import { generatePath, useNavigate } from "react-router-dom";
import routes from "../Router/routes";
import useCompanyData from "../../hooks/useCompanyData";
import useUserData from "../../hooks/useUserData";

function Row({children}: PropsWithChildren) {
    return (
        <div
            role='row'
            // className='table-row w-full bg-charcoal-700 text-white border-b border-charcoal-050'
            className='table-row w-full text-white last:border-b first:border-b border-charcoal-050'
        >
            { children }
        </div>
    );
}

type IColProps = PropsWithChildren<{
    indent?: number
    header?: boolean
    baseline?: boolean
    rightAlign?: boolean
}>

function Col({children, indent, header, baseline=false, rightAlign}: IColProps) {
    let classNames = 'p-1 table-cell '
    classNames += indent ? 'pl-' + indent * 4 : '';
    classNames += rightAlign ? 'text-right' : '';

    return (
        <div
            className={classNames}
            style={{
                backgroundColor: baseline ? '#282f36' : '',
            }}
        >
            { header 
                ? <span className="text-xs">children</span>
                : children
            }
        </div>
    );
}

type SecrRenderProps = {
    report: any,
    baselineReport: any,
    isOwn?: boolean
    onPublish?: () => void;
    companyReports: {id: string, label: string}[]
    companyId?: string
}

function SecrRender({report, baselineReport, isOwn, onPublish, companyReports, companyId}: SecrRenderProps) {
    const { checkAccess } = useContext(userContext);
    const formatNumber = Intl.NumberFormat('en-GB').format;
    const currencySymbol = getCurrencySymbol(report.currencyCode || 'GBP')
    const [portfolioModalOpen, setPortfolioModalOpen] = useState(false)

    const navigate = useNavigate();

    const dataInput = (key: string, type: string) => {
        const value = report[key];
        if(value === null || value === undefined) {
            return <span />
        }

        return type === 'number'
            ? <span style={{textAlign: 'right'}}>{ formatNumber(value) }</span>
            : <span>{ value }</span>
    }

    const screenScrape = checkAccess('company.screenScrape');
    const { userData } = useContext(userContext);
    return(
        <div className={`mb-8 ${screenScrape ? '' : 'select-none'}`}>
            <div className="text-white">
                <div className="flex items-center">
                    <div className="text-3xl pr-4">
                        { report.organization }
                    </div>
                    { checkAccess('company.portfolios') && !isOwn && (
                        <Button
                            type="Primary"
                            text="Add to portfolio"
                            hasTrailingIcon
                            icon="fa-regular fa-plus"
                            onClick={() => setPortfolioModalOpen(true)}
                        />
                    )}
                </div>
                <div className="flex mt-2">
                    <div className="grow">
                        <table className="border-separate border-spacing-1">
                            <tr>
                                <td>
                                    <Label>
                                        Jurisdiction
                                    </Label>
                                </td>
                                <td>{ report.jurisdiction }</td>
                            </tr>    
                            <tr>
                                <td>
                                    <Label>
                                    Registration Number
                                    </Label>
                                </td>
                                <td>{ report.companyNumber }</td>
                            </tr>
                            <tr>
                                <td>
                                    <Label>
                                    Legal Form
                                    </Label>
                                </td>
                                <td>{ report.organizationType }</td>
                            </tr>
                            <tr>
                                <td>
                                    <Label>
                                    Registered address
                                    </Label>
                                </td>
                                <td>{ report.address }</td>
                            </tr>
                        </table>
                    </div>
                    { isOwn && (
                        <div>
                            { !report.status && (
                                <div>
                                    <div>This report has not been published</div>
                                    { onPublish && (
                                        <Button
                                            type="Primary"
                                            text="Publish Report"
                                            additionalClass="mt-4"
                                            onClick={onPublish}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="bg-charcoal-600 p-2 rounded mt-4">

                    <div className="flex items-end space-x-8 p-5">
                        <div className="basis-2/3 pb-5">
                            <div>
                                <Label className="block">
                                    Select Reporting Period
                                </Label>
                                <select
                                    className="rounded text-charcoal-900"
                                    defaultValue={report.id}
                                    onChange={e => {
                                        const alt = companyReports.find(alt => alt.id === e.target.value);
                                        console.log(alt)
                                        if(!alt) {
                                            return;
                                        }

                                        const path = isOwn ? routes.myReport.path : routes.publicReport.path;
                                        navigate(generatePath(path, { reportId: alt.id}));
                                    }}
                                >
                                    {companyReports.map(altReport => {
                                        return (
                                            <option value={altReport.id} key={altReport.id}>
                                                {altReport.label}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                            
                            <div className="flex mt-4">
                                <div className="basis-1/2">
                                    <Label className="mr-2 inline-block w-32">Type</Label>{ report.reportType }<br />
                                    <Label className="mr-2 inline-block w-32">Status</Label>{ formatReportStatus(report.status) }<br />
                                    { report.firstPublishDate && (
                                        <div>
                                            <Label className="mr-2 inline-block w-32">Report first published</Label>
                                            { formatDate(report.firstPublishDate) }
                                        </div>
                                    )}
                                    { (report.publishDate && report.publishDate !== report.firstPublishDate) && (
                                        <div>
                                            <Label className="mr-2 inline-block w-32">Last updated</Label>
                                            { formatDate(report.publishDate) }
                                        </div>
                                    )}
                                </div>
                                <div className="basis-1/2">
                                    <div>
                                        <div className='text-charcoal-100 mt-8'>Intensity ratio</div>
                                        {dataInput("intensityRatio", "number")}<br />
                                        kgCO₂e / {currencySymbol} turnover 
                                    </div>
                                    <div className="flex mt-8">
                                        { report.totalEmissions !== null && (
                                            <div className="basis-1/2">
                                                <div className="inline-block">
                                                    <div className='text-charcoal-100'>Total  (kg CO₂e)</div>
                                                    <div className="text-right">
                                                        {dataInput("totalEmissions", "number")}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        { report.totalEnergy !== null && (
                                            <div className="basis-1/2 ml-2">
                                                <div className="inline-block">
                                                    <div className='text-charcoal-100'>Total (kWh)</div>
                                                    <div className="text-right">{dataInput("totalEnergy", "number")}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { baselineReport && (
                            <div className="basis-1/3">
                                <div className="flex items-center">
                                    <div>
                                        <div className='text-charcoal-100'>Reference Period</div>
                                        { formatDate(baselineReport?.start_date)} - {formatDate(baselineReport?.end_date)}
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <div className='text-charcoal-100'>Intensity ratio</div>
                                    { baselineReport?.intensityRatio }<br />
                                    kgCO₂e / {currencySymbol} turnover 
                                </div>
                                    <div className="flex mt-8">
                                        { baselineReport.totalEmissions !== null && (
                                            <div className="basis-1/2">
                                                <div className="inline-block">
                                                    <div className='text-charcoal-100'>Total (kgCO₂e)</div>
                                                    <div className="text-right">{ formatNumber(baselineReport?.totalEmissions) }</div>
                                                </div>
                                            </div>
                                        )}
                                        { baselineReport.totalEnergy !== null && (
                                            <div className="basis-1/2 ml-2">
                                                <div className='text-charcoal-100'>Total (kWh)</div>
                                                <div className="text-right">{ formatNumber(baselineReport?.totalEnergy) }</div>
                                            </div>
                                        )}
                                    </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            role='table'
                            className='table border-collapse w-full bg-charcoal-700 [&>div>div:nth-child(n+2)]:w-1/6 [&>div>div:nth-child(3)]:pr-4 [&>div>div:nth-child(4)]:border-l [&>div>div:nth-child(4)]:border-charcoal-200 [&>div>div:nth-child(n+4)]:text-charcoal-200'
                        >
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col style={{textAlign: 'right', background: '#282f36'}}></col>
                                <col style={{textAlign: 'right', background: '#282f36'}}></col>
                            </colgroup>
                            <div
                                role='row'
                                className='table-row text-xs w-full bg-charcoal-800 text-charcoal-050 border-b border-charcoal-050'
                            >
                                <Col />
                                <Col rightAlign>kgCO₂e</Col>
                                <Col rightAlign>kWh</Col>
                                { baselineReport && (
                                    <>
                                        <Col rightAlign>kgCO₂e</Col>
                                        <Col rightAlign>kWh</Col>
                                    </>
                                )}
                            </div>
                            <Row>
                                <Col>
                                    <div className="mt-4">
                                        <Label>Direct Emissions</Label>
                                    </div>
                                </Col>
                                { baselineReport && (
                                    <>
                                        <Col />
                                        <Col />
                                        <Col />
                                        <Col />
                                    </>
                                )}
                            </Row>
                            <Row>
                                <Col indent={1}>
                                    Scope 1 subtotal
                                </Col>
                                <Col rightAlign>{dataInput("scope1Total", "number")}</Col>
                                <Col />
                                { baselineReport && (
                                    <>
                                        <Col rightAlign>{ baselineReport && baselineReport.scope1Total }</Col>
                                        <Col rightAlign>{ baselineReport && baselineReport.scope1Total }</Col>
                                    </>
                                )}
                            </Row>
                            { report.scope1LineItems.map((lineItem: any) => {
                                return (
                                    <Row key={lineItem.name}>
                                        <Col indent={2}>{ lineItem.name}</Col>
                                        <Col rightAlign>{ lineItem.total}</Col>
                                        <Col rightAlign>{ lineItem.energy}</Col>
                                        { baselineReport && (
                                            <Col rightAlign>{baselineReport?.scope1Fuels}</Col>
                                        )}
                                    </Row>
                                )
                            })}

                            <Row>
                                <Col>
                                    <div className="mt-4">
                                        <Label>Indirect Emissions</Label>
                                    </div>
                                </Col>
                                { baselineReport && (
                                    <>
                                        <Col />
                                        <Col />
                                        <Col />
                                        <Col />
                                    </>
                                )}
                            </Row>

                            <Row>
                                <Col indent={1}>Scope 2 sub total</Col>
                                <Col rightAlign>{dataInput("scope2Total", "number")}</Col>
                                <Col rightAlign>{dataInput("scope2TotalEnergy", "number")}</Col>
                                { baselineReport && (
                                    <>
                                        <Col rightAlign baseline>{baselineReport && formatNumber(baselineReport?.scope2Total)}</Col>
                                        <Col rightAlign baseline>{baselineReport && formatNumber(baselineReport?.scope2TotalEnergy)}</Col>
                                    </>
                                )}
                            </Row>
                            { report.scope2LineItems.map((lineItem: any) => {
                                return (
                                    <Row key={lineItem.name}>
                                        <Col indent={2}>{ lineItem.name}</Col>
                                        <Col rightAlign>{ lineItem.total}</Col>
                                        <Col rightAlign>{ lineItem.energy}</Col>
                                        { baselineReport     && (
                                            <Col rightAlign>{baselineReport?.scope1Fuels}</Col>
                                        )}
                                    </Row>
                                )
                            })}

                            <Row>
                                <Col indent={1}>Scope 3 (upstream) sub total</Col>
                                <Col rightAlign>{dataInput("scope3Total", "number")}</Col>
                                <Col />
                                { baselineReport && (
                                    <Col rightAlign baseline>{baselineReport && formatNumber(baselineReport?.scope3Total)}</Col>  
                                )}
                            </Row>
                            { report.scope3LineItems.map((lineItem: any) => {
                                return (
                                    <Row key={lineItem.name}>
                                        <Col indent={2}>{ lineItem.name}</Col>
                                        <Col rightAlign>{ lineItem.total}</Col>
                                        <Col rightAlign>{ lineItem.energy}</Col>
                                        { baselineReport && (
                                            <Col rightAlign>{baselineReport?.scope1Fuels}</Col>
                                        )}
                                    </Row>
                                )
                            })}
                        </div>
                    </div>
                    
                    <div className="mt-8">
                        <Label>
                            Calculation Methodology
                        </Label>
                        <p className="whitespace-pre">{ report.methodologyText }</p>
                    </div>

                    <div className="mt-4">
                        <Label>
                            Confirmation
                        </Label>
                        <p className="whitespace-pre">
                            { report.disclosureText }
                        </p>
                    </div>
                    
                    <div className="mt-4">
                        <Label>Report Authorised</Label>
                        <div>
                            { report. signeeName }
                        </div>
                        <div>
                            { report.signeeTitle }
                        </div>
                    </div>

                    <hr className="my-8" />

                    { report.informationText && (
                        <div className="mt-8">
                            <Label>
                                Information Sources
                            </Label>
                            <p>{ report.informationText }</p>
                        </div>
                    )}
                </div>

            </div>
            { companyId && (
                <AddToPortfolioModal
                    open={portfolioModalOpen}
                    onClose={() => setPortfolioModalOpen(false)}
                    companyId={companyId}
                    companyName={report.organization}
                />
            )}
        </div>
    )
}

export default SecrRender;
