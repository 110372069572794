import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PaginationMeta } from '../../types/PaginationMeta'
import { PurchaseCarbonData } from '../../types/PurchaseCarbonData'
import type { RootState } from '../store'

type PurchasesCarbonAccountsType = {
	status: FetchStatus
	selectedStatus: FetchStatus
	error?: string
	purchasesData: Array<PurchaseCarbonData>
	selectedData: Array<PurchaseCarbonData>
	pagination: PaginationMeta
}

const purchasesCarbonAccountsInitialState: PurchasesCarbonAccountsType = {
	status: FetchStatus.initial,
	selectedStatus: FetchStatus.initial,
	error: undefined,
	purchasesData: [],
	selectedData: [],
	pagination: {
		total: 0,
		per_page: 0,
		current_page: 1,
		last_page: 1,
		first_page: 1,
	},
}

export const PurchasesCarbonAccounts = createSlice({
	name: 'purchasesData',
	initialState: purchasesCarbonAccountsInitialState,
	reducers: {
		purchasesCarbonData_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		purchasesCarbonData_LOADING_SELECTED: (state) => {
			return {
				...state,
				selectedStatus: FetchStatus.loading,
			}
		},
		purchasesCarbonData_LOADED: (state: any, action: PayloadAction<any>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				purchasesData: action.payload.items,
				pagination: {
					total: action.payload.totalItems,
					per_page: action.payload.per_page,
					current_page: action.payload.currentPage,
					last_page: action.payload.lastPage,
					first_page: action.payload.first_page,
				},
			}
		},
		purchasesCarbonData_LOADED_SELECTED: (state, action) => {
			return {
				...state,
				selectedStatus: FetchStatus.loaded,
				selectedData: action.payload.purchases,
			}
		},
		purchasesCarbonData_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		purchasesCarbonData_ERROR_SELECTED: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		purchasesCarbonData_RESET: () => {
			return purchasesCarbonAccountsInitialState
		},
	},
})

// Action types
export const {
	purchasesCarbonData_LOADING,
	purchasesCarbonData_LOADING_SELECTED,
	purchasesCarbonData_LOADED,
	purchasesCarbonData_LOADED_SELECTED,
	purchasesCarbonData_ERROR,
	purchasesCarbonData_ERROR_SELECTED,
	purchasesCarbonData_RESET,
} = PurchasesCarbonAccounts.actions

// Selectors
export const selectpurchasesCarbonDataStatus = (state: RootState) =>
	state.purchasesCarbonAccounts.status
export const selectpurchasesCarbonDataError = (state: RootState) =>
	state.purchasesCarbonAccounts.error
export const selectAllpurchasesCarbonData = (state: RootState) =>
	state.purchasesCarbonAccounts.purchasesData

export const selectAllScope1PurchasesCarbonData = (state: RootState) =>
	state.purchasesCarbonAccounts.purchasesData.filter((purchase) => Number(purchase.scope) === 1)
export const selectAllScope2PurchasesCarbonData = (state: RootState) =>
	state.purchasesCarbonAccounts.purchasesData.filter((purchase) => Number(purchase.scope) === 2)
export const selectpurchasesCarbonDataPagination = (state: RootState) =>
	state.purchasesCarbonAccounts.pagination

export const selectSelectedPurchsesStatus = (state: RootState) =>
	state.purchasesCarbonAccounts.selectedStatus
export const selectAllSelectedPurchases = (state: RootState) =>
	state.purchasesCarbonAccounts.selectedData

export default PurchasesCarbonAccounts.reducer
