import { useMemo, useState } from "react";

const asc = ['asc', 'ascending'];

type TableDefaultSorting = {
    key: string,
    direction:'ascending'|'descending'
};

function useDataSort(initialSortKey: string, initialDirection?: 'asc'|'desc' ) {
    const [sortKey, setSortKey] = useState(initialSortKey);
    const [direction, setDirection] = useState(initialDirection || 'asc');
    const params = useMemo(() => ({
        sortKey,
        sortDirection: direction,
    }), [sortKey, direction]);

    return {
        onChange: (key: string, newDirection: string) => {
            setSortKey(key);
            setDirection(
                asc.includes(newDirection) ? 'asc' : 'desc',
            );
        },
        setSortKey,
        setDirection,
        params,
        defaultSorting: {
            key: sortKey,
            direction: (asc.includes(direction) ? 'ascending' : 'descending'),
        } as TableDefaultSorting
    }
}

export default useDataSort;
