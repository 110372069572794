import { Dispatch } from 'redux'
import {
	CARBONSUMMARY_ERROR,
	CARBONSUMMARY_FETCHING,
	CARBONSUMMARY_SUCCESS,
} from '../slices/carbonAccountSummarySlice'
import { NeoniApi } from '../../lib/api'

export const getCarbonSummaryData = (api: NeoniApi, accountingPeriodId: string) => async (dispatch: Dispatch) => {
	dispatch({
		type: CARBONSUMMARY_FETCHING,
	})

	try {
		const body = await api.get(`accountingPeriods/` + accountingPeriodId + '/emissions')
		dispatch({
			type: CARBONSUMMARY_SUCCESS,
			payload: body.data,
		})
	} catch (error) {
		dispatch({
			type: CARBONSUMMARY_ERROR,
			payload: error,
		})
	}
}
