import { matchRoutes, Navigate, renderMatches, useLocation } from 'react-router-dom'
import { orderedRoutes, routes } from './routes'
import ReactModal from 'react-modal'
import TopBar from '../Sidebar/TopBar'
import Notifications from '../Notifications'
import { useContext, useEffect, useMemo, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { userContext } from '../../context/UserContext'
import { ErrorBoundary } from 'react-error-boundary'

const Router = () => {

	// Root element does not exist in testing environment :-(
	if (process.env.NODE_ENV !== 'test') {
		ReactModal.setAppElement('#root')
	}

	const { checkAccess, userData } = useContext(userContext);

	const location = useLocation();
	const validroutes = orderedRoutes.filter(route => {

		const hasAccess = !route.accessRequired || route.accessRequired.every(key => checkAccess(key));
		const hasRole = !route.rolesAllowed || route.rolesAllowed.some(role => role === userData?.role);
		if(route.name === 'Landing Page') {
			console.log({hasRole, hasAccess})
		}
		return hasRole && hasAccess;
	})
	const matches = useMemo(() => {
		const routes = validroutes.map(route => {
			return {
				...route,
				path: route.path,
				element: <ErrorBoundary FallbackComponent={Fallback} onError={logError}>{ route.component() }</ErrorBoundary>,
			}
		})
		return matchRoutes(routes, location);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	const routeGroupDefaultPath: Record<string, string> = {
		admin: routes.adminDashboard.path,
		company: routes.companyDashboard.path,
	}

	const roleBasedRedirectPath = userData
		? routeGroupDefaultPath[userData.role]
		: routes.publicLogin.path;

	if(!matches || !matches.length) {
		console.log(`Valid route not found for ${location.pathname}  navigating to `, roleBasedRedirectPath)
		return <Navigate to={roleBasedRedirectPath}/>
	}

	const route = matches[0].route
	const rendered = renderMatches(matches);
	console.log('Shown route', route);

	return (
		<div className="flex flex-col h-screen" style={{background: '#374654'}}>
			<TopBar />
			<div className="flex content-stretch grow overflow-hidden">
				{ route.sidebar && <Sidebar validRoutes={validroutes} /> }
				<div className="grow relative overflow-y-auto">
					{ rendered }
					<Notifications />
				</div>
			</div>
		</div>
	)
}

function Fallback({error, resetErrorBoundary}:{error: Error, resetErrorBoundary: Function}) {
	const location = useLocation();
	const [startLocation] = useState(location)

	useEffect(() => {
		if(location.pathname !== startLocation.pathname) {
			resetErrorBoundary()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])
	return <span className="text-charcoal-300 p-8">Error: {error.message}</span>
}

function logError(error: Error) {
	console.error(error);
}

export default Router
