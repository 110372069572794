import dayjs from "dayjs"
import { CurrencyCode } from "../../types/currency"

/* eslint-disable */
async function getCSRFToken() {
	let csrfResponse = await fetch(`${process.env.REACT_APP_BASE_API}/csrfToken`)
	csrfResponse = await csrfResponse.json()
	return (csrfResponse as any)['csrf']
}

const createCurrencyFormatter = (currencyCode: string) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currencyCode,
	})
}

// currency arrives as a cents integer from the backend
// and needs to be converted to an actual float before
// being put into the `NumericFormat` input
const centsToCurrency = (cents?: number): number | undefined => {
	if (!cents) return cents
	return cents / 100
}

const getCurrencySymbol = (currencyCode: CurrencyCode) => {
	switch(currencyCode) {
		case 'GBP':
			return '£';
		case 'EUR':
			return '€';
		case 'USD':
			return '$';
	}
}
// The `NumericFormat` outputs a string or number with commas an
// a period as a decimal separator, we need to convert this back to
// cents (integer) before sending to the backend
const currencyToCents = (currency: string | number): number => {
	currency = Number(currency.toString().replaceAll(',', ''))
	return currency * 100
}

const formatEmissionCO2Kg = (value: number): string => {
	const formatter = new Intl.NumberFormat('en-UK', {
		style: 'unit',
		unit: 'kilogram',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
	return formatter.format(value)
}

const validateEmail = (email: string) => {
	if (email === '') {
		return false
	}
	const regexp = new RegExp(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	)
	return regexp.test(email)
}
const getParams = (paramsParameter: any) => {
	let params = new URLSearchParams(paramsParameter)
	let keysForDel: any = []
	params.forEach((value, key) => {
		if (value == '') {
			keysForDel.push(key)
		}
	})

	keysForDel.forEach((key: any) => {
		params.delete(key)
	})
	return params
}

const formatDate = (dateString: string) => {
	return dayjs(dateString).format('DD/MM/YY')
}

const formatControlRequestStatus = (status: string = '') => {
	if(!status) {
		return '';
	}
	if(status === 'rejected') {
		status = 'request rejected';
	}
	const withSpaces = status.replace(/_+/g, ' ');
	const upperCase = withSpaces[0].toUpperCase() + withSpaces.slice(1);
	return upperCase;
}

const formatReportStatus = formatControlRequestStatus;

export {
	getCSRFToken,
	centsToCurrency,
	currencyToCents,
	createCurrencyFormatter,
	formatEmissionCO2Kg,
	validateEmail,
	getParams,
	getCurrencySymbol,
	formatDate,
	formatControlRequestStatus,
	formatReportStatus,
}
