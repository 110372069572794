import { Dispatch } from "redux"
import { getParams } from "../../lib/utils"
import { REFINEMENTS_SCOPE1_SELECTION_LOADING, REFINEMENTS_SCOPE1_SELECTION_ERROR, REFINEMENTS_SCOPE1_SELECTION_LOADED, REFINEMENTS_SCOPE1_EMISSIONS_LOADING, REFINEMENTS_SCOPE1_EMISSIONS_ERROR, REFINEMENTS_SCOPE1_EMISSIONS_LOADED, REFINEMENTS_SCOPE2_SELECTION_LOADING, REFINEMENTS_SCOPE2_SELECTION_ERROR, REFINEMENTS_SCOPE2_SELECTION_LOADED, REFINEMENTS_SCOPE3_SUPPLIERS_LOADING, REFINEMENTS_SCOPE3_SUPPLIERS_ERROR, REFINEMENTS_SCOPE3_SUPPLIERS_LOADED } from "../slices/refinementsSlice"
import { GET_PURCHASES_CARBON_ACCOUNTS_LIMIT } from "./carbonAccountsPurchasesActions"
import { NeoniApi } from "../../lib/api"


export const getScope1SelectionItems = (api: NeoniApi, accountingPeriodId: string, page: number, orderBy: string, direction: 'asc'|'desc', perPage = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT, searchText: string) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: REFINEMENTS_SCOPE1_SELECTION_LOADING})
    const params = getParams({ page, searchText, orderBy, direction, perPage})
    try {
      const result = await api.get(`accountingPeriod/${accountingPeriodId}/refinements/scope1SelectionItems?` +
        params
      )
      if(!result.success) {
        dispatch({
          type: REFINEMENTS_SCOPE1_SELECTION_ERROR,
          error: result.message,
        })
        return
      }

      dispatch({
        type: REFINEMENTS_SCOPE1_SELECTION_LOADED,
        payload: {
          items: result.data.items,
          pagination: {
            first_page: 1,
            current_page: result.data.currentPage,
            last_page: result.data.lastPage,
            total: result.data.totalItems,
            per_page: perPage,
          }
        },
      })
    } catch(error) {
      dispatch({
        type: REFINEMENTS_SCOPE1_SELECTION_ERROR,
        error,
      })
    }
  }

export const getScope1EmissionItems = (api: NeoniApi, accountingPeriodId: string, page: number, orderBy: string, direction: 'asc'|'desc', perPage = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT) => 
  async (dispatch: Dispatch) => {
    dispatch({ type: REFINEMENTS_SCOPE1_EMISSIONS_LOADING})
    const params = getParams({ page, orderBy, direction, perPage})
    try {
      const result = await api.get(`/accountingPeriod/${accountingPeriodId}/refinements/scope1EmissionItems?` +
        params
      )
      if(!result.success) {
        dispatch({
          type: REFINEMENTS_SCOPE1_EMISSIONS_ERROR,
          error: result.message,
        })
      }

      dispatch({
        type: REFINEMENTS_SCOPE1_EMISSIONS_LOADED,
        payload: {
          items: result.data.items,
          pagination: {
            first_page: 1,
            current_page: result.data.currentPage,
            last_page: result.data.lastPage,
            total: result.data.totalItems,
            per_page: perPage,
          }
        },
      })
    } catch(error) {
      dispatch({
        type: REFINEMENTS_SCOPE1_EMISSIONS_ERROR,
        error,
      })
    }
  }

export const getScope2SelectionItems = (api: NeoniApi, accountingPeriodId: string, page: number, orderBy: string, direction: 'asc'|'desc', perPage = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT, searchText: string) => 
  async (dispatch: Dispatch) => {
    dispatch({ type: REFINEMENTS_SCOPE2_SELECTION_LOADING})
    const params = getParams({ page, searchText, orderBy, direction, perPage})
    try {
      const result = await api.get(`/accountingPeriod/${accountingPeriodId}/refinements/scope2SelectionItems?` +
        params
      )
      if(!result.success) {
        dispatch({
          type: REFINEMENTS_SCOPE2_SELECTION_ERROR,
          error: result.message,
        })
      }

      dispatch({
        type: REFINEMENTS_SCOPE2_SELECTION_LOADED,
        payload: {
          items: result.data.items,
          pagination: {
            first_page: 1,
            current_page: result.data.currentPage,
            last_page: result.data.lastPage,
            total: result.data.totalItems,
            per_page: perPage,
          }
        },
      })
    } catch(error) {
      dispatch({
        type: REFINEMENTS_SCOPE2_SELECTION_ERROR,
        error,
      })
    }
  }

export const getScope3Suppliers = (api: NeoniApi, accountingPeriodId: string, page: number, orderBy: string, direction: 'asc'|'desc', perPage = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT, searchText: string) => 
  async (dispatch: Dispatch) => {
    dispatch({ type: REFINEMENTS_SCOPE3_SUPPLIERS_LOADING})
    const params = getParams({ page, searchText, orderBy, direction, perPage})
    try {
      const result = await api.get(`accountingPeriod/${accountingPeriodId}/refinements/scope3RefinementSuppliers?` +
        params
      )
      if(!result.success) {
        dispatch({
          type: REFINEMENTS_SCOPE3_SUPPLIERS_ERROR,
          error: result.message,
        })
      }

      dispatch({
        type: REFINEMENTS_SCOPE3_SUPPLIERS_LOADED,
        payload: {
          suppliers: result.data.suppliers,
          pagination: {
            first_page: 1,
            current_page: result.data.currentPage,
            last_page: result.data.lastPage,
            total: result.data.totalItems,
            per_page: perPage,
          }
        },
      })
    } catch(error) {
      dispatch({
        type: REFINEMENTS_SCOPE3_SUPPLIERS_ERROR,
        error,
      })
    }
  }
