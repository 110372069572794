import { useEffect, useState } from 'react'
import useApi from './useApi'

type Company = {
	id: string
	name: string
	address: string
	created_at: string
	industry_codes: string[]
	currency_code?: string
	jurisdiction_code: string
	number: number
	number_of_employees?: number
	turnover?: number
	type?: string
	updated_at: string
}

const useCompanyData = (companyId?: string) => {
	const [companyData, setCompanyData] = useState<Company | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)
	const api = useApi();

	const fetchCompanyData = async () => {
		try {
			const body = await api.get(`company/${companyId}`)
			const storedCodes = body.company.industryCodes.map((ic: { code: any }) => ic.code)

			const company = {
				...body.company,
				industry_codes: storedCodes,
			}
			setCompanyData(company)
		} catch (error) {
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (typeof companyId !== 'undefined') {
			fetchCompanyData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId])

	return { companyData, isLoading, error }
}

export default useCompanyData
