import { PropsWithChildren } from "react";
import Button from "./Button/Button";
import Modal from "./Modal/Modal";

type ConfirmationModalProps = PropsWithChildren<{
    open?: boolean,
    onCancel: () => void,
    onConfirm: () => void,
}>

function ConfirmationModal({open = false, onCancel, onConfirm, children}: ConfirmationModalProps) {
    return (
        <Modal open={open} onClose={onCancel} size="small"  >
            <div>
                { children }
            </div>
            <div className="flex space-x-2">
                <Button type="Primary" text="Confirm" onClick={onConfirm} />
                <Button type="Outlined" text="Cancel" onClick={onCancel} />
            </div>
        </Modal>
    )
}

export default ConfirmationModal;
