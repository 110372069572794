import { PropsWithChildren, createContext, useState } from "react";

export type NotificationInput = {
    title?: string;
    body: string
    type?: string;
}

export type NotificationData = NotificationInput & {
    creationTime: number;
    id: number;
}

type NotificationContext = {
    notifications: NotificationData[],
    addNotification: (notification: NotificationInput) => void,
    clearNotifications: () => void,    
    removeNotification: (id: number) => void,
}

const notificationContext = createContext<NotificationContext>({
    notifications: [],
    addNotification: () => {},
    clearNotifications: () => {},
    removeNotification: () => {},
});

let nextId = 1;

function NotificationProvider({children}: PropsWithChildren) {
    const [notifications, setNotifications] = useState<NotificationData[]>([]);
    
    const addNotification = (notification: NotificationInput) => {
        setNotifications(notifications => {
            const newList = notifications.slice();
            newList.push({
                type: 'default',
                ...notification,
                creationTime: Date.now(),
                id: nextId++,
            })
            return newList;
        })

    }

    const clearNotifications = () => setNotifications([]);
    const removeNotification = (id: number) => {
        console.log('REmoving', id);
        console.log(notifications.slice())
        setNotifications(notifications => {
            const index = notifications.findIndex(notification => notification.id === id);
            const newList = notifications.slice();
            if(index !== -1) {
                newList.splice(index, 1);
            }
            return newList;
        })
    }

    const value: NotificationContext = {
        notifications,
        addNotification,
        clearNotifications,
        removeNotification,
    };

    return (
        <notificationContext.Provider value={value}>{ children }</notificationContext.Provider>
    )
}

export {
    NotificationProvider,
    notificationContext,
};
