export const countries = [
	{
		'label': 'Afghanistan',
		'value': 'AF',
		'alpha-3': 'AFG',
		'country-code': '004',
		'iso_3166-2': 'ISO 3166-2:AF',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Åland Islands',
		'value': 'AX',
		'alpha-3': 'ALA',
		'country-code': '248',
		'iso_3166-2': 'ISO 3166-2:AX',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Albania',
		'value': 'AL',
		'alpha-3': 'ALB',
		'country-code': '008',
		'iso_3166-2': 'ISO 3166-2:AL',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Algeria',
		'value': 'DZ',
		'alpha-3': 'DZA',
		'country-code': '012',
		'iso_3166-2': 'ISO 3166-2:DZ',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'American Samoa',
		'value': 'AS',
		'alpha-3': 'ASM',
		'country-code': '016',
		'iso_3166-2': 'ISO 3166-2:AS',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Andorra',
		'value': 'AD',
		'alpha-3': 'AND',
		'country-code': '020',
		'iso_3166-2': 'ISO 3166-2:AD',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Angola',
		'value': 'AO',
		'alpha-3': 'AGO',
		'country-code': '024',
		'iso_3166-2': 'ISO 3166-2:AO',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Anguilla',
		'value': 'AI',
		'alpha-3': 'AIA',
		'country-code': '660',
		'iso_3166-2': 'ISO 3166-2:AI',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Antarctica',
		'value': 'AQ',
		'alpha-3': 'ATA',
		'country-code': '010',
		'iso_3166-2': 'ISO 3166-2:AQ',
		'region': '',
		'sub-region': '',
		'intermediate-region': '',
		'region-code': '',
		'sub-region-code': '',
		'intermediate-region-code': '',
	},
	{
		'label': 'Antigua and Barbuda',
		'value': 'AG',
		'alpha-3': 'ATG',
		'country-code': '028',
		'iso_3166-2': 'ISO 3166-2:AG',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Argentina',
		'value': 'AR',
		'alpha-3': 'ARG',
		'country-code': '032',
		'iso_3166-2': 'ISO 3166-2:AR',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Armenia',
		'value': 'AM',
		'alpha-3': 'ARM',
		'country-code': '051',
		'iso_3166-2': 'ISO 3166-2:AM',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Aruba',
		'value': 'AW',
		'alpha-3': 'ABW',
		'country-code': '533',
		'iso_3166-2': 'ISO 3166-2:AW',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Australia',
		'value': 'AU',
		'alpha-3': 'AUS',
		'country-code': '036',
		'iso_3166-2': 'ISO 3166-2:AU',
		'region': 'Oceania',
		'sub-region': 'Australia and New Zealand',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '053',
		'intermediate-region-code': '',
	},
	{
		'label': 'Austria',
		'value': 'AT',
		'alpha-3': 'AUT',
		'country-code': '040',
		'iso_3166-2': 'ISO 3166-2:AT',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Azerbaijan',
		'value': 'AZ',
		'alpha-3': 'AZE',
		'country-code': '031',
		'iso_3166-2': 'ISO 3166-2:AZ',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Bahamas',
		'value': 'BS',
		'alpha-3': 'BHS',
		'country-code': '044',
		'iso_3166-2': 'ISO 3166-2:BS',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Bahrain',
		'value': 'BH',
		'alpha-3': 'BHR',
		'country-code': '048',
		'iso_3166-2': 'ISO 3166-2:BH',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Bangladesh',
		'value': 'BD',
		'alpha-3': 'BGD',
		'country-code': '050',
		'iso_3166-2': 'ISO 3166-2:BD',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Barbados',
		'value': 'BB',
		'alpha-3': 'BRB',
		'country-code': '052',
		'iso_3166-2': 'ISO 3166-2:BB',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Belarus',
		'value': 'BY',
		'alpha-3': 'BLR',
		'country-code': '112',
		'iso_3166-2': 'ISO 3166-2:BY',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Belgium',
		'value': 'BE',
		'alpha-3': 'BEL',
		'country-code': '056',
		'iso_3166-2': 'ISO 3166-2:BE',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Belize',
		'value': 'BZ',
		'alpha-3': 'BLZ',
		'country-code': '084',
		'iso_3166-2': 'ISO 3166-2:BZ',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Benin',
		'value': 'BJ',
		'alpha-3': 'BEN',
		'country-code': '204',
		'iso_3166-2': 'ISO 3166-2:BJ',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Bermuda',
		'value': 'BM',
		'alpha-3': 'BMU',
		'country-code': '060',
		'iso_3166-2': 'ISO 3166-2:BM',
		'region': 'Americas',
		'sub-region': 'Northern America',
		'intermediate-region': '',
		'region-code': '019',
		'sub-region-code': '021',
		'intermediate-region-code': '',
	},
	{
		'label': 'Bhutan',
		'value': 'BT',
		'alpha-3': 'BTN',
		'country-code': '064',
		'iso_3166-2': 'ISO 3166-2:BT',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Bolivia (Plurinational State of)',
		'value': 'BO',
		'alpha-3': 'BOL',
		'country-code': '068',
		'iso_3166-2': 'ISO 3166-2:BO',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Bonaire, Sint Eustatius and Saba',
		'value': 'BQ',
		'alpha-3': 'BES',
		'country-code': '535',
		'iso_3166-2': 'ISO 3166-2:BQ',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Bosnia and Herzegovina',
		'value': 'BA',
		'alpha-3': 'BIH',
		'country-code': '070',
		'iso_3166-2': 'ISO 3166-2:BA',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Botswana',
		'value': 'BW',
		'alpha-3': 'BWA',
		'country-code': '072',
		'iso_3166-2': 'ISO 3166-2:BW',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Southern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '018',
	},
	{
		'label': 'Bouvet Island',
		'value': 'BV',
		'alpha-3': 'BVT',
		'country-code': '074',
		'iso_3166-2': 'ISO 3166-2:BV',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Brazil',
		'value': 'BR',
		'alpha-3': 'BRA',
		'country-code': '076',
		'iso_3166-2': 'ISO 3166-2:BR',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'British Indian Ocean Territory',
		'value': 'IO',
		'alpha-3': 'IOT',
		'country-code': '086',
		'iso_3166-2': 'ISO 3166-2:IO',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Brunei Darussalam',
		'value': 'BN',
		'alpha-3': 'BRN',
		'country-code': '096',
		'iso_3166-2': 'ISO 3166-2:BN',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Bulgaria',
		'value': 'BG',
		'alpha-3': 'BGR',
		'country-code': '100',
		'iso_3166-2': 'ISO 3166-2:BG',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Burkina Faso',
		'value': 'BF',
		'alpha-3': 'BFA',
		'country-code': '854',
		'iso_3166-2': 'ISO 3166-2:BF',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Burundi',
		'value': 'BI',
		'alpha-3': 'BDI',
		'country-code': '108',
		'iso_3166-2': 'ISO 3166-2:BI',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Cabo Verde',
		'value': 'CV',
		'alpha-3': 'CPV',
		'country-code': '132',
		'iso_3166-2': 'ISO 3166-2:CV',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Cambodia',
		'value': 'KH',
		'alpha-3': 'KHM',
		'country-code': '116',
		'iso_3166-2': 'ISO 3166-2:KH',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Cameroon',
		'value': 'CM',
		'alpha-3': 'CMR',
		'country-code': '120',
		'iso_3166-2': 'ISO 3166-2:CM',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Canada',
		'value': 'CA',
		'alpha-3': 'CAN',
		'country-code': '124',
		'iso_3166-2': 'ISO 3166-2:CA',
		'region': 'Americas',
		'sub-region': 'Northern America',
		'intermediate-region': '',
		'region-code': '019',
		'sub-region-code': '021',
		'intermediate-region-code': '',
	},
	{
		'label': 'Cayman Islands',
		'value': 'KY',
		'alpha-3': 'CYM',
		'country-code': '136',
		'iso_3166-2': 'ISO 3166-2:KY',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Central African Republic',
		'value': 'CF',
		'alpha-3': 'CAF',
		'country-code': '140',
		'iso_3166-2': 'ISO 3166-2:CF',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Chad',
		'value': 'TD',
		'alpha-3': 'TCD',
		'country-code': '148',
		'iso_3166-2': 'ISO 3166-2:TD',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Chile',
		'value': 'CL',
		'alpha-3': 'CHL',
		'country-code': '152',
		'iso_3166-2': 'ISO 3166-2:CL',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'China',
		'value': 'CN',
		'alpha-3': 'CHN',
		'country-code': '156',
		'iso_3166-2': 'ISO 3166-2:CN',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Christmas Island',
		'value': 'CX',
		'alpha-3': 'CXR',
		'country-code': '162',
		'iso_3166-2': 'ISO 3166-2:CX',
		'region': 'Oceania',
		'sub-region': 'Australia and New Zealand',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '053',
		'intermediate-region-code': '',
	},
	{
		'label': 'Cocos (Keeling) Islands',
		'value': 'CC',
		'alpha-3': 'CCK',
		'country-code': '166',
		'iso_3166-2': 'ISO 3166-2:CC',
		'region': 'Oceania',
		'sub-region': 'Australia and New Zealand',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '053',
		'intermediate-region-code': '',
	},
	{
		'label': 'Colombia',
		'value': 'CO',
		'alpha-3': 'COL',
		'country-code': '170',
		'iso_3166-2': 'ISO 3166-2:CO',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Comoros',
		'value': 'KM',
		'alpha-3': 'COM',
		'country-code': '174',
		'iso_3166-2': 'ISO 3166-2:KM',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Congo',
		'value': 'CG',
		'alpha-3': 'COG',
		'country-code': '178',
		'iso_3166-2': 'ISO 3166-2:CG',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Congo, Democratic Republic of the',
		'value': 'CD',
		'alpha-3': 'COD',
		'country-code': '180',
		'iso_3166-2': 'ISO 3166-2:CD',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Cook Islands',
		'value': 'CK',
		'alpha-3': 'COK',
		'country-code': '184',
		'iso_3166-2': 'ISO 3166-2:CK',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Costa Rica',
		'value': 'CR',
		'alpha-3': 'CRI',
		'country-code': '188',
		'iso_3166-2': 'ISO 3166-2:CR',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': "Côte d'Ivoire",
		'value': 'CI',
		'alpha-3': 'CIV',
		'country-code': '384',
		'iso_3166-2': 'ISO 3166-2:CI',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Croatia',
		'value': 'HR',
		'alpha-3': 'HRV',
		'country-code': '191',
		'iso_3166-2': 'ISO 3166-2:HR',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Cuba',
		'value': 'CU',
		'alpha-3': 'CUB',
		'country-code': '192',
		'iso_3166-2': 'ISO 3166-2:CU',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Curaçao',
		'value': 'CW',
		'alpha-3': 'CUW',
		'country-code': '531',
		'iso_3166-2': 'ISO 3166-2:CW',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Cyprus',
		'value': 'CY',
		'alpha-3': 'CYP',
		'country-code': '196',
		'iso_3166-2': 'ISO 3166-2:CY',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Czechia',
		'value': 'CZ',
		'alpha-3': 'CZE',
		'country-code': '203',
		'iso_3166-2': 'ISO 3166-2:CZ',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Denmark',
		'value': 'DK',
		'alpha-3': 'DNK',
		'country-code': '208',
		'iso_3166-2': 'ISO 3166-2:DK',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Djibouti',
		'value': 'DJ',
		'alpha-3': 'DJI',
		'country-code': '262',
		'iso_3166-2': 'ISO 3166-2:DJ',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Dominica',
		'value': 'DM',
		'alpha-3': 'DMA',
		'country-code': '212',
		'iso_3166-2': 'ISO 3166-2:DM',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Dominican Republic',
		'value': 'DO',
		'alpha-3': 'DOM',
		'country-code': '214',
		'iso_3166-2': 'ISO 3166-2:DO',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Ecuador',
		'value': 'EC',
		'alpha-3': 'ECU',
		'country-code': '218',
		'iso_3166-2': 'ISO 3166-2:EC',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Egypt',
		'value': 'EG',
		'alpha-3': 'EGY',
		'country-code': '818',
		'iso_3166-2': 'ISO 3166-2:EG',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'El Salvador',
		'value': 'SV',
		'alpha-3': 'SLV',
		'country-code': '222',
		'iso_3166-2': 'ISO 3166-2:SV',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Equatorial Guinea',
		'value': 'GQ',
		'alpha-3': 'GNQ',
		'country-code': '226',
		'iso_3166-2': 'ISO 3166-2:GQ',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Eritrea',
		'value': 'ER',
		'alpha-3': 'ERI',
		'country-code': '232',
		'iso_3166-2': 'ISO 3166-2:ER',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Estonia',
		'value': 'EE',
		'alpha-3': 'EST',
		'country-code': '233',
		'iso_3166-2': 'ISO 3166-2:EE',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Eswatini',
		'value': 'SZ',
		'alpha-3': 'SWZ',
		'country-code': '748',
		'iso_3166-2': 'ISO 3166-2:SZ',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Southern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '018',
	},
	{
		'label': 'Ethiopia',
		'value': 'ET',
		'alpha-3': 'ETH',
		'country-code': '231',
		'iso_3166-2': 'ISO 3166-2:ET',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Falkland Islands (Malvinas)',
		'value': 'FK',
		'alpha-3': 'FLK',
		'country-code': '238',
		'iso_3166-2': 'ISO 3166-2:FK',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Faroe Islands',
		'value': 'FO',
		'alpha-3': 'FRO',
		'country-code': '234',
		'iso_3166-2': 'ISO 3166-2:FO',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Fiji',
		'value': 'FJ',
		'alpha-3': 'FJI',
		'country-code': '242',
		'iso_3166-2': 'ISO 3166-2:FJ',
		'region': 'Oceania',
		'sub-region': 'Melanesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '054',
		'intermediate-region-code': '',
	},
	{
		'label': 'Finland',
		'value': 'FI',
		'alpha-3': 'FIN',
		'country-code': '246',
		'iso_3166-2': 'ISO 3166-2:FI',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'France',
		'value': 'FR',
		'alpha-3': 'FRA',
		'country-code': '250',
		'iso_3166-2': 'ISO 3166-2:FR',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'French Guiana',
		'value': 'GF',
		'alpha-3': 'GUF',
		'country-code': '254',
		'iso_3166-2': 'ISO 3166-2:GF',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'French Polynesia',
		'value': 'PF',
		'alpha-3': 'PYF',
		'country-code': '258',
		'iso_3166-2': 'ISO 3166-2:PF',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'French Southern Territories',
		'value': 'TF',
		'alpha-3': 'ATF',
		'country-code': '260',
		'iso_3166-2': 'ISO 3166-2:TF',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Gabon',
		'value': 'GA',
		'alpha-3': 'GAB',
		'country-code': '266',
		'iso_3166-2': 'ISO 3166-2:GA',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Gambia',
		'value': 'GM',
		'alpha-3': 'GMB',
		'country-code': '270',
		'iso_3166-2': 'ISO 3166-2:GM',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Georgia',
		'value': 'GE',
		'alpha-3': 'GEO',
		'country-code': '268',
		'iso_3166-2': 'ISO 3166-2:GE',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Germany',
		'value': 'DE',
		'alpha-3': 'DEU',
		'country-code': '276',
		'iso_3166-2': 'ISO 3166-2:DE',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Ghana',
		'value': 'GH',
		'alpha-3': 'GHA',
		'country-code': '288',
		'iso_3166-2': 'ISO 3166-2:GH',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Gibraltar',
		'value': 'GI',
		'alpha-3': 'GIB',
		'country-code': '292',
		'iso_3166-2': 'ISO 3166-2:GI',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Greece',
		'value': 'GR',
		'alpha-3': 'GRC',
		'country-code': '300',
		'iso_3166-2': 'ISO 3166-2:GR',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Greenland',
		'value': 'GL',
		'alpha-3': 'GRL',
		'country-code': '304',
		'iso_3166-2': 'ISO 3166-2:GL',
		'region': 'Americas',
		'sub-region': 'Northern America',
		'intermediate-region': '',
		'region-code': '019',
		'sub-region-code': '021',
		'intermediate-region-code': '',
	},
	{
		'label': 'Grenada',
		'value': 'GD',
		'alpha-3': 'GRD',
		'country-code': '308',
		'iso_3166-2': 'ISO 3166-2:GD',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Guadeloupe',
		'value': 'GP',
		'alpha-3': 'GLP',
		'country-code': '312',
		'iso_3166-2': 'ISO 3166-2:GP',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Guam',
		'value': 'GU',
		'alpha-3': 'GUM',
		'country-code': '316',
		'iso_3166-2': 'ISO 3166-2:GU',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Guatemala',
		'value': 'GT',
		'alpha-3': 'GTM',
		'country-code': '320',
		'iso_3166-2': 'ISO 3166-2:GT',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Guernsey',
		'value': 'GG',
		'alpha-3': 'GGY',
		'country-code': '831',
		'iso_3166-2': 'ISO 3166-2:GG',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': 'Channel Islands',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '830',
	},
	{
		'label': 'Guinea',
		'value': 'GN',
		'alpha-3': 'GIN',
		'country-code': '324',
		'iso_3166-2': 'ISO 3166-2:GN',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Guinea-Bissau',
		'value': 'GW',
		'alpha-3': 'GNB',
		'country-code': '624',
		'iso_3166-2': 'ISO 3166-2:GW',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Guyana',
		'value': 'GY',
		'alpha-3': 'GUY',
		'country-code': '328',
		'iso_3166-2': 'ISO 3166-2:GY',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Haiti',
		'value': 'HT',
		'alpha-3': 'HTI',
		'country-code': '332',
		'iso_3166-2': 'ISO 3166-2:HT',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Heard Island and McDonald Islands',
		'value': 'HM',
		'alpha-3': 'HMD',
		'country-code': '334',
		'iso_3166-2': 'ISO 3166-2:HM',
		'region': 'Oceania',
		'sub-region': 'Australia and New Zealand',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '053',
		'intermediate-region-code': '',
	},
	{
		'label': 'Holy See',
		'value': 'VA',
		'alpha-3': 'VAT',
		'country-code': '336',
		'iso_3166-2': 'ISO 3166-2:VA',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Honduras',
		'value': 'HN',
		'alpha-3': 'HND',
		'country-code': '340',
		'iso_3166-2': 'ISO 3166-2:HN',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Hong Kong',
		'value': 'HK',
		'alpha-3': 'HKG',
		'country-code': '344',
		'iso_3166-2': 'ISO 3166-2:HK',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Hungary',
		'value': 'HU',
		'alpha-3': 'HUN',
		'country-code': '348',
		'iso_3166-2': 'ISO 3166-2:HU',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Iceland',
		'value': 'IS',
		'alpha-3': 'ISL',
		'country-code': '352',
		'iso_3166-2': 'ISO 3166-2:IS',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'India',
		'value': 'IN',
		'alpha-3': 'IND',
		'country-code': '356',
		'iso_3166-2': 'ISO 3166-2:IN',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Indonesia',
		'value': 'ID',
		'alpha-3': 'IDN',
		'country-code': '360',
		'iso_3166-2': 'ISO 3166-2:ID',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Iran (Islamic Republic of)',
		'value': 'IR',
		'alpha-3': 'IRN',
		'country-code': '364',
		'iso_3166-2': 'ISO 3166-2:IR',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Iraq',
		'value': 'IQ',
		'alpha-3': 'IRQ',
		'country-code': '368',
		'iso_3166-2': 'ISO 3166-2:IQ',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Ireland',
		'value': 'IE',
		'alpha-3': 'IRL',
		'country-code': '372',
		'iso_3166-2': 'ISO 3166-2:IE',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Isle of Man',
		'value': 'IM',
		'alpha-3': 'IMN',
		'country-code': '833',
		'iso_3166-2': 'ISO 3166-2:IM',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Israel',
		'value': 'IL',
		'alpha-3': 'ISR',
		'country-code': '376',
		'iso_3166-2': 'ISO 3166-2:IL',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Italy',
		'value': 'IT',
		'alpha-3': 'ITA',
		'country-code': '380',
		'iso_3166-2': 'ISO 3166-2:IT',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Jamaica',
		'value': 'JM',
		'alpha-3': 'JAM',
		'country-code': '388',
		'iso_3166-2': 'ISO 3166-2:JM',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Japan',
		'value': 'JP',
		'alpha-3': 'JPN',
		'country-code': '392',
		'iso_3166-2': 'ISO 3166-2:JP',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Jersey',
		'value': 'JE',
		'alpha-3': 'JEY',
		'country-code': '832',
		'iso_3166-2': 'ISO 3166-2:JE',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': 'Channel Islands',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '830',
	},
	{
		'label': 'Jordan',
		'value': 'JO',
		'alpha-3': 'JOR',
		'country-code': '400',
		'iso_3166-2': 'ISO 3166-2:JO',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Kazakhstan',
		'value': 'KZ',
		'alpha-3': 'KAZ',
		'country-code': '398',
		'iso_3166-2': 'ISO 3166-2:KZ',
		'region': 'Asia',
		'sub-region': 'Central Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '143',
		'intermediate-region-code': '',
	},
	{
		'label': 'Kenya',
		'value': 'KE',
		'alpha-3': 'KEN',
		'country-code': '404',
		'iso_3166-2': 'ISO 3166-2:KE',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Kiribati',
		'value': 'KI',
		'alpha-3': 'KIR',
		'country-code': '296',
		'iso_3166-2': 'ISO 3166-2:KI',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': "Korea (Democratic People's Republic of)",
		'value': 'KP',
		'alpha-3': 'PRK',
		'country-code': '408',
		'iso_3166-2': 'ISO 3166-2:KP',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Korea, Republic of',
		'value': 'KR',
		'alpha-3': 'KOR',
		'country-code': '410',
		'iso_3166-2': 'ISO 3166-2:KR',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Kuwait',
		'value': 'KW',
		'alpha-3': 'KWT',
		'country-code': '414',
		'iso_3166-2': 'ISO 3166-2:KW',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Kyrgyzstan',
		'value': 'KG',
		'alpha-3': 'KGZ',
		'country-code': '417',
		'iso_3166-2': 'ISO 3166-2:KG',
		'region': 'Asia',
		'sub-region': 'Central Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '143',
		'intermediate-region-code': '',
	},
	{
		'label': "Lao People's Democratic Republic",
		'value': 'LA',
		'alpha-3': 'LAO',
		'country-code': '418',
		'iso_3166-2': 'ISO 3166-2:LA',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Latvia',
		'value': 'LV',
		'alpha-3': 'LVA',
		'country-code': '428',
		'iso_3166-2': 'ISO 3166-2:LV',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Lebanon',
		'value': 'LB',
		'alpha-3': 'LBN',
		'country-code': '422',
		'iso_3166-2': 'ISO 3166-2:LB',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Lesotho',
		'value': 'LS',
		'alpha-3': 'LSO',
		'country-code': '426',
		'iso_3166-2': 'ISO 3166-2:LS',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Southern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '018',
	},
	{
		'label': 'Liberia',
		'value': 'LR',
		'alpha-3': 'LBR',
		'country-code': '430',
		'iso_3166-2': 'ISO 3166-2:LR',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Libya',
		'value': 'LY',
		'alpha-3': 'LBY',
		'country-code': '434',
		'iso_3166-2': 'ISO 3166-2:LY',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'Liechtenstein',
		'value': 'LI',
		'alpha-3': 'LIE',
		'country-code': '438',
		'iso_3166-2': 'ISO 3166-2:LI',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Lithuania',
		'value': 'LT',
		'alpha-3': 'LTU',
		'country-code': '440',
		'iso_3166-2': 'ISO 3166-2:LT',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Luxembourg',
		'value': 'LU',
		'alpha-3': 'LUX',
		'country-code': '442',
		'iso_3166-2': 'ISO 3166-2:LU',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Macao',
		'value': 'MO',
		'alpha-3': 'MAC',
		'country-code': '446',
		'iso_3166-2': 'ISO 3166-2:MO',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Madagascar',
		'value': 'MG',
		'alpha-3': 'MDG',
		'country-code': '450',
		'iso_3166-2': 'ISO 3166-2:MG',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Malawi',
		'value': 'MW',
		'alpha-3': 'MWI',
		'country-code': '454',
		'iso_3166-2': 'ISO 3166-2:MW',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Malaysia',
		'value': 'MY',
		'alpha-3': 'MYS',
		'country-code': '458',
		'iso_3166-2': 'ISO 3166-2:MY',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Maldives',
		'value': 'MV',
		'alpha-3': 'MDV',
		'country-code': '462',
		'iso_3166-2': 'ISO 3166-2:MV',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Mali',
		'value': 'ML',
		'alpha-3': 'MLI',
		'country-code': '466',
		'iso_3166-2': 'ISO 3166-2:ML',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Malta',
		'value': 'MT',
		'alpha-3': 'MLT',
		'country-code': '470',
		'iso_3166-2': 'ISO 3166-2:MT',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Marshall Islands',
		'value': 'MH',
		'alpha-3': 'MHL',
		'country-code': '584',
		'iso_3166-2': 'ISO 3166-2:MH',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Martinique',
		'value': 'MQ',
		'alpha-3': 'MTQ',
		'country-code': '474',
		'iso_3166-2': 'ISO 3166-2:MQ',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Mauritania',
		'value': 'MR',
		'alpha-3': 'MRT',
		'country-code': '478',
		'iso_3166-2': 'ISO 3166-2:MR',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Mauritius',
		'value': 'MU',
		'alpha-3': 'MUS',
		'country-code': '480',
		'iso_3166-2': 'ISO 3166-2:MU',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Mayotte',
		'value': 'YT',
		'alpha-3': 'MYT',
		'country-code': '175',
		'iso_3166-2': 'ISO 3166-2:YT',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Mexico',
		'value': 'MX',
		'alpha-3': 'MEX',
		'country-code': '484',
		'iso_3166-2': 'ISO 3166-2:MX',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Micronesia (Federated States of)',
		'value': 'FM',
		'alpha-3': 'FSM',
		'country-code': '583',
		'iso_3166-2': 'ISO 3166-2:FM',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Moldova, Republic of',
		'value': 'MD',
		'alpha-3': 'MDA',
		'country-code': '498',
		'iso_3166-2': 'ISO 3166-2:MD',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Monaco',
		'value': 'MC',
		'alpha-3': 'MCO',
		'country-code': '492',
		'iso_3166-2': 'ISO 3166-2:MC',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Mongolia',
		'value': 'MN',
		'alpha-3': 'MNG',
		'country-code': '496',
		'iso_3166-2': 'ISO 3166-2:MN',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Montenegro',
		'value': 'ME',
		'alpha-3': 'MNE',
		'country-code': '499',
		'iso_3166-2': 'ISO 3166-2:ME',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Montserrat',
		'value': 'MS',
		'alpha-3': 'MSR',
		'country-code': '500',
		'iso_3166-2': 'ISO 3166-2:MS',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Morocco',
		'value': 'MA',
		'alpha-3': 'MAR',
		'country-code': '504',
		'iso_3166-2': 'ISO 3166-2:MA',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'Mozambique',
		'value': 'MZ',
		'alpha-3': 'MOZ',
		'country-code': '508',
		'iso_3166-2': 'ISO 3166-2:MZ',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Myanmar',
		'value': 'MM',
		'alpha-3': 'MMR',
		'country-code': '104',
		'iso_3166-2': 'ISO 3166-2:MM',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Namibia',
		'value': 'NA',
		'alpha-3': 'NAM',
		'country-code': '516',
		'iso_3166-2': 'ISO 3166-2:NA',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Southern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '018',
	},
	{
		'label': 'Nauru',
		'value': 'NR',
		'alpha-3': 'NRU',
		'country-code': '520',
		'iso_3166-2': 'ISO 3166-2:NR',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Nepal',
		'value': 'NP',
		'alpha-3': 'NPL',
		'country-code': '524',
		'iso_3166-2': 'ISO 3166-2:NP',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Netherlands',
		'value': 'NL',
		'alpha-3': 'NLD',
		'country-code': '528',
		'iso_3166-2': 'ISO 3166-2:NL',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'New Caledonia',
		'value': 'NC',
		'alpha-3': 'NCL',
		'country-code': '540',
		'iso_3166-2': 'ISO 3166-2:NC',
		'region': 'Oceania',
		'sub-region': 'Melanesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '054',
		'intermediate-region-code': '',
	},
	{
		'label': 'New Zealand',
		'value': 'NZ',
		'alpha-3': 'NZL',
		'country-code': '554',
		'iso_3166-2': 'ISO 3166-2:NZ',
		'region': 'Oceania',
		'sub-region': 'Australia and New Zealand',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '053',
		'intermediate-region-code': '',
	},
	{
		'label': 'Nicaragua',
		'value': 'NI',
		'alpha-3': 'NIC',
		'country-code': '558',
		'iso_3166-2': 'ISO 3166-2:NI',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Niger',
		'value': 'NE',
		'alpha-3': 'NER',
		'country-code': '562',
		'iso_3166-2': 'ISO 3166-2:NE',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Nigeria',
		'value': 'NG',
		'alpha-3': 'NGA',
		'country-code': '566',
		'iso_3166-2': 'ISO 3166-2:NG',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Niue',
		'value': 'NU',
		'alpha-3': 'NIU',
		'country-code': '570',
		'iso_3166-2': 'ISO 3166-2:NU',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Norfolk Island',
		'value': 'NF',
		'alpha-3': 'NFK',
		'country-code': '574',
		'iso_3166-2': 'ISO 3166-2:NF',
		'region': 'Oceania',
		'sub-region': 'Australia and New Zealand',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '053',
		'intermediate-region-code': '',
	},
	{
		'label': 'North Macedonia',
		'value': 'MK',
		'alpha-3': 'MKD',
		'country-code': '807',
		'iso_3166-2': 'ISO 3166-2:MK',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Northern Mariana Islands',
		'value': 'MP',
		'alpha-3': 'MNP',
		'country-code': '580',
		'iso_3166-2': 'ISO 3166-2:MP',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Norway',
		'value': 'NO',
		'alpha-3': 'NOR',
		'country-code': '578',
		'iso_3166-2': 'ISO 3166-2:NO',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Oman',
		'value': 'OM',
		'alpha-3': 'OMN',
		'country-code': '512',
		'iso_3166-2': 'ISO 3166-2:OM',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Pakistan',
		'value': 'PK',
		'alpha-3': 'PAK',
		'country-code': '586',
		'iso_3166-2': 'ISO 3166-2:PK',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Palau',
		'value': 'PW',
		'alpha-3': 'PLW',
		'country-code': '585',
		'iso_3166-2': 'ISO 3166-2:PW',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Palestine, State of',
		'value': 'PS',
		'alpha-3': 'PSE',
		'country-code': '275',
		'iso_3166-2': 'ISO 3166-2:PS',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Panama',
		'value': 'PA',
		'alpha-3': 'PAN',
		'country-code': '591',
		'iso_3166-2': 'ISO 3166-2:PA',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Central America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '013',
	},
	{
		'label': 'Papua New Guinea',
		'value': 'PG',
		'alpha-3': 'PNG',
		'country-code': '598',
		'iso_3166-2': 'ISO 3166-2:PG',
		'region': 'Oceania',
		'sub-region': 'Melanesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '054',
		'intermediate-region-code': '',
	},
	{
		'label': 'Paraguay',
		'value': 'PY',
		'alpha-3': 'PRY',
		'country-code': '600',
		'iso_3166-2': 'ISO 3166-2:PY',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Peru',
		'value': 'PE',
		'alpha-3': 'PER',
		'country-code': '604',
		'iso_3166-2': 'ISO 3166-2:PE',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Philippines',
		'value': 'PH',
		'alpha-3': 'PHL',
		'country-code': '608',
		'iso_3166-2': 'ISO 3166-2:PH',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Pitcairn',
		'value': 'PN',
		'alpha-3': 'PCN',
		'country-code': '612',
		'iso_3166-2': 'ISO 3166-2:PN',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Poland',
		'value': 'PL',
		'alpha-3': 'POL',
		'country-code': '616',
		'iso_3166-2': 'ISO 3166-2:PL',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Portugal',
		'value': 'PT',
		'alpha-3': 'PRT',
		'country-code': '620',
		'iso_3166-2': 'ISO 3166-2:PT',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Puerto Rico',
		'value': 'PR',
		'alpha-3': 'PRI',
		'country-code': '630',
		'iso_3166-2': 'ISO 3166-2:PR',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Qatar',
		'value': 'QA',
		'alpha-3': 'QAT',
		'country-code': '634',
		'iso_3166-2': 'ISO 3166-2:QA',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Réunion',
		'value': 'RE',
		'alpha-3': 'REU',
		'country-code': '638',
		'iso_3166-2': 'ISO 3166-2:RE',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Romania',
		'value': 'RO',
		'alpha-3': 'ROU',
		'country-code': '642',
		'iso_3166-2': 'ISO 3166-2:RO',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Russian Federation',
		'value': 'RU',
		'alpha-3': 'RUS',
		'country-code': '643',
		'iso_3166-2': 'ISO 3166-2:RU',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Rwanda',
		'value': 'RW',
		'alpha-3': 'RWA',
		'country-code': '646',
		'iso_3166-2': 'ISO 3166-2:RW',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Saint Barthélemy',
		'value': 'BL',
		'alpha-3': 'BLM',
		'country-code': '652',
		'iso_3166-2': 'ISO 3166-2:BL',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Saint Helena, Ascension and Tristan da Cunha',
		'value': 'SH',
		'alpha-3': 'SHN',
		'country-code': '654',
		'iso_3166-2': 'ISO 3166-2:SH',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Saint Kitts and Nevis',
		'value': 'KN',
		'alpha-3': 'KNA',
		'country-code': '659',
		'iso_3166-2': 'ISO 3166-2:KN',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Saint Lucia',
		'value': 'LC',
		'alpha-3': 'LCA',
		'country-code': '662',
		'iso_3166-2': 'ISO 3166-2:LC',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Saint Martin (French part)',
		'value': 'MF',
		'alpha-3': 'MAF',
		'country-code': '663',
		'iso_3166-2': 'ISO 3166-2:MF',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Saint Pierre and Miquelon',
		'value': 'PM',
		'alpha-3': 'SPM',
		'country-code': '666',
		'iso_3166-2': 'ISO 3166-2:PM',
		'region': 'Americas',
		'sub-region': 'Northern America',
		'intermediate-region': '',
		'region-code': '019',
		'sub-region-code': '021',
		'intermediate-region-code': '',
	},
	{
		'label': 'Saint Vincent and the Grenadines',
		'value': 'VC',
		'alpha-3': 'VCT',
		'country-code': '670',
		'iso_3166-2': 'ISO 3166-2:VC',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Samoa',
		'value': 'WS',
		'alpha-3': 'WSM',
		'country-code': '882',
		'iso_3166-2': 'ISO 3166-2:WS',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'San Marino',
		'value': 'SM',
		'alpha-3': 'SMR',
		'country-code': '674',
		'iso_3166-2': 'ISO 3166-2:SM',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Sao Tome and Principe',
		'value': 'ST',
		'alpha-3': 'STP',
		'country-code': '678',
		'iso_3166-2': 'ISO 3166-2:ST',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Middle Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '017',
	},
	{
		'label': 'Saudi Arabia',
		'value': 'SA',
		'alpha-3': 'SAU',
		'country-code': '682',
		'iso_3166-2': 'ISO 3166-2:SA',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Senegal',
		'value': 'SN',
		'alpha-3': 'SEN',
		'country-code': '686',
		'iso_3166-2': 'ISO 3166-2:SN',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Serbia',
		'value': 'RS',
		'alpha-3': 'SRB',
		'country-code': '688',
		'iso_3166-2': 'ISO 3166-2:RS',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Seychelles',
		'value': 'SC',
		'alpha-3': 'SYC',
		'country-code': '690',
		'iso_3166-2': 'ISO 3166-2:SC',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Sierra Leone',
		'value': 'SL',
		'alpha-3': 'SLE',
		'country-code': '694',
		'iso_3166-2': 'ISO 3166-2:SL',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Singapore',
		'value': 'SG',
		'alpha-3': 'SGP',
		'country-code': '702',
		'iso_3166-2': 'ISO 3166-2:SG',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Sint Maarten (Dutch part)',
		'value': 'SX',
		'alpha-3': 'SXM',
		'country-code': '534',
		'iso_3166-2': 'ISO 3166-2:SX',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Slovakia',
		'value': 'SK',
		'alpha-3': 'SVK',
		'country-code': '703',
		'iso_3166-2': 'ISO 3166-2:SK',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'Slovenia',
		'value': 'SI',
		'alpha-3': 'SVN',
		'country-code': '705',
		'iso_3166-2': 'ISO 3166-2:SI',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Solomon Islands',
		'value': 'SB',
		'alpha-3': 'SLB',
		'country-code': '090',
		'iso_3166-2': 'ISO 3166-2:SB',
		'region': 'Oceania',
		'sub-region': 'Melanesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '054',
		'intermediate-region-code': '',
	},
	{
		'label': 'Somalia',
		'value': 'SO',
		'alpha-3': 'SOM',
		'country-code': '706',
		'iso_3166-2': 'ISO 3166-2:SO',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'South Africa',
		'value': 'ZA',
		'alpha-3': 'ZAF',
		'country-code': '710',
		'iso_3166-2': 'ISO 3166-2:ZA',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Southern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '018',
	},
	{
		'label': 'South Georgia and the South Sandwich Islands',
		'value': 'GS',
		'alpha-3': 'SGS',
		'country-code': '239',
		'iso_3166-2': 'ISO 3166-2:GS',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'South Sudan',
		'value': 'SS',
		'alpha-3': 'SSD',
		'country-code': '728',
		'iso_3166-2': 'ISO 3166-2:SS',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Spain',
		'value': 'ES',
		'alpha-3': 'ESP',
		'country-code': '724',
		'iso_3166-2': 'ISO 3166-2:ES',
		'region': 'Europe',
		'sub-region': 'Southern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '039',
		'intermediate-region-code': '',
	},
	{
		'label': 'Sri Lanka',
		'value': 'LK',
		'alpha-3': 'LKA',
		'country-code': '144',
		'iso_3166-2': 'ISO 3166-2:LK',
		'region': 'Asia',
		'sub-region': 'Southern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '034',
		'intermediate-region-code': '',
	},
	{
		'label': 'Sudan',
		'value': 'SD',
		'alpha-3': 'SDN',
		'country-code': '729',
		'iso_3166-2': 'ISO 3166-2:SD',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'Suriname',
		'value': 'SR',
		'alpha-3': 'SUR',
		'country-code': '740',
		'iso_3166-2': 'ISO 3166-2:SR',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Svalbard and Jan Mayen',
		'value': 'SJ',
		'alpha-3': 'SJM',
		'country-code': '744',
		'iso_3166-2': 'ISO 3166-2:SJ',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Sweden',
		'value': 'SE',
		'alpha-3': 'SWE',
		'country-code': '752',
		'iso_3166-2': 'ISO 3166-2:SE',
		'region': 'Europe',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'Switzerland',
		'value': 'CH',
		'alpha-3': 'CHE',
		'country-code': '756',
		'iso_3166-2': 'ISO 3166-2:CH',
		'region': 'Europe',
		'sub-region': 'Western Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '155',
		'intermediate-region-code': '',
	},
	{
		'label': 'Syrian Arab Republic',
		'value': 'SY',
		'alpha-3': 'SYR',
		'country-code': '760',
		'iso_3166-2': 'ISO 3166-2:SY',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Taiwan, Province of China',
		'value': 'TW',
		'alpha-3': 'TWN',
		'country-code': '158',
		'iso_3166-2': 'ISO 3166-2:TW',
		'region': 'Asia',
		'sub-region': 'Eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '030',
		'intermediate-region-code': '',
	},
	{
		'label': 'Tajikistan',
		'value': 'TJ',
		'alpha-3': 'TJK',
		'country-code': '762',
		'iso_3166-2': 'ISO 3166-2:TJ',
		'region': 'Asia',
		'sub-region': 'Central Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '143',
		'intermediate-region-code': '',
	},
	{
		'label': 'Tanzania, United Republic of',
		'value': 'TZ',
		'alpha-3': 'TZA',
		'country-code': '834',
		'iso_3166-2': 'ISO 3166-2:TZ',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Thailand',
		'value': 'TH',
		'alpha-3': 'THA',
		'country-code': '764',
		'iso_3166-2': 'ISO 3166-2:TH',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Timor-Leste',
		'value': 'TL',
		'alpha-3': 'TLS',
		'country-code': '626',
		'iso_3166-2': 'ISO 3166-2:TL',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Togo',
		'value': 'TG',
		'alpha-3': 'TGO',
		'country-code': '768',
		'iso_3166-2': 'ISO 3166-2:TG',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Western Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '011',
	},
	{
		'label': 'Tokelau',
		'value': 'TK',
		'alpha-3': 'TKL',
		'country-code': '772',
		'iso_3166-2': 'ISO 3166-2:TK',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Tonga',
		'value': 'TO',
		'alpha-3': 'TON',
		'country-code': '776',
		'iso_3166-2': 'ISO 3166-2:TO',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Trinidad and Tobago',
		'value': 'TT',
		'alpha-3': 'TTO',
		'country-code': '780',
		'iso_3166-2': 'ISO 3166-2:TT',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Tunisia',
		'value': 'TN',
		'alpha-3': 'TUN',
		'country-code': '788',
		'iso_3166-2': 'ISO 3166-2:TN',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'Turkey',
		'value': 'TR',
		'alpha-3': 'TUR',
		'country-code': '792',
		'iso_3166-2': 'ISO 3166-2:TR',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Turkmenistan',
		'value': 'TM',
		'alpha-3': 'TKM',
		'country-code': '795',
		'iso_3166-2': 'ISO 3166-2:TM',
		'region': 'Asia',
		'sub-region': 'Central Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '143',
		'intermediate-region-code': '',
	},
	{
		'label': 'Turks and Caicos Islands',
		'value': 'TC',
		'alpha-3': 'TCA',
		'country-code': '796',
		'iso_3166-2': 'ISO 3166-2:TC',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Tuvalu',
		'value': 'TV',
		'alpha-3': 'TUV',
		'country-code': '798',
		'iso_3166-2': 'ISO 3166-2:TV',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Uganda',
		'value': 'UG',
		'alpha-3': 'UGA',
		'country-code': '800',
		'iso_3166-2': 'ISO 3166-2:UG',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Ukraine',
		'value': 'UA',
		'alpha-3': 'UKR',
		'country-code': '804',
		'iso_3166-2': 'ISO 3166-2:UA',
		'region': 'Europe',
		'sub-region': 'Eastern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '151',
		'intermediate-region-code': '',
	},
	{
		'label': 'United Arab Emirates',
		'value': 'AE',
		'alpha-3': 'ARE',
		'country-code': '784',
		'iso_3166-2': 'ISO 3166-2:AE',
		'region': 'Uae',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'United Kingdom of Great Britain and Northern Ireland',
		'value': 'GB',
		'alpha-3': 'GBR',
		'country-code': '826',
		'iso_3166-2': 'ISO 3166-2:GB',
		'region': 'Uk',
		'sub-region': 'Northern Europe',
		'intermediate-region': '',
		'region-code': '150',
		'sub-region-code': '154',
		'intermediate-region-code': '',
	},
	{
		'label': 'United States of America',
		'value': 'US',
		'alpha-3': 'USA',
		'country-code': '840',
		'iso_3166-2': 'ISO 3166-2:US',
		'region': 'Americas',
		'sub-region': 'Northern America',
		'intermediate-region': '',
		'region-code': '019',
		'sub-region-code': '021',
		'intermediate-region-code': '',
	},
	{
		'label': 'United States Minor Outlying Islands',
		'value': 'UM',
		'alpha-3': 'UMI',
		'country-code': '581',
		'iso_3166-2': 'ISO 3166-2:UM',
		'region': 'Oceania',
		'sub-region': 'Micronesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '057',
		'intermediate-region-code': '',
	},
	{
		'label': 'Uruguay',
		'value': 'UY',
		'alpha-3': 'URY',
		'country-code': '858',
		'iso_3166-2': 'ISO 3166-2:UY',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Uzbekistan',
		'value': 'UZ',
		'alpha-3': 'UZB',
		'country-code': '860',
		'iso_3166-2': 'ISO 3166-2:UZ',
		'region': 'Asia',
		'sub-region': 'Central Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '143',
		'intermediate-region-code': '',
	},
	{
		'label': 'Vanuatu',
		'value': 'VU',
		'alpha-3': 'VUT',
		'country-code': '548',
		'iso_3166-2': 'ISO 3166-2:VU',
		'region': 'Oceania',
		'sub-region': 'Melanesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '054',
		'intermediate-region-code': '',
	},
	{
		'label': 'Venezuela (Bolivarian Republic of)',
		'value': 'VE',
		'alpha-3': 'VEN',
		'country-code': '862',
		'iso_3166-2': 'ISO 3166-2:VE',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'South America',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '005',
	},
	{
		'label': 'Viet Nam',
		'value': 'VN',
		'alpha-3': 'VNM',
		'country-code': '704',
		'iso_3166-2': 'ISO 3166-2:VN',
		'region': 'Asia',
		'sub-region': 'South-eastern Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '035',
		'intermediate-region-code': '',
	},
	{
		'label': 'Virgin Islands (British)',
		'value': 'VG',
		'alpha-3': 'VGB',
		'country-code': '092',
		'iso_3166-2': 'ISO 3166-2:VG',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Virgin Islands (U.S.)',
		'value': 'VI',
		'alpha-3': 'VIR',
		'country-code': '850',
		'iso_3166-2': 'ISO 3166-2:VI',
		'region': 'Americas',
		'sub-region': 'Latin America and the Caribbean',
		'intermediate-region': 'Caribbean',
		'region-code': '019',
		'sub-region-code': '419',
		'intermediate-region-code': '029',
	},
	{
		'label': 'Wallis and Futuna',
		'value': 'WF',
		'alpha-3': 'WLF',
		'country-code': '876',
		'iso_3166-2': 'ISO 3166-2:WF',
		'region': 'Oceania',
		'sub-region': 'Polynesia',
		'intermediate-region': '',
		'region-code': '009',
		'sub-region-code': '061',
		'intermediate-region-code': '',
	},
	{
		'label': 'Western Sahara',
		'value': 'EH',
		'alpha-3': 'ESH',
		'country-code': '732',
		'iso_3166-2': 'ISO 3166-2:EH',
		'region': 'Africa',
		'sub-region': 'Northern Africa',
		'intermediate-region': '',
		'region-code': '002',
		'sub-region-code': '015',
		'intermediate-region-code': '',
	},
	{
		'label': 'Yemen',
		'value': 'YE',
		'alpha-3': 'YEM',
		'country-code': '887',
		'iso_3166-2': 'ISO 3166-2:YE',
		'region': 'Asia',
		'sub-region': 'Western Asia',
		'intermediate-region': '',
		'region-code': '142',
		'sub-region-code': '145',
		'intermediate-region-code': '',
	},
	{
		'label': 'Zambia',
		'value': 'ZM',
		'alpha-3': 'ZMB',
		'country-code': '894',
		'iso_3166-2': 'ISO 3166-2:ZM',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
	{
		'label': 'Zimbabwe',
		'value': 'ZW',
		'alpha-3': 'ZWE',
		'country-code': '716',
		'iso_3166-2': 'ISO 3166-2:ZW',
		'region': 'Africa',
		'sub-region': 'Sub-Saharan Africa',
		'intermediate-region': 'Eastern Africa',
		'region-code': '002',
		'sub-region-code': '202',
		'intermediate-region-code': '014',
	},
]
