import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { PeriodStatistic } from '../../../../../types/PeriodStatistic'
import useCurrency from '../../../../../hooks/useCurrency'
import { getCurrencySymbol } from '../../../../../lib/utils'

type ISupplierInsightsScope1Graph = {
	chartData: Array<PeriodStatistic>
}

const SupplierInsightsScope1Graph: FunctionComponent<ISupplierInsightsScope1Graph> = ({
	chartData,
}) => {
	const currencyCode = useCurrency();
	const currencySymbol = getCurrencySymbol(currencyCode);
	const scope1ChartData = chartData
		.map((period) => {
			return {
				name: `${dayjs(period.startdate).format('MM/YYYY')} - ${dayjs(period.endDate).format(
					'MM/YYYY'
				)}`,
				RefrigerantOther: Number(
					period.statistics.scope1ByLevel1.filter((item) => item.label === 'Refrigerant & other')[0]
						.sum
				),
				Bioenergy: Number(
					period.statistics.scope1ByLevel1.filter((item) => item.label === 'Bioenergy')[0].sum
				),
				Fuels: Number(
					period.statistics.scope1ByLevel1.filter((item) => item.label === 'Fuels')[0].sum
				),
			}
		})
		.reverse()

	return (
		<div className='relative pl-4 overflow-hidden'>
			{/* Custom Y axis label - sorry */}
			<div className='text-white text-sm absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-center'>
				<span className='-rotate-90 origin-top-left'>
					<span className='absolute -translate-x-1/2'>
						Intensity Ratio (kg CO₂e per {currencySymbol}1 Turnover)
					</span>
				</span>
			</div>
			<ResponsiveContainer width='100%' aspect={1.778}>
				<BarChart
					width={500}
					height={300}
					data={scope1ChartData}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<XAxis
						dataKey='name'
						axisLine={false}
						tickLine={false}
						style={{ fontSize: '12px' }}
						tick={{ fill: '#FFF' }}
					/>
					<YAxis
						axisLine={false}
						tickLine={false}
						style={{ fontSize: '12px' }}
						tick={{ fill: '#FFF' }}
					/>
					<Tooltip
						contentStyle={{ backgroundColor: '#2E3A46', borderColor: '#252F38', borderWidth: 2 }}
						labelStyle={{ color: 'white' }}
						cursor={{ fill: '#12171C' }}
					/>
					<Legend
						align='right'
						verticalAlign='top'
						layout='vertical'
						formatter={(value) => <span className='text-white ml-2 text-sm'>{value}</span>}
					/>
					<Bar dataKey='Fuels' stackId='a' fill='#E6EFFF' name='Fuels' barSize={48} />
					<Bar dataKey='Bioenergy' stackId='a' fill='#669EFF' name='Bioenergy' barSize={48} />
					<Bar
						dataKey='RefrigerantOther'
						stackId='a'
						fill='#005EFF'
						name='Refrigerant & Other'
						barSize={48}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default SupplierInsightsScope1Graph
