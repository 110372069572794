import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button, Input } from '../components/BaseComponents'
import { SortConfig } from '../components/BaseComponents/Datagrid/useSortableData'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import Pagination from '../components/BaseComponents/Pagination/Pagination'
import SimpleSortTable, {
	TableColumn,
} from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import routes from '../components/Router/routes'
import { useEffectWithoutInitialRender } from '../hooks/useEffectWithoutInitialRender'
import { getItems, GET_ITEMS_LIMIT } from '../redux/actions/itemActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	ITEMS_RESET,
	PurchaseItem,
	selectAllItems,
	selectItemsPagination,
	selectItemsStatus,
} from '../redux/slices/itemsSlice'
import { FetchStatus } from '../types/LoadingStates'
import useApi from '../hooks/useApi'

const CompanyItems = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();

	const itemsStatus = useAppSelector(selectItemsStatus)
	const pagination = useAppSelector(selectItemsPagination)
	const allItems = useAppSelector(selectAllItems)

	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	const [orderBy, setOrderBy] = useState<string>('name')
	const [direction, setDirection] = useState<'asc' | 'desc' | undefined>('asc')

	// Data loading
	useEffect(() => {
		dispatch({
			type: ITEMS_RESET,
		})
		dispatch(getItems(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{ id: 'name', label: 'Item', sortable: true },
		{ id: 'supplierName', label: 'Supplier', sortable: false },
		{
			id: 'scope',
			label: 'Scope',
			sortable: true,
			columnClass: 'w-32',
		},
		{
			id: 'edit',
			label: '',
			sortable: false,
			columnClass: 'w-44',
			renderer: (columnKey, rowData) => {
				return (
					<Button
						type='Primary'
						size='sm'
						text='Edit item'
						hasLeadingIcon
						icon='fa-regular fa-pen-to-square'
						accessibilityLabel={rowData.itemName}
						additionalClass='float-right'
						onClick={() => {
							navigate(generatePath(routes.companyItemEdit.path, { itemId: rowData._id }))
						}}
					/>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'name',
		direction: 'ascending',
	}

	// DataGrid data constructor
	const constructData = (dataSet: Array<PurchaseItem>) => [
		...dataSet.map((item) => {
			return {
				_id: item.id,
				name: item.name,
				supplierName: item?.supplier?.name,
				scope: Number(item.scope),
				edit: '',
			}
		}),
	]

	// Timeout for search
	useEffectWithoutInitialRender(() => {
		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			dispatch(
				getItems(api, {
					page: pagination.first_page,
					limit: GET_ITEMS_LIMIT,
					orderBy: orderBy,
					direction: 'asc',
					q: searchKeyword,
				})
			)

			setSearchInProgress(false)
		}, 500)

		if (searchKeyword !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchKeyword])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyItems.name}</title>
			</Helmet>
			<PageHeadings
				title='Purchases'
				subTitle='Items'
				subSubTitle='Overview'
				routeBack={routes.companyPurchases.path}
			/>

			{itemsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching items associated with this organisation. If this problem
						persists, please contact the Neoni team.
					</p>
				</div>
			)}

			<div className='items-start'>
				<div className='relative max-w-2xl'>
					<label htmlFor='filterItems' className='sr-only'>
						Search items
					</label>
					<Input
						type={'text'}
						name={'filterItems'}
						placeholder='Search for an item'
						value={searchKeyword}
						onChange={(event) => {
							setSearchKeyword(event.target.value)
						}}
					/>
					<label htmlFor='filterPurchases' className='sr-only'>
						Search items
					</label>
					<Button
						hasLeadingIcon
						type='White'
						size='sm'
						icon={` ${
							searchInProgress
								? 'fas fa-loader fa-spin'
								: searchKeyword.length > 0
								? 'fa-solid fa-times'
								: 'fa-solid fa-search'
						}`}
						additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
						onClick={() => {
							setSearchKeyword('')
							dispatch(
								getItems(api, {
									page: pagination.first_page,
									limit: GET_ITEMS_LIMIT,
									orderBy: orderBy,
									direction: 'asc',
									q: '',
								})
							)
						}}
					/>
				</div>
			</div>

			<SimpleSortTable
				isLoading={itemsStatus === FetchStatus.loading}
				additionalClass='mt-4'
				data={constructData(allItems)}
				columns={columns}
				defaultSorting={defaultSorting}
				onSortPress={(key, direction) => {
					// Store current sorting values for pagination to reference in its API calls
					setOrderBy(key)
					setDirection(direction === 'ascending' ? 'asc' : 'desc')
					dispatch(
						getItems(api, {
							page: pagination.current_page,
							limit: GET_ITEMS_LIMIT,
							orderBy: key,
							direction: direction === 'ascending' ? 'asc' : 'desc',
							q: searchKeyword,
						})
					)
				}}
			/>

			<Pagination
				isLoading={itemsStatus === FetchStatus.loading}
				pagination={pagination}
				onPageChange={(newPageNumber) => {
					dispatch(
						getItems(api, {
							page: newPageNumber,
							limit: GET_ITEMS_LIMIT,
							orderBy: orderBy,
							direction: direction,
							q: searchKeyword,
						})
					)
				}}
			/>
		</PageTemplate>
	)
}

export default CompanyItems
