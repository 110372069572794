import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PeriodStatistic } from '../../types/PeriodStatistic'
import type { RootState } from '../store'

type periodStatisticsState = {
	status: FetchStatus
	error?: string
	stats: Array<PeriodStatistic>
}

const periodStatisticsInitialState: periodStatisticsState = {
	status: FetchStatus.initial,
	error: undefined,
	stats: [],
}

export const periodStatisticsSlice = createSlice({
	name: 'periodStatistics',
	initialState: periodStatisticsInitialState,
	reducers: {
		PERIODSTATS_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		PERIODSTATS_LOADED: (state, action: PayloadAction<Array<PeriodStatistic>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				stats: action.payload,
			}
		},
		PERIODSTATS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		PERIODSTATS_RESET: () => {
			return periodStatisticsInitialState
		},
	},
})

// Action types
export const { PERIODSTATS_LOADING, PERIODSTATS_LOADED, PERIODSTATS_ERROR, PERIODSTATS_RESET } =
	periodStatisticsSlice.actions

// Selectors
export const selectPeriodStatisticsStatus = (state: RootState) => state.periodStatistics.status
export const selectPeriodStatisticsError = (state: RootState) => state.periodStatistics.error
export const selectAllPeriodStatistics = (state: RootState) => state.periodStatistics.stats

export default periodStatisticsSlice.reducer
