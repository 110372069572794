import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, Input, LoadingSpinner } from '../components/BaseComponents'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import {
	getAccountingPeriods,
	updateAccountingPeriodStage,
} from '../redux/actions/accountingPeriodsActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	ACCOUNTINGPERIODS_UPDATE_RESET,
	selectAccountingPeriods,
	selectAccountingPeriodsError,
	selectAccountingPeriodsStatus,
	selectAccountingPeriodsUpdateError,
	selectAccountingPeriodsUpdateStatus,
} from '../redux/slices/accountingPeriodsSlice'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import { SortConfig } from '../components/BaseComponents/Datagrid/useSortableData'
import { centsToCurrency } from '../lib/utils'
import { updateItemsScope } from '../redux/actions/updateItemsActions'
import {
	selectUpdateItemsError,
	selectUpdateItemsStatus,
	UPDATEITEMS_RESET,
} from '../redux/slices/updateItemsSlice'
import { PurchaseUploadState } from '../enums/PurchaseUploadState'
import Modal from '../components/BaseComponents/Modal/Modal'
import { GET_PURCHASES_CARBON_ACCOUNTS_LIMIT } from '../redux/actions/carbonAccountsPurchasesActions'
import SimpleSortTable, {
	TableColumn,
} from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import Pagination from '../components/BaseComponents/Pagination/Pagination'
import { useEffectWithoutInitialRender } from '../hooks/useEffectWithoutInitialRender'
import { Scope1SelectionItem } from '../types/PurchaseCarbonData'
import {
	REFINEMENTS_SCOPE1_EMISSIONS_RESET,
	REFINEMENTS_SCOPE1_SELECTION_RESET,
	selectRefinementsScope1SelectionError,
	selectRefinementsScope1SelectionItems,
	selectRefinementsScope1SelectionStatus,
} from '../redux/slices/refinementsSlice'
import { getScope1SelectionItems } from '../redux/actions/refinementsActions'
import { PaginationMeta } from '../types/PaginationMeta'
import useCurrencyFormatter from '../hooks/useCurrencyFormatter'
import useApi from '../hooks/useApi'

export const CompanyRefineEmissions = () => {
	const formatCurrency = useCurrencyFormatter();
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();
	const { accountingPeriodId } = useParams()

	const accountingPeriodsStatus = useAppSelector(selectAccountingPeriodsStatus)
	const accountingPeriodsError = useAppSelector(selectAccountingPeriodsError)
	const accountingPeriodUpdateStatus = useAppSelector(selectAccountingPeriodsUpdateStatus)
	const accountingPeriodUpdateError = useAppSelector(selectAccountingPeriodsUpdateError)
	const allAccountingPeriodData = useAppSelector(selectAccountingPeriods)

	const itemLoadStatus = useAppSelector(selectRefinementsScope1SelectionStatus)
	const itemError = useAppSelector(selectRefinementsScope1SelectionError)
	const itemData = useAppSelector(selectRefinementsScope1SelectionItems)
	const defaultPagination: PaginationMeta = {
		current_page: 1,
		first_page: 1,
		last_page: 1,
		per_page: 20,
		total: 0,
	}
	const { items, pagination } = itemData || { items: null, pagination: defaultPagination }

	const updateItemsStatus = useAppSelector(selectUpdateItemsStatus)
	const updateItemsError = useAppSelector(selectUpdateItemsError)

	// Find the accounting period to match the url param
	const thisAccountingPeriod = allAccountingPeriodData.filter(
		(period) => period.id === accountingPeriodId
	)[0]

	const [companyId, setCompanyId] = useState('')

	const [errorNoPeriod, setErrorNoPeriod] = useState(false)
	const [showHelp, setShowHelp] = useState(false)

	const [dirtyItems, setDirtyItems] = useState<{ id: string; name: string; selected: boolean }[]>(
		[]
	)
	const [confirmPurchaseModal, setConfirmPurchaseModal] = useState<boolean>(false)

	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
	const perPage = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT
	const [orderBy, setOrderBy] = useState<string>('totalSpend')
	const [direction, setDirection] = useState<'asc' | 'desc'>('desc')
	console.log('orderBy', orderBy, direction)

	const [readyToNavigateAway, setReadyToNavigateAway] = useState(false)

	// Data loading
	useEffect(() => {
		// Fetch accounting periods if they aren't in the store, we need to compare against the IDs
		if (accountingPeriodsStatus === FetchStatus.initial) {
			dispatch(getAccountingPeriods(api))
		}

		// // Reset the purchases store if it's currently filled
		if (itemLoadStatus === FetchStatus.loaded) {
			dispatch({
				type: REFINEMENTS_SCOPE1_SELECTION_RESET,
			})
			dispatch({
				type: ACCOUNTINGPERIODS_UPDATE_RESET,
			})
		}

		if (accountingPeriodsStatus === FetchStatus.loaded) {
			// If there's no accounting period defined, the url parameter is probably wrong, show an error
			if (typeof thisAccountingPeriod !== 'undefined') {
				setCompanyId(thisAccountingPeriod.company_id)

				// Fetch all purchases (paginated)
				dispatch(
					getScope1SelectionItems(api, thisAccountingPeriod.id, 1, orderBy, direction, perPage, '')
				)
			} else {
				setErrorNoPeriod(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountingPeriodsStatus])

	// Navigate to next page on successful submission
	useEffect(() => {
		if (readyToNavigateAway && dirtyItems.length > 0) {
			dispatch(
				updateAccountingPeriodStage(api, {
					accountingPeriodId: thisAccountingPeriod.id,
					uploadState: PurchaseUploadState.scope1Selected,
				})
			)
			dispatch({
				type: UPDATEITEMS_RESET,
			})
			dispatch({
				type: REFINEMENTS_SCOPE1_EMISSIONS_RESET,
			})

			navigate(generatePath(routes.companyRefineEmissionsGeneratedType.path, { accountingPeriodId: thisAccountingPeriod.id}))
		} else if (readyToNavigateAway && dirtyItems.length === 0) {
			dispatch(
				updateAccountingPeriodStage(api, {
					accountingPeriodId: thisAccountingPeriod.id,
					uploadState: PurchaseUploadState.scope1Refined,
				})
			)
			dispatch({
				type: UPDATEITEMS_RESET,
			})
			dispatch({
				type: REFINEMENTS_SCOPE1_EMISSIONS_RESET,
			})

			navigate(generatePath(routes.companyRefineElectricity.path, { accountingPeriodId: thisAccountingPeriod.id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyToNavigateAway])

	// Listen to updateItemStatus so we know when items have successfully updated and we're ready to nav away
	useEffect(() => {
		if (updateItemsStatus === PostStatus.sent) {
			setReadyToNavigateAway(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateItemsStatus])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{
			id: 'selected',
			label: '',
			sortable: false,
			columnClass: 'w-3', // narrow fixed column width
			renderer: (columnKey, rowData) => {
				return (
					<input
						type='checkbox'
						className='rounded border-charcoal-300 text-algae-600 focus:ring-algae-500'
						value={rowData.name}
						checked={rowData.selected}
						onClick={(event: React.SyntheticEvent) => {
							// We don’t want the click event to bubble up through the
							// checkbox into the `tr` and trigger the table’s `rowClickHandler`
							event.stopPropagation()
						}}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							event.stopPropagation()
							const changed = dirtyItems.filter((item) => item.id !== rowData._id)
							if (event.target.checked !== (rowData.scope === 1)) {
								changed.push({
									id: rowData._id,
									name: rowData.itemName,
									selected: event.target.checked,
								})
							}
							setDirtyItems(changed)
						}}
					/>
				)
			},
		},
		{ id: 'itemName', label: 'Product', sortable: true },
		{ id: 'supplierName', label: 'Supplier', sortable: true },
		{
			id: 'totalQuantity',
			label: 'Amount',
			sortable: true,
			renderer: (columnKey, rowData) => {
				return (
					<span className=' font-medium text-white '>
						{rowData.totalQuantity} {rowData.quantity_unit}
					</span>
				)
			},
		},
		{
			id: 'totalSpend',
			label: 'Total Spend',
			textAlign: 'right',
			sortable: true,
			renderer: (columnKey, rowData) => {
				return (
					<span className=' font-medium text-white '>{formatCurrency(rowData.totalSpend)}</span>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'totalSpend',
		direction: 'descending',
	}

	// DataGrid data constructor
	const constructData = (dataSet: Scope1SelectionItem[]) =>
		dataSet.map((item) => {
			const dirtyItem = dirtyItems.find((dirty) => dirty.id === item.itemId)
			return {
				_id: item.itemId,
				itemName: item.itemName,
				totalSpend: centsToCurrency(item.totalSpend),
				scope: Number(item.scope),
				supplierName: item.supplierName,
				totalQuantity: item.totalQuantity,
				quantityUnit: item.quantityUnit,
				selected: dirtyItem ? dirtyItem.selected : item.scope === 1,
			}
		})

	// Timeout for search
	useEffectWithoutInitialRender(() => {
		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			if (companyId !== '' && accountingPeriodId !== '') {
				dispatch(
					getScope1SelectionItems(
						api,
						thisAccountingPeriod.id,
						1,
						orderBy,
						direction,
						perPage,
						searchKeyword
					)
				)
			}
			setSearchInProgress(false)
		}, 500)

		if (searchKeyword !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchKeyword])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyRefineEmissionsGenerated.name}</title>
			</Helmet>
			<PageHeadings
				title={'Accounting periods'}
				subTitle={'Refine scope 1 data'}
				subSubTitle={'Emissions generated'}
				routeBack={routes.companyAddAccountingPeriods.path}
			/>

			<>
				{/* Helpful information */}
				<div className='rounded-lg bg-sun-500 p-4 my-5'>
					<p className='mb-5'>Select all purchases that generate emissions on your site(s).</p>

					<div className={`rounded-lg bg-white p-4 mb-5 ${!showHelp && 'hidden'}`} id='helpSection'>
						<p className='mb-5'>Items that generate emissions on your site will include:</p>

						<ul className='pl-5 list-disc'>
							<li>
								<p className=''>Fuels burnt on site:</p>
								<p className='mb-5'>
									e.g. Natural Gas and stationary combustion of LPG, oil, diesel and coal
								</p>
							</li>

							<li>
								<p className=''>Fuels burnt in vehicles under the ownership of the organisation:</p>
								<p className='mb-5'>usually petrol, diesel and sometimes LPG</p>
							</li>
							<li>
								<p className=''>Substances that release "fugitive emissions":</p>
								<p className='mb-5'>e.g. refrigerants and leaks from air conditioning</p>
							</li>

							<li>
								<p className=''>Emissions released in specific manufacturing processes:</p>
								<p>such as those for cement, tyres and steel.</p>
							</li>
						</ul>
					</div>

					<Button
						type={'Primary'}
						text={`${showHelp ? 'Hide information' : 'What does that include?'}`}
						hasLeadingIcon
						size='sm'
						icon={`fa-regular ${showHelp ? 'fa-minus' : 'fa-plus'}`}
						onClick={() => setShowHelp(!showHelp)}
						ariaControls='helpSection'
						ariaExpanded={showHelp}
					/>
				</div>

				<div className='items-start'>
					<div className='relative max-w-2xl'>
						<label htmlFor='searchPurchases' className='sr-only'>
							Search
						</label>
						<Input
							type={'text'}
							name={'searchPurchases'}
							placeholder='Search by product name'
							value={searchKeyword}
							onChange={(event) => {
								setSearchKeyword(event.target.value)
							}}
						/>
						<Button
							hasLeadingIcon
							type='White'
							size='sm'
							icon={` ${
								searchInProgress
									? 'fas fa-loader fa-spin'
									: searchKeyword.length > 0
									? 'fa-solid fa-times'
									: 'fa-solid fa-search'
							}`}
							additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
							onClick={() => {
								setSearchKeyword('')
								dispatch(
									getScope1SelectionItems(
										api,
										thisAccountingPeriod.id,
										1,
										orderBy,
										direction,
										perPage,
										''
									)
								)
							}}
						/>
					</div>
				</div>

				<SimpleSortTable
					isLoading={
						accountingPeriodsStatus === FetchStatus.loading ||
						itemLoadStatus === FetchStatus.loading
					}
					additionalClass='mt-4'
					data={constructData(items || [])}
					columns={columns}
					defaultSorting={defaultSorting}
					onSortPress={(key, direction) => {
						// Store current sorting values for pagination to reference in its API calls
						setOrderBy(key)
						setDirection(direction === 'ascending' ? 'asc' : 'desc')
						dispatch(
							getScope1SelectionItems(
								api,
								thisAccountingPeriod.id,
								pagination?.current_page,
								key,
								direction === 'ascending' ? 'asc' : 'desc',
								perPage,
								searchKeyword
							)
						)
					}}
				/>
				<Pagination
					isLoading={
						accountingPeriodsStatus === FetchStatus.loading ||
						itemLoadStatus === FetchStatus.loading
					}
					pagination={pagination}
					onPageChange={(newPageNumber) => {
						dispatch(
							getScope1SelectionItems(
								api,
								thisAccountingPeriod.id,
								newPageNumber,
								orderBy,
								direction,
								perPage,
								searchKeyword
							)
						)
					}}
				/>
				<div className='rounded-lg bg-charcoal-600 p-2 mt-5'>
					<Button
						type='Primary'
						text='Next step'
						hasTrailingIcon
						icon='fa-regular fa-chevron-right'
						additionalClass='float-right'
						onClick={() => {
							setConfirmPurchaseModal(!confirmPurchaseModal)
						}}
					/>
				</div>
				<Modal
					open={confirmPurchaseModal}
					onClose={() => setConfirmPurchaseModal(false)}
					size='small'
				>
					<>
						<PageHeadings title='Confirm Scope 1 Selections' />

						<div className='max-w-2xl'>
							{!dirtyItems.some((item) => item.selected) ? (
								<label className='block text-white pt-6 pb-4'>
									Are you sure? You haven't selected any scope 1 purchases.
								</label>
							) : (
								<>
									<label className='block text-white pt-6 pb-4'>
										Have you selected all your scope 1 purchases?
									</label>
									<table className='table-fixed w-full py-2 divide-y-1 divide-charcoal-050'>
										<thead className='bg-charcoal-800 border-t-2 border-b-2 border-charcoal-050'>
											<tr>
												<th
													scope='col'
													className='p-lg text-left text-sm font-semibold text-white leading-5'
												>
													Selected Products
												</th>
											</tr>
										</thead>
										<tbody className='divide-y divide-charcoal-050'>
											{dirtyItems
												.filter((item) => item.selected)
												.map((item) => {
													return (
														<tr key={item.id}>
															<td className='truncate p-lg text-sm text-white'>{item.name}</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</>
							)}
						</div>

						<Button
							hasLeadingIcon
							icon='fa-regular fa-plus'
							type='Outlined'
							text='Add a purchase'
							additionalClass=' mr-5'
							onClick={() => {
								setSearchKeyword('')
								setConfirmPurchaseModal(false)
							}}
						/>
						<Button
							hasTrailingIcon
							icon='fa-regular fa-chevron-right'
							onClick={() => {
								// Dispatch data to item scope update endpoint
								if (dirtyItems.length > 0) {
									dispatch(
										updateItemsScope(
											api,
											dirtyItems.map((item) => ({
												id: item.id,
												scope: item.selected ? 1 : null,
											}))
										)
									)
									// @todo - update
								} else {
									setReadyToNavigateAway(true)
								}
							}}
							type='Primary'
							text='Confirm Selection'
							additionalClass='mt-9'
						/>
					</>
				</Modal>
			</>

			{/* Errors and loading states*/}
			{accountingPeriodsStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading accounting periods...' />
			)}
			{(updateItemsStatus === PostStatus.sending ||
				accountingPeriodUpdateStatus === PostStatus.sending) && (
				<LoadingSpinner loadingText='Sending items...' />
			)}
			{accountingPeriodsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the accounting periods list. If this problem persists,
						please contact the Neoni team.
					</p>
					<p className='text-white'>{accountingPeriodsError}</p>
				</div>
			)}
			{itemLoadStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the purchases for this accounting period (
						{accountingPeriodId}). If this problem persists, please contact the Neoni team.
					</p>
					<p className='text-white'>{itemError}</p>
				</div>
			)}
			{updateItemsStatus === PostStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error updating those items' scope. If this problem persists, please contact
						the Neoni team.
					</p>
					<p className='text-white mb-5'>{updateItemsError}</p>
					<Button
						type='Primary'
						text='Retry'
						hasTrailingIcon
						icon='fa-regular fa-refresh'
						onClick={() => {
							// Try another dispatch
							if (dirtyItems.length > 0) {
								dispatch(
									updateItemsScope(
										api,
										dirtyItems.map((item) => ({
											id: item.id,
											scope: item.selected ? 1 : null,
										}))
									)
								)
							} else {
								setReadyToNavigateAway(true)
							}
						}}
					/>
				</div>
			)}
			{accountingPeriodUpdateStatus === PostStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error updating the accounting periods list. If this problem persists,
						please contact the Neoni team.
					</p>
					<p className='text-white'>{accountingPeriodUpdateError}</p>
				</div>
			)}
			{errorNoPeriod && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error whilst trying to find purchases for that accounting period ID (
						{accountingPeriodId}). If this problem persists, please contact the Neoni team.
					</p>
					<Button
						onClick={() => {
							navigate(generatePath(routes.companyRefineEmissionsGenerated.path, { accountingPeriodId }), {
								replace: true,
							})
						}}
						type='Primary'
						hasTrailingIcon={true}
						icon='fa-solid fa-refresh'
						text='Try again'
						additionalClass='mt-9 mr-5'
					/>
					<Button
						type='Outlined'
						text='Back to accounting periods'
						onClick={() => {
							navigate(routes.companyAddAccountingPeriods.path)
						}}
						additionalClass='mt-6 mr-5'
					/>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyRefineEmissions
