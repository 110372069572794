import { useEffect, useState } from "react";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import { Button } from "../../components/BaseComponents";
import { countries } from "../../assets/countryList";
import useApi from "../../hooks/useApi";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import { formatDate } from "../../lib/utils";
import { Link } from "react-router-dom";
import Label from "../../components/BaseComponents/Label";
import usePagination from "../../hooks/usePagination";
import useCleanup from "../../hooks/useCleanup";

const columns: TableColumn[] = [
    {
        id: 'companyName',
        label: 'Organisation',
        renderer: (key, data) => (
			<Link className="underline" to={`/admin/companies/${data.company.id}`}>
				{data.company.name}
			</Link>
		)
    },
    {
        id: 'companyNumber',
        label: 'Organisation #',
        renderer: (key, data) => data.company.number,
    },
    {
        id: 'companyTier',
        label: 'Tier',
        renderer: (key, data) => data.company.tier,
    },
    {
        id: 'period',
        label: 'Period',
        renderer: (key, data) => (
			<span>
				{ formatDate(data.start_date) }
				{' - '}
				{ formatDate(data.end_date) }
			</span>
		)
    },
    {
        id: 'start_date',
        label: 'Start Date',
        renderer: (key, data) => formatDate(data[key])
    },
    {
        id: 'signed_by',
        label: 'Signed By',
        renderer: (key, data) => (
			<span>{ data.signee_name } - { data.signee_title }</span>
		)
    },
    {
        id: 'user',
        label: 'User',
        renderer: (key, data) => data.user?.full_name,
    },
    {
        id: 'user_email',
        label: 'User Email',
        renderer: (key, data) => data.user?.email,
    },
    {
        id: 'end_date',
        label: 'End Date',
        renderer: (key, data) => formatDate(data[key])
    },
    {
        id: 'view',
        label: 'View',
    },
    {
        id: 'created_at',
        label: 'Created At',
		renderer: (key, data) => formatDate(data[key]),
    },
	{
		id: 'report_type',
		label: 'Report Type',
		renderer: () => 'SECR',
	},
	{
		id: 'publish_status',
		label: 'Publish Status',
		renderer: (key, data) => {
			const value = data[key];
			let label = 'Draft';
			if(value === 'published') {
				label = 'Published';
			}
			return (
				<span>{ label }</span>
			)
		},
	}
]

function AdminReports() {
    const api = useApi();
    const [data, setData] = useState([]);

	const pagination = usePagination();

	const [filters, setFilters] = useState({
		signeeName: '',
		signeeTitle: '',
		userFullName: '',
		userEmail: '',
        date: '',
        reportType: '',
		companyName: '',
		number: '',
		type: 'any',
		jurisdiction: 'any',
		publicBody: 'any',
		setupStatus: 'any',
		invitationStatus: 'any',
		tier: 'any',
		currencyCode: 'any',
	})

	const [dynamicFacets, setDynamicFacets] = useState({
		currencyCodes: [] as string[],
		jurisdictions: [] as string[],
		companyTypes: [] as string[],
	});

	useEffect(() => {
		let cancelled = false;
		const run = async () => {
			const apiResult = await api.get('admin/companiesSearchFacets');
			if(!cancelled) {
				setDynamicFacets(apiResult.data);
			}
		}
		run();

		return () => { cancelled = true }
	}, []);

    useEffect(() => {
        onSearch()
    }, [filters, pagination.params])


    const onSearch = useCleanup(() => {
		let cancelled = false;
		const run = async () => {
			const qs = new URLSearchParams({
				...filters,
				...pagination.params,
			})
			const results = await api.get('admin/reports?' + qs.toString());
			if(cancelled) {
				return;
			}
			setData(results.data.reports);
			pagination.readMeta(results.data.meta);
		}
		run();
		return () => {
			cancelled = true;
		}
    })

    return (
        <PageTemplate>
            <PageHeadings title="Reports" />
			<div className="text-white mt-8 mb-1">
				Report Filters
			</div>
			<div className="flex space-x-3">
				<div>
					<Label className="block">
						Report type
					</Label>
					<select className="rounded">
						<option>Any</option>
						<option>SECR</option>
					</select>
				</div>
				<div>
					<Label className="block">
						Signee Name
					</Label>
					<input
						type="text"
						placeholder="Jo Bloggs..."
						className="rounded"
						value={filters.signeeName}
						onChange={e => setFilters({...filters, signeeName: e.target.value})}
					/>
				</div>
				<div>
					<Label className="block">
						Signee Title
					</Label>
					<input
						type="text"
						placeholder="Director..."
						className="rounded"
						value={filters.signeeTitle}
						onChange={e => setFilters({...filters, signeeTitle :e.target.value})}
					/>
				</div>
			</div>
			<div className="text-white mt-4 mb-1">
				User Filters
			</div>
			<div className="flex space-x-3">
				<div>
					<Label className="block">
						User Full name
					</Label>
					<input
						type="text"
						placeholder="Jo Bloggs..."
						className="rounded"
						value={filters.userFullName}
						onChange={e => setFilters({...filters, userFullName :e.target.value})}
					/>
				</div>
				<div>
					<Label className="block">
						User Email
					</Label>
					<input
						type="email"
						placeholder="test@neoni-app.com..."
						className="rounded"
						value={filters.userEmail}
						onChange={e => setFilters({...filters, userEmail :e.target.value})}
					/>
				</div>
			</div>
			<div className="text-white mt-4 mb-1">
				Organisation filters
			</div>
			<div className="flex space-x-3">
				<div>
					<Label className="block">Name</Label>
					<input
						type="text"
						placeholder="iSumio..."
						className="rounded"
						value={filters.companyName}
						onChange={e => setFilters({...filters, companyName: e.target.value})}
					/>
				</div>
				<label>
					<Label className="block">Organisation Number</Label>
					<input
						type="text"
						placeholder="123..."
						className="rounded"
						value={filters.number}
						onChange={e => setFilters({...filters, number: e.target.value})}
					/>
				</label>
				<label>
					<Label className="block">Organisation Type</Label>
					<select
						className="rounded"
						value={filters.type}
						onChange={e => setFilters({...filters, type: e.target.value})}
					>
						<option value="any">Any</option>
						{ dynamicFacets.companyTypes.map( companyType => (
							<option
								key={companyType}
								value={companyType}
							>
								{ companyType || '[unset]'}
							</option>
						))}
					</select>
				</label>	
				<label>
					<Label className="block">Tier</Label>
					<select
						className="rounded"
						value={filters.tier}
						onChange={e => setFilters({...filters, tier: e.target.value})}
					>
						<option value="any">Any</option>
						<option value="micro">Micro</option>
						<option value="standard">Standard</option>
						<option value="premium">Premium</option>
					</select>
				</label>
			</div>
			<div className="flex space-x-3 my-3">
				<label>
					<Label className="block">Jurisdiction</Label>
					<select
						className="rounded inline-block"
						value={filters.jurisdiction}
						onChange={e => setFilters({...filters, jurisdiction: e.target.value})}
					>
						<option value="any">Any</option>
						{ countries.map( country => {
							if(!dynamicFacets.jurisdictions.includes(country['value'])) {
								return null;
							}
							return (
								<option
									key={country['iso_3166-2']}
									value={country.value}
								>
									{ country.label }
								</option>
							);
						})}
					</select>
				</label>
				<label>
					<Label className="block">Public Body</Label>
					<select
						className="rounded"
						value={filters.publicBody}
						onChange={e => setFilters({...filters, publicBody: e.target.value})}
					>
						<option value="any">Any</option>
						<option value="yes">Yes</option>
						<option value="no">No</option>
					</select>
				</label>
				<label>
					<Label className="block">Set Up</Label>
					<select
						className="rounded"
						value={filters.setupStatus}
						onChange={e => setFilters({...filters, setupStatus: e.currentTarget.value})}
					>
						<option value="any">Any</option>
						<option value="no">Not set up</option>
						<option value="yes">Set up</option>
					</select>
				</label>

				<label>
					<Label className="block">Invitation Status</Label>
					<select
						className="rounded bg-charcoal-200"
						value={filters.invitationStatus}
						onChange={e => setFilters({...filters, invitationStatus: e.target.value})}
						disabled
					>
						<option value="any">Any</option>
						<option>None</option>
						<option>Sent</option>
						<option>Accepted</option>
					</select>
				</label>

				<label>
					<Label className="block">Currency</Label>
					<select
						className="rounded"
						value={filters.currencyCode}
						onChange={e => setFilters({...filters, currencyCode: e.target.value})}
					>
						<option value="any">Any</option>
						{ dynamicFacets.currencyCodes.map( currencyCode => (
							<option
								key={currencyCode}
								value={currencyCode || '[unset]'}
							>
								{ currencyCode || '[unset]'}
							</option>
						))}
					</select>
				</label>

			</div>

			<div>
				<Button
					type='Primary'
					text='Search'
					size='sm'
					hasTrailingIcon
					onClick={onSearch}
					additionalClass='mt-4 mb-2'
				/>
			</div>

            <SimpleSortTable
				additionalClass="mt-4"
                columns={columns}
                data={data}

            />
            <Pagination
                pagination={pagination.pagination}
                onPageChange={pagination.onPageChange}
            />
        </PageTemplate>
    )
}

export default AdminReports;
