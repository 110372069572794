import { Dispatch } from 'redux'
import {
	SUPPLIERSTATS_ERROR,
	SUPPLIERSTATS_LOADED,
	SUPPLIERSTATS_LOADING,
} from '../slices/supplierStatisticsSlice'
import { NeoniApi } from '../../lib/api'

export const getSupplierPeriodStatistics = (api: NeoniApi, supplierId: string) => async (dispatch: Dispatch) => {
	dispatch({
		type: SUPPLIERSTATS_LOADING,
	})

	try {
		const body = await api.get(`suppliers/${supplierId}/statistics`);

		if (body.success === true) {
			// Successfully fetched supplier statistics
			dispatch({
				type: SUPPLIERSTATS_LOADED,
				payload: body.summaries,
			})
		} else {
			// Error
			dispatch({
				type: SUPPLIERSTATS_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: SUPPLIERSTATS_ERROR,
			payload: error,
		})
	}
}
