import { useState } from "react"
import { Button, LoadingSpinner } from "../../components/BaseComponents"
import InputCheckbox from "../../components/BaseComponents/InputCheckbox"
import Modal from "../../components/BaseComponents/Modal/Modal"
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings"

type SecrPublishConfirmationProps = {
    companyName: string
    userFullName: string,
    signeeName: string,
    signeeTitle: string,
    open?: boolean;
    onClose: () => void,
    onConfirm: () => void,
    loading?: boolean;
}

function SecrPublishConfirmation({companyName, userFullName, signeeName, signeeTitle, loading, open=false, onClose, onConfirm}: SecrPublishConfirmationProps) {
    const [confirmed, setConfirmed] = useState(false)
    return (
        <Modal open={open} onClose={onClose} size="small">
            <div>
                <PageHeadings title="Declaration" />

                { loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <p className="my-2 text-white">
                            I am authorised by { companyName } to publish thiscarbon emissions report. This report may be publicly accessible and made available to other Neoni users.
                        </p>
                        <p className="my-1 text-white">
                            <InputCheckbox
                                checked={confirmed}
                                onChange={e => setConfirmed(e.target.checked) }
                                className="mb-2 mr-2"
                            />
                            {' '}
                            I, {userFullName}, confirm that the information is true and complete to the best of my knowledge and that { signeeName } {signeeTitle && <>({signeeTitle})</>} has approved these accounts for publication.
                        </p>
                        <div className="mt-8 flex space-x-4">
                            <Button disabled={!confirmed} type="Primary" text="Publish" onClick={onConfirm} />
                            <Button type="Outlined" text="Cancel" onClick={onClose} />
                        </div>
                    </>
                )}

            </div>
        </Modal>
    )
}

export default SecrPublishConfirmation;
