import { FunctionComponent, useEffect, useState } from 'react'
import Select from 'react-select'
import { EmissionType } from '../../../types/EmissionType'

type IEmissionTypeInput = {
	emissionTypeData: EmissionType[]
	initialValue?: string
	onChange?: (id?: string) => void
	additionalClass?: string
	isDisabled?: boolean
}

const EmissionTypeInput: FunctionComponent<IEmissionTypeInput> = ({
	emissionTypeData,
	initialValue,
	onChange,
	additionalClass,
	isDisabled,
}) => {
	const [level3, setlevel3] = useState<string | undefined>(undefined)
	const [unit, setunit] = useState<string | undefined>(undefined)
	const [id, setId] = useState<string | undefined>(undefined)

	// Add initial value if defined
	useEffect(() => {
		if (initialValue) {
			const initialEmission = emissionTypeData.filter((emission) => emission.id === initialValue)[0]
			setlevel3(initialEmission.level3)
			setunit(initialEmission.unit)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// If there's only one unit available, autoselect it
	useEffect(() => {
		if (level3) {
			if (emissionTypeData.filter((emission) => emission.level3 === level3).length === 1) {
				setunit(emissionTypeData.filter((emission) => emission.level3 === level3)[0].unit)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [level3])

	// Resolve ID through level 3 and unit
	useEffect(() => {
		if (level3 && unit) {
			setId(
				emissionTypeData.filter(
					(emission) => emission.level3 === level3 && emission.unit === unit
				)[0].id
			)
		} else {
			setId(undefined)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [level3, unit])

	useEffect(() => {
		if (onChange) {
			onChange(id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<div className={`flex flex-wrap ${additionalClass} text-charcoal-600`}>
			<div className='min-w-[250px]'>
				<Select
					className='mr-2'
					key={`level3select_${level3}`}
					options={[...new Set(emissionTypeData.map((emission) => emission.level3))].map((l3) => {
						return {
							value: l3,
							label: l3,
						}
					})}
					placeholder='Choose a value'
					isSearchable={true}
					isDisabled={isDisabled}
					isClearable={true}
					value={
						level3
							? {
								value: level3,
								label: level3,
							}
							: undefined
					}
					onChange={(option) => {
						if (option) {
							// Set level3 selection
							setlevel3(option.value)
							setunit(undefined)
						} else {
							// Remove lower selections
							setlevel3(undefined)
							setunit(undefined)
						}
					}}
				/>
			</div>
			{level3 && (
				<Select
					key={`unitselect_${unit}`}
					defaultValue={undefined}
					options={
						level3
							? emissionTypeData
								?.filter((emission) => emission.level3 === level3)
								.map((emissionunit) => {
									return {
										value: emissionunit.unit,
										label: emissionunit.unit,
									}
								})
							: undefined
					}
					isDisabled={!level3 || isDisabled}
					placeholder='Unit'
					isSearchable={false}
					value={
						unit
							? {
								value: unit,
								label: unit,
							}
							: undefined
					}
					onChange={(option) => {
						if (option) {
							// Add to selected
							setunit(option.value)
						} else {
							// Remove associated selection
							setunit(undefined)
						}
					}}
				/>
			)}
		</div>
	)
}

export default EmissionTypeInput
