import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { LoadingSpinner } from '../../../components/BaseComponents'
import PageHeadings from '../../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../../components/BaseComponents/PageTemplate/PageTemplate'
import TabList from '../../../components/BaseComponents/TabList/TabList'
import routes from '../../../components/Router/routes'
import { getPeriodStatistics } from '../../../redux/actions/periodStatisticsActions'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/reduxHooks'
import {
	PERIODSTATS_RESET,
	selectAllPeriodStatistics,
	selectPeriodStatisticsStatus,
} from '../../../redux/slices/periodStatisticsSlice'
import { FetchStatus } from '../../../types/LoadingStates'
import SupplierInsightsEmissionQualityGraph from '../Suppliers/Trends/Graphs/QualityRating'
import CompanyInsightsOverviewGraph from './Graphs/Overview'
import CompanyInsightsScope1Graph from './Graphs/Scope1'
import CompanyInsightsScope2Graph from './Graphs/Scope2'
import CompanyInsightsScope3Graph from './Graphs/Scope3'
import useApi from '../../../hooks/useApi'

const CompanyInsightsTrends = () => {
	const dispatch = useAppDispatch()
	const api = useApi();

	const endpointStatus = useAppSelector(selectPeriodStatisticsStatus)
	const allPeriodStatistics = useAppSelector(selectAllPeriodStatistics)

	// Reset data
	useEffect(() => {
		dispatch({ type: PERIODSTATS_RESET })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Load accounting periods
	useEffect(() => {
		if (endpointStatus === FetchStatus.initial) {
			dispatch(getPeriodStatistics(api))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endpointStatus])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyInsightsTrends.name}</title>
			</Helmet>

			<PageHeadings title='Insights' subTitle='Carbon Account Trends' />

			{endpointStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading your statistics...' />
			)}

			{endpointStatus === FetchStatus.loaded && (
				<>
					{allPeriodStatistics.length > 0 ? (
						<TabList
							tabDescription='Emission breakdowns by scope'
							additionalPanelClass='p-4'
							tabs={[
								{
									label: 'Total Emissions by Scopes',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>
												Total Emissions by Scopes
											</h2>
											<div className='p-4 max-w-6xl'>
												{active && <CompanyInsightsOverviewGraph chartData={allPeriodStatistics} />}
											</div>
										</>
									),
								},
								{
									label: 'Scope 1',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>Scope 1 Breakdown</h2>
											<div className='p-4 max-w-6xl'>
												{active && <CompanyInsightsScope1Graph chartData={allPeriodStatistics} />}
											</div>
										</>
									),
								},
								{
									label: 'Scope 2',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>Scope 2 Breakdown</h2>
											<div className='p-4 max-w-6xl'>
												{active && <CompanyInsightsScope2Graph chartData={allPeriodStatistics} />}
											</div>
										</>
									),
								},
								{
									label: 'Scope 3 by Sector',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>
												Scope 3 Breakdown by Sector
											</h2>
											<div className='p-4 max-w-6xl'>
												{active && <CompanyInsightsScope3Graph chartData={allPeriodStatistics} />}
											</div>
										</>
									),
								},
								{
									label: 'Data Quality',
									component: (active) => (
										<>
											<h2 className='text-xl text-white font-bold mb-4'>
												Data Quality
											</h2>
											<div className='p-4 max-w-6xl'>
												{active && <SupplierInsightsEmissionQualityGraph chartData={allPeriodStatistics} />}
											</div>
										</>
									),
								},
							]}
							additionalContainerClass='mt-8'
						/>
					) : (
						<div className='rounded-lg bg-charcoal-600 p-4 mt-8'>
							<p className='text-white'>
								Sorry! We couldn't find any completed accounting periods to compare
							</p>
						</div>
					)}
				</>
			)}

			{endpointStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching accounting periods associated with this organisation. If this
						problem persists, please contact the Neoni team.
					</p>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyInsightsTrends
