import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { PeriodStatistic } from '../../../../types/PeriodStatistic'

type ICompanyInsightsOverviewGraph = {
	chartData: Array<PeriodStatistic>
}

const CompanyInsightsOverviewGraph: FunctionComponent<ICompanyInsightsOverviewGraph> = ({
	chartData,
}) => {
	const allScopesChartData = chartData
		.map((period) => {
			return {
				name: `${dayjs(period.startdate).format('MM/YYYY')} - ${dayjs(period.endDate).format(
					'MM/YYYY'
				)}`,
				// @ts-ignore
				scope1: period.statistics.byScope[0].sum.toFixed(2),
				// @ts-ignore
				scope2: period.statistics.byScope[1].sum.toFixed(2),
				// @ts-ignore
				scope3: period.statistics.byScope[2].sum.toFixed(2),
			}
		})
		.reverse()

	return (
		<ResponsiveContainer width='100%' aspect={1.778}>
			<BarChart
				width={500}
				height={300}
				data={allScopesChartData}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<XAxis
					dataKey='name'
					axisLine={false}
					tickLine={false}
					style={{ fontSize: '12px' }}
					tick={{ fill: '#FFF' }}
				/>
				<YAxis
					label={{ value: 'kgC02e', angle: -90, position: 'left', fill: '#FFF' }}
					axisLine={false}
					tickLine={false}
					style={{ fontSize: '12px' }}
					tick={{ fill: '#FFF' }}
				/>
				<Tooltip
					contentStyle={{ backgroundColor: '#2E3A46', borderColor: '#252F38', borderWidth: 2 }}
					labelStyle={{ color: 'white' }}
					cursor={{ fill: '#12171C' }}
				/>
				<Legend
					align='right'
					verticalAlign='top'
					layout='vertical'
					formatter={(value) => <span className='text-white ml-2 text-sm'>{value}</span>}
				/>

				<Bar dataKey='scope1' stackId='a' fill='#FFE0CE' name='Scope 1' barSize={48} />
				<Bar dataKey='scope2' stackId='a' fill='#FFA675' name='Scope 2' barSize={48} />
				<Bar dataKey='scope3' stackId='a' fill='#FF7426' name='Scope 3' barSize={48} />
			</BarChart>
		</ResponsiveContainer>
	)
}

export default CompanyInsightsOverviewGraph
