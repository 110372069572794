import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invitation } from '../../types/Invitation'
import { PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type CreateInvitationsState = {
	status: PostStatus
	error?: string
	invite?: Invitation
	email?: string
	companyId?: string
	sendEmail?: boolean
}
type invitationToSend = {
	email?: string
	companyId?: string
	sendEmail?: boolean
}
const createInvitationsInitialState: CreateInvitationsState = {
	status: PostStatus.initial,
	error: undefined,
	invite: undefined,
	email: '',
	companyId: '',
	sendEmail: true,
}

export const createInvitationsSlice = createSlice({
	name: 'createInvitations',
	initialState: createInvitationsInitialState,
	reducers: {
		INVITATIONS_CREATE_SENDING: (state) => {
			return {
				...state,
				status: PostStatus.sending,
			}
		},
		INVITATIONS_CREATE_SENT: (state, action: PayloadAction<Invitation>) => {
			return {
				...state,
				status: PostStatus.sent,
				invite: action.payload,
			}
		},
		INVITATIONS_CREATE_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.error,
				error: action.payload,
			}
		},
		INVITATIONS_CREATE_RESET: () => {
			return createInvitationsInitialState
		},
		INVITATION_SETEMAIL: (state, action: PayloadAction<invitationToSend>) => {
			return {
				...state,
				email: action.payload.email,
				companyId: action.payload.companyId,
				sendEmail: action.payload.sendEmail,
			}
		},
		INVITATION_RESETEMAIL: (state) => {
			return {
				...state,
				email: undefined,
				companyId: undefined,
				sendEmail: true,
			}
		},
	},
})

// Action types
export const {
	INVITATIONS_CREATE_SENDING,
	INVITATIONS_CREATE_SENT,
	INVITATIONS_CREATE_ERROR,
	INVITATIONS_CREATE_RESET,
	INVITATION_SETEMAIL,
	INVITATION_RESETEMAIL,
} = createInvitationsSlice.actions

// Selectors
export const selectCreateInvitationsStatus = (state: RootState) => state.createInvitations.status
export const selectCreateInvicationsError = (state: RootState) => state.createInvitations.error
export const selectCreateInvitation = (state: RootState) => state.createInvitations.invite
export const selectCreateInviteEmail = (state: RootState) => state.createInvitations.email
export const selectCreateInviteCompanyID = (state: RootState) => state.createInvitations.companyId
export const selectCreateInviteSendEmail = (state: RootState) => state.createInvitations.sendEmail

export default createInvitationsSlice.reducer
