import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import { formatDate, getCSRFToken } from "../../lib/utils";
import { PaginationMeta } from "../../types/PaginationMeta";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import { Button } from "../../components/BaseComponents";
import useDataSort from "../../hooks/useDataSort";
import usePagination from "../../hooks/usePagination";
import useCleanup from "../../hooks/useCleanup";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";

type AdminCompanyUsersProps = {
    companyId: string;
}

function AdminCompanyUsers({ companyId }: AdminCompanyUsersProps) {
    const [results, setResults] = useState<any[]>([])

    const [loading, setLoading] = useState(false);
    const dataSort = useDataSort('updated_at', 'desc');
    const pagination = usePagination();

    const [deletionCandidate, setDeletionCandidate] = useState<any>(null);

    const api = useApi();
    const onSearch = useCleanup(() => {
        let cancelled = false;
        
        const run = async () => {
            setLoading(true);
            const qs = new URLSearchParams({
                ...pagination.params,
                ...dataSort.params,
            })
            const apiResult = await api.get(`/admin/company/${companyId}/users?` + qs.toString());
            if(cancelled) {
                return;
            }
            setResults(apiResult.data.data);
            pagination.readMeta(apiResult.data.meta);
            setLoading(false);
        }
        run()
        return () => {
            console.log('cancel')
            cancelled = true;
        }
    });

    const onLockUser = async (userId: string, locked: boolean) => {
        setLoading(true);
        await api.put(`/admin/user/${userId}`, {
            user: {
                adminLocked: locked,
            },
            _csrf: await getCSRFToken(),
        })
        await onSearch();
    }

    const onDeleteUser = async (userId: string) => {
        setLoading(true);
        setDeletionCandidate(null);
        await api.delete(`/admin/user/${userId}`, {
            _csrf: await getCSRFToken(),
        })
        await onSearch();
    }

    useEffect(() => {
        onSearch();
    }, [pagination.params, dataSort.params])
    
    const dateRenderer = (date: string) => {
        return <span title={date}>{ formatDate(date) }</span>
    }
    const columns: TableColumn[] = [{
        id: 'full_name',
        label: 'Full Name',
        sortable: true,
    },{
        id: 'email',
        label: 'Email',
        sortable: true,
    }, {
        id: 'role.',
        label: 'Role',
        renderer: (key, row) => <span>{ row.role.name }</span>
    }, {
        id: 'email_confirmed',
        label: 'Email Confirmed',
        renderer: (key, row) => <span>{ row[key] ? 'Yes' : 'No' }</span>,
        sortable: true,
    }, {
        id: 'created_at',
        label: 'Created',
        renderer: (key, row) => dateRenderer(row[key]),
        sortable: true,
    }, {
        id: 'updated_at', 
        label: 'Updated At',
        renderer: (key, row) => dateRenderer(row[key]),
        sortable: true,
    }, {
        id: 'admin_locked',
        label: 'Locked',
        renderer: (key, row) => {
            const userId = row['id'];
            const isLocked = row['admin_locked'];
            return (
                <Button
                    type="Primary"
                    size="sm"
                    text={`${isLocked ? 'Unlock' : 'Lock'} User`}
                    onClick={() => onLockUser(userId, !isLocked)}
                />
            );
        },
        sortable: true,
    }, {
        id: 'delete',
        label: 'Delete',
        renderer: (key, row) => {
            const userId = row['id'];
            return (
                <Button
                    type="Primary"
                    size="sm"
                    text="Delete user"
                    onClick={() => setDeletionCandidate(row)}
                />
            );
        },
        sortable: true,
    }]
    return (
        <div>
            <h3 className="text-white mb-3 mt-8">Users</h3>
            <SimpleSortTable
                columns={columns}
                data={results}
                isLoading={loading}
				defaultSorting={dataSort.defaultSorting}
				onSortPress={dataSort.onChange}
            />
			<Pagination 
				onPageChange={pagination.onPageChange}
				pagination={pagination.pagination}
                isLoading={loading}
			/>
            { deletionCandidate && (
                <Modal open={!!deletionCandidate} onClose={() => setDeletionCandidate(null)} size="small">
                    <PageHeadings title="Delete user?"/>
                    <p className="text-white mt-4">User name:{ deletionCandidate.full_name}</p>
                    <p className="text-white">User email:{ deletionCandidate.email}</p>
                    <p className="text-white">This will irreversibly anonymise the name and email and lock the user.</p>
                    <div className="flex mt-4">
                        <Button
                            type="Primary"
                            text="Confirm"
                            onClick={() => onDeleteUser(deletionCandidate.id)}
                            additionalClass="mr-4"
                        />
                        <Button type="Secondary" text="Cancel" onClick={() => setDeletionCandidate(null)} />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default AdminCompanyUsers;
