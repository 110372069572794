import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type UpdateItemsSlice = {
	status: PostStatus
	error?: string
	message?: string
}

const UpdateItemsInitialState: UpdateItemsSlice = {
	status: PostStatus.initial,
	error: undefined,
	message: undefined,
}

export const updateItemsSlice = createSlice({
	name: 'Update Items',
	initialState: UpdateItemsInitialState,
	reducers: {
		UPDATEITEMS_SENDING: (state) => {
			return {
				...state,
				status: PostStatus.sending,
				message: undefined,
			}
		},
		UPDATEITEMS_SENT: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.sent,
				error: undefined,
				message: action.payload,
			}
		},
		UPDATEITEMS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.error,
				error: action.payload,
			}
		},
		UPDATEITEMS_RESET: () => {
			return UpdateItemsInitialState
		},
	},
})

// Action types
export const { UPDATEITEMS_SENDING, UPDATEITEMS_SENT, UPDATEITEMS_ERROR, UPDATEITEMS_RESET } =
	updateItemsSlice.actions

// Selectors
export const selectUpdateItemsStatus = (state: RootState) => state.updateItems.status
export const selectUpdateItemsError = (state: RootState) => state.updateItems.error
export const selectUpdateItemsMessage = (state: RootState) => state.updateItems.message

export default updateItemsSlice.reducer
