import { FunctionComponent, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks'
import {
	selectAccountingPeriods,
	selectAccountingPeriodsError,
	selectAccountingPeriodsStatus,
	selectAccountingPeriodsUpdateStatus,
} from '../../redux/slices/accountingPeriodsSlice'
import { SelectTypeOption } from '../../types/SelectTypeOptions'
import {
	selectCarbonSummary,
	selectCarbonSummaryError,
	selectCarbonSummaryStatus,
	selectTotalEmissionsSummary,
} from '../../redux/slices/carbonAccountSummarySlice'
import { getCarbonSummaryData } from '../../redux/actions/carbonAccountSummaryAction'
import { getAccountingPeriods } from '../../redux/actions/accountingPeriodsActions'
import { FetchStatus, PostStatus } from '../../types/LoadingStates'
import routes from '../../components/Router/routes'
import { centsToCurrency, formatEmissionCO2Kg } from '../../lib/utils'
import Button from '../../components/BaseComponents/Button/Button'
import { SortConfig } from '../../components/BaseComponents/Datagrid/useSortableData'
import { Input } from '../../components/BaseComponents'
import Pagination from '../../components/BaseComponents/Pagination/Pagination'
import {
	getPurchases,
	GET_PURCHASES_CARBON_ACCOUNTS_LIMIT,
} from '../../redux/actions/carbonAccountsPurchasesActions'
import {
	selectAllpurchasesCarbonData,
	selectpurchasesCarbonDataError,
	selectpurchasesCarbonDataPagination,
	selectpurchasesCarbonDataStatus,
} from '../../redux/slices/carbonAccountsPurchasesSlice'
import SimpleSortTable, {
	TableColumn,
} from '../../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import { suppliersCarbonData_RESET } from '../../redux/slices/carbonAccountsSupplier'
import { useEffectWithoutInitialRender } from '../../hooks/useEffectWithoutInitialRender'
import { PurchaseCarbonData } from '../../types/PurchaseCarbonData'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import useCurrencyFormatter from '../../hooks/useCurrencyFormatter'
import useApi from '../../hooks/useApi'

type ICompanyCarbonAccountsPurchases = {
	setPageMode: (newMode: 'purchases' | 'suppliers') => void
	setPurchasesTab: (tab: string) => void
	purchasesTab: string
}

export const CompanyCarbonAccountsPurchases: FunctionComponent<ICompanyCarbonAccountsPurchases> = ({
	setPageMode,
	setPurchasesTab,
	purchasesTab,
}) => {
	const formatCurrency = useCurrencyFormatter();
	const limit = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT
	const [scope, setScope] = useState('')
	const [orderBy, setOrderBy] = useState<string>('totalEmissions')
	const [direction, setDirection] = useState<'asc' | 'desc' | undefined>('desc')
	const [companyId, setCompanyId] = useState('')
	const [accountingPeriodIdVal, setAccountingPeriodIdVal] = useState('')

	const dispatch = useAppDispatch()
	const api = useApi();
	const navigate = useNavigate()
	const { accountingPeriodId } = useParams()
	const accountingPeriodsStatus = useAppSelector(selectAccountingPeriodsStatus)
	const allAccountingPeriodData =
		accountingPeriodId !== undefined
			? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAppSelector(selectAccountingPeriods)
			: // eslint-disable-next-line react-hooks/rules-of-hooks
			useAppSelector(selectAccountingPeriods).filter(
				(period) => period.status !== 'WAITING_FOR_UPLOAD' && period.upload_state !== null
			)
	const [checkCompletion, setCheckCompletion] = useState(true)
	const accountingPeriodsError = useAppSelector(selectAccountingPeriodsError)
	const [errorNoPeriod, setErrorNoPeriod] = useState(false)
	const [accountingTypeOptions, setaccountingTypeOptions] = useState<Array<SelectTypeOption>>([])
	const [thisAccountingPeriod, setthisAccountingPeriod] = useState(
		allAccountingPeriodData.filter(
			(period) => period.id === allAccountingPeriodData[allAccountingPeriodData.length - 1].id
		)[0]
	)

	const purchasesCarbonAccountsStatus = useAppSelector(selectpurchasesCarbonDataStatus)
	const purchasesCarbonAccountsError = useAppSelector(selectpurchasesCarbonDataError)
	const purchasesCarbonAccountsData = useAppSelector(selectAllpurchasesCarbonData)
	const pagination = useAppSelector(selectpurchasesCarbonDataPagination)

	const carbonSummaryStatus = useAppSelector(selectCarbonSummaryStatus)
	const carbonSummaryError = useAppSelector(selectCarbonSummaryError)
	const carbonSummary = useAppSelector(selectCarbonSummary)
	const totalCarbonSummary = useAppSelector(selectTotalEmissionsSummary)

	const scope1Percentage = Math.round(Number(carbonSummary.scope1Completion)) || 0
	const scope2Percentage = Math.round(Number(carbonSummary.scope2Completion)) || 0
	const scope3Percentage = Math.round(Number(carbonSummary.scope3Completion)) || 0
	const totalPercentage = Math.round(Number(carbonSummary.totalCompletion)) || 0

	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	const updateStatus = useAppSelector(selectAccountingPeriodsUpdateStatus)
	const isLoading =
		accountingPeriodsStatus === FetchStatus.loading ||
		carbonSummaryStatus === FetchStatus.loading ||
		purchasesCarbonAccountsStatus === FetchStatus.loading ||
		updateStatus === PostStatus.sending

	//tabs
	const tabslist = [
		{ name: 'Overview', href: '#', current: true },
		{ name: 'Scope 1 - Direct emissions', href: '#', current: false },
		{ name: 'Scope 2 - Indirect emissions', href: '#', current: false },
		{ name: 'Scope 3 - Indirect emissions', href: '#', current: false },
	]
	const [tab, setTab] = useState('Overview')
	const [tabs, setTabs] = useState<Array<any>>(tabslist)

	const updateTab = (value: string) => {
		let scopeVal = ''
		if (value === 'Overview') {
			scopeVal = ''
		} else if (value === 'Scope 1 - Direct emissions') {
			scopeVal = '1'
		} else if (value === 'Scope 2 - Indirect emissions') {
			scopeVal = '2'
		} else if (value === 'Scope 3 - Indirect emissions') {
			scopeVal = '3'
		}
		setScope(scopeVal)
		setTab(value)
		let newtabs = [...tabs]
		// eslint-disable-next-line array-callback-return
		newtabs.map((tab) => {
			if (tab.name === value) {
				tab.current = true
			} else {
				tab.current = false
			}
		})
		setTabs(newtabs)
		dispatch(
			getPurchases(api, {
				companyId,
				accountingPeriodId: accountingPeriodIdVal,
				page: pagination.first_page,
				limit,
				orderBy,
				direction,
				scope: scopeVal,
				q: searchKeyword,
			})
		)
	}
	useEffect(() => {
		if (purchasesTab !== '' && companyId !== '' && accountingPeriodIdVal !== '') {
			updateTab(purchasesTab)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purchasesTab, companyId, accountingPeriodIdVal])

	// Select accounting period that is in URL
	useEffect(() => {
		if (accountingPeriodId !== undefined) {
			allAccountingPeriodData.filter((item) => item.id === accountingPeriodId)[0]?.status !== 'WAITING_FOR_UPLOAD'
				? setCheckCompletion(true)
				: setCheckCompletion(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allAccountingPeriodData])

	// Timeout for search
	useEffectWithoutInitialRender(() => {
		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			if (companyId !== '' && accountingPeriodIdVal !== '') {
				dispatch(
					getPurchases(api, {
						companyId,
						accountingPeriodId: accountingPeriodIdVal,
						page: pagination.first_page,
						limit,
						orderBy,
						direction,
						scope,
						q: searchKeyword,
					})
				)
			}
			setSearchInProgress(false)
		}, 1000)

		if (searchKeyword !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchKeyword])

	// Change selected accounting period
	const onchangeSelect = (event: any) => {
		setthisAccountingPeriod(
			allAccountingPeriodData.filter((period) => period.id === event.value)[0]
		)
		setCompanyId(event.companyid)
		setAccountingPeriodIdVal(event.value)
		dispatch(
			getPurchases(api, {
				companyId: event.companyid,
				accountingPeriodId: event.value,
				page: pagination.first_page,
				limit,
				orderBy,
				direction,
				scope,
				q: searchKeyword,
			})
		)
		dispatch(getCarbonSummaryData(api, event.value))
	}

	// Initial data loading
	useEffect(() => {
		// Fetch accounting periods if they aren't in the store, we need to compare against the IDs
		if (accountingPeriodsStatus === FetchStatus.initial) {
			dispatch(getAccountingPeriods(api))
		}

		// // Reset the purchases store if it's currently filled
		if (purchasesCarbonAccountsStatus === FetchStatus.loaded) {
			dispatch({
				type: suppliersCarbonData_RESET,
			})
		}

		if (accountingPeriodsStatus === FetchStatus.loaded) {
			setaccountingTypeOptions(
				allAccountingPeriodData
					.filter((period) => period.status !== 'WAITING_FOR_UPLOAD')
					.map((period) => {
						return {
							value: period.id,
							label: `${period.start_date} - ${period.end_date}`,
							companyid: period.company_id,
						}
					})
			)
			if (accountingPeriodId !== null && accountingPeriodId !== undefined) {
				setthisAccountingPeriod(
					allAccountingPeriodData.filter((period) => period.id === accountingPeriodId)[0]
				)
				const currAccountingPeriod = allAccountingPeriodData.filter(
					(period) => period.id === accountingPeriodId
				)
				// If there's no accounting period defined, the url parameter is probably wrong, show an error
				if (typeof currAccountingPeriod !== 'undefined') {
					setCompanyId(currAccountingPeriod[0].company_id)
					setAccountingPeriodIdVal(currAccountingPeriod[0].id)
					dispatch(
						getPurchases(api, {
							companyId: currAccountingPeriod[0].company_id,
							accountingPeriodId: currAccountingPeriod[0].id,
							page: pagination.first_page,
							limit,
							orderBy,
							direction,
							scope,
							q: searchKeyword,
						})
					)
					dispatch(getCarbonSummaryData(api, currAccountingPeriod[0].id))
				} else {
					setErrorNoPeriod(true)
				}
			} else {
				setthisAccountingPeriod(
					allAccountingPeriodData.filter(
						(period) => period.id === allAccountingPeriodData[allAccountingPeriodData.length - 1].id
					)[0]
				)
				// If there's no accounting period defined, the url parameter is probably wrong, show an error
				if (typeof allAccountingPeriodData[allAccountingPeriodData.length - 1] !== 'undefined') {
					setCompanyId(allAccountingPeriodData[allAccountingPeriodData.length - 1].company_id)
					setAccountingPeriodIdVal(allAccountingPeriodData[allAccountingPeriodData.length - 1].id)

					dispatch(
						getPurchases(api, {
							companyId: allAccountingPeriodData[allAccountingPeriodData.length - 1].company_id,
							accountingPeriodId: allAccountingPeriodData[allAccountingPeriodData.length - 1].id,
							page: pagination.first_page,
							limit,
							orderBy,
							direction,
							scope,
							q: searchKeyword,
						})
					)
					dispatch(
						getCarbonSummaryData(api, allAccountingPeriodData[allAccountingPeriodData.length - 1].id)
					)
				} else {
					setErrorNoPeriod(true)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountingPeriodsStatus])

	const updateValue = (rowData: any) => {
		navigate(generatePath(routes.companyItemEdit.path, { itemId: rowData._id}))
	}

	// DataGrid Columns
	const columns: TableColumn[] = [
		{ id: 'itemName', label: 'Item Name', sortable: true },
		{ id: 'level1', label: 'Emission Type', sortable: true },
		{
			id: 'totalSpend',
			label: 'Total Spend',
			textAlign: 'right',
			sortable: true,
			renderer: (columnKey, rowData) => {
				return <span className=' font-medium text-white '>{formatCurrency(rowData.totalSpend)}</span>
			},
		},
		{
			id: 'totalEmissions',
			label: 'CO₂e Emissions',
			textAlign: 'right',
			sortable: true,
			renderer: (columnKey, rowData) => {
				return (
					<span className='space-x-2.5 font-medium text-white '>
						{rowData.scope === 3 ? (
							<>
								{rowData.estimationType === 'reference_data' && (
									<>
										{' '}
										<span
											className='font-extrabold'
											id={'my' + rowData._id}
											data-tooltip-content='We have estimated these emissions using reference data'
										>
											≈
										</span>
										<ReactTooltip variant='light' anchorId={'my' + rowData._id} />
									</>
								)}
							</>
						) : null}{' '}
						{rowData.totalEmissions && <span>{formatEmissionCO2Kg(rowData.totalEmissions)} </span>}
					</span>
				)
			},
		},
		{
			id: 'refineData',
			label: '',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						{rowData.emission_level1 === null && rowData.scope === 1 ? (
							<Button
								type={'Primary'}
								onClick={() => updateValue(rowData)}
								icon={'fa-regular fa-plus'}
								text={'Add Emission Type'}
								accessibilityLabel={' ' + rowData.name}
								size='xs'
								hasLeadingIcon={true}
							/>
						) : (
							null
							// <Button
							// 	type={'Link'}
							// 	onClick={() => updateValue(rowData)}
							// 	icon={'fa-regular fa-pen-to-square'}
							// 	text={'Edit'}
							// 	accessibilityLabel={' ' + rowData.name}
							// 	size='xs'
							// 	hasLeadingIcon={true}
							// />
						)}
					</>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'totalEmissions',
		direction: 'descending',
	}

	function formatToIncludeCommas(currency: number | string) {
		if (typeof currency === 'number') {
			currency = currency.toString()
		}
		return currency.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}

	// DataGrid data constructor
	const constructData = (dataSet: Array<PurchaseCarbonData>) => [
		...dataSet.map((item) => {
			return {
				_id: item.itemId,
				itemName: item.itemName,
				totalEmissions: item.totalEmissions,
				totalSpend: centsToCurrency(item.totalSpend),
				scope: Number(item.scope),
				emission_level1: item.level1,
				estimationType: item.estimationType,
				level1:
					Number(item.scope) === 1
						? item.level1 !== undefined && item.level1 !== null
							? item.level1 + ' (Scope 1)'
							: 'Scope 1'
						: Number(item.scope) === 2
							? 'Electricity (Scope 2)'
							: 'Value chain (Scope 3)',
			}
		}),
	]

	return (
		<>
			<div className='mt-5 flex space-x-5'>
				<div>
					<span className='text-charcoal-100'>Select Reporting Period</span>
					<Select
						options={accountingTypeOptions}
						placeholder='DD/MM/YY - DD/MM/YY'
						onChange={(option) => {
							onchangeSelect(option)
						}}
						value={accountingTypeOptions.filter(function (option) {
							return option.value === thisAccountingPeriod.id
						})}
					/>
				</div>

				<div className='items-start mt-5 w-1/5'>
					<div className='relative max-w-2xl'>
						<label htmlFor='filterPurchases' className='sr-only'>
							Filter purchases
						</label>
						<Input
							type={'text'}
							name={'filterPurchases'}
							placeholder='Search'
							value={searchKeyword}
							onChange={(event: any) => {
								setSearchKeyword(event.target.value)
							}}
						/>
						<Button
							hasLeadingIcon
							type='White'
							size='sm'
							icon={` ${searchInProgress
								? 'fas fa-loader fa-spin'
								: searchKeyword.length > 0
									? 'fa-solid fa-times'
									: 'fa-solid fa-search'
								}`}
							additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
							onClick={() => {
								setSearchKeyword('')
								dispatch(
									getPurchases(api, {
										companyId,
										accountingPeriodId: accountingPeriodIdVal,
										page: pagination.first_page,
										limit,
										orderBy,
										direction,
										scope,
										q: '',
									})
								)
							}}
						/>
					</div>
				</div>

				<div className='pl-2 mt-4 text-white flex items-center space-y-0 space-x-8'>
					<div className='flex items-center'>
						<input
							id='supplier'
							name='notification-method'
							type='radio'
							onChange={() => {
								setPurchasesTab(tab)
								setPageMode('suppliers')
							}}
							defaultChecked={false}
							className='h-4 w-4 border-charcoal-300 text-algae-600 focus:ring-algae-600'
						/>
						<label htmlFor='supplier' className='ml-3 block text-sm font-medium text-white'>
							Suppliers
						</label>
					</div>
					<div className='flex items-center'>
						<input
							id='purchase'
							name='notification-method'
							type='radio'
							defaultChecked={true}
							onChange={() => { }}
							className='h-4 w-4 border-charcoal-300 text-algae-600 focus:ring-algae-600'
						/>
						<label htmlFor='purchase' className='ml-3 block text-sm font-medium text-white'>
							Items
						</label>
					</div>
				</div>
				<div className="grow flex justify-end items-center">
					<Button
						type="Primary"
						text="Publish Accounts"
						onClick={() =>{ 
							navigate(routes.myReportsCreate.path, { state: { periodId: thisAccountingPeriod.id }})
						}}
					/>
				</div>
			</div>
			{/* header totals */}
			{carbonSummaryStatus === FetchStatus.loaded && (
				<div
					className={`rounded-lg mt-5 grid ${tab === 'Overview' ? 'grid-cols-5' : 'grid-cols-3'}`}
				>
					<div className='p-4 rounded-l bg-white flex justify-between flex-col'>
						<div>
							<p className='text-xs text-charcoal-500 font-semibold'>TOTAL EMISSIONS</p>
							<p>
								<span className='text-2xl text-charcoal-900 font-semibold'>
									{totalCarbonSummary !== null ? formatToIncludeCommas(totalCarbonSummary) : 0}
								</span>{' '}
								<span className='text-sm text-charcoal-400 font-medium'>kgC02e</span>
							</p>
						</div>
						<div className='align-bottom px-3 py-2 bg-success-100 rounded-full text-center mt-4'>
							<p className='text-sm text-charcoal-600 font-medium'>{totalPercentage}% complete</p>
						</div>
					</div>
					<div className='p-4 bg-ozone-050'>
						<p className='text-xs text-charcoal-500 font-semibold'>INTENSITY RATIO</p>
						<p>
							<span className='text-2xl text-charcoal-900 font-semibold'>
								{carbonSummary.localIntensityRatio !== null
									? Number(carbonSummary.localIntensityRatio).toFixed(6)
									: 0}
							</span>{' '}
							<span className='text-sm text-charcoal-400 font-medium'>kgC02e / Turnover</span>
						</p>
					</div>
					{(tab === 'Scope 1 - Direct emissions' || tab === 'Overview') && (
						<div className='p-4 bg-sun-100 flex justify-between flex-col'>
							<div>
								<p className='text-xs text-charcoal-500 font-semibold'>SCOPE 1 TOTAL</p>
								<p>
									<span className='text-2xl text-charcoal-900 font-semibold'>
										{carbonSummary.scope1Total !== null
											? formatToIncludeCommas(carbonSummary.scope1Total)
											: 0}
									</span>{' '}
									<span className='text-sm text-charcoal-400 font-medium'>kgC02e</span>
								</p>
							</div>
							<div className='align-bottom px-3 py-2 bg-success-100 rounded-full text-center mt-4'>
								<p className='text-sm text-charcoal-600 font-medium'>
									{scope1Percentage}% complete
								</p>
							</div>
						</div>
					)}
					{(tab === 'Scope 2 - Indirect emissions' || tab === 'Overview') && (
						<div className='p-4 bg-sun-300 flex justify-between flex-col'>
							<div>
								<p className='text-xs text-charcoal-500 font-semibold'>SCOPE 2 TOTAL</p>
								<p>
									<span className='text-2xl text-charcoal-900 font-semibold'>
										{carbonSummary.scope2Total !== null
											? formatToIncludeCommas(carbonSummary.scope2Total)
											: 0}
									</span>{' '}
									<span className='text-sm text-charcoal-400 font-medium'>kgC02e</span>
								</p>
							</div>
							<div className='align-bottom px-3 py-2 bg-success-100 rounded-full text-center mt-4'>
								<p className='text-sm text-charcoal-600 font-medium'>
									{scope2Percentage}% complete
								</p>
							</div>
						</div>
					)}
					{(tab === 'Scope 3 - Indirect emissions' || tab === 'Overview') && (
						<div className='p-4 rounded-r bg-sun-500 flex justify-between flex-col'>
							<div>
								<p className='text-xs text-charcoal-500 font-semibold'>SCOPE 3 TOTAL</p>
								<p>
									<span className='text-2xl text-charcoal-900 font-semibold'>
										{carbonSummary.scope3Total !== null
											? formatToIncludeCommas(carbonSummary.scope3Total)
											: 0}
									</span>{' '}
									<span className='text-sm text-charcoal-400 font-medium'>kgC02e</span>
								</p>
							</div>
							<div className='align-bottom px-3 py-2 bg-success-100 rounded-full text-center mt-4'>
								<p className='text-sm text-charcoal-600 font-medium'>
									{scope3Percentage}% complete
								</p>
							</div>
						</div>
					)}
				</div>
			)}
			{carbonSummaryStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the carbon summary for this accounting period (
						{thisAccountingPeriod.id}). If this problem persists, please contact the Neoni team.
					</p>
					<p className='text-white'>{carbonSummaryError}</p>
				</div>
			)}
			{/* tabs */}
			<div>
				<div className='hidden sm:block'>
					<nav className='-mb-px flex' aria-label='Tabs'>
						{tabs.map((tab) => (
							<a
								key={tab.name}
								href={tab.href}
								className={classNames(
									tab.current
										? 'border-algae-200 text-white border-b-2'
										: 'border-transparent text-charcoal-200',
									'w-1/4 py-4 px-1 text-center font-medium text-sm'
								)}
								onClick={(e) => {
									e.preventDefault()
									updateTab(tab.name)
								}}
							>
								{tab.name}
							</a>
						))}
					</nav>
				</div>
			</div>
			<SimpleSortTable
				isLoading={isLoading}
				additionalClass='mt-4'
				data={constructData(purchasesCarbonAccountsData)}
				columns={columns}
				defaultSorting={defaultSorting}
				onSortPress={(key, direction) => {
					// Store current sorting values for pagination to reference in its API calls
					setOrderBy(key)
					setDirection(direction === 'ascending' ? 'asc' : 'desc')
					dispatch(
						getPurchases(api, {
							companyId,
							accountingPeriodId: accountingPeriodIdVal,
							page: pagination.current_page,
							limit,
							orderBy: key,
							direction: direction === 'ascending' ? 'asc' : 'desc',
							scope,
							q: searchKeyword,
						})
					)
				}}
			/>

			<Pagination
				isLoading={isLoading}
				pagination={pagination}
				onPageChange={(newPageNumber) => {
					dispatch(
						getPurchases(api, {
							companyId,
							accountingPeriodId: accountingPeriodIdVal,
							page: newPageNumber,
							limit,
							orderBy,
							direction,
							scope,
							q: searchKeyword,
						})
					)
				}}
			/>

			{accountingPeriodsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the accounting periods list. If this problem persists,
						please contact the Neoni team.
					</p>
					<p className='text-white'>{accountingPeriodsError}</p>
				</div>
			)}
			{purchasesCarbonAccountsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the supplier accounts data for this accounting period (
						{thisAccountingPeriod.id}). If this problem persists, please contact the Neoni team.
					</p>
					<p className='text-white'>{purchasesCarbonAccountsError}</p>
				</div>
			)}
			{errorNoPeriod && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There are no completed accounting periods to view. If this problem persists, please
						contact the Neoni team.
					</p>
				</div>
			)}
			{/* {!checkCompletion && !isLoading && (
				<>
					<div className='rounded-lg bg-error-500 p-4 mt-4'>
						<p className='text-white'>
							This accounting period is not yet marked as complete. Please finish refining the
							period, so that we can accurately calculate your emissions data.
						</p>
					</div>
					<Button
						type='Primary'
						text='Back to accounting periods'
						onClick={() => {
							navigate(routes.companyAddAccountingPeriods.path)
						}}
						additionalClass='mt-6 mr-5 w-1/5'
					/>
				</>
			)} */}
		</>
	)
}

export default CompanyCarbonAccountsPurchases
