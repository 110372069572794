import { FunctionComponent, useEffect, useState } from 'react'
import { Button, Input } from '../../../../components/BaseComponents'
import { SortConfig } from '../../../../components/BaseComponents/Datagrid/useSortableData'
import Pagination from '../../../../components/BaseComponents/Pagination/Pagination'
import SimpleSortTable, {
	TableColumn,
} from '../../../../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import { useEffectWithoutInitialRender } from '../../../../hooks/useEffectWithoutInitialRender'
import { getSuppliers, GET_SUPPLIERS_LIMIT } from '../../../../redux/actions/suppliersActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/reduxHooks'
import {
	selectSuppliersStatus,
	selectSuppliersPagination,
	selectAllSuppliers,
	SUPPLIERS_RESET,
} from '../../../../redux/slices/suppliersSlice'
import { FetchStatus } from '../../../../types/LoadingStates'
import { Supplier } from '../../../../types/Supplier'
import { WORST_SUPPLIER_LIMIT_QUANTITY } from '../Graphs/ECO2Supplier'
import useApi from '../../../../hooks/useApi'

// This component should only refresh/be doing stuff when marked as 'active' as it lives inside
// a modal and might otherwise be rendered inivisbly and cause interference

type IModalSupplierSearch = {
	onChange?: (newValue?: Supplier[]) => void
	active?: boolean
	sectorId?: string | number
}

export const ModalSupplierSearch: FunctionComponent<IModalSupplierSearch> = ({
	onChange,
	active = true,
	sectorId
}) => {
	const dispatch = useAppDispatch()
	const api = useApi();

	const suppliersStatus = useAppSelector(selectSuppliersStatus)
	const pagination = useAppSelector(selectSuppliersPagination)
	const allSuppliers = useAppSelector(selectAllSuppliers)

	const [searchKeyword, setSearchKeyword] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	const [orderBy, setOrderBy] = useState<string>('name')
	const [direction, setDirection] = useState<'asc' | 'desc' | undefined>('asc')

	const [selection, setSelection] = useState<Supplier[]>([])

	// Data loading (refire every time marked active)
	useEffect(() => {
		if (active) {
			dispatch({
				type: SUPPLIERS_RESET,
			})
			if (sectorId !== undefined && sectorId !== null) {

				dispatch(getSuppliers(api, {
					sectorCodes: sectorId,
				}))
			}
			else {

				dispatch(getSuppliers(api))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active])
	useEffectWithoutInitialRender(() => {
		if (suppliersStatus === FetchStatus.loaded && sectorId !== undefined && sectorId !== null) {
			onChange && onChange(allSuppliers)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [suppliersStatus])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{
			id: 'selected',
			label: '',
			sortable: false,
			columnClass: 'w-3', // narrow fixed column width
			renderer: (columnKey, rowData) => {
				return (
					<input
						type='checkbox'
						className='rounded border-charcoal-300 text-algae-600 focus:ring-algae-500'
						value={rowData._id}
						checked={rowData.selected}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							event.stopPropagation()

							if (selection.filter((supplier) => supplier.id === rowData._id).length > 0) {
								// Remove from selection
								setSelection([...selection.filter((supplier) => supplier.id !== rowData._id)])
							} else {
								// Check there's still room to add another
								if (selection.length !== WORST_SUPPLIER_LIMIT_QUANTITY) {
									// Add to selected
									setSelection([...selection, rowData.supplierObject])
								}
							}
						}}
					/>
				)
			},
		},
		{ id: 'name', label: 'Supplier', sortable: true },
		{
			id: 'isicCode',
			label: 'Sector Code (ISIC)',
			sortable: false,
			columnClass: 'col-span-2',
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{rowData.isicCode}
							{rowData.isicCodeName && (
								<span className='text-charcoal-050'> - {rowData.isicCodeName}</span>
							)}
						</p>
					</>
				)
			},
		},
		{
			id: 'jurisdiction',
			label: 'Jurisdiction',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>{rowData.jurisdiction_code}</p>
					</>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'name',
		direction: 'ascending',
	}

	// DataGrid data constructor
	const constructData = (dataSet: Array<Supplier>) => [
		...dataSet.map((supplier) => {
			return {
				_id: supplier.id,
				name: supplier.name,
				isicCode: supplier.supplierIndustryCode?.code || '-',
				isicCodeName: supplier.supplierIndustryCode?.name || undefined,
				jurisdiction_code: supplier.jurisdiction_code || '-',
				selected:
					selection.filter((selectedSupplier) => selectedSupplier.id === supplier.id).length > 0,
				supplierObject: supplier,
			}
		}),
	]

	// Timeout for search
	useEffectWithoutInitialRender(() => {
		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			dispatch(
				getSuppliers(api, {
					page: pagination.first_page,
					limit: GET_SUPPLIERS_LIMIT,
					orderBy: orderBy,
					direction: 'asc',
					q: searchKeyword,
				})
			)

			setSearchInProgress(false)
		}, 500)

		if (searchKeyword !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchKeyword])

	return (
		<>
			{suppliersStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching suppliers associated with this organisation. If this problem
						persists, please contact the Neoni team.
					</p>
				</div>
			)}

			<h3 className='font-bold text-xl text-white mb-4'>Select Suppliers to Compare</h3>

			<p className='text-white font-bold mb-4'>
				Please select up to {WORST_SUPPLIER_LIMIT_QUANTITY} suppliers to compare their embedded CO₂
				emissions:
			</p>

			{selection !== void 0 && selection.length > 0 && (
				<>
					{selection.map((supplier) => {
						return (
							<Button
								key={supplier.id}
								type='Outlined'
								size='sm'
								additionalClass='mr-2 mb-2'
								text={supplier.name}
								hasTrailingIcon
								icon='fa-regular fa-times'
								onClick={() => {
									// Remove from selection
									setSelection([
										...selection.filter((selectedSupplier) => selectedSupplier.id !== supplier.id),
									])
								}}
							/>
						)
					})}
					<div className='mt-4 mb-8'>
						<Button
							type='Primary'
							text='Save Selection'
							onClick={() => {
								onChange && onChange(selection)
							}}
						/>
					</div>
				</>
			)}

			<div className='items-start mt-4'>
				<div className='relative max-w-2xl'>
					<label htmlFor='filterItems' className='sr-only'>
						Filter suppliers
					</label>
					<Input
						type={'text'}
						name={'filterSuppliers'}
						placeholder='Search for a supplier'
						value={searchKeyword}
						onChange={(event) => {
							setSearchKeyword(event.target.value)
						}}
					/>
					<label htmlFor='filterPurchases' className='sr-only'>
						Filter suppliers
					</label>
					<Button
						hasLeadingIcon
						type='White'
						size='sm'
						icon={` ${searchInProgress
							? 'fas fa-loader fa-spin'
							: searchKeyword.length > 0
								? 'fa-solid fa-times'
								: 'fa-solid fa-search'
							}`}
						additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
						onClick={() => {
							setSearchKeyword('')
							dispatch(
								getSuppliers(api, {
									page: pagination.first_page,
									limit: GET_SUPPLIERS_LIMIT,
									orderBy: orderBy,
									direction: direction,
									q: '',
								})
							)
						}}
					/>
				</div>
			</div>

			<SimpleSortTable
				isLoading={suppliersStatus === FetchStatus.loading}
				additionalClass='mt-4'
				data={constructData(allSuppliers)}
				columns={columns}
				defaultSorting={defaultSorting}
				onSortPress={(key, direction) => {
					// Store current sorting values for pagination to reference in its API calls
					setOrderBy(key)
					setDirection(direction === 'ascending' ? 'asc' : 'desc')
					dispatch(
						getSuppliers(api, {
							page: pagination.current_page,
							limit: GET_SUPPLIERS_LIMIT,
							orderBy: key,
							direction: direction === 'ascending' ? 'asc' : 'desc',
							q: searchKeyword,
						})
					)
				}}
			/>

			<Pagination
				isLoading={suppliersStatus === FetchStatus.loading}
				pagination={pagination}
				onPageChange={(newPageNumber) => {
					dispatch(
						getSuppliers(api, {
							page: newPageNumber,
							limit: GET_SUPPLIERS_LIMIT,
							orderBy: orderBy,
							direction: direction,
							q: searchKeyword,
						})
					)
				}}
			/>

			{selection !== void 0 && selection.length > 0 && (
				<div className='mt-4 flex-row-reverse w-full flex'>
					<Button
						type='Primary'
						text='Save Selection'
						onClick={() => {
							onChange && onChange(selection)
						}}
					/>
				</div>
			)}
		</>
	)
}
