import { useEffect, useState } from "react";
import { Button } from "../../components/BaseComponents";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

function AdminTextSettings() {
    const [termsText, setTermsText] = useState('');
    const [disclosureText, setDisclosureText] = useState('');
    const [methodologyText, setMethodologyText] = useState('');

    const [loading, setLoading] = useState(true);

    const api = useApi();

    useEffect(() =>{ 
        const run = async () => {
            const result = await api.get('admin/settings');
            setTermsText(result.data.terms_conditions)
            setDisclosureText(result.data.default_disclosure_text)
            setMethodologyText(result.data.default_methodology_text);
            setLoading(false);
        }
        run();
    }, [])

    const onSave = async () => {
        setLoading(true)
        await api.put('admin/settings', {
            _csrf: await getCSRFToken(),
            settings: {
                defaultDisclosureText: disclosureText,
                defaultMethodologyText: methodologyText,
                termsConditions: termsText,
            }
        })
        setLoading(false);
    }

    return (
        <div>
            <h3 className="text-white">
                Default system text
            </h3>
            <div className="mt-8 rounded-lg bg-charcoal-600 p-3">
                <h2 className="text-white ">General: Terms & Conditions</h2>
                <div>
                    <textarea
                        disabled={loading}
                        className="w-full rounded my-4"
                        value={termsText}
                        onChange={e => setTermsText(e.target.value)}
                    />
                </div>
                <Button type="Primary" text="Save" size="sm" disabled={loading} onClick={onSave}/>
            </div>
            <div className="mt-8 rounded-lg bg-charcoal-600 p-3">
                <h2 className="text-white ">Report: Confirmation Statement</h2>
                <div>
                    <textarea
                        disabled={loading}
                        className="w-full rounded my-4"
                        value={disclosureText}
                        onChange={e => setDisclosureText(e.target.value)}
                    />
                </div>
                <Button type="Primary" text="Save" size="sm" disabled={loading} onClick={onSave}/>
            </div>
            <div className="mt-8 rounded-lg bg-charcoal-600 p-3">
                <h2 className="text-white ">Report: Methodology</h2>
                <div>
                    <textarea
                        disabled={loading}
                        className="w-full rounded my-4"
                        value={methodologyText}
                        onChange={e => setMethodologyText(e.target.value)}
                    />
                </div>
                <Button type="Primary" text="Save" size="sm" disabled={loading} onClick={onSave}/>
            </div>
        </div>
    )
}

export default AdminTextSettings;
