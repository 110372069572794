import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { CurrencyCode } from "../types/currency";
import { LoadingSpinner } from "../components/BaseComponents";
import { UserRole } from "../types/UserRole";
import { AccessConfig } from "../types/AccessConfig";
import { Navigate } from "react-router-dom";
import routes from "../components/Router/routes";

export type UserData = {
    email: string,
    fullName: string,
    companyId: string,
    companyName: string,
    role: UserRole,
    hasCompletedSetup: boolean,
    currencyCode: CurrencyCode,
    access?: AccessConfig,
    original: {
        companyId: string,
        companyName: string,
    }
}

type UserContextValue = {
    userData?: UserData;
    setCompanyAssumption?: (companyAssumption: CompanyAssumption|null) => void;
    fetchUserData: () => Promise<void>;
    checkAccess: (accessKey: string) => boolean;
    clearUserData: () => void;
}

type CompanyAssumption = {
    type: 'admin'|'accounting'|'parent',
    companyId: string,
};

const userContext = createContext<UserContextValue>({
    checkAccess: () => false,
    fetchUserData: async () => {},
    clearUserData: () => {},
});

function UserProvider({children}: PropsWithChildren) {
    const [userData, setUserData] = useState<UserData>();
    const [initialLoading, setInitialLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>(null)
    
    const clearUserData = () => setUserData(undefined);

    const checkAccess = (accessKey: string) => {
        if(!userData) {
            return false;
        }

        const accessStrings: Record<string, boolean> = {
            'user.write': userData.access?.user.permissions.write || false,
            'user.manage': userData.access?.user.permissions.manage || false,
            'company.admin': userData.access?.company.permissions.admin || false,
            'company.portfolios': userData.access?.company.permissions.portfolios || false,
            'company.projects': userData.access?.company.permissions.projects || false,
            'company.invite': userData.access?.company.permissions.invite || false,
            'company.accounting': userData.access?.company.permissions.accounting || false,
            'company.insights': userData.access?.company.permissions.insights || false,
            'company.clientAccounting': userData.access?.company.permissions.clientAccounting || false,
        };

        return accessStrings[accessKey];
    }

    const [companyAssumption, setCompanyAssumption] = useState<CompanyAssumption|null>(null);

    const fetchUserData = async () => {
        const headers: Record<string, string> = {};
        if(companyAssumption) {
            headers[`neoni-${companyAssumption.type}-assumed-company-id`] = companyAssumption.companyId;
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_API}/me`,
                {
                    headers,
                }
            )
            // if(!response.ok) {
            //     throw new Error('Failed to get user session data');
            // }
            const body = await response.json()
            setUserData(body.data)
        } catch (error) {
            setError(error)
        } finally {
            setInitialLoading(false)
        }
    }

    useEffect(() => {
        fetchUserData()
		//eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyAssumption])

    if(initialLoading) {
        return <LoadingSpinner />
    }

    if (error) {
        // Handle error here
        console.log('Usercontext error, Navigating to login')
        return <Navigate to={routes.publicLogin.path} />
    }

    const value = {
        userData,
        setCompanyAssumption,
        fetchUserData,
        checkAccess,
        clearUserData
    };

    return (
        <userContext.Provider value={value}>{ children }</userContext.Provider>
    )
};

export {
    UserProvider,
    userContext,
};
