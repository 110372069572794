import { useContext } from 'react'

import { userContext } from '../context/UserContext'

const useUserData = ()=> {
	const { userData } = useContext(userContext);
	if(!userData) {
		throw new Error('Missing user data');
	}

	return userData;
}

export default useUserData
