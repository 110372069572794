import { Dispatch } from 'redux'
import { getParams } from '../../lib/utils'
import {
	purchasesCarbonData_ERROR,
	purchasesCarbonData_ERROR_SELECTED,
	purchasesCarbonData_LOADED,
	purchasesCarbonData_LOADED_SELECTED,
	purchasesCarbonData_LOADING,
	purchasesCarbonData_LOADING_SELECTED,
} from '../slices/carbonAccountsPurchasesSlice'
import { NeoniApi } from '../../lib/api'

export const GET_PURCHASES_CARBON_ACCOUNTS_LIMIT = 20

type IgetPurchasesCarbonAccounts = {
	companyId: string
	accountingPeriodId: string
	page?: number
	limit?: number
	orderBy?: string
	direction?: string
	scope?: string
	q?: string
	excludeScope1?: boolean | string
	excludeNullScope?: boolean | string
}

export const getPurchases =
	(api: NeoniApi, {
		accountingPeriodId,
		page = 1,
		limit = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT,
		orderBy = '',
		direction = '',
		scope = '',
		q = '',
	}: IgetPurchasesCarbonAccounts) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: purchasesCarbonData_LOADING,
		})

		if (orderBy === 'level1') {
			orderBy = 'emission_type'
		}

		try {
			const paramVals = {
				scope,
				page,
				itemsPerPage: limit,
				orderBy,
				direction,
				q,
			}
			let params = getParams(paramVals)
			const body = await api.get(`accountingPeriod/${accountingPeriodId}/carbonAccounts/purchases?${params}`)

			body.data.per_page = limit
			if (body.success === true) {
				// Successfully fetched purchases
				dispatch({
					type: purchasesCarbonData_LOADED,
					payload: body.data,
				})
			} else {
				// Error
				dispatch({
					type: purchasesCarbonData_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: purchasesCarbonData_ERROR,
				payload: error,
			})
		}
	}

// This basically does the same as above but inserts them into another part of the state for referencing
export const getSelectionsForPeriod =
	(api: NeoniApi, {
		companyId,
		accountingPeriodId,
		page = 1,
		limit = 100,
		orderBy = '',
		direction = '',
		scope = '',
		q = '',
		excludeScope1 = '',
		excludeNullScope = '',
	}: IgetPurchasesCarbonAccounts) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: purchasesCarbonData_LOADING_SELECTED,
		})

		if (orderBy === 'level1') {
			orderBy = 'emission_type'
		}

		try {
			const paramVals = {
				scope,
				page,
				itemsPerPage: limit,
				orderBy,
				direction,
				q,
				excludeScope1,
				excludeNullScope,
			}
			let params = getParams(paramVals)
			const body = await api.get(`purchases/${companyId}/${accountingPeriodId}/items?` +
					params);
			body.data.per_page = limit
			if (body.success === true) {
				// Successfully fetched purchases
				dispatch({
					type: purchasesCarbonData_LOADED_SELECTED,
					payload: body.data,
				})
			} else {
				// Error
				dispatch({
					type: purchasesCarbonData_ERROR_SELECTED,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: purchasesCarbonData_ERROR_SELECTED,
				payload: error,
			})
		}
	}
