import { configureStore } from '@reduxjs/toolkit'
import accountingPeriodsSlice from './slices/accountingPeriodsSlice'

import createCompanySlice from './slices/createCompanySlice'
import createInvitationsSlice from './slices/createInvitationsSlice'
import addPurchasesUploadSlice from './slices/addPurchasesUploadSlice'
import invitationsSlice from './slices/invitationsSlice'
import openCorporatesSlice from './slices/openCorporatesSlice'
import PurchaseSliceDeprecated from './slices/purchasesSlice'
import updateItemsSlice from './slices/updateItemsSlice'
import emissionTypesSlice from './slices/emissionTypesSlice'
import itemsSlice from './slices/itemsSlice'
import isicSlice from './slices/isicSlice'
import CarbonAccountsSummarySlice from './slices/carbonAccountSummarySlice'
import suppliersSlice from './slices/suppliersSlice'
import updateSuppliersSlice from './slices/updateSuppliersSlice'
import sentInvitationsCompanySlice from './slices/companySentInvitationsSlice'
import receivedInvitationsCompanySlice from './slices/companyReceivedInvitationsSlice'
import createSupplyChainInvitationsSlice from './slices/createSupplyChainInvitation'
import companyListSlice from './slices/getCompanyListSlice'
import rolesSlice from './slices/rolesSlice'
import SupplierCarbonAccounts from './slices/carbonAccountsSupplier'
import PurchasesCarbonAccounts from './slices/carbonAccountsPurchasesSlice'
import periodStatisticsSlice from './slices/periodStatisticsSlice'
import supplierStatisticsSlice from './slices/supplierStatisticsSlice'
import UpdateBulkSuppliersSlice from './slices/updateBulkSuppliersSlice'
import csvSlice from './slices/csvFilesSlice'
import sectorSlice from './slices/sectorSlice'
import supplyChainGraphDataSlice from './slices/supplyChainGraphDataSlice'
import refinementsSlice from './slices/refinementsSlice'
import periodicReportsSlice from './slices/periodicReportingSlice'

export const store = configureStore({
	reducer: {
		invitations: invitationsSlice,
		createInvitations: createInvitationsSlice,
		accountingPeriods: accountingPeriodsSlice,
		createCompany: createCompanySlice,
		openCorporates: openCorporatesSlice,
		addPurchasesUpload: addPurchasesUploadSlice,
		purchases: PurchaseSliceDeprecated,
		updateItems: updateItemsSlice,
		emissonTypes: emissionTypesSlice,
		items: itemsSlice,
		isic: isicSlice,
		carbonSummary: CarbonAccountsSummarySlice,
		suppliers: suppliersSlice,
		updateSuppliers: updateSuppliersSlice,
		companySentInvitations: sentInvitationsCompanySlice,
		companyReceivedInvitations: receivedInvitationsCompanySlice,
		createSupplyChainInvitations: createSupplyChainInvitationsSlice,
		companyList: companyListSlice,
		roles: rolesSlice,
		suppliersCarbonAccounts: SupplierCarbonAccounts,
		purchasesCarbonAccounts: PurchasesCarbonAccounts,
		periodStatistics: periodStatisticsSlice,
		supplierStatistics: supplierStatisticsSlice,
		updateBulkSuppliers: UpdateBulkSuppliersSlice,
		csvFiles: csvSlice,
		sector: sectorSlice,
		supplyChainGraphData: supplyChainGraphDataSlice,
		refinements: refinementsSlice,
		periodicReports: periodicReportsSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoreActions: true,
			},
		}),
	devTools: {
		actionSanitizer: (action) => ({
			...action,
			type: action.type.toString(),
		}),
	},
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
