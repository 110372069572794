/* eslint-disable */
import Input from './Input'
import Button from '../Button/Button'
import { useState } from 'react'
import { useAppDispatch } from '../../../redux/hooks/reduxHooks'
import { createInvitation } from '../../../redux/actions/invitationsActions'
import { createInvitationCompany } from '../../../redux/actions/companySentInvitationsActions'
import Select from 'react-select'
import { validateEmail } from '../../../lib/utils'
import useApi from '../../../hooks/useApi'

type FormProps = {
	companyId: string
	type: string
	supplierId?: string
	roleId?: string
}

function CreateInviteForm({ companyId, type, supplierId, roleId }: FormProps) {
	const api = useApi();
	const optionlist = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	]
	const dispatch = useAppDispatch()

	const [email, setEmail] = useState<string>('')
	const [sendEmail, setSendEmail] = useState<boolean>(true)
	const [valid, setValid] = useState<boolean>(true)

	return (
		<form className='mt-lg' onSubmit={(e) => e.preventDefault()}>
			<Input
				onChange={(event) => {
					setEmail(event.target.value)
				}}
				label='Invitee organisation email address'
				type='text'
				placeholder='invitee@companyemail.com'
				name='email'
			/>

			{!valid && (
				<div className='rounded-lg bg-error-500 p-4 mb-4 mt-4'>
					<p className='text-white'>You must enter a valid email address</p>
				</div>
			)}
			<div className='mt-lg mb-5'>
				<Button
					onClick={() => {
						if (validateEmail(email) && type === 'Admin') {
							dispatch(createInvitation(api, companyId, email, sendEmail, roleId))
						} else if (validateEmail(email) && type === 'Company') {
							dispatch(createInvitationCompany(api, companyId, email, sendEmail, supplierId))
						} else {
							setValid(false)
						}
					}}
					type='Primary'
					icon='fa-light fa-paper-plane-top'
					text={`${valid ? 'Send Invite' : 'Retry Invite'}`}
					buttonType='submit'
				/>
			</div>
		</form>
	)
}
export default CreateInviteForm
