import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PaginationMeta } from '../../types/PaginationMeta'
import { Scope1SelectionItem, Scope1EmissionItem, Scope2SelectionItem, Scope3SupplierItem } from '../../types/PurchaseCarbonData'
import type { RootState } from '../store'


type RefinementsDataType = {
	scope1SelectionItemsStatus: FetchStatus
	scope1EmissionItemsStatus: FetchStatus
	scope2SelectionItemsStatus: FetchStatus
	scope3SuppliersStatus: FetchStatus
	scope1SelectionItemsError?: string
	scope1EmissionItemsError?: string
	scope2SelectionItemsError?: string
	scope3SuppliersError?: string

	scope1SelectionItems: {
		items: Scope1SelectionItem[],
		pagination: PaginationMeta
  } | null
	scope1EmissionItems: {
		items: Scope1EmissionItem[],
		pagination: PaginationMeta,
  } | null
	scope2SelectionItems: {
		items: Scope2SelectionItem[],
		pagination: PaginationMeta,
  } | null
	scope3SupplierItems: {
		suppliers: Scope3SupplierItem[],
		pagination: PaginationMeta,
  } | null
	error?: string
}

const refinementsInitialState: RefinementsDataType = {
	scope1SelectionItemsStatus: FetchStatus.initial,
	scope1EmissionItemsStatus: FetchStatus.initial,
	scope2SelectionItemsStatus: FetchStatus.initial,
	scope3SuppliersStatus: FetchStatus.initial,
	scope1SelectionItems: null,
	scope1EmissionItems: null,
	scope2SelectionItems: null,
	scope3SupplierItems: null,
}

export const refinements = createSlice({
	name: 'purchasesData',
	initialState: refinementsInitialState,
	reducers: {
		REFINEMENTS_SCOPE1_SELECTION_LOADING: (state) => {
			return {
				...state,
				scope1SelectionItemsStatus: FetchStatus.loading,
				scope1SelectionItemsError: undefined,
				pagination: null,
				scope1UserSelections: [],
			}
		},
		REFINEMENTS_SCOPE1_SELECTION_LOADED: (state, action: PayloadAction<{items: Scope1SelectionItem[], pagination: PaginationMeta}>) => {
			return {
				...state,
				scope1SelectionItemsStatus: FetchStatus.loaded,
				scope1SelectionItemsError: undefined,
				scope1SelectionItems: action.payload,
			}
		},
		REFINEMENTS_SCOPE1_SELECTION_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				scope1SelectionItemsStatus: FetchStatus.error,
				scope1SelectionItemsError: action.payload,
				scope1SelectionItems: null,
				pagination: null,
				scope1UserSelections: [],
			}
		},
		REFINEMENTS_SCOPE1_SELECTION_RESET: (state) => {
			return {
				...state,
				scope1SelectionItemsStatus: FetchStatus.initial,
				scope1SelectionItems: null,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE1_EMISSIONS_LOADING: (state) => {
			return {
				...state,
				scope1EmissionItemsStatus: FetchStatus.loading,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE1_EMISSIONS_LOADED: (state, action: PayloadAction<{items: Scope1EmissionItem[], pagination: PaginationMeta}>) => {
			return {
				...state,
				scope1EmissionItemsStatus: FetchStatus.loaded,
				scope1EmissionItems: action.payload,
			}
		},
		REFINEMENTS_SCOPE1_EMISSIONS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				scope1EmissionItemsStatus: FetchStatus.error,
				scope1EmissionItemsError: action.payload,
				scope1EmissionItems: null,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE1_EMISSIONS_RESET: (state) => {
			return {
				...state,
				scope1EmissionItemsStatus: FetchStatus.initial,
				scope1EmissionItems: null,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE2_SELECTION_LOADING: (state) => {
			return {
				...state,
				scope2SelectionItemsStatus: FetchStatus.loading,
				scope2SelectionItems: null,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE2_SELECTION_LOADED: (state, action: PayloadAction<{items: Scope2SelectionItem[], pagination: PaginationMeta}>) => {
			return {
				...state,
				scope2SelectionItemsStatus: FetchStatus.loaded,
				pagination: null,
				scope2SelectionItems: action.payload,
			}
		},
		REFINEMENTS_SCOPE2_SELECTION_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				scope2SelectionItemsStatus: FetchStatus.error,
				scope2SelectionItemsError: action.payload,
				scope2SelectionItems: null,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE2_SELECTION_RESET: (state) => {
			return {
				...state,
				scope2SelectionItemsStatus: FetchStatus.initial,
				scope2SelectionItems: null,
				pagination: null,
			}
		},
		REFINEMENTS_SCOPE3_SUPPLIERS_LOADING: (state) => {
			return {
				...state,
				scope3SuppliersStatus: FetchStatus.loading,
				scope3SupplierItems: null,
			}
		},
		REFINEMENTS_SCOPE3_SUPPLIERS_LOADED: (state, action: PayloadAction<{suppliers: Scope3SupplierItem[], pagination: PaginationMeta}>) => {
			return {
				...state,
				scope3SuppliersStatus: FetchStatus.loaded,
				scope3SupplierItems: action.payload,
			}
		},
		REFINEMENTS_SCOPE3_SUPPLIERS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				scope3SuppliersStatus: FetchStatus.error,
				scope3RefinementSuppliersError: action.payload,
				scope3SupplierItems: null,
			}
		},
		REFINEMENTS_SCOPE3_SUPPLIERS_RESET: (state) => {
			return {
				...state,
				scope3SuppliersStatus: FetchStatus.initial,
				scope3SupplierItems: null,
			}
		},
	},
})

// Action types
export const {
	REFINEMENTS_SCOPE1_SELECTION_LOADING,
	REFINEMENTS_SCOPE1_SELECTION_LOADED,
	REFINEMENTS_SCOPE1_SELECTION_ERROR,
	REFINEMENTS_SCOPE1_SELECTION_RESET,
	REFINEMENTS_SCOPE1_EMISSIONS_LOADING,
	REFINEMENTS_SCOPE1_EMISSIONS_LOADED,
	REFINEMENTS_SCOPE1_EMISSIONS_ERROR,
	REFINEMENTS_SCOPE1_EMISSIONS_RESET,
	REFINEMENTS_SCOPE2_SELECTION_LOADING,
	REFINEMENTS_SCOPE2_SELECTION_LOADED,
	REFINEMENTS_SCOPE2_SELECTION_ERROR,
	REFINEMENTS_SCOPE2_SELECTION_RESET,
	REFINEMENTS_SCOPE3_SUPPLIERS_LOADING,
	REFINEMENTS_SCOPE3_SUPPLIERS_LOADED,
	REFINEMENTS_SCOPE3_SUPPLIERS_ERROR,
	REFINEMENTS_SCOPE3_SUPPLIERS_RESET,
} = refinements.actions

// Selectors
export const selectRefinementsScope1SelectionStatus = (state: RootState) =>
	state.refinements.scope1SelectionItemsStatus
export const selectRefinementsScope1SelectionItems = (state: RootState) =>
	state.refinements.scope1SelectionItems
export const selectRefinementsScope1SelectionError = (state: RootState) =>
	state.refinements.scope1SelectionItemsError

export const selectRefinementsScope1EmissionStatus = (state: RootState) =>
	state.refinements.scope1EmissionItemsStatus
export const selectRefinementsScope1EmissionItems = (state: RootState) =>
	state.refinements.scope1EmissionItems
export const selectRefinementsScope1EmissionError = (state: RootState) =>
	state.refinements.scope1EmissionItemsError

export const selectRefinementsScope2SelectionStatus = (state: RootState) =>
	state.refinements.scope2SelectionItemsStatus
export const selectRefinementsScope2SelectionItems = (state: RootState) =>
	state.refinements.scope2SelectionItems
export const selectRefinementsScope2SelectionError = (state: RootState) =>
	state.refinements.scope2SelectionItemsError

export const selectRefinementsscope3SupplierStatus = (state: RootState) =>
	state.refinements.scope3SuppliersStatus
export const selectRefinementsscope3SupplierItems = (state: RootState) =>
	state.refinements.scope3SupplierItems
export const selectRefinementsscope3SuppliersError = (state: RootState) =>
	state.refinements.scope3SuppliersError

export default refinements.reducer
