import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import SecrRender from "./SecrRender";
import { LoadingSpinner } from "../BaseComponents";
import { formatDate, getCSRFToken } from "../../lib/utils";


type SecrViewProps = {
    reportId: string;
}

function SecrDisplay({ reportId }: SecrViewProps) {
    const [data, setData] = useState<any>();
    const [baselineData, setBaselineData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [companyReports, setCompanyReports] = useState<any[]>([]);

    const api = useApi();

    useEffect(() => {
        loadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    const loadData = async() => {
        setLoading(true);
        const [result, baselineResult] = await Promise.all([
            api.get(`secr/${reportId}`),
            api.get(`secr/${reportId}/baseline`),
        ]);

        setData(result.data.report);
        setCompanyReports(result.data.companyReports);
        setBaselineData(baselineResult.data);
        setLoading(false);
    }

    const onPublish = async () => {
        setLoading(true);
        await api.post(`secr/${reportId}/publish/`, {
            _csrf: await getCSRFToken()
        });
        loadData();
        setLoading(false);
    }

    if(loading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <SecrRender
            isOwn
            report={data}
            baselineReport={baselineData}
            onPublish={onPublish}
            companyReports={companyReports.map(report => {
                return {
                    id: report.id,
                    label: formatDate(report.start_date) + ' - ' + formatDate(report.end_date)
                }
            })}
        />
    );
}
export default SecrDisplay;
