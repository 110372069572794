import { Dispatch } from 'redux'
import { ApiPagination } from '../../types/PaginationApi'
import {
	COMPANYLIST_FAILURE,
	COMPANYLIST_FETCH,
	COMPANYLIST_SUCCESS,
} from '../slices/getCompanyListSlice'
import { NeoniApi } from '../../lib/api'

export const GET_COMPANIES_LIMIT = 20
interface IGetCompanyList extends Omit<ApiPagination, 'orderBy'> {
	orderBy?: 'name' | string
}

export const getCompanyList =
	(api: NeoniApi, {
		page = 1,
		limit = GET_COMPANIES_LIMIT,
		orderBy = 'name',
		direction = 'asc',
	}: IGetCompanyList = {}) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: COMPANYLIST_FETCH,
		})

		try {
			const body = await api.get(`company?` +
				new URLSearchParams({
					page: page.toString(),
					limit: limit.toString(),
					orderBy,
					direction,
				})
			);

			dispatch({
				type: COMPANYLIST_SUCCESS,
				payload: body.companies,
			})
		} catch (error) {
			dispatch({
				type: COMPANYLIST_FAILURE,
				payload: error,
			})
		}
	}
