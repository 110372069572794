import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PeriodStatistic } from '../../types/PeriodStatistic'
import type { RootState } from '../store'

type supplierStatisticsState = {
	status: FetchStatus
	error?: string
	stats: Array<PeriodStatistic>
}

const supplierStatisticsInitialState: supplierStatisticsState = {
	status: FetchStatus.initial,
	error: undefined,
	stats: [],
}

export const supplierStatisticsSlice = createSlice({
	name: 'supplierStatistics',
	initialState: supplierStatisticsInitialState,
	reducers: {
		SUPPLIERSTATS_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		SUPPLIERSTATS_LOADED: (state, action: PayloadAction<Array<PeriodStatistic>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				stats: action.payload,
			}
		},
		SUPPLIERSTATS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		SUPPLIERSTATS_RESET: () => {
			return supplierStatisticsInitialState
		},
	},
})

// Action types
export const {
	SUPPLIERSTATS_LOADING,
	SUPPLIERSTATS_LOADED,
	SUPPLIERSTATS_ERROR,
	SUPPLIERSTATS_RESET,
} = supplierStatisticsSlice.actions

// Selectors
export const selectSupplierStatisticsStatus = (state: RootState) => state.supplierStatistics.status
export const selectSupplierStatisticsError = (state: RootState) => state.supplierStatistics.error
export const selectAllSupplierStatistics = (state: RootState) => state.supplierStatistics.stats

export default supplierStatisticsSlice.reducer
