type ElementProps<T extends keyof JSX.IntrinsicElements> = JSX.IntrinsicElements[T];

function InputCheckbox(props: ElementProps<"input">) {
    let classes = 'rounded border-charcoal-300 text-algae-600 focus:ring-algae-500'
    if(props.className) {
        classes += ' ' + props.className;
    }

    return <input {...props} className={classes} type="checkbox" />
}

export default InputCheckbox;
