type Validation = {
	required?: boolean
	min?: Number
	max?: Number
	minLength?: Number
	maxLength?: Number
	pattern?: RegExp
}

type InputProps = {
	register?: any
	errors?: any
	type: 'text' | 'number' | 'date'
	name: string
	defaultValue?: string | number
	label?: string
	placeholder?: string
	disabled?: boolean
	validation?: Validation
	patternErrorMessage?: string
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
	additionalClass?: string
	value?: string
}

function Input({
	register = () => { },
	errors,
	type,
	name,
	defaultValue,
	label,
	placeholder,
	disabled,
	validation,
	patternErrorMessage,
	onChange,
	onBlur,
	additionalClass,
	value,
}: InputProps) {
	return (
		<div className={`${additionalClass && additionalClass}`}>
			{label && (
				<label htmlFor={name} className='block text-sm font-medium text-charcoal-100 my-1'>
					{label}
				</label>
			)}
			<div className='relative rounded shadow-sm'>
				<input
					className={`block w-full rounded focus:outline-none border-charcoal-200 focus:border-charcoal-200 focus:ring-1 appearance-none ${errors && errors[name]
							? 'border-error-500 bg-error-050 text-error-800 focus:border-error-500'
							: 'focus:border-algae-500 focus:ring-algae-500'
						} ${disabled
							? 'border-charcoal-500 bg-charcoal-050/75 text-charcoal-800 focus:border-charcoal-500'
							: ''
						}`}
					type={type}
					placeholder={placeholder}
					disabled={!!disabled}
					defaultValue={defaultValue}
					onChange={(e) => onChange && onChange(e)}
					onBlur={(e) => onBlur && onBlur(e)}
					value={value}
					{...register(name, validation)}
				/>

				{/* errors will return when field validation fails  */}
			</div>
			{errors && errors[name] && (
				<p className='mt-2 text-sm text-error-700'>
					{errors[name].type === 'required' && 'This field is required'}
					{errors[name].type === 'min' && `This value must be higher than ${validation?.min}`}
					{errors[name].type === 'max' && `This value must be lower than ${validation?.max}`}
					{errors[name].type === 'minLength' &&
						`Please enter at least ${validation?.minLength} characters`}
					{errors[name].type === 'maxLength' &&
						`Please enter fewer than ${validation?.maxLength} characters`}
					{errors[name].type === 'pattern' &&
						`${patternErrorMessage
							? patternErrorMessage
							: 'This field does not match the required pattern'
						}`}
				</p>
			)}
		</div>
	)
}
export default Input
