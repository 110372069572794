import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CsvType } from '../../types/CsvFile'
import { FetchStatus, PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type CsvSliceType = {
	status: FetchStatus
	error?: string
	csvFiles: Array<CsvType>
	deleteStatus: PostStatus
	deleteError?: string
}

const csvFilesInitialState: CsvSliceType = {
	status: FetchStatus.initial,
	error: undefined,
	csvFiles: [],
	deleteStatus: PostStatus.initial,
	deleteError: undefined,
}

export const csvSlice = createSlice({
	name: 'Csv Lists',
	initialState: csvFilesInitialState,
	reducers: {
		CSV_LIST_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		CSV_LIST_LOADED: (state, action: PayloadAction<Array<CsvType>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				csvFiles: action.payload,
			}
		},
		CSV_LIST_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		CSV_LIST_RESET: () => {
			return csvFilesInitialState
		},
		DELETE_CSV_SENDING: (state) => {
			return {
				...state,
				deleteStatus: PostStatus.sending,
			}
		},
		DELETE_CSV_SENT: (state, action: PayloadAction<string>) => {
			return {
				...state,
				deleteStatus: PostStatus.sent,
				deleteError: undefined,
				csvFiles: state.csvFiles.filter((item) => item.id !== action.payload),
			}
		},
		DELETE_CSV_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				deleteStatus: PostStatus.error,
				error: action.payload,
			}
		},
		DELETE_CSV_RESET: (state) => {
			return {
				...state,
				deleteStatus: PostStatus.initial,
				deleteError: undefined,
			}
		},
		UPLOAD_FILE_DATA_ADDITION: (state, action: PayloadAction<CsvType>) => {
			return {
				...state,
				csvFiles: [
					...state.csvFiles,
					{
						...action.payload,
					},
				],
			}
		},
	},
})

// Action types
export const {
	CSV_LIST_LOADING,
	CSV_LIST_LOADED,
	CSV_LIST_ERROR,
	CSV_LIST_RESET,
	DELETE_CSV_SENDING,
	DELETE_CSV_SENT,
	DELETE_CSV_ERROR,
	DELETE_CSV_RESET,
	UPLOAD_FILE_DATA_ADDITION,
} = csvSlice.actions

// Selectors
export const selectCsvFileStatus = (state: RootState) => state.csvFiles.status
export const selectCsvFileError = (state: RootState) => state.csvFiles.error
export const selectCsvFiles = (state: RootState) => state.csvFiles.csvFiles

export const deleteCsvFileStatus = (state: RootState) => state.csvFiles.deleteStatus
export const deleteCsvFileError = (state: RootState) => state.csvFiles.deleteError

export default csvSlice.reducer
