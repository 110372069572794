import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { Role } from '../../types/Roles'
import type { RootState } from '../store'

type rolesSliceType = {
	status: FetchStatus
	error?: string
	roles: Array<Role>
}
const rolesInitialState: rolesSliceType = {
	status: FetchStatus.initial,
	error: undefined,
	roles: [],
}
export const rolesSlice = createSlice({
	name: 'roles',
	initialState: rolesInitialState,
	reducers: {
		ROLES_FETCHING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		ROLES_SUCCESS: (state, action: PayloadAction<Array<Role>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				roles: action.payload,
			}
		},
		ROLES_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		ROLES_RESET: () => {
			return rolesInitialState
		},
	},
})

// Action types
export const { ROLES_FETCHING, ROLES_SUCCESS, ROLES_ERROR, ROLES_RESET } = rolesSlice.actions

// Selectors
export const selectRolesStatus = (state: RootState) => state.roles.status
export const selectRolesError = (state: RootState) => state.roles.error
export const selectRoles = (state: RootState) => state.roles.roles

export default rolesSlice.reducer
