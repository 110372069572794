import { useEffect, useRef } from 'react'

export const useEffectWithoutInitialRender = (fn: () => void, inputs: Array<any>) => {
	const didMountRef = useRef(false)

	useEffect(() => {
		if (didMountRef.current) {
			return fn()
		}
		didMountRef.current = true
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, inputs)
}
