import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { ItemScopeUpdate } from '../../types/PurchaseItem'
import {
	UPDATEITEMS_ERROR,
	UPDATEITEMS_SENDING,
	UPDATEITEMS_SENT,
} from '../slices/updateItemsSlice'
import { NeoniApi } from '../../lib/api'


export const updateItemsScope =
	(api: NeoniApi, items: ItemScopeUpdate[]) => async (dispatch: Dispatch) => {
		dispatch({
			type: UPDATEITEMS_SENDING,
		})

		try {
			const csrf = await getCSRFToken()
			
			const body = await api.post('items/bulkUpdateScopes', { items, _csrf: csrf })

			if (body.success === true) {
				dispatch({
					type: UPDATEITEMS_SENT,
					payload: body.message,
				})
			} else {
				dispatch({
					type: UPDATEITEMS_ERROR,
					payload: { error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: UPDATEITEMS_ERROR,
				payload: { error: error },
			})
		}
	}

export const updateItemsEmissionsType =
	(api: NeoniApi, items: {id: string, emission_type?: string|null}[]) => async (dispatch: Dispatch) => {
		dispatch({
			type: UPDATEITEMS_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			const body = await api.put('items/emission_types', { items, _csrf: csrf })

			if (body.success === true) {
				dispatch({
					type: UPDATEITEMS_SENT,
					payload: body.message,
				})
			} else {
				dispatch({
					type: UPDATEITEMS_ERROR,
					payload: { error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: UPDATEITEMS_ERROR,
				payload: { error: error },
			})
		}
	}
