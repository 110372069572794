import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../components/BaseComponents'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'

import { routes } from '../components/Router/routes'

const CompanyPurchases = () => {
	const [showAccordion, setShowAccordion] = useState<boolean>(false)
	const [showAccordion2, setShowAccordion2] = useState<boolean>(false)

	return (
		<PageTemplate testid='purchases-main'>
			<Helmet>
				<title>{routes.companyPurchases.name}</title>
			</Helmet>
			<PageHeadings title='Purchases' subTitle='Get Started' />
			<div className='mt-8 grid grid-cols-3 gap-4'>
				<div className='bg-charcoal-700 rounded-lg p-4 flex flex-col justify-between'>
					<div>
						<h2 className='text-charcoal-200 text-right text-lg font-semibold'>STEP 1</h2>
						<h3 className='text-white my-4'>Setup your accounting periods</h3>
						<p className='text-charcoal-100 text-sm mb-4'>What you need to do:</p>
						<ul className='text-charcoal-100 text-sm'>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Let us know when your accounting period runs</p>
							</li>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Enter the turnover for each accounting period</p>
							</li>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>We recommend starting with the last 5 years</p>
							</li>
						</ul>
					</div>
					<Button
						type='Primary'
						text='Add accounting period'
						hasTrailingIcon
						icon='fa-regular fa-arrow-right'
						isLink
						url={routes.companyAddAccountingPeriods.path}
						additionalClass='mt-4'
						fullWidth
					/>
				</div>
				<div className='bg-charcoal-700 rounded-lg p-4 flex flex-col justify-between'>
					<div>
						<h2 className='text-charcoal-200 text-right text-lg font-semibold'>STEP 2</h2>
						<h3 className='text-white my-4'>Prepare your data for upload</h3>
						<p className='text-charcoal-100 text-sm mb-4'>What you need to do:</p>
						<ul className='text-charcoal-100 text-sm'>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Download our .csv template</p>
							</li>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Review the required data</p>
							</li>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Prepare exports from your accounting software</p>
							</li>
						</ul>
					</div>
					<Button
						onClick={() => {
							window.location.href = `${process.env.REACT_APP_BASE_API}/csv/purchases-template`
						}}
						type='Primary'
						hasTrailingIcon={true}
						icon='fa-regular fa-download'
						text='Download CSV template'
						fullWidth
						additionalClass='mt-4'
					/>
				</div>
				<div className='bg-charcoal-700 rounded-lg p-4 flex flex-col justify-between'>
					<div>
						<h2 className='text-charcoal-200 text-right text-lg font-semibold'>STEP 3</h2>
						<h3 className='text-white my-4'>Upload your purchases data</h3>
						<p className='text-charcoal-100 text-sm mb-4'>What you need to do:</p>
						<ul className='text-charcoal-100 text-sm'>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Ensure each period contains the correct purchases</p>
							</li>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Go to the accounting periods page</p>
							</li>
							<li className='mb-4 flex items-center'>
								<i className='fa-regular fa-arrow-right text-algae-500 mr-4' />
								<p>Upload the corresponding .csv for that period</p>
							</li>
						</ul>
					</div>
					<Button
						type='Primary'
						isLink
						hasTrailingIcon
						icon='fa-regular fa-arrow-right'
						text='View accounting periods'
						fullWidth
						url={routes.companyAddAccountingPeriods.path}
						additionalClass='mt-4'
					/>
				</div>
			</div>
			<div className='mt-8 rounded-lg bg-charcoal-600 p-4 text-white'>
				<div
					role='button'
					tabIndex={0}
					aria-controls='accordion1'
					aria-expanded={showAccordion}
					className='flex items-center justify-between cursor-pointer'
					onClick={() => setShowAccordion(!showAccordion)}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							setShowAccordion(!showAccordion)
						}
					}}
				>
					<h2 className='text-lg'>Data collection and csv template</h2>
					<i className={`fa ${showAccordion ? 'fa-minus' : 'fa-plus'} text-algae-300`} />
				</div>
				<div
					id='accordion1'
					className={`${!showAccordion && 'hidden'} border-t border-charcoal-400 mt-4`}
				>
					<p className='my-4'>
						Firstly, we recommend you download our .csv template and familiarise yourself with the
						headers:
					</p>
					<ul className='pl-4'>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>product_name:</span> this is a
								mandatory free text field, which describes the item you have bought.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>product_code:</span> this is an
								optional and additional identifier, if you use product codes in your accounting
								software.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>supplier_name:</span> this is a
								mandatory field for the supplier of the purchase described in this row.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>supplier_code:</span> similar to
								product_code the supplier_code is an optional and additional identifier. Typically
								you would find supplier_codes in reports in your Accounting Software, which are
								based on your purchase ledger
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>description:</span> Additional
								free-text description of the item/purchase. Non-mandatory
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>cost:</span> this is a mandatory
								field and describes the NET cost of your purchase. Please make sure that you have
								two decimals, which can be denoted either with “.” or “,”. Please don't use
								separators for thousands.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>currency:</span> this is a mandatory
								field. Please use standard abbreviations such as GBP, USD or EUR.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>amount:</span> this a mandatory
								numeric field that quantifies the amount of the product that you purchased.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>amount_unit:</span> this is a
								mandatory free text field that describes the units used for the quantity of the
								product. This means that whatever format your accounting software exports will work.
							</p>
						</li>
						<li className='mb-2'>
							<p>
								<span className='text-algae-300 font-semibold'>date:</span> this is a mandatory
								field in the format DD.MM.YYYY or DD/MM/YYYY. This is usually the date of a purchase
								transaction and can be +/- one year to cover potential edge cases.
							</p>
						</li>
					</ul>
				</div>
			</div>

			<div className='mt-4 rounded-lg bg-charcoal-600 p-4 text-white'>
				<div
					role='button'
					tabIndex={0}
					aria-controls='accordion2'
					aria-expanded={showAccordion2}
					className='flex items-center justify-between cursor-pointer'
					onClick={() => setShowAccordion2(!showAccordion2)}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							setShowAccordion2(!showAccordion2)
						}
					}}
				>
					<h2 className='text-lg'>Data collection of your accounting period</h2>
					<i className={`fa ${showAccordion2 ? 'fa-minus' : 'fa-plus'} text-algae-300`} />
				</div>
				<div
					id='accordion2'
					className={`${!showAccordion2 && 'hidden'} border-t border-charcoal-400 mt-4`}
				>
					<h3 className='my-4'>What does Neoni expect in general?</h3>
					<p className='text-charcoal-100'>
						Neoni expects from you to provide ALL purchase transactions within an accounting period. The total currency value of all these transactions should add up to your total non-salary total spend for the period. This total spend should be entered in the field “Total Purchase Spend”.
					</p>
					<p className='text-charcoal-100'>
						These transactions can be uploaded in one .csv file for the relevant accounting period that you have set up.
						Alternatively, you may choose to upload multiple .csv files. This may be appropriate if, for example, the structure of your accounting software means your purchase transactions are exported from different purchase ledgers.
					</p>

					<h3 className='mt-8 mb-4'>What does “all purchases” mean?</h3>
					<p className='text-charcoal-100 mb-4'>
						After you have successfully uploaded your csv Neoni will guide you through the process
						of classifying items and purchases as different types of carbon emissions, starting with
						your direct emissions (Scope 1), such as fuel, bioenergy and refrigerants, then the
						electricity you have used (Scope 2) and lastly all other purchases, grouped by supplier
						(Scope 3).
					</p>

					<h3 className='mt-8 mb-4'>Specific Requirements for Scope 1 and 2</h3>
					<p className='mb-4 text-charcoal-100'>
						<span className='text-algae-300 font-semibold'>Scope 1:</span> it is vital that your
						transaction list reflects the different types of emissions, such as “petrol” and “diesel”
						rather than “fuel”. You will classify these so that Neoni can calculate your emissions
						based on reference value for this specific fuel/bioenergy/refrigerant in that particular
						year.
					</p>
					<p className='mb-4 text-charcoal-100'>
						<span className='text-algae-300 font-semibold'>Scope 2:</span> it is vital that your
						transaction list includes your electricity consumption in kwH. This can be one line
						representing the entire period or multiple lines that total the period. You may need to
						gather this information separately from your accounting software download and add it to
						your .csv before upload.
					</p>
					<p>
						Neoni will save this information, so that every following set of accounts will already
						identify items and suppliers you already have allocated in previous accounts.
					</p>
				</div>
			</div>
		</PageTemplate>
	)
}

export default CompanyPurchases
