import { useContext, useEffect, useState } from "react";
import { NotificationData, notificationContext } from "../context/NotificationContext";

type NotificationProps = {
    data: NotificationData;
    onRemove: () => void;
}

const DISPLAY_TIME = 3000;
const TRANSITION_TIME = 300;

function Notification({data, onRemove}: NotificationProps) {
    const hideTime = data.creationTime + DISPLAY_TIME;
    const fadeDelay = hideTime - Date.now();
    const removeDelay = fadeDelay + TRANSITION_TIME;

    const [hide, setHide] = useState(fadeDelay <= 0);

    useEffect(() => {
        const fadeTimeout = setTimeout(() => {
            setHide(true);
        }, fadeDelay)
        
        
        const removalTimeout = setTimeout(() => {
            onRemove();
        }, removeDelay)
        return () => {
            clearTimeout(fadeTimeout);
            clearTimeout(removalTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`cursor-pointer group rounded p-4 my-2 bg-algae-400 w-96 -translate-x-1/2 flex transition-opacity duration-300 ${hide ? 'opacity-0' : 'opacity-100'}`}  onClick={() => onRemove()}>
            <div className="grow">
                { data.body }
            </div>
            <div>
                <span className="fa-regular fa-x opacity-25 group-hover:opacity-100"/>
            </div>
        </div>
    )
}

function Notifications() {
    const { notifications, removeNotification } = useContext(notificationContext);

    return (
        <div className="flex flex-col absolute bottom-0 w-0 left-1/2">
            { notifications.map(notification => {
                const onRemove = () => removeNotification(notification.id);
                return <Notification key={notification.id} data={notification} onRemove={onRemove} />
            })}
        </div>
    )
}

export default Notifications;
