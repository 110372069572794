import {  useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LoadingSpinner } from '../components/BaseComponents'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import InvitationsTable from '../components/InvitationsTable/InvitationsTable'
import routes from '../components/Router/routes'
import { getReceivedInvitations } from '../redux/actions/companyReceivedInvitationsActions'
import { getSentInvitations } from '../redux/actions/companySentInvitationsActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectReceivedInvitations,
	selectReceivedInvitationsStatus,
} from '../redux/slices/companyReceivedInvitationsSlice'
import {
	selectInvitations,
	selectInvitationsStatus,
} from '../redux/slices/companySentInvitationsSlice'
import { FetchStatus } from '../types/LoadingStates'
import classNames from 'classnames'
import useApi from '../hooks/useApi'
import AdvisorsTable from '../components/AdvisorsTable'

export const CompanySupplyChain = () => {
	const dispatch = useAppDispatch()
	const api = useApi();

	const allInvitations = useAppSelector(selectInvitations)
	const invitationsStatus = useAppSelector(selectInvitationsStatus)

	const allReceivedInvitations = useAppSelector(selectReceivedInvitations)
	const invitationsReceivedStatus = useAppSelector(selectReceivedInvitationsStatus)

	useEffect(() => {
		dispatch(getSentInvitations(api))
		dispatch(getReceivedInvitations(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const tabslist = [
		{ name: 'To Suppliers', href: '#', current: true },
		{ name: 'From Customers', href: '#', current: false },
		{ name: 'From Advisors', href: '#', current: false },
	]
	const [tab, setTab] = useState('To Suppliers')
	const [tabs, setTabs] = useState<Array<any>>(tabslist)

	const updateTab = (value: string) => {
		setTab(value)
		let newtabs = [...tabs]
		// eslint-disable-next-line array-callback-return
		newtabs.map((tab) => {
			if (tab.name === value) {
				tab.current = true
			} else {
				tab.current = false
			}
		})
		setTabs(newtabs)
	}
	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companySupplyChainRequests.name}</title>
			</Helmet>
			<PageHeadings title="External Network" />

			<div className='mt-9'>
				<div className='hidden sm:block'>
					<nav className='-mb-px flex' aria-label='Tabs'>
						{tabs.map((tab) => (
							<a
								key={tab.name}
								href={tab.href}
								className={classNames(
									tab.current
										? 'border-algae-200 text-white border-b-2'
										: 'border-transparent text-charcoal-200',
									'w-1/2 py-4 px-1 text-center font-medium text-sm'
								)}
								onClick={(e) => {
									e.preventDefault()
									updateTab(tab.name)
								}}
							>
								{tab.name}
							</a>
						))}
					</nav>
				</div>
			</div>
			{tab === 'To Suppliers' && (
				<>
					{invitationsStatus === FetchStatus.loading && <LoadingSpinner />}

					{/* Loaded */}
					{invitationsStatus === FetchStatus.loaded && allInvitations.length > 0 && (
						<InvitationsTable type='Company' invitations={allInvitations} />
					)}
					{invitationsStatus === FetchStatus.loaded && allInvitations.length === 0 && (
						<>
							<p className='text-white px-4 py-2 mt-2'>We did not find any sent invitations.</p>
						</>
					)}

					{/* Error */}
					{invitationsStatus === FetchStatus.error && (
						<p className='my-lg text-white'>
							Oops! There was an error fetching the invitations data sent to Suppliers.
						</p>
					)}
				</>
			)}
			{tab === 'From Customers' && (
				<>
					{invitationsReceivedStatus === FetchStatus.loading && <LoadingSpinner />}

					{/* Loaded */}
					{invitationsReceivedStatus === FetchStatus.loaded &&
						allReceivedInvitations.length > 0 && (
							<InvitationsTable
								invitations={allReceivedInvitations}
								type='CompanyReceivedInvitations'
							/>
						)}
					{invitationsReceivedStatus === FetchStatus.loaded &&
						allReceivedInvitations.length === 0 && (
							<>
								<p className='text-white px-4 py-2 mt-2'>
									We did not find any received invitations.
								</p>
							</>
						)}

					{/* Error */}
					{invitationsReceivedStatus === FetchStatus.error && (
						<p className='my-lg text-white'>
							Oops! There was an error fetching the received invitations.
						</p>
					)}
				</>
			)}
			{tab === 'From Advisors' && (
				<AdvisorsTable />
			)}
		</PageTemplate>
	)
}

export default CompanySupplyChain
