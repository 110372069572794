import { Dispatch } from 'redux'
import { ISIC_ERROR, ISIC_LOADED, ISIC_LOADING } from '../slices/isicSlice'
import { NeoniApi } from '../../lib/api'

export const getIsicCodes = (api: NeoniApi) => async (dispatch: Dispatch) => {
	dispatch({
		type: ISIC_LOADING,
	})

	try {
		const body = await api.get(`supplier_industry_codes`)

		if (body.success === true) {
			// Successfully fetched purchases
			dispatch({
				type: ISIC_LOADED,
				payload: body.data.supplierIndustryCodes,
			})
		} else {
			// Error
			dispatch({
				type: ISIC_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: ISIC_ERROR,
			payload: error,
		})
	}
}
