import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CarbonAccountSummary } from '../../types/CarbonAccountSummary'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type carbonAccountSummarySliceType = {
	status: FetchStatus
	error?: string
	summary: CarbonAccountSummary
}
const carbonSummaryInitialState: carbonAccountSummarySliceType = {
	status: FetchStatus.initial,
	error: undefined,
	summary: {
		intensityRatio: '',
		localIntensityRatio: '',
		scope1Total: '',
		scope2Total: '',
		scope3Total: '',
		scope1Completion: '',
		scope2Completion: '',
		scope3Completion: '',
		totalCompletion: '',
	},
}
export const CarbonAccountsSummarySlice = createSlice({
	name: 'carbonSummary',
	initialState: carbonSummaryInitialState,
	reducers: {
		CARBONSUMMARY_FETCHING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		CARBONSUMMARY_SUCCESS: (state, action: PayloadAction<CarbonAccountSummary>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				summary: action.payload,
			}
		},
		CARBONSUMMARY_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		CARBONSUMMARY_RESET: () => {
			return carbonSummaryInitialState
		},
	},
})

// Action types
export const {
	CARBONSUMMARY_FETCHING,
	CARBONSUMMARY_SUCCESS,
	CARBONSUMMARY_ERROR,
	CARBONSUMMARY_RESET,
} = CarbonAccountsSummarySlice.actions

// Selectors
export const selectCarbonSummaryStatus = (state: RootState) => state.carbonSummary.status
export const selectCarbonSummaryError = (state: RootState) => state.carbonSummary.error
export const selectCarbonSummary = (state: RootState) => state.carbonSummary.summary
export const selectTotalEmissionsSummary = (state: RootState) =>
	Number(state.carbonSummary.summary.scope1Total) +
	Number(state.carbonSummary.summary.scope2Total) +
	Number(state.carbonSummary.summary.scope3Total)

export default CarbonAccountsSummarySlice.reducer
