import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, LoadingSpinner } from '../components/BaseComponents'
import EmissionTypeInput from '../components/BaseComponents/Forms/EmissionTypeInput'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import { getScope1EmissionTypes } from '../redux/actions/emissionTypesActions'
import { getItem } from '../redux/actions/itemActions'
import { updateItemsEmissionsType, updateItemsScope } from '../redux/actions/updateItemsActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	selectAllScope1EmissionTypes,
	selectEmissionTypesStatus,
} from '../redux/slices/emissionTypesSlice'
import {
	ITEMS_RESET,
	selectIndividualItem,
	selectItemsError,
	selectItemsStatus,
} from '../redux/slices/itemsSlice'
import { selectUpdateItemsStatus, UPDATEITEMS_RESET } from '../redux/slices/updateItemsSlice'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import useApi from '../hooks/useApi'

const CompanyEditItem = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { itemId } = useParams()
	const api = useApi();

	const itemsStatus = useAppSelector(selectItemsStatus)
	const itemsUpdateStatus = useAppSelector(selectUpdateItemsStatus)
	const itemsError = useAppSelector(selectItemsError)
	const itemData = useAppSelector(selectIndividualItem)
	const emissionTypesStatus = useAppSelector(selectEmissionTypesStatus)
	const allScope1EmissionTypes = useAppSelector(selectAllScope1EmissionTypes)

	// Local state for item scope and emission type selection
	const [selected, setSelected] = useState<string | undefined>(undefined)
	const [isScope1, setIsScope1] = useState<boolean>(false)
	const [isScope2, setIsScope2] = useState<boolean>(false)

	// Reset item data
	useEffect(() => {
		dispatch({
			type: ITEMS_RESET,
		})
		dispatch({
			type: UPDATEITEMS_RESET,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Data loading - Item
	useEffect(() => {
		if (itemsStatus === FetchStatus.initial) {
			// No item data, attempt to load item
			dispatch(getItem(api, itemId))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsStatus])

	// Data loading - Emission Types
	useEffect(() => {
		// Load emission types (if not loaded)
		if (emissionTypesStatus === FetchStatus.initial) {
			dispatch(getScope1EmissionTypes(api))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emissionTypesStatus])

	// Pre populate local state from loaded information
	useEffect(() => {
		if (emissionTypesStatus === FetchStatus.loaded && itemsStatus === FetchStatus.loaded) {
			setSelected(itemData?.emission_type || undefined)
		}

		if (itemsStatus === FetchStatus.loaded) {
			setIsScope1(Number(itemData?.scope) === 1)
			setIsScope2(Number(itemData?.scope) === 2)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emissionTypesStatus, itemsStatus])

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyItemEdit.name}</title>
			</Helmet>
			<PageHeadings
				title='Purchases'
				subTitle='Items'
				subSubTitle='Edit item'
				routeBack={routes.companyItems.path}
			/>

			{itemsStatus === FetchStatus.loaded && emissionTypesStatus === FetchStatus.loaded && (
				<>
					<dl className='mt-3 grid grid-cols-3 text-white'>
						<dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Item name</dt>
						<dd className='col-span-2 p-4 border-y border-charcoal-200'>{itemData?.name || '-'}</dd>

						<dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Product code</dt>
						<dd className='col-span-2 p-4 border-b border-charcoal-200'>
							{itemData?.product_code || '-'}
						</dd>

						<dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Supplier name</dt>
						<dd className='col-span-2 p-4 border-b border-charcoal-200'>
							{itemData?.supplier?.name || '-'}
						</dd>
					</dl>

					<form
						onSubmit={(e) => {
							e.preventDefault()
						}}
					>
						<div className='my-4 p-4 bg-charcoal-600 rounded-lg max-w-5xl'>
							<div className='flex items-center'>
								<input
									aria-expanded={isScope1}
									aria-controls='emissionTypes'
									className='mr-4 ml-1'
									type='checkbox'
									checked={isScope1}
									onChange={(e) => {
										if (e.target.checked) {
											// It is scope 1
											setIsScope1(true)
											setIsScope2(false)
										} else {
											// It is not scope 1
											setIsScope1(false)
											setSelected(undefined)
										}
									}}
									disabled={itemsUpdateStatus === PostStatus.sent}
								/>
								<p
									className={`${
										itemsUpdateStatus === PostStatus.sent ? 'text-charcoal-200' : 'text-white'
									}`}
								>
									This item is an on-site use of fuels or refridgerants{isScope1 && ':'}
								</p>
							</div>

							<EmissionTypeInput
								additionalClass={`mt-4 ${!isScope1 && 'hidden'}`}
								emissionTypeData={allScope1EmissionTypes}
								initialValue={itemData?.emission_type || undefined}
								onChange={(id) => {
									if (id !== void 0) {
										// Add to selected
										setSelected(id)
									} else {
										// Remove associated selection
										setSelected(undefined)
									}
								}}
								isDisabled={itemsUpdateStatus === PostStatus.sent}
							/>
						</div>

						<div className='flex items-center p-4 mb-4 bg-charcoal-600 rounded-lg max-w-5xl'>
							<input
								className='mr-4 ml-1'
								type='checkbox'
								checked={isScope2}
								onChange={(e) => {
									if (e.target.checked) {
										// It is scope 2
										setIsScope1(false)
										setIsScope2(true)
										setSelected(undefined)
									} else {
										// It is not scope 2
										setIsScope2(false)
									}
								}}
								disabled={itemsUpdateStatus === PostStatus.sent}
							/>
							<p
								className={`${
									itemsUpdateStatus === PostStatus.sent ? 'text-charcoal-200' : 'text-white'
								}`}
							>
								This item is electricity
							</p>
						</div>

						<div>
							{itemsUpdateStatus === PostStatus.sending && (
								<div className='mt-8'>
									<LoadingSpinner inline loadingText='Updating item...' />
								</div>
							)}

							{itemsUpdateStatus === PostStatus.error && (
								<div className='rounded-lg bg-error-500 p-4 mt-4'>
									<p className='text-white'>
										There was an error attempting to update your item. Please try again. If the
										problem persists, please contact the Neoni team for support.
									</p>
								</div>
							)}

							{itemsUpdateStatus === PostStatus.sent && (
								<>
									<div className='mt-8 mb-4 bg-white rounded-lg p-4'>
										<p>
											<span className='fa-regular fa-check mr-2 text-algae-700' />
											Your item was successfully updated
										</p>
									</div>

									<Button
										type='Primary'
										onClick={() => {
											navigate(routes.companyItems.path)
										}}
										text='Return to items overview'
										hasLeadingIcon
										additionalClass='mt-4'
										icon='fa-regular fa-arrow-left'
									/>
									<Button
										type='Outlined'
										onClick={() => {
											navigate(0)
										}}
										text='Edit'
										hasLeadingIcon
										additionalClass='mt-4 ml-4'
										icon='fa-regular fa-pen-to-square'
									/>
								</>
							)}

							{itemsUpdateStatus === PostStatus.initial && (
								<Button
									buttonType='submit'
									type='Primary'
									text='Update item'
									hasTrailingIcon
									icon='fa-regular fa-pen-to-square'
									additionalClass='mt-4'
									onClick={() => {
										// Calculate new scope
										let newScope = isScope1 ? 1 : isScope2 ? 2 : 3

										// Check for scope change, send if found
										if (itemData) {
											if (Number(itemData.scope) !== newScope) {
												dispatch(updateItemsScope(api, [
													{
														...itemData,
														scope: newScope,
													}
												]))
											}
										}

										// Check for emission type change, send if found
										if (itemData) {
											if (selected !== itemData.emission_type) {
												if (!(typeof selected === 'undefined' && itemData.emission_type === null)) {
													dispatch(
														updateItemsEmissionsType(api, [
															{ ...itemData, emission_type: selected || null },
														])
													)
												}
											}
										}
									}}
								/>
							)}
						</div>
					</form>
				</>
			)}

			{(itemsStatus === FetchStatus.loading || emissionTypesStatus === FetchStatus.loading) && (
				<LoadingSpinner loadingText='Loading item...' />
			)}

			{itemsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white mb-4'>
						There was an error whilst trying to fetch this item. If this problem persists, please
						contact the Neoni team.
					</p>
					{itemsError && <p className='text-white mb-4'>Error message: {itemsError.toString()}</p>}
					<Button
						type='Primary'
						hasLeadingIcon
						additionalClass='mt-4'
						icon='fa-regular fa-arrow-left'
						text='Return to items overview'
						onClick={() => {
							navigate(routes.companyItems.path)
						}}
					/>
				</div>
			)}

			{emissionTypesStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the possible emission types. If this problem persists,
						please contact the Neoni team.
					</p>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyEditItem
