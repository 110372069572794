import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import {
	ADDPURCHASESUPLOAD_FAILURE,
	ADDPURCHASESUPLOAD_RESETFILE,
	ADDPURCHASESUPLOAD_SENDING,
	ADDPURCHASESUPLOAD_SENT,
	ADDPURCHASESUPLOAD_SETFILE,
} from '../slices/addPurchasesUploadSlice'
import { UPLOAD_FILE_DATA_ADDITION } from '../slices/csvFilesSlice'
import { NeoniApi } from '../../lib/api'

type IAddPurchasesUploadFile = {
	file: string
	accountingPeriodId: string
	filename: string
}

// Stores the file blob name and associated accounting period in the store, ready for upload use
export const setAddPurchaseUploadFile =
	({ file, accountingPeriodId, filename }: IAddPurchasesUploadFile) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: ADDPURCHASESUPLOAD_SETFILE,
			payload: {
				file,
				accountingPeriodId,
				filename,
			},
		})
	}

export const addPurchasesUploadFile =
	(api: NeoniApi, { file, accountingPeriodId, filename }: IAddPurchasesUploadFile) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: ADDPURCHASESUPLOAD_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			// Convert blob to a file again
			const fileResponse = await fetch(file)
			const unblobbedFile = await fileResponse.blob()

			// Use form data since we're sending a file too
			const formData = new FormData()
			formData.append('file', unblobbedFile)
			formData.append('accountingPeriodId', accountingPeriodId)
			formData.append('filename', filename)
			formData.append('_csrf', csrf)

			const body = await api.form(`csv/purchases`, formData)

			if (body.success === true) {
				// Successfully uploaded the file, show any errors or success
				dispatch({
					type: ADDPURCHASESUPLOAD_SENT,
					payload: {
						accountingPeriodId: body.accountingPeriodId,
						attempts: body.attemptCount,
						errors: body.errors,
						numberOfRecords: body.numberOfRecords,
					},
				})
				if (body.csvFileId !== undefined) {
					dispatch({
						type: UPLOAD_FILE_DATA_ADDITION,
						payload: {
							accountingPeriodId: body.accountingPeriodId,
							id: body.csvFileId,
							filename: body.filename,
							rowCount: body.numberOfRecords,
							uploaded_date: body.uploaded_date,
							uploader: body.uploader,
						},
					})
				}

				// Release the original blob from browser memory
				URL.revokeObjectURL(file)

				// Remove the blob file reference from the redux store
				dispatch({
					type: ADDPURCHASESUPLOAD_RESETFILE,
				})
			} else {
				// Error
				dispatch({
					type: ADDPURCHASESUPLOAD_FAILURE,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: ADDPURCHASESUPLOAD_FAILURE,
				payload: error,
			})
		}
	}
