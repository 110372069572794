import { useState } from "react";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import CurrencyInput from "../../components/BaseComponents/Forms/CurrencyInput";
import Label from "../../components/BaseComponents/Label";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import useApi from "../../hooks/useApi";
import { currencyToCents, getCSRFToken } from "../../lib/utils";

type CompanyProjectEditModalProps = {
    open?: boolean;
    onClose: () => void,
    onEdited: () => void,
    projectId: string;
    projectName: string;
    projectCode: string;
    projectValue: number;
}

function CompanyProjectEditModal({open=false, onClose, projectId, projectName, projectCode, projectValue, onEdited}: CompanyProjectEditModalProps) {
    const [loading, setLoading] = useState(false);
    const api = useApi();

    const onSave = async () => {
        setLoading(true)
        await api.put(`/projects/${projectId}/`, {
            _csrf: await getCSRFToken(),
            project: { name, revenue }
        })
        setLoading(false)
        onEdited();
        onClose()
    }

    const [name, setName] = useState(projectName);
    const [code, setCode] = useState(projectCode);
    const [revenue, setRevenue] = useState(projectValue);

    return (
        <Modal open={open} onClose={onClose} size="small">
            <PageHeadings title="Edit Project" />
            { loading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <Label className="block">Project name</Label>
                    <input
                        type="text"
                        className="rounded w-full"
                        value={name} 
                        onChange={e => setName(e.target.value)}
                    />
                    <Label className="block">Unique project code</Label>
                    <input
                        disabled
                        type="text"
                        className="rounded w-full text-charcoal-500 bg-charcoal-100"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                    />
                    <Label className="block">Project value (total sales/revenues)</Label>

                    <CurrencyInput name="revenue" defaultValue={revenue} onChange={value => setRevenue(currencyToCents(value))}/>
                    <div className="flex mt-4 space-x-2">
                        <Button type="Primary" text="Update" onClick={onSave} />
                        <Button type="Outlined" text="Cancel" onClick={onClose} />
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default CompanyProjectEditModal;
