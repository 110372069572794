import { Helmet } from "react-helmet";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../../components/Router/routes";
import { useEffect, useState } from "react";
import { generatePath, Navigate, useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import EditPortfolioModal from "./EditPortfolioModal";
import useCleanup from "../../hooks/useCleanup";
import Label from "../../components/BaseComponents/Label";
import usePagination from "../../hooks/usePagination";
import JurisdictionSelect from "../../components/BaseComponents/JurisdictionSelect";
import DeletePortfolioEntryModal from "./DeletePortfolioEntryModal";
import { formatDate, formatEmissionCO2Kg } from "../../lib/utils";
import useDataSort from "../../hooks/useDataSort";


function Portfolios() {
    const columns: TableColumn[] = [
        {
            id: 'jurisdictionCode',
            label: 'Location',
            sortable: true,
        },
        {
            id: 'organization',
            label: 'Organisation',
            sortable: true,
        },
        {
            id: 'end_date',
            label: 'Period',
            sortable: true,
            renderer: (key, data) => <span>{formatDate(data.startDate)} - {formatDate(data.endDate)} </span>
        },
        {
            id: 'scope1_total',
            label: 'Scope 1',
            sortable: true,
            renderer: (key, data) => <span>{ formatEmissionCO2Kg(data[key] || 0) }</span>
        },
        {
            id: 'scope2_total',
            label: 'Scope 2',
            sortable: true,
            renderer: (key, data) => <span>{ formatEmissionCO2Kg(data[key] || 0) }</span>
        },
        {
            id: 'scope3_total',
            label: 'Scope 3',
            sortable: true,
            renderer: (key, data) => <span>{ formatEmissionCO2Kg(data[key] || 0) }</span>
        },
        {
            id: 'total_emissions',
            label: 'Total',
            sortable: true,
            renderer: (key, data) => <span>{ formatEmissionCO2Kg(data[key] || 0) }</span>
        },
        {
            id: 'intensity_ratio',
            label: 'Intenstity',
            sortable: true,
            renderer: (key, data) => data[key]?.toFixed(6),
        },
        {
            id: 'options',
            label: 'Actions',
            renderer: (columnKey, row) => {
                return (
                    <Button
                        type="Primary"
                        icon="fa-regular fa-trash"
                        hasLeadingIcon
                        size="sm"
                        onClick={() => setDeletionCandidate(row) }
                    />
                )
            }
        },
        {
            id: 'view',
            label: '',
            renderer: (columnKey, row) => {
                return (
                    <Button
                        isLink
                        text="View"
                        type="Primary" size="sm"
                        url={generatePath(
                            routes.publicProfile.path,
                            {companyId: row['companyId']}
                        )}
                    />
                )
            }
        }
    ]
    const [portfolios, setPortfolios] = useState<any[]>([]);
    const api = useApi();
    const navigate = useNavigate();

    const { portfolioId } = useParams()

    const pagination = usePagination();

    const [loadingPortfolios, setLoadingPortfilios] = useState(true);
    const [loadingEntries, setLoadingEntries] = useState(true);

    const [portfolioEntries, setPortflioEntries] = useState<any>([])

    const [openEditModal, setOpenEditModal] = useState(false);

    const [deletionCandidate, setDeletionCandidate] = useState<any>(null);

    const dataSort = useDataSort('organization', 'desc');

    const loadPortfolios = useCleanup(() => {
        let cancelled = false;
        const run = async () => {
            const qs = new URLSearchParams({
                ...pagination.params
            });
            const result = await api.get('portfolios/?' + qs)
            if(cancelled) {
                return;
            }

            setPortfolios(result.data.portfolios);
            setLoadingPortfilios(false);
        }

        run();

        return () => {
            cancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    useEffect(() => {
        loadPortfolios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.params])

    const loadEntries = useCleanup(() => {
        let cancelled = false;
        setLoadingEntries(true);
        const run = async () => {
            const qs = new URLSearchParams({
                ...dataSort.params,
                ...filters,
                ...pagination.params,
            });
            const result = await api.get(`portfolios/${portfolioId}/companies?` + qs)
            if(cancelled) {
                return;
            }
            setPortflioEntries(result.data.companies);
            setLoadingEntries(false);
            pagination.readMeta(result.data.meta)
        }

        if(portfolioId) {
            run();
        } else {
            setLoadingEntries(false);
            setPortflioEntries([]);
        }

        return () => {
            cancelled = true;
        };
    })

    const initialFilters = {
        year: '',
        organisationName: '',
        organisationNumber: '',
        publicBody: '',
        currency: '',
        jurisdictionCode: '',
    };

    const [filters, setFilters] = useState(initialFilters);

    useEffect(
        loadEntries,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [portfolioId, filters, pagination.params, dataSort.params]
    );


    if(loadingPortfolios) {
        return (
            <LoadingSpinner />
        )
    }

    const portfolio = portfolios.find(portfolio => portfolio.id === portfolioId);
    if(!portfolio) {
        return <Navigate to={routes.companiesPortfolio.path} />
    }

	const startYear = 2010;
	const years = [];
	for(let i = startYear; i <= new Date().getFullYear(); i++) {
		years.unshift(i)
	}

    return(

        <PageTemplate testid='company-dashboard-main'>
            <Helmet>
                <title>{routes.portfolio.name}</title>
            </Helmet>
            <PageHeadings title='Portfolio Views' />
            <div className="flex mt-8 items-end">
                <div>
                    <Label className="block">Select portfolio</Label>
                    <select
                        className="rounded mr-4"
                        onChange={e => {
                            const portfolioId = e.target.value;
                            navigate(generatePath(routes.companyPortfolio.path, { portfolioId}));
                        }}
                        value={portfolioId}
                    >   
                        <option value="">Select Portfolio</option>
                        { portfolios.map((portfolio: any) => {
                            return (
                                <option
                                    value={portfolio.id}
                                    key={portfolio.id}
                                >
                                    { portfolio.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="flex">
                    <Button type="Primary" text="Edit portfolio" onClick={() => setOpenEditModal(true)} size="sm" />
                </div>
                <div className="grow text-right">
                    <Button size="sm" type="Primary" disabled text="Chart Views" icon="fa-regular fa-chart-tree-map" hasLeadingIcon/>
                </div>
            </div>

            <div className="flex mt-4 space-x-2">
                <div>
                    <Label className="block">Name</Label>
                    <input
                        type="text"
                        className="rounded"
                        placeholder="iSumio..."
                        value={filters.organisationName}
                        onChange={e => setFilters({...filters, organisationName: e.target.value})}
                    />
                </div>
                <div>
                    <Label className="block">Organisation Number</Label>
                    <input
                        type="text"
                        className="rounded"
                        placeholder="123..."
                        value={filters.organisationNumber}
                        onChange={e => setFilters({...filters, organisationNumber: e.target.value})}
                    />
                </div>
            </div>
            <div className="flex mt-2 space-x-2">
                <div>
                    <Label className="block">Jurisdiction</Label>
                    <JurisdictionSelect
                        value={filters.jurisdictionCode}
                        onChange={value => setFilters({...filters, jurisdictionCode: value})}
                    />
                </div>
                <div>
                    <Label className="block">Public Body</Label>
                    <select
                        className="rounded"
                        value={filters.publicBody}
                        onChange={e => setFilters({...filters, publicBody: e.target.value})}
                    >
                        <option value="">Any</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div>
                    <Label className="block">Year</Label>
                    <select
                        className="rounded"
                        value={filters.year}
                        onChange={e => setFilters({...filters, year: e.target.value})}
                    >
                        <option value=''>Any</option>
                        { years.map(year => <option key={year} value={year}>{ year}</option> )}
                    </select>
                </div>
                <div className="grow text-right">
                    <Button
                        size="sm"
                        type="Primary"
                        text="Clear filters"
                        icon="fa-regular fa-filter-circle-xmark"
                        hasLeadingIcon
                        onClick={() => setFilters(initialFilters)}
                    />
                </div>
            </div>

            <SimpleSortTable
                isLoading={loadingEntries}
                additionalClass="mt-8"
                data={portfolioEntries}
                columns={columns}
                defaultSorting={dataSort.defaultSorting}
                onSortPress={dataSort.onChange}
            />
            <Pagination
                isLoading={loadingEntries}
                pagination={pagination.pagination}
                onPageChange={pagination.onPageChange}
            />
            <EditPortfolioModal
                onClose={() => setOpenEditModal(false)}
                onEdited={() => {
                    setOpenEditModal(false);
                    loadPortfolios()
                }}
                portfolioId={portfolio.id}
                portfolioName={portfolio.name}
                isOpen={openEditModal}
            />
            { deletionCandidate && (
                <DeletePortfolioEntryModal
                    companyId={deletionCandidate.companyId}
                    companyName={deletionCandidate.name}
                    onClose={() => setDeletionCandidate(null)}
                    onDeleted={() => {
                        setDeletionCandidate(null);
                        loadEntries();
                    }}
                    portfolioId={portfolio.id}
                    portfolioName={portfolio.name}
                    isOpen
                />
            )}
        </PageTemplate>
    )
}

export default Portfolios;
