import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type UpdateBulkSuppliersSliceType = {
	status: PostStatus
	error?: string
	message?: string
}

const UpdateBulkSuppliersInitialState: UpdateBulkSuppliersSliceType = {
	status: PostStatus.initial,
	error: undefined,
	message: undefined,
}

export const UpdateBulkSuppliersSlice = createSlice({
	name: 'Update Bulk Suppliers',
	initialState: UpdateBulkSuppliersInitialState,
	reducers: {
		UPDATE_BULK_SUPPLIERS_SENDING: (state) => {
			return {
				...state,
				status: PostStatus.sending,
				message: undefined,
			}
		},
		UPDATE_BULK_SUPPLIERS_SENT: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.sent,
				error: undefined,
				message: action.payload,
			}
		},
		UPDATE_BULK_SUPPLIERS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: PostStatus.error,
				error: action.payload,
			}
		},
		UPDATE_BULK_SUPPLIERS_RESET: () => {
			return UpdateBulkSuppliersInitialState
		},
	},
})

// Action types
export const {
	UPDATE_BULK_SUPPLIERS_SENDING,
	UPDATE_BULK_SUPPLIERS_SENT,
	UPDATE_BULK_SUPPLIERS_ERROR,
	UPDATE_BULK_SUPPLIERS_RESET,
} = UpdateBulkSuppliersSlice.actions

// Selectors
export const selectUpdateBulkSuppliersStatus = (state: RootState) =>
	state.updateBulkSuppliers.status
export const selectUpdateBulkSuppliersError = (state: RootState) => state.updateBulkSuppliers.error
export const selectUpdateBulkSuppliersMessage = (state: RootState) =>
	state.updateBulkSuppliers.message

export default UpdateBulkSuppliersSlice.reducer
