import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { PeriodStatistic } from '../../../../types/PeriodStatistic'

type ICompanyInsightsScope1Graph = {
	chartData: Array<PeriodStatistic>
}

const CompanyInsightsScope1Graph: FunctionComponent<ICompanyInsightsScope1Graph> = ({
	chartData,
}) => {
	const scope1ChartData = chartData
		.map((period) => {
			return {
				name: `${dayjs(period.startdate).format('MM/YYYY')} - ${dayjs(period.endDate).format(
					'MM/YYYY'
				)}`,
				RefrigerantOther: Number(
					period.statistics.scope1ByLevel1
						.filter((item) => item.label === 'Refrigerant & other')[0]
						.sum.toFixed(2)
				),
				Bioenergy: Number(
					period.statistics.scope1ByLevel1
						.filter((item) => item.label === 'Bioenergy')[0]
						.sum.toFixed(2)
				),
				Fuels: Number(
					period.statistics.scope1ByLevel1
						.filter((item) => item.label === 'Fuels')[0]
						.sum.toFixed(2)
				),
			}
		})
		.reverse()

	return (
		<ResponsiveContainer width='100%' aspect={1.778}>
			<BarChart
				width={500}
				height={300}
				data={scope1ChartData}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<XAxis
					dataKey='name'
					axisLine={false}
					tickLine={false}
					style={{ fontSize: '12px' }}
					tick={{ fill: '#FFF' }}
				/>
				<YAxis
					label={{ value: 'kgC02e', angle: -90, position: 'left', fill: '#FFF' }}
					axisLine={false}
					tickLine={false}
					style={{ fontSize: '12px' }}
					tick={{ fill: '#FFF' }}
				/>
				<Tooltip
					contentStyle={{ backgroundColor: '#2E3A46', borderColor: '#252F38', borderWidth: 2 }}
					labelStyle={{ color: 'white' }}
					cursor={{ fill: '#12171C' }}
				/>
				<Legend
					align='right'
					verticalAlign='top'
					layout='vertical'
					formatter={(value) => <span className='text-white ml-2 text-sm'>{value}</span>}
				/>
				<Bar dataKey='Fuels' stackId='a' fill='#E6EFFF' name='Fuels' barSize={48} />
				<Bar dataKey='Bioenergy' stackId='a' fill='#669EFF' name='Bioenergy' barSize={48} />
				<Bar
					dataKey='RefrigerantOther'
					stackId='a'
					fill='#005EFF'
					name='Refrigerant & Other'
					barSize={48}
				/>
			</BarChart>
		</ResponsiveContainer>
	)
}

export default CompanyInsightsScope1Graph
