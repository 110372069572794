import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Company } from '../../types/Company'
import { PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type CreateCompanyState = {
	loading: PostStatus
	error?: string
	company?: Company
}
const createCompanyInitialState: CreateCompanyState = {
	loading: PostStatus.initial,
	error: undefined,
	company: undefined,
}

export const createCompanySlice = createSlice({
	name: 'createCompany',
	initialState: createCompanyInitialState,
	reducers: {
		CREATECOMPANY_SENDING: (state) => {
			return {
				...state,
				loading: PostStatus.sending,
			}
		},
		CREATECOMPANY_SENT: (state, action: PayloadAction<Company>) => {
			return {
				...state,
				loading: PostStatus.sent,
				company: action.payload,
			}
		},
		CREATECOMPANY_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				loading: PostStatus.error,
				error: action.payload,
			}
		},
		CREATECOMPANY_RESET: () => {
			return createCompanyInitialState
		},
	},
})

// Action types
export const {
	CREATECOMPANY_SENDING,
	CREATECOMPANY_SENT,
	CREATECOMPANY_ERROR,
	CREATECOMPANY_RESET,
} = createCompanySlice.actions

// Selectors
export const selectCreateCompanyStatus = (state: RootState) => state.createCompany.loading
export const selectCreateCompanyError = (state: RootState) => state.createCompany.error
export const selectCreatedCompany = (state: RootState) => state.createCompany.company

export default createCompanySlice.reducer
