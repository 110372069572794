import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import useCompanyData from '../hooks/useCompanyData'
import { Button, Input, LoadingSpinner } from '../components/BaseComponents'
import {
	default as ISICSelector,
	validate as ISICValidation,
} from '../components/BaseComponents/ISICSelector/ISICSelector'
import { currencyToCents, getCSRFToken } from '../lib/utils'
import CurrencyInput from '../components/BaseComponents/Forms/CurrencyInput'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useUserData from '../hooks/useUserData'
import { routes } from '../components/Router/routes'
import { CurrencyCode } from '../types/currency'
import useApi from '../hooks/useApi'

type CompanyRequest = {
	id: string
	turnover: number
	number_of_employees?: number
	industryCodes: string[]
	currencyCode: string
}

function CompanySetup() {
	const userData = useUserData()
	const companyId = userData.companyId
	const navigate = useNavigate()
	const { companyData, error, isLoading } = useCompanyData(companyId)
	const [submissionError, setSubmissionError] = useState(false)
	const [currencyCode, setCurrencyCode] = useState<CurrencyCode>('GBP');
	const api = useApi();

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm({ mode: 'onSubmit' })

	useEffect(() => {
		reset()
	}, [companyData])

	const onSubmit: SubmitHandler<any> = async (data: CompanyRequest) => {
		let csrfToken = await getCSRFToken()

		// The frontend always send type: ISIC align with the code for now, since we don’t use any other type.
		const isic = data.industryCodes.map((code) => ({ codeType: 'ISIC', code }))

		// Convert turnover value if it has changed
		let turnover = companyData?.turnover
		if (companyData?.turnover !== data.turnover) {
			turnover = currencyToCents(data.turnover)
		}

		const dataWithCsrf = {
			...data,
			industryCodes: isic,
			turnover,
			currencyCode,
			_csrf: csrfToken,
		}

		try {
			await api.post(`company/setUp/${companyId}`, dataWithCsrf)
			setSubmissionError(false)
			navigate(routes.landingPage.path, { replace: true })
		} catch(error) {
			// Display submission error box
			setSubmissionError(true)
		}
	}

	return (
		<div data-testid='company-setup-main' className='w-full'>
			<Helmet>
				<title>{routes.companySetup.name}</title>
			</Helmet>
			{isLoading && <LoadingSpinner />}
			{error && (
				<div className='flex h-screen justify-center items-center text-white text-2xl'>
					Could not load organisation data. Please try again.
				</div>
			)}
			{companyData && (
				<form className='flex justify-center items-center h-screen flex-col'>
					<h2 className='text-2xl font-bold text-charcoal-050'>Review Organisation details</h2>

					<p className='sr-only'>* denotes a required field</p>

					<div className='my-3xl p-lg bg-charcoal-050 rounded-md w-2/3 lg:w-1/3 grid'>
						<div className='grid grid-cols-2 my-2'>
							<span className='font-bold'>{companyData.name}</span>
						</div>

						<hr className='text-charcoal-200 mt-sm' />

						<div className="flex mt-sm">
							<div className='text-charcoal-500'>Jurisdiction:</div>
							<div className="grow text-right">{companyData.jurisdiction_code}</div>
						</div>
						<div className="flex mt-sm">
							<div className='text-charcoal-500'>Organization Number:</div>
							<div className="grow text-right">{companyData.number}</div>
						</div>

						<hr className='text-charcoal-200 mt-sm'></hr>

						<div className='grid grid-cols-2 my-2'>
							<p>
								<span className='text-charcoal-500'>Currency</span>
								<span className='text-error-600'> *</span>
								<span className='sr-only'>(required)</span>
							</p>

							<select
								value={currencyCode}
								onChange={(e => setCurrencyCode(e.target.value as CurrencyCode))}
								className={`block w-full rounded focus:outline-none border-charcoal-200 focus:border-charcoal-200 focus:ring-1 appearance-none focus:border-algae-500 focus:ring-algae-500`}
							>
								<option>GBP</option>
								<option>EUR</option>
								<option>USD</option>
							</select>
						</div>
						<div className='grid grid-cols-2 my-2'>
							<p>
								<span className='text-charcoal-500'>Average yearly turnover</span>
								<span className='text-error-600'> *</span>
								<span className='sr-only'>(required)</span>
							</p>

							<Controller
								name='turnover'
								control={control}
								defaultValue={companyData.turnover}
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<>
										<CurrencyInput
											name='turnover'
											errors={errors}
											placeholder={'0,000,000'}
											defaultValue={companyData.turnover}
											onChange={onChange}
											currencyCode={currencyCode}
										/>
										Value: { JSON.stringify(value) }
									</>
								)}
							/>
						</div>
						<hr className='text-charcoal-200 my-sm'></hr>

						<div className='grid grid-cols-2 my-2'>
							<p className='text-charcoal-500'>
								Number of employees
								<span className='block text-xs'>Full time equivalent</span>
							</p>

							<Input
								register={register}
								errors={errors}
								type='number'
								name='numberOfEmployees'
								validation={{ min: 0 }}
								defaultValue={companyData.number_of_employees && companyData.number_of_employees}
							/>
						</div>

						<div className='my-2'>
							<p>
								<span className='text-charcoal-500'>Nature of business: Sector Code (ISIC)</span>
								<span className='text-error-600'> *</span>
								<span className='sr-only'>(required)</span>
							</p>
							<Controller
								name='industryCodes'
								control={control}
								defaultValue={(companyData && companyData.industry_codes) || []}
								rules={{
									validate: {
										minmax: ISICValidation(1, 4, (companyData && companyData.industry_codes) || []),
									},
								}}
								render={({ field: { onChange }, fieldState: { error, isDirty } }) => (
									<ISICSelector
										updateHandler={onChange}
										error={error}
										isDirty={isDirty || !!error}
										fixedValues={(companyData && companyData.industry_codes) || []}
									/>
								)}
							/>
						</div>
						<hr className='text-charcoal-200 my-sm'></hr>

						<p className='text-charcoal-500 mt-2'>Registered address</p>
						<p className='text-sm text-charcoal-900 mt-3'>{companyData.address}</p>
						<hr className='text-charcoal-200 my-sm'></hr>
					</div>

					{submissionError && (
						<div className='my-3xl p-lg px-5 bg-error-400 rounded-md w-2/3 lg:w-1/3 grid'>
							<p className='flex items-center gap-5'>
								<span
									className='h-xl w-xl text-charcoal-500 fa-regular fa-exclamation-circle'
									aria-hidden='true'
								></span>
								<span>
									Oops! There was an error submitting your data. Please try again: if the problem
									persists, please contact the Neoni team.
								</span>
							</p>
						</div>
					)}

					<Button
						onClick={handleSubmit(onSubmit)}
						type='Primary'
						hasTrailingIcon={true}
						icon='fa-regular fa-chevron-right'
						text='Finish Setup'
					/>
				</form>
			)}
		</div>
	)
}
export default CompanySetup
