import { Dispatch } from 'redux'
import { ApiPagination } from '../../types/PaginationApi'
import {
	SUPPLIERS_ERROR,
	SUPPLIERS_LOADED,
	SUPPLIERS_LOADING,
	SUPPLIERS_SINGLELOADED,
} from '../slices/suppliersSlice'
import { NeoniApi } from '../../lib/api'

export const GET_SUPPLIERS_LIMIT = 20

interface IGetSuppliers extends Omit<ApiPagination, 'orderBy'> {
	orderBy?: 'name' | 'supplierName' | 'scope' | 'jurisdiction' | string
	connectedSuppliers?: boolean
	sectorCodes?: any
}

export const getSuppliers =
	(api: NeoniApi, {
		page = 1,
		limit = GET_SUPPLIERS_LIMIT,
		orderBy = 'name',
		direction = 'asc',
		connectedSuppliers = undefined,
		q = '',
		sectorCodes,
	}: IGetSuppliers = {}) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: SUPPLIERS_LOADING,
		})
		try {
			const urlParams = {
				page: page.toString(),
				limit: limit.toString(),
				orderBy,
				direction,
				q,
			}

			let combinedUrlParams

			if (connectedSuppliers) {
				combinedUrlParams = {
					...urlParams,
					connectedSuppliers: '1',
				}
			} else if (sectorCodes) {
				combinedUrlParams = {
					...urlParams,
					sectorCodes: sectorCodes.toString(),
				}
			} else {
				combinedUrlParams = urlParams
			}

			const body = await api.get(`suppliers?` + new URLSearchParams(combinedUrlParams))

			if (body.success === true) {
				// Successfully fetched suppliers
				dispatch({
					type: SUPPLIERS_LOADED,
					payload: body.suppliers,
				})
			} else {
				// Error
				dispatch({
					type: SUPPLIERS_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: SUPPLIERS_ERROR,
				payload: error,
			})
		}
	}

export const getSupplier = (api: NeoniApi, supplierId?: string) => async (dispatch: Dispatch) => {
	dispatch({
		type: SUPPLIERS_LOADING,
	})

	try {
		const body = await api.get(`suppliers/${supplierId}`);

		if (body.success === true) {
			// Successfully fetched suppliers
			dispatch({
				type: SUPPLIERS_SINGLELOADED,
				payload: body.supplier,
			})
		} else {
			// Error
			dispatch({
				type: SUPPLIERS_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: SUPPLIERS_ERROR,
			payload: error,
		})
	}
}
