import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { SupplierRefinementUpdate } from '../../types/PurchaseItem'
import {
	UPDATE_BULK_SUPPLIERS_ERROR,
	UPDATE_BULK_SUPPLIERS_SENDING,
	UPDATE_BULK_SUPPLIERS_SENT,
} from '../slices/updateBulkSuppliersSlice'
import { NeoniApi } from '../../lib/api'

export const refineSuppliers =
	(api: NeoniApi, suppliers: SupplierRefinementUpdate[], accountingPeriodId: string) => async (dispatch: Dispatch) => {
		dispatch({
			type: UPDATE_BULK_SUPPLIERS_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			const body = await api.post('purchases/scope3Refinement', {
				supplierList: suppliers,
				accountingPeriodId: accountingPeriodId,
				_csrf: csrf,

			})

			if (body.success === true) {
				dispatch({
					type: UPDATE_BULK_SUPPLIERS_SENT,
					payload: body.message,
				})
			} else {
				dispatch({
					type: UPDATE_BULK_SUPPLIERS_ERROR,
					payload: { error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: UPDATE_BULK_SUPPLIERS_ERROR,
				payload: { error: error },
			})
		}
	}
