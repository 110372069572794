import { ReactComponent as NeoniLogo } from '../../assets/neoni-logo.svg'
import Button from '../BaseComponents/Button/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormEvent, useContext, useState } from 'react';
import routes from '../Router/routes';
import { userContext } from '../../context/UserContext';


function TopBar() {
	const navigate = useNavigate();
	const [searchString, setSearchString] = useState('');

	const { userData } = useContext(userContext);

	const onSearch = (e: FormEvent) => {
		e.preventDefault();
		const qs = new URLSearchParams({s: searchString})
		navigate(`/public/profiles/?` + qs.toString());
	}

	const location = useLocation();
	
	const disabled = location.pathname === routes.secrSearch.path;

	return (
		<>
			<div
				data-testid='sidebar'
				className='p-sm bg-charcoal-700 flex items-center grow-0'
				style={{
					height: '68px',
				}}
			>
				<div className="flex-grow">
					<Link to="/">
						<NeoniLogo className='my-lg mx-4' />
					</Link>
				</div>
				<form onSubmit={e => onSearch(e)}>
					<div className="pr-8 flex">
						<input
							disabled={disabled}
							type="text"
							className={`rounded-l-sm ${(disabled ? 'bg-charcoal-400' : '')}`}
							placeholder="Search for organisation by name or number" 
							onChange={e => setSearchString(e.target.value)}
							value={searchString} />

						<button
							className={`flex items-center rounded-r-sm  border  px-md text-charcoal-500 ${disabled ? 'bg-charcoal-100 border-charcoal-500' : 'bg-algae-100 border-algae-500'}`}
							type="submit"
							disabled={disabled}
						>
							Search
							<span className='ml-md fa-regular fa-search'></span>
						</button>
					</div>
				</form>

				{ !userData && (
					<div>
						<Button
							type="Primary"
							text="Log in"
							isLink
							url={routes.publicLogin.path}
							size="md" />
						
						<Link to={routes.publicSignUp.path} className="text-white px-2">
							Sign up
						</Link>
					</div>
				)}
			</div>
		</>
	)
}

export default TopBar
