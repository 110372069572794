import { useContext, useEffect, useState } from "react";
import PageHeadings from "../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate";
import useApi from "../hooks/useApi";
import SimpleSortTable, { TableColumn } from "../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import { centsToCurrency, formatDate, getCSRFToken } from "../lib/utils";
import { Link } from "react-router-dom";
import Pagination from "../components/BaseComponents/Pagination/Pagination";
import useUserData from "../hooks/useUserData";
import useDataSort from "../hooks/useDataSort";
import usePagination from "../hooks/usePagination";
import useCurrencyFormatter from "../hooks/useCurrencyFormatter";
import { userContext } from "../context/UserContext";
import useCleanup from "../hooks/useCleanup";

type ProjectSelectProps = {
    projects: { id: string, name: string }[],
    projectId: string,
    purchaseId: string;
}
function ProjectSelect({projects, projectId, purchaseId}: ProjectSelectProps) {
    const [value, setValue] = useState(projectId);
    const [loading, setLoading] = useState(false);
    const api = useApi();

    const updatePurchaseProject = async (projectId: string) => {
        setLoading(true);
        setValue(projectId);
        await api.put(`/singlePurchase/${purchaseId}`, {
            _csrf: await getCSRFToken(),
            purchase: {
                projectId,
            }
        });
        setLoading(false);
    }

    return (
        <select
            className="rounded text-charcoal-900"
            value={value}
            disabled={loading}
            onChange={(e) => {
                updatePurchaseProject(e.target.value)
            }}
        >
            <option value="">-</option>
            { projects.map(project => {
                return (
                    <option key={project.id} value={project.id}>
                        { project.name}
                    </option>
                )
            })}
        </select>
    )
}

function PurchaseList() {
	const formatCurrency = useCurrencyFormatter();
    const formatNumber = Intl.NumberFormat('en-GB').format;

    
    const updatePurchaseProject = async (purchaseId: string, projectId: string) => {
        await api.put(`/singlePurchase/${purchaseId}`, {
            _csrf: await getCSRFToken(),
            purchase: {
                projectId,
            }
        });
        loadPurchases()
    }

    const columns: TableColumn[] = [
        {
            id: 'purchaseDate',
            label: 'Date',
            renderer: (key, data) => <span>{formatDate(data[key])}</span>,
            sortable: true,
        },
        {
            id: 'price',
            label: 'Price',
            renderer: (key, data) => {
                return (
                    <span>
                        { formatCurrency(Number(centsToCurrency(data.price))) }
                    </span>
                );
            },
            textAlign: 'right',
            sortable: true,
        },
        {
            id: 'quantity',
            label: 'Quantity',
            renderer: (key, data) => (
                <span>
                    { data.quantity } { data.unit }
                </span>
            ),
            textAlign: 'right',
            sortable: true,
        },
        {
            id: 'emission_value',
            label: 'Emissions',
            renderer: (key, data) => {
                return (
                    <span>
                        {
                            data[key]
                                ? ( formatNumber(data[key]) + ' kgCO₂e' )
                                : '-'
                        }
                    </span>
                );
            },
            textAlign: 'right',
            sortable: true,
        },
        {
            id: 'item_name',
            label: 'Item Name',
            renderer: (key, data) => (
                <Link
                    className="underline" to={`/purchases/items/${data['itemId']}`}
                >
                    {data[key]}
                </Link>
            ),
            sortable: true,
        },
        {
            id: 'supplier_name',
            label: 'Supplier',
            renderer: (key, data) => (
                <Link
                    className="underline" to={`/purchases/suppliers/${data['supplier_id']}`}
                >
                    {data[key]}
                </Link>
            ),
            sortable: true,
        },
        {
            id: 'item_scope',
            label: 'Scope',
            sortable: true,
        },
    ]
    const api = useApi();
    const userData = useUserData();

    const dataSort = useDataSort('purchaseDate', 'desc');

    const [data, setData] = useState<any[]>([])

    const pagination = usePagination(1, 20);

    const [itemSearchString, setItemSearchString] = useState('');
    const [supplierSearchString, setSupplierSearchString] = useState('');
    const [loading, setLoading] = useState(false);

    const { checkAccess } = useContext(userContext);
    const [projects, setProjects] = useState<any[]>([]);
    const [projectId, setProjectId] = useState<any>('');



    if(checkAccess('company.projects')) {
        columns.push({
            id: 'project_name',
            label: 'Project',
            renderer: (key, data) => {
                const projectId = data['projectId'];
                const purchaseId = data['id']
                return <ProjectSelect
                    projectId={projectId}
                    projects={projects}
                    purchaseId={purchaseId}
                />

                // return (
                //     <select
                //         className="rounded text-charcoal-900"
                //         value={projectId}
                //         onChange={(e) => {
                //             updatePurchaseProject(purchaseId, e.target.value)
                //         }}
                //         disabled={loadingPurchaseIds.includes(purchaseId)}
                //     >
                //         <option value="">-</option>
                //         { projects.map(project => {
                //             return (
                //                 <option key={project.id} value={project.id}>
                //                     { project.name}
                //                 </option>
                //             )
                //         })}
                //     </select>
                // );
            },
            sortable: true,
        })
    }

    useEffect(() => {
        if(!checkAccess) {
            return;
        }
        const run = async () => {
            const results = await api.get('/projects?sortKey=name');
            setProjects(results.data.rows);
        }
        run();
    }, [])
    
    const loadPurchases = useCleanup(() => {
        const run = async () => {
            setLoading(true);
            const qs = new URLSearchParams({
                ...pagination.params,
                ...dataSort.params,
                projectId: projectId,
                itemName: itemSearchString,
                supplierName: supplierSearchString,
            })
            const result = await api.get('/purchaseList?' + qs.toString());
            setData(result.data.data)
            pagination.readMeta(result.data.meta);
            setLoading(false);
        }

        run();
    });

    useEffect(
        loadPurchases, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        pagination.params,
        dataSort.params,
        itemSearchString,
        supplierSearchString,
        projectId,
    ])

    return (
        <PageTemplate>
            <PageHeadings title="Purchase List" />
            <div className="flex space-x-4 my-4">
                <div>
                    <label className="text-charcoal-100">Item Name</label>
                    <br />
                    <input
                        type="text"
                        className="rounded"
                        value={itemSearchString}
                        onChange={e => setItemSearchString(e.target.value)}
                        placeholder="Search by item name"
                    />
                </div>
                <div>
                    <label className="text-charcoal-100">Supplier Name</label>
                    <br />
                    <input
                        type="text"
                        className="rounded"
                        value={supplierSearchString}
                        onChange={e => setSupplierSearchString(e.target.value)}
                        placeholder="Search by supplier name"
                    />
                </div>
                { userData.access?.company.permissions.projects && (
                    <div>
                        <label className="text-charcoal-100 block">Project</label>
                        <select
                            className="rounded"
                            value={projectId}
                            onChange={e => setProjectId(e.target.value)}
                        >
                            <option value="">any</option>
                            {projects.map(project => {
                                return (
                                    <option value={project.id}>{project.name}</option>
                                )
                            })}
                        </select>
                    </div>
                )}
            </div>
            <SimpleSortTable
                isLoading={loading}
                defaultSorting={dataSort.defaultSorting}
                onSortPress={dataSort.onChange}
                data={data}
                columns={columns}
            />

            <Pagination
                isLoading={loading}
                pagination={ pagination.pagination}
                onPageChange={pagination.onPageChange}
            />
        </PageTemplate>
    )
}

export default PurchaseList;
