import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import { getCSRFToken } from "../../lib/utils";
import useCleanup from "../../hooks/useCleanup";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import ISICSelector from "../../components/BaseComponents/ISICSelector/ISICSelector";

type AdminCompanySummaryProps = {
    companyId: string;
}

const formatNumber = Intl.NumberFormat().format;

function AdminCompanySummary({ companyId }: AdminCompanySummaryProps) {
    const [data, setData] = useState<any>();
    const api = useApi();

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteCofirmationOpen, setDeleteConfirmatinOpen] = useState(false);
    const [deletionConfirmationText, setDeletionConfirmationText] = useState('');

    const [isUpdating, setIsUpdating] = useState(false);

    const [industryCodes, setIndustryCodes] = useState<string[]>([]);

    const updateIndustryCodes = async () => {
        setIsUpdating(true);
        await api.put(`/admin/company/${companyId}`, {
            _csrf: await getCSRFToken(),
            company: {
                industryCodes,
            }
        });
        setIsUpdating(false);
    };

    const getData = useCleanup(() => {
        let cancelled = false;
        const run = async () => {
            const result = await api.get(`admin/company/${companyId}/summary`);
            setData(result.data);
            setIndustryCodes(result.data?.isicCode ? [result.data?.isicCode] : [])
        };
        run();
        return () => {
            cancelled = true;
        }
    })
    useEffect(() => {
        getData()
    }, [companyId])


    const onDeleteClick = () => {
        setDeleteConfirmatinOpen(true);
    }

    const onDelete = async () => {
        setIsDeleting(true);
        setDeleteConfirmatinOpen(false);
        await api.delete(`/admin/company/${companyId}/data`, {
            _csrf: await getCSRFToken()
        });
        await getData();

        setIsDeleting(false);
    }

    if(!data) {
        return <LoadingSpinner />
    }
    return (
        <div>
            <dl className='mt-3 grid grid-cols-3 text-white'>
                <dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Organisation Name</dt>
                <dd className='col-span-2 p-4 border-y border-charcoal-200'>
                    { data.company.name }
                </dd>

                <dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>iSumio Internal ID</dt>
                <dd className='col-span-2 p-4 border-y border-charcoal-200'>
                    { data.company.id }
                </dd>

                <dt className='bg-charcoal-400 p-4 border-y border-charcoal-200'>Organisation number</dt>
                <dd className='col-span-2 p-4 border-y border-charcoal-200'>
                    { data.company.number || '-'}
                </dd>

                <dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Jurisdiction</dt>
                <dd className='col-span-2 p-4 border-b border-charcoal-200'>
                    {data.company.jurisdiction_code || '-'}
                </dd>

                <dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Currency Code</dt>
                <dd className='col-span-2 p-4 border-b border-charcoal-200'>
                    { data.company.currency_code || '-' }
                </dd>

                <dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>Address</dt>
                <dd className='col-span-2 p-4 border-b border-charcoal-200'>
                    { data.company.address || '-' }
                </dd>

                <dt className='bg-charcoal-400 p-4 border-b border-charcoal-200'>ISIC Code</dt>
                <dd className='col-span-2 p-4 border-b border-charcoal-200'>
                    <div className="flex space-x-2">
                        { isUpdating ? (
                            <LoadingSpinner inline />
                        ) : (
                            <>
                                <div className="flex-grow text-charcoal-900">
                                    <ISICSelector
                                        min={1}
                                        max={1}
                                        value={industryCodes}
                                        updateHandler={setIndustryCodes}
                                    />
                                </div>
                                <div>
                                    <Button type="Primary" text="Update" size="md"
                                        onClick={() => updateIndustryCodes()}/>
                                </div>
                            </>
                        )}
                    </div>
                </dd>
            </dl>
            <div className="text-white">Organisation Data</div>
            { isDeleting 
            ? (
                <LoadingSpinner/>
            ) : (
                <dl className='mt-3 grid grid-cols-3 text-white'>
                    <dt className='bg-charcoal-400 p-1 border-y border-charcoal-200'>Accounting Periods</dt>
                    <dd className='col-span-2 p-1 border-y border-charcoal-200'>
                        { formatNumber(data.periodCount || 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-y border-charcoal-200'>Suppliers</dt>
                    <dd className='col-span-2 p-1 border-y border-charcoal-200'>
                        { formatNumber(data.supplierCount || 0)}
                    </dd>


                    <dt className='bg-charcoal-400 p-1 border-y border-charcoal-200'>Items</dt>
                    <dd className='col-span-2 p-1 border-y border-charcoal-200'>
                        { formatNumber(data.itemCount|| 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Purchases</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        {formatNumber(data.purchaseCount|| 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Projects</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        { formatNumber(data.projectCount || 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Reports</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        { formatNumber(data.reportCount || 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Portfolios</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        { formatNumber(data.portfolioCount || 0)}
                    </dd>
                </dl>
            )}
            <Button
                additionalClass="mt-4" type="Primary" text="Delete Organisation Data"
                onClick={onDeleteClick}
                disabled={isDeleting}
            />
            <Modal
                open={deleteCofirmationOpen}
                onClose={() => setDeleteConfirmatinOpen(false)}
            >
                <PageHeadings title="Confirm Deletion of Organisation Data" />
                <p className="text-white mt-8">
                    Confirm deleting the following data for <strong>{data.company.name}</strong>?
                </p>

                <dl className='mt-3 grid grid-cols-3 text-white'>
                    <dt className='bg-charcoal-400 p-1 border-y border-charcoal-200'>Accounting Periods</dt>
                    <dd className='col-span-2 p-1 border-y border-charcoal-200'>
                        { formatNumber(data.periodCount || 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-y border-charcoal-200'>Suppliers</dt>
                    <dd className='col-span-2 p-1 border-y border-charcoal-200'>
                        { formatNumber(data.supplierCount || 0)}
                    </dd>


                    <dt className='bg-charcoal-400 p-1 border-y border-charcoal-200'>Items</dt>
                    <dd className='col-span-2 p-1 border-y border-charcoal-200'>
                        { formatNumber(data.itemCount|| 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Purchases</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        {formatNumber(data.purchaseCount|| 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Projects</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        { formatNumber(data.projectCount || 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Reports</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        { formatNumber(data.reportCount || 0)}
                    </dd>

                    <dt className='bg-charcoal-400 p-1 border-b border-charcoal-200'>Portfolios</dt>
                    <dd className='col-span-2 p-1 border-b border-charcoal-200'>
                        { formatNumber(data.portfolioCount || 0)}
                    </dd>
                </dl>

                <br />
                <label className="text-white">To confirm type <em>delete</em> in the field</label>
                <input
                    type="text" placeholder="delete" className="rounded block mt-2"
                    value={deletionConfirmationText}
                    onChange={e => setDeletionConfirmationText(e.target.value)}
                />

                <div className="flex mt-4">
                    <Button
                        type="Primary" text="Confirm" additionalClass="mr-4" onClick={onDelete}
                        disabled={deletionConfirmationText !== 'delete'}
                    />
                    <Button type="Secondary" text="Cancel" onClick={() => setDeleteConfirmatinOpen(false)}/>
                </div>
            </Modal>
        </div>
    )
}

export default AdminCompanySummary;
