import { Helmet } from "react-helmet";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../../components/Router/routes";
import {  useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { formatDate, formatReportStatus, getCSRFToken } from "../../lib/utils";
import usePagination from "../../hooks/usePagination";
import useDataSort from "../../hooks/useDataSort";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import useCleanup from "../../hooks/useCleanup";
import Modal from "../../components/BaseComponents/Modal/Modal";
import useAddNotification from "../../hooks/useAddNotification";

function MyReports() {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [deletionCandidate, setDeletionCandidate] = useState<any>();
    const [deleting, setDeleting] = useState(false);

    const navigate = useNavigate();
    const api = useApi();
    const addNotification = useAddNotification();

    const onDelete = async (reportId: string) => {
        setDeleting(true);
        await api.delete(`/secr/${reportId}`, {
            _csrf: await getCSRFToken(),
        });
        addNotification({
            body: `Report ${formatDate(deletionCandidate.start_date)} - ${formatDate(deletionCandidate.end_date)} deleted`,
        })
        setDeletionCandidate(null);
        setDeleting(false);

        loadData();
    }

    const loadData = useCleanup(() => {
        let cancelled = false;
        const run  = async () => {
            const qs = new URLSearchParams({
                ...pagination.params,
                ...dataSort.params,
            })

            const result = await api.get('secr/own?' + qs);

            if(cancelled) {
                return;
            }
            setData(result.data.reports);
            pagination.readMeta(result.data.meta);

            setLoading(false);
        }
        run()
        return () => {
            cancelled = true;
        }
    });

    const pagination = usePagination();
    const dataSort = useDataSort('end_date', 'desc');

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.params, dataSort.params])

    if(loading || !data) {
        return <LoadingSpinner />
    }

    const columns: TableColumn[] = [
        {
            id: 'end_date',
            label: 'Name',
            renderer: (key, row) => {
                return (
                    <span>
                        { formatDate(row.start_date) }
                        {' - '}
                        { formatDate(row.end_date) }
                    </span>
                )
            },
            sortable: true,
        },
        {
            id: 'type',
            label: 'Created By',
            sortable: true,
                renderer: (key, row) => {
                const type = row[key];
                const createdBy = type === 'Accounts summary'
                    ? 'System generated'
                    : 'Manual input';
                return (
                    <span>
                        { createdBy}
                    </span>
                );
            }
        },
        {
            id: 'status',
            label: 'Status',
            sortable: true,
            renderer: (key, row) => <span>{formatReportStatus(row[key])}</span>,
        },
        {
            id: 'first_published',
            label: 'First published',
            renderer: (key, row) => <span>{formatDate(row[key])}</span>,
            sortable: true,
        },
        {
            id: 'publish_date',
            label: 'Last updated',
            renderer: (key, row) => <span>{row[key]  && formatDate(row[key])}</span>,
            sortable: true,
        },
        {
            id: 'actions',
            label: 'Actions',
            textAlign: 'center',
            renderer: (key, row) => {
                const editRoute = (row['type'] === 'Accounts summary' && row['accounting_period_id'])
                    ? generatePath(routes.companyCarbonAccounts.path, { accountingPeriodId: row['accounting_period_id'] })
                    : generatePath(routes.myReportsEdit.path, { reportId: row['id']});
                return (
                    <div className="space-x-2">
                        <Button type="Primary" icon="fa-regular fa-trash" hasLeadingIcon size="sm" onClick={() => setDeletionCandidate(row)} />
                        <Button
                            type="Primary"
                            icon="fa-regular fa-edit"
                            hasLeadingIcon
                            size="sm"
                            isLink
                            url={editRoute}
                        />
                    </div>
                )
            }
        },
        {
            id: 'baseline', 
            label: 'Baseline',
            textAlign: 'center',
            renderer: (key, row) => {
                const { is_baseline } = row;
                const onSetBaseline = async () => {
                    await api.post(
                        'secr/setBaseline',
                        {
                            reportId: row.id,
                            _csrf: await getCSRFToken(),
                        }
                    )
                    loadData()
                }
                return (
                    <input
                        type="radio"
                        className='h-4 w-4 border-charcoal-300 text-algae-600 focus:ring-algae-600 mr-2'
                        checked={is_baseline}
                        onClick={is_baseline ? undefined : onSetBaseline}
                    />
                )
            }
        },
        {
            id: 'view', 
            label: 'View',
            textAlign: 'center',
            renderer: (key, row) => {
                const route = generatePath(routes.myReport.path, { reportId: row.id });
                return (
                    <Link to={route}>
                        <Button
                            type='Primary'
                            text='View'
                            size='sm'
                            hasTrailingIcon
                        />
                    </Link>
                )
            }
        },
    ]

    return(
        <PageTemplate testid='company-dashboard-main'>
            <Helmet>
                <title>{routes.myReports.name}</title>
            </Helmet>
            <PageHeadings title='My Reports'/>

			<Button
				type='Primary'
				text='Manually create report'
				size='sm'
				hasTrailingIcon
				icon='fa-regular fa-plus'
				onClick={() => {
					navigate(routes.myReportsCreate.path)
				}}
				additionalClass='mt-7 w-1/4'
			/>
            <div className="mt-8">
                <SimpleSortTable
                    columns={columns}
                    data={data}
                    defaultSorting={dataSort.defaultSorting}
                    onSortPress={dataSort.onChange}
                />
                <Pagination
                    pagination={pagination.pagination}
                    onPageChange={pagination.onPageChange}
                />
            </div>
            { deletionCandidate && (
                <Modal open size="small" onClose={() => setDeletionCandidate(null)}>
                    { deleting ? (
                        <LoadingSpinner />
                    ) : (

                        <div>
                            <PageHeadings
                                title={`Delete report for ${formatDate(deletionCandidate.start_date)} - ${formatDate(deletionCandidate.end_date)}`} 
                            />
                            <div className="mt-8 text-white">
                                Confirm deletion of report for period {formatDate(deletionCandidate.start_date)} - {formatDate(deletionCandidate.end_date)}
                            </div>
                            <div className="flex space-x-2 mt-8">
                                <Button type="Primary" text="Confirm" onClick={() => onDelete(deletionCandidate.id)}/>
                                <Button type="Outlined" text="Cancel" onClick={() => setDeletionCandidate(null)}/>
                            </div>
                        </div>
                    )}
                </Modal>
            )}
        </PageTemplate>
    )
}

export default MyReports;
