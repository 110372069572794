import { FunctionComponent, useEffect, useState } from 'react'
import { Button } from '../../../../components/BaseComponents'
import { SortConfig } from '../../../../components/BaseComponents/Datagrid/useSortableData'
import Pagination from '../../../../components/BaseComponents/Pagination/Pagination'
import SimpleSortTable, {
	TableColumn,
} from '../../../../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import { getSectors } from '../../../../redux/actions/sectorActions'
import { getSuppliers, GET_SUPPLIERS_LIMIT } from '../../../../redux/actions/suppliersActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/reduxHooks'
import {
	SECTOR_RESET,
	selectAllSector,
	selectSectorStatus,
} from '../../../../redux/slices/sectorSlice'
import { selectAllSuppliers, selectSuppliersPagination, selectSuppliersStatus, SUPPLIERS_RESET } from '../../../../redux/slices/suppliersSlice'
import { FetchStatus } from '../../../../types/LoadingStates'
import { Sector } from '../../../../types/Sector'
import { Supplier } from '../../../../types/Supplier'
import { WORST_SECTOR_LIMIT_QUANTITY } from '../Graphs/ECO2Sector'
import { WORST_SUPPLIER_LIMIT_QUANTITY } from '../Graphs/ECO2Supplier'
import useApi from '../../../../hooks/useApi'

// This component should only refresh/be doing stuff when marked as 'active' as it lives inside
// a modal and might otherwise be rendered inivisbly and cause interference

// Sector endpoint does not have pagination

type IModalSectorSearch = {
	onChange?: (newValue?: Supplier[]) => void
	active?: boolean
}

export const ModalSectorToSupplierSearch: FunctionComponent<IModalSectorSearch> = ({
	onChange,
	active = true,
}) => {
	const dispatch = useAppDispatch()
	const api = useApi();

	const sectorsStatus = useAppSelector(selectSectorStatus)
	const allSectors = useAppSelector(selectAllSector)

	const [selection, setSelection] = useState<Sector[]>([])
	const [supplierSelection, setSupplierSelection] = useState<boolean>(false)

	const suppliersStatus = useAppSelector(selectSuppliersStatus)
	const pagination = useAppSelector(selectSuppliersPagination)
	const allSuppliers = useAppSelector(selectAllSuppliers)
	const [selectionSuppliers, setSelectionSuppliers] = useState<Supplier[]>([])
	const [orderBy, setOrderBy] = useState<string>('name')
	const [direction, setDirection] = useState<'asc' | 'desc' | undefined>('asc')


	// Data loading (refire every time marked active)
	useEffect(() => {
		if (active) {
			dispatch({
				type: SECTOR_RESET,
			})
			dispatch({
				type: SUPPLIERS_RESET,
			})
			dispatch(getSectors(api))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{
			id: 'selected',
			label: '',
			columnClass: 'w-3', // narrow fixed column width
			renderer: (columnKey, rowData) => {
				return (
					<input
						type='checkbox'
						className='rounded border-charcoal-300 text-algae-600 focus:ring-algae-500'
						value={rowData._id}
						checked={rowData.selected}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							event.stopPropagation()

							if (selection.filter((sector) => sector.id === rowData._id).length > 0) {
								// Remove from selection
								setSelection([...selection.filter((sector) => sector.id !== rowData._id)])
							} else {
								// Check there's still room to add another
								if (selection.length !== WORST_SECTOR_LIMIT_QUANTITY) {
									// Add to selected
									setSelection([...selection, rowData.sectorObject])
								}
							}
						}}
					/>
				)
			},
		},
		{
			id: 'isicCode',
			label: 'Code',
			renderer: (columnKey, rowData) => {
				return <p className='text-white'>{rowData._id}</p>
			},
		},
		{ id: 'name', label: 'Description' },
	]

	// DataGrid data constructor
	const constructData = (dataSet: Array<any>) => [
		...dataSet.map((sector) => {
			return {
				_id: sector.code,
				name: sector.name,
				selected:
					selection.filter((selectedSupplier) => selectedSupplier.code === sector.code).length > 0,
				sectorObject: sector,
			}
		}),
	]
	const getSuppliersList = () => {
		let sectorCodes = selection.reduce((partialSum, a) => partialSum === '' ? partialSum + a.code : partialSum + ',' + a.code, '');
		dispatch(getSuppliers(api, {
			sectorCodes: sectorCodes,
		}))
	}
	// DataGrid Columns
	const columnsSupplier: TableColumn[] = [
		{
			id: 'selected',
			label: '',
			sortable: false,
			columnClass: 'w-3', // narrow fixed column width
			renderer: (columnKey, rowData) => {
				return (
					<input
						type='checkbox'
						className='rounded border-charcoal-300 text-algae-600 focus:ring-algae-500'
						value={rowData._id}
						checked={rowData.selected}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							event.stopPropagation()

							if (selectionSuppliers.filter((supplier) => supplier.id === rowData._id).length > 0) {
								// Remove from selection
								setSelectionSuppliers([...selectionSuppliers.filter((supplier) => supplier.id !== rowData._id)])
							} else {
								// Check there's still room to add another
								if (selectionSuppliers.length !== WORST_SUPPLIER_LIMIT_QUANTITY) {
									// Add to selected
									setSelectionSuppliers([...selectionSuppliers, rowData.supplierObject])
								}
							}
						}}
					/>
				)
			},
		},
		{ id: 'name', label: 'Supplier', sortable: true },
		{
			id: 'isicCode',
			label: 'Sector Code (ISIC)',
			sortable: false,
			columnClass: 'col-span-2',
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>
							{rowData.isicCode}
							{rowData.isicCodeName && (
								<span className='text-charcoal-050'> - {rowData.isicCodeName}</span>
							)}
						</p>
					</>
				)
			},
		},
		{
			id: 'jurisdiction',
			label: 'Jurisdiction',
			sortable: false,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className='text-white'>{rowData.jurisdiction_code}</p>
					</>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'name',
		direction: 'ascending',
	}

	// DataGrid data constructor
	const constructDataSupplier = (dataSet: Array<Supplier>) => [
		...dataSet.map((supplier) => {
			return {
				_id: supplier.id,
				name: supplier.name,
				isicCode: supplier.supplierIndustryCode?.code || '-',
				isicCodeName: supplier.supplierIndustryCode?.name || undefined,
				jurisdiction_code: supplier.jurisdiction_code || '-',
				selected:
					selectionSuppliers.filter((selectedSupplier) => selectedSupplier.id === supplier.id).length > 0,
				supplierObject: supplier,
			}
		}),
	]

	return (
		<>
			{!supplierSelection && <>
				{sectorsStatus === FetchStatus.error && (
					<div className='rounded-lg bg-error-500 p-4 mt-4'>
						<p className='text-white'>
							There was an error fetching sectors associated with this organisation. If this problem
							persists, please contact the Neoni team.
						</p>
					</div>
				)}

				<h3 className='font-bold text-xl text-white mb-4'>Select Sectors to Compare</h3>

				<p className='text-white font-bold mb-4'>
					Please select up to {WORST_SECTOR_LIMIT_QUANTITY} sectors to compare their embedded CO₂
					emissions:
				</p>

				{selection !== void 0 && selection.length > 0 && (
					<>
						{selection.map((sector) => {
							return (
								<Button
									key={sector.code}
									type='Outlined'
									size='sm'
									additionalClass='mr-2 mb-2'
									text={`${sector.code} - ${sector.name}`}
									hasTrailingIcon
									icon='fa-regular fa-times'
									onClick={() => {
										// Remove from selection
										setSelection([
											...selection.filter((selectedSector) => selectedSector.code !== sector.code),
										])
									}}
								/>
							)
						})}
						<div className='mt-4 mb-8'>
							<Button
								type='Primary'
								text='Fetch Suppliers'
								onClick={() => {
									getSuppliersList()
									setSupplierSelection(true)
								}}
							/>
						</div>
					</>
				)}

				<SimpleSortTable
					isLoading={sectorsStatus === FetchStatus.loading}
					additionalClass='mt-4'
					data={constructData(allSectors)}
					columns={columns}
				/>

				{selection !== void 0 && selection.length > 0 && (
					<div className='mt-4 flex-row-reverse w-full flex'>
						<Button
							type='Primary'
							text='Fetch Suppliers'
							onClick={() => {
								getSuppliersList()
								setSupplierSelection(true)
							}}
						/>
					</div>
				)}</>}
			{supplierSelection && <>
				{suppliersStatus === FetchStatus.error && (
					<div className='rounded-lg bg-error-500 p-4 mt-4'>
						<p className='text-white'>
							There was an error fetching suppliers associated with this organisation. If this problem
							persists, please contact the Neoni team.
						</p>
					</div>
				)}

				<h3 className='font-bold text-xl text-white mb-4'>Select Suppliers to Compare</h3>

				<p className='text-white font-bold mb-4'>
					Please select up to {WORST_SUPPLIER_LIMIT_QUANTITY} suppliers to compare their embedded CO₂
					emissions:
				</p>

				{selectionSuppliers !== void 0 && selectionSuppliers.length > 0 && (
					<>
						{selectionSuppliers.map((supplier) => {
							return (
								<Button
									key={supplier.id}
									type='Outlined'
									size='sm'
									additionalClass='mr-2 mb-2'
									text={supplier.name}
									hasTrailingIcon
									icon='fa-regular fa-times'
									onClick={() => {
										// Remove from selectionSuppliers
										setSelectionSuppliers([
											...selectionSuppliers.filter((selectedSupplier) => selectedSupplier.id !== supplier.id),
										])
									}}
								/>
							)
						})}
						<div className='mt-4 mb-8'>
							<Button
								type='Primary'
								text='Save Selection'
								onClick={() => {
									onChange && onChange(selectionSuppliers)
								}}
							/>
						</div>
					</>
				)}
				<SimpleSortTable
					isLoading={suppliersStatus === FetchStatus.loading}
					additionalClass='mt-4'
					data={constructDataSupplier(allSuppliers)}
					columns={columnsSupplier}
					defaultSorting={defaultSorting}
					onSortPress={(key, direction) => {
						// Store current sorting values for pagination to reference in its API calls
						setOrderBy(key)
						setDirection(direction === 'ascending' ? 'asc' : 'desc')
						dispatch(
							getSuppliers(api, {
								page: pagination.current_page,
								limit: GET_SUPPLIERS_LIMIT,
								orderBy: key,
								direction: direction === 'ascending' ? 'asc' : 'desc'
							})
						)
					}}
				/>

				<Pagination
					isLoading={suppliersStatus === FetchStatus.loading}
					pagination={pagination}
					onPageChange={(newPageNumber) => {
						dispatch(
							getSuppliers(api, {
								page: newPageNumber,
								limit: GET_SUPPLIERS_LIMIT,
								orderBy: orderBy,
								direction: direction
							})
						)
					}}
				/>

				{selectionSuppliers !== void 0 && selectionSuppliers.length > 0 && (
					<div className='mt-4 flex-row-reverse w-full flex'>
						<Button
							type='Primary'
							text='Save Selection'
							onClick={() => {
								onChange && onChange(selectionSuppliers)
							}}
						/>
					</div>
				)}
			</>}
		</>
	)
}
