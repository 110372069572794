import { MouseEvent, FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'

type ButtonSize = 'xs' | 'sm' | 'md' | 'lg'
type ButtonType =
	| 'Primary'
	| 'Secondary'
	| 'White'
	| 'on dark'
	| 'Green-Secondary'
	| 'Outlined'
	| 'Link'
	| 'Pagination'

type ButtonProps = {
	type: ButtonType
	text?: string
	onClick?: (event: MouseEvent) => void
	disabled?: boolean
	isRounded?: boolean
	hasLeadingIcon?: boolean
	hasTrailingIcon?: boolean
	icon?: string
	size?: ButtonSize
	isLink?: boolean
	url?: string
	fullWidth?: boolean
	additionalClass?: string
	ariaControls?: string
	ariaExpanded?: boolean
	buttonType?: 'button' | 'submit'
	accessibilityLabel?: string
	routeState?: string
}

const Button: FunctionComponent<ButtonProps> = ({
	type,
	text = '',
	onClick,
	disabled = false,
	isRounded,
	hasLeadingIcon,
	hasTrailingIcon,
	icon,
	size = 'md',
	isLink = false,
	url = '/',
	additionalClass,
	fullWidth,
	ariaControls = undefined,
	ariaExpanded = undefined,
	buttonType = 'button',
	accessibilityLabel,
	routeState = undefined,
}) => {
	const buttonTypes = {
		'Primary':
			'text-charcoal-900 bg-algae-500 hover:bg-algae-600 focus:bg-algae-500 border-2 border-algae-500 hover:border-algae-600 focus:outline-none focus:ring focus:ring-algae-100',
		'Secondary':
			'bg-charcoal-100 hover:bg-charcoal-300 focus:bg-charcoal-50 border-2 border-charcoal-100 hover:border-charcoal-300 focus:outline-none focus:ring focus:ring-charcoal-900',
		'White':
			'bg-white hover:bg-charcoal-050 focus:bg-white border-2 border-white hover:border-charcoal-050 focus:outline-none focus:ring focus:ring-algae-500',
		'On dark':
			'bg-white hover:bg-charcoal-050 focus:bg-white border-2 border-white focus:outline-none',
		'Green-Secondary':
			'bg-algae-200 hover:bg-algae-300 focus:bg-algae-200 border-2 border-algae-200 hover:border-algae-300 focus:outline-none focus:ring focus:ring-white',
		'Outlined':
			'text-algae-200 hover:text-charcoal-900 font-semibold border-2 border-algae-200 hover:bg-algae-100 hover:border-algae-100 focus:outline-none focus:ring focus:ring-algae-500',
		'Link': 'text-charcoal-100 hover:text-charcoal-050 focus:outline-none focus:ring-none pl-0',
		'Pagination':
			'text-algae-200 hover:text-charcoal-900 font-semibold border-2 border-transparent hover:bg-algae-100 hover:border-algae-100 focus:outline-none focus:ring focus:ring-algae-500',
	}

	const paddingClasses = {
		xs: 'px-2.5 py-1.5 text-xs',
		sm: 'py-1.5 px-3',
		md: 'py-2.5 px-4',
		lg: 'py-3 px-5',
	}

	const getClassName = (type: string) => {
		const baseClass =
			'inline-block font-medium disabled:bg-charcoal-300 disabled:text-charcoal-600 disabled:border-charcoal-300 '
		let className =
			baseClass + `${paddingClasses[size]} text-${size} ${fullWidth && 'w-full text-center'} `
		type ObjectKey = keyof typeof buttonTypes
		let typeVal = type as ObjectKey
		className = className + buttonTypes[typeVal]
		if (isRounded) {
			className = className + ' rounded-full'
		} else {
			className = className + ' rounded'
		}
		if (typeof additionalClass !== 'undefined') {
			className = className + ' ' + additionalClass
		}
		return className
	}

	const getIcon = (icon: string) => {
		if (type === 'Outlined') {
			icon = icon + ' text-algae-500'
		}
		if (text !== '') {
			if (hasLeadingIcon) {
				icon = icon + ' mr-md'
			}
			if (hasTrailingIcon) {
				icon = icon + ' ml-md'
			}
		}
		return icon
	}

	type ILinkOptionWrapper = {
		isLink?: boolean
		url: string
		children?: React.ReactNode
	}

	const ButtonWrapper: FunctionComponent<ILinkOptionWrapper> = ({ isLink, url, children }) => {
		return isLink ? (
			// NavLink version of button for use with router
			<NavLink
				to={url}
				state={routeState}
				className={getClassName(type)}
				onClick={(event) => {
					onClick && onClick(event)
				}}
			>
				{children}
			</NavLink>
		) : (
			// Normal button element
			<button
				data-testid='component-btn'
				onClick={(event) => {
					if (!disabled) {
						onClick && onClick(event)
					}
				}}
				className={getClassName(type)}
				disabled={disabled ? true : false}
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				type={buttonType}
			>
				{children}
			</button>
		)
	}

	return (
		<ButtonWrapper isLink={isLink} url={url}>
			{hasLeadingIcon && icon && <span className={getIcon(icon)}></span>}
			{text}
			{accessibilityLabel && <span className='sr-only'> {accessibilityLabel}</span>}
			{hasTrailingIcon && icon && <span className={getIcon(icon)}></span>}
		</ButtonWrapper>
	)
}
export default Button
