import { Helmet } from "react-helmet"
import { LoadingSpinner } from "../components/BaseComponents"
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate"
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userContext } from "../context/UserContext";

function AssumeCompany() {

    const { setCompanyAssumption, userData } = useContext(userContext)
    const navigate = useNavigate();

    useEffect(() => {
        setCompanyAssumption && setCompanyAssumption(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(!userData?.access?.assumedCompanyType) {
            navigate('/', { replace: true })
        }
    }, [userData?.access?.assumedCompanyType])

	return (
		<PageTemplate testid='add-accounting-periods-main'>
			<Helmet>
				<title>Loading</title>
			</Helmet>
            <LoadingSpinner />
        </PageTemplate>
	)
}

export default AssumeCompany
