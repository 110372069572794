import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

type TierButtonProps = {
    value: string;
    current: string;
    onSubmit: (tier: string) => void
    loading?: boolean;
}

function TierButton({value, current, onSubmit, loading}: TierButtonProps) {
    if(loading) {
        return (
            <button className="rounded p-2 bg-charcoal-200" disabled>
                <span className="fa-solid fa-spinner fa-spin text-white" />
            </button>
        )
    }

    const selected = value === current;
    if(selected) {
        return (
            <button className="rounded p-2 bg-charcoal-200" disabled>
                Current tier
            </button>
        )
    } else {
        return (
            <button className="rounded p-2 bg-algae-400" onClick={() => onSubmit(value)}>
                Set as tier
            </button>
        );
    }
}

type AdminCompanyTierProps = {
    companyId: string;
}

function AdminCompanyTier({companyId}: AdminCompanyTierProps) {
    const api = useApi();

    const [tier, setTier] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        let cancelled = false;
        const run = async () => {
            const result = await api.get(`admin/company/${companyId}/tier`);
            if(cancelled) {
                return;
            }
            setTier(result.tier);
            setLoading(false);
        }

        run();
        return () => {
            cancelled = true;
        }
    }, [companyId])

    const onSubmit = async (tier: string) => {
        setLoading(true);
        const result = await await api.put(`admin/company/${companyId}/tier`, {
            tier,
            _csrf: await getCSRFToken(),
        })
        setTier(result.tier);
        setLoading(false);
    }

    if(tier === 'admin') {
        return (
            <div>
                <h3 className="text-white mb-3 mt-8">Tier</h3>
                <div className="rounded bg-charcoal-600 p-5 space-y-5 text-white">
                    Organisation is Admin
                </div>
            </div>
        )
    }
    return (
        <div>
            <h3 className="text-white mb-3 mt-8">Tier</h3>
            <div className="rounded bg-charcoal-600 p-5 space-y-5">
                <div className="p-2 rounded bg-charcoal-400">
                    <div className="flex space-x-2">
                        <div className="w-40 text-white">Micro</div>
                        <TierButton value="micro" current={tier} onSubmit={onSubmit} loading={loading} />
                    </div>
                    <ul>
                        <li className="text-success-500">✓ Create reports</li>
                        <li className="text-error-500">✗ Manage Accounting Periods</li>
                        <li className="text-error-500">✗ Insights</li>
                    </ul>
                </div>
                <div className="p-2 rounded bg-charcoal-400">
                    <div className="flex space-x-2">
                        <div className="w-40 text-white">Standard</div>
                        <TierButton value="standard" current={tier} onSubmit={onSubmit} loading={loading} />
                    </div>
                    <ul>
                        <li className="text-success-500">✓ Create reports</li>
                        <li className="text-success-500">✓ Manage Accounting Periods</li>
                        <li className="text-error-500">✗ Insights</li>
                    </ul>
                </div>
                <div className="p-2 rounded bg-charcoal-400">
                    <div className="flex space-x-2">
                        <div className="w-40 text-white">Premium</div>
                        <TierButton value="premium" current={tier} onSubmit={onSubmit} loading={loading} />
                    </div>
                    <ul>
                        <li className="text-success-500">✓ Create reports</li>
                        <li className="text-success-500">✓ Manage Accounting Periods</li>
                        <li className="text-success-500">✓ Insights</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AdminCompanyTier;
