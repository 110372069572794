import { useState } from 'react'
import { Helmet } from 'react-helmet'
import PageHeadings from '../../../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../../../components/BaseComponents/PageTemplate/PageTemplate'
import TabList from '../../../components/BaseComponents/TabList/TabList'
import VerticalTabList from '../../../components/BaseComponents/TabList/VerticalTabList'
import { routes } from '../../../components/Router/routes'
import ECO2SectorGraph from './Graphs/ECO2Sector'
import ECO2SupplierGraph from './Graphs/ECO2Supplier'
import IntensitySupplierGraph from './Graphs/IntensitySupplierGraph'

const CompanyInsightsSupplyChainAnalysisPage = () => {
	const [initialActiveTab, setInitialActiveTab] = useState(0)
	const [sectorIds, setSectorIds] = useState<number | undefined>(undefined)
	const updateTab = (val: any) => {
		setSectorIds(val)
		setInitialActiveTab(1)
	}
	const updateSectorId = () => {
		setSectorIds(undefined)
		setInitialActiveTab(0)
	}
	return (
		<>
			<PageTemplate testid='company-dashboard-main'>
				<Helmet>
					<title>{routes.companyInsightsSupplyChainAnalysis.name}</title>
				</Helmet>
				<PageHeadings title='Insights' subTitle='Supply Chain Analysis' />

				<TabList
					additionalContainerClass='mt-8'
					tabs={[
						{
							label: 'Embedded CO₂e',
							component: (activeTab) => (
								<>
									{activeTab && (
										<VerticalTabList
											updateInitialTab={initialActiveTab === 0 ? false : true}
											additionalContainerClass='mt-4'
											additionalPanelClass='p-4'
											tabs={[
												{
													label: 'Embedded CO₂e in Purchases by Sector',
													component: (active) => <>{active && <ECO2SectorGraph onChangeInitialActiveTab={updateTab} />}</>,
												},
												{
													label: 'Embedded CO₂e in Purchases by Supplier',
													component: (active) => <>{active && <ECO2SupplierGraph sectorIds={sectorIds} updateSectorId={updateSectorId} />}</>,
												},
											]}
										/>
									)}
								</>
							),
						},
						{
							label: 'Carbon Intensity',
							component: (activeTab) => (
								<>
									{activeTab && (
										<VerticalTabList
											updateInitialTab={initialActiveTab === 0 ? false : true}
											additionalContainerClass='mt-4'
											additionalPanelClass='p-4'
											tabs={[
												{
													label: 'Intensity ratio by Supplier',
													component: (active) => <>{active && <IntensitySupplierGraph sectorIds={sectorIds} updateSectorId={updateSectorId} />}</>,
												},
											]}
										/>
									)}
								</>
							),
						},
					]}
				/>
			</PageTemplate>
		</>
	)
}
export default CompanyInsightsSupplyChainAnalysisPage
