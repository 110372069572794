import { FunctionComponent } from 'react'

type IPageTemplate = {
	testid?: string
	children: React.ReactNode
}

const PageTemplate: FunctionComponent<IPageTemplate> = ({ children, testid }) => (
	<div
		data-testid={testid}
		className='pt-3xl mx-lg p-4 flex flex-col'
	>
		{children}
	</div>
)

export default PageTemplate
