import { Helmet } from "react-helmet";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../../components/Router/routes";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import CreatePortfolioModal from "./CreatePortfolioModal";
import { formatDate } from "../../lib/utils";
import DeletePortfolioModal from "./DeletePortfolioModal";
import useCleanup from "../../hooks/useCleanup";
import EditPortfolioModal from "./EditPortfolioModal";
import usePagination from "../../hooks/usePagination";


function PortfolioList() {
    const columns: TableColumn[] = [
        {
            id: 'name',
            label: 'Portfolio name',
        },
        {
            id: 'created',
            label: 'Date created',
            renderer: (key, row) => <span>{ formatDate(row[key]) }</span>
        },
        {
            id: 'companyCount',
            label: 'Organisation count',
        },
        {
            id: 'options',
            label: 'Actions',
            textAlign: 'center',
            renderer: (columnKey, row) => {
                return (
                    <div className="space-x-2">
                        <Button type="Primary" icon="fa-regular fa-trash" hasLeadingIcon size="sm" onClick={() => setDeletionCandidate(row)} />
                        <Button type="Primary" icon="fa-regular fa-edit" hasLeadingIcon size="sm"  onClick={() => setEditCandidate(row)} />
                    </div>
                )
            }
        },
        {
            id: 'view',
            label: 'View / refine data',
            renderer: (columnKey, row) => {
                return (
                    <Button
                        type="Primary"
                        text="View portfolio"
                        size="sm"
                        isLink
                        url={generatePath(routes.companyPortfolio.path, { portfolioId: row['id']})}
                    />
                )
            }
    
        }
    ]

    const pagination = usePagination();

    const [portfolios, setPortfolios] = useState<any[]>([]);
    const api = useApi();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const openCreateModal = () => setCreateModalOpen(true);

    const [deletionCandidate, setDeletionCandidate] = useState<any>(null);
    const [editCandidate, setEditCandidate] = useState<any>(null);

    const [loadingPortfolios, setLoadingPortfilios] = useState(true);

    const loadPortfolios = useCleanup(() => {
        let cancelled = false;
        const run = async () => {
            const qs = new URLSearchParams({
                ...pagination.params,
            })
            const result = await api.get('portfolios/?' + qs)
            if(cancelled) {
                return;
            }
            setPortfolios(result.data.portfolios);
            pagination.readMeta(result.data.meta);
            setLoadingPortfilios(false);
        }

        run();

        return () => {
            cancelled = true;
        };
    })
    useEffect(() => {
        loadPortfolios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(loadingPortfolios) {
        return (
            <LoadingSpinner />
        )
    }

    return(

        <PageTemplate testid='company-dashboard-main'>
            <Helmet>
                <title>{routes.portfolio.name}</title>
            </Helmet>
            <PageHeadings title='Data Hub' subTitle="Portfolios" />
                <div className="flex">
                <Button
                    type="Primary"
                    text="Create portfolio"
                    hasTrailingIcon
                    icon="fa-regular fa-plus"
                    onClick={() => openCreateModal()}
                    additionalClass="mt-4"
                    size="sm"
                />
                <CreatePortfolioModal
                    isOpen={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onCreated={() => {
                        setCreateModalOpen(false);
                        loadPortfolios();
                    }}
                />
            </div>

            { (!loadingPortfolios && !portfolios.length) ? (
                <p className="mt-8 text-white">
                    Create your first portfolio of organisations you would like to follow.
                </p>
            ) :(
                <>
                    <SimpleSortTable additionalClass="mt-8" data={portfolios} columns={columns} isLoading={loadingPortfolios} />
                    <Pagination
                        pagination={pagination.pagination}
                        isLoading={loadingPortfolios}
                        onPageChange={() => {}}
                    />
                </>
            )}

            { deletionCandidate && (
                <DeletePortfolioModal
                    onClose={() => setDeletionCandidate(null)}
                    isOpen
                    onDeleted={() => {
                        setDeletionCandidate(null);
                        loadPortfolios();
                    }}
                    portfolioId={deletionCandidate.id}
                    portfolioName={deletionCandidate.name}
                />
            )}
            { editCandidate && (
                <EditPortfolioModal
                    onClose={() => setEditCandidate(null)}
                    isOpen
                    onEdited={() => {
                        setEditCandidate(null);
                        loadPortfolios();
                    }}
                    portfolioId={editCandidate.id}
                    portfolioName={editCandidate.name}
                />
            )}
        </PageTemplate>
    )
}

export default PortfolioList;
