import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { SUPPLIERS_SINGLELOADED } from '../slices/suppliersSlice'
import {
	UPDATESUPPLIERS_SENT,
	UPDATESUPPLIERS_ERROR,
	UPDATESUPPLIERS_SENDING,
} from '../slices/updateSuppliersSlice'
import { NeoniApi } from '../../lib/api'

type IUpdateSupplier = {
	supplierId?: string
	jurisdication?: string | null
	code?: string | null
}

export const updateSupplier =
	(api: NeoniApi, { supplierId, jurisdication, code }: IUpdateSupplier) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: UPDATESUPPLIERS_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			const body = await api.put(`suppliers/${supplierId}`, {
				id: supplierId,
				jurisdictionCode: jurisdication,
				supplierIndustryCode: code,
				_csrf: csrf,

			});

			if (body.success === true) {
				dispatch({
					type: UPDATESUPPLIERS_SENT,
					payload: body.supplier,
				})
				dispatch({
					type: SUPPLIERS_SINGLELOADED,
					payload: body.supplier,
				})
			} else {
				dispatch({
					type: UPDATESUPPLIERS_ERROR,
					payload: { error: body.message },
				})
			}
		} catch (error) {
			dispatch({
				type: UPDATESUPPLIERS_ERROR,
				payload: { error: error },
			})
		}
	}
