import { useEffect, useRef } from "react";

type Cleaner = () => void;
type CleanupCallback = () => (void | Cleaner);

function useCleanup(fn: CleanupCallback) {
    let prevCleaner = useRef<Cleaner | void>();

    useEffect(() => {
        return () => {
            if(prevCleaner.current) {
                prevCleaner.current();
            }
        }
    }, []);

    return () => {
        if(prevCleaner.current) {
            prevCleaner.current();
        }
        prevCleaner.current = fn();
    }
}

export default useCleanup;
