import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import {
	CSV_LIST_ERROR,
	CSV_LIST_LOADED,
	CSV_LIST_LOADING,
	DELETE_CSV_ERROR,
	DELETE_CSV_SENDING,
	DELETE_CSV_SENT,
} from '../slices/csvFilesSlice'
import { NeoniApi } from '../../lib/api'

export const GET_ITEMS_LIMIT = 20

export const getCsvFiles = (api: NeoniApi, accountingPeriodId: any) => async (dispatch: Dispatch) => {
	dispatch({
		type: CSV_LIST_LOADING,
	})

	try {
		const body = await api.get(`accountingPeriod/${accountingPeriodId}/csvFiles`)

		if (body.success === true) {
			// Successfully fetched items
			dispatch({
				type: CSV_LIST_LOADED,
				payload: body.csvFiles,
			})
		} else {
			// Error
			dispatch({
				type: CSV_LIST_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: CSV_LIST_ERROR,
			payload: error,
		})
	}
}

export const deleteCsvFile =
	(api: NeoniApi, accountingPeriodId: any, csvId: any) => async (dispatch: Dispatch) => {
		dispatch({
			type: DELETE_CSV_SENDING,
		})

		try {
			const csrf = await getCSRFToken()
			const body = await api.delete(`accountingPeriod/${accountingPeriodId}/csvFiles/${csvId}`,
				{_csrf: csrf}
			);

			if (body.success === true) {
				// Successfully fetched items
				dispatch({
					type: DELETE_CSV_SENT,
					payload: csvId,
				})
			} else {
				// Error
				dispatch({
					type: DELETE_CSV_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: DELETE_CSV_ERROR,
				payload: error,
			})
		}
	}
