import { useState, useEffect } from "react";
import { Button, LoadingSpinner } from "../../components/BaseComponents";
import SimpleSortTable from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import useApi from "../../hooks/useApi";
import Modal from "../../components/BaseComponents/Modal/Modal";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import { getCSRFToken } from "../../lib/utils";
import useDataSort from "../../hooks/useDataSort";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import useCleanup from "../../hooks/useCleanup";

function AdminUsers() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const api = useApi();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const dataSort = useDataSort('email', 'asc');
    const pagination = usePagination();

    const onCloseModal = () => {
        setModalOpen(false);
    }

    const loadData = useCleanup(() => {
        let cancelled = false;
        const run = async () => {
            const qs = new URLSearchParams({
                ...pagination.params,
                ...dataSort.params,
            })
            const result = await api.get('admin/adminUsers?' + qs)
            if(cancelled) {
                return;
            }
            setData(result.data.users);
            pagination.readMeta(result.data.meta);
            setLoading(false);
        }
        run()
        return () => {
            cancelled = true;
        }
    });

    useEffect(() => {
        loadData();
    }, [dataSort.params, pagination.params])

    const onCreateUser = async () => {
        await api.post('admin/adminUsers', {
            email,
            fullName: name,
            _csrf: await getCSRFToken(),
        })
        loadData();
    }

    if(loading) {
        <LoadingSpinner />
    }

    const columns = [
        {
            id: 'email',
            sortable: true,
            label: 'Email',
        },
        {
            id: 'fullName',
            sortable: true,
            label: 'Name',
        },
        {
            id: 'role',
            sortable: true,
            label: 'Role',
        },
    ]

    return (
        <div className="mt-9 text-white">
            <div className="flex">
                <div className="grow">Users</div>
                <Button type="Primary" text="Create" size="sm" onClick={() => setModalOpen(true)}/>
            </div>
            <SimpleSortTable
                additionalClass='mt-4'
                data={data}
                columns={columns}
                isLoading={loading}
            />
            <Pagination
                pagination={pagination.pagination}
                onPageChange={pagination.onPageChange}
                isLoading={loading}
            />
            <Modal open={modalOpen} onClose={onCloseModal} size="small">
                <div className="flex flex-col">
                    <PageHeadings title="Create Admin User" />
                    <div className="mt-4">
                        <div className="text-sm font-medium text-charcoal-100 my-1">Name</div>
                        <input
                            className="rounded"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="mt-4">
                        <div className="text-sm font-medium text-charcoal-100 my-1">Email</div>
                        <input
                            className="rounded"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex mt-20">
                        <div className="grow">
                            <Button
                                type="Primary"
                                text="Create Admin User"
                                onClick={onCreateUser}
                            />
                        </div>
                        <Button type="Outlined" text="Cancel" onClick={() => setModalOpen(false)}/>
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default AdminUsers;
