// Graph: Emebedded CO2e in purchases by Sector, including "DIY" selection of sectors functionality

import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Button } from '../../../../components/BaseComponents'
import Modal from '../../../../components/BaseComponents/Modal/Modal'
import Skeleton from '../../../../components/BaseComponents/Skeleton/Skeleton'
import { useEffectWithoutInitialRender } from '../../../../hooks/useEffectWithoutInitialRender'
import { getSectorECO2eData } from '../../../../redux/actions/ECO2SectorActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/reduxHooks'
import {
	selectSortedSupplyChainGraphData,
	selectSupplyChainGraphDataStatus,
	SUPPLYCHAINGRAPHDATA_RESET,
} from '../../../../redux/slices/supplyChainGraphDataSlice'
import { FetchStatus } from '../../../../types/LoadingStates'
import { Sector } from '../../../../types/Sector'
import { ModalSectorSearch } from '../ModalSearch/ModalSectorSearch'
import useApi from '../../../../hooks/useApi'

// Dayjs extension required to format dates from UK format DD/MM/YYYY explicitly as thinks it is MM/DD/YYYY
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const WORST_SECTOR_LIMIT_QUANTITY = 5
type IECO2SectorGraph = {
	onChangeInitialActiveTab: (sectorId: any) => void
}
const ECO2SectorGraph = (props: IECO2SectorGraph) => {
	const { onChangeInitialActiveTab } = props
	const dispatch = useAppDispatch()
	const api = useApi();

	const graphDataStatus = useAppSelector(selectSupplyChainGraphDataStatus)
	const sortedGraphData = useAppSelector(selectSortedSupplyChainGraphData)

	const [modalOpen, setModalOpen] = useState(false)

	// Used to filter the sortedGraphData to get the five worst sectors across time (default view)
	const [graphDataFilteredByBiggest, setGraphDataFilteredByBiggest] = useState<any>([])
	const [biggestSectorsInLastPeriod, setBiggestSectorsInLastPeriod] = useState<any>([])
	const [biggestSectorsInLastPeriodLabels, setBiggestSectorsInLastPeriodLabels] = useState<any>([])

	// Used for the custom view, sent to API to retreive specific sectors
	const [customView, setCustomView] = useState(false)
	const [graphDataCustom, setGraphDataCustom] = useState<any>([])
	const [selectedSectors, setSelectedSectors] = useState<Array<any>>([])

	// Initial data loading stage
	useEffect(() => {
		dispatch({ type: SUPPLYCHAINGRAPHDATA_RESET })
		dispatch(getSectorECO2eData(api))
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Custom data loading
	useEffect(() => {
		if (selectedSectors.length > 0) {
			dispatch({ type: SUPPLYCHAINGRAPHDATA_RESET })
			dispatch(getSectorECO2eData(api, selectedSectors.map((sector) => sector.code).join(',')))
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSectors])

	// Data processing stage (race condition with reset on first load)
	useEffectWithoutInitialRender(() => {
		if (graphDataStatus === FetchStatus.loaded) {
			// Default view mode (N worst sectors)
			if (!customView && selectedSectors.length <= 0) {
				const biggestSectors = sortedGraphData[sortedGraphData.length - 1]?.data
					.filter((entry, index) => index < WORST_SECTOR_LIMIT_QUANTITY)
					.map((entry) => entry.id)

				// Store biggest keys and labels for later
				setBiggestSectorsInLastPeriodLabels(
					sortedGraphData[sortedGraphData.length - 1]?.data
						.filter((entry, index) => index < WORST_SECTOR_LIMIT_QUANTITY)
						.map((entry) => entry.label)
				)
				setBiggestSectorsInLastPeriod(biggestSectors)

				setGraphDataFilteredByBiggest(
					constructData(
						[...sortedGraphData].map((period) => {
							return {
								periodId: period.periodId,
								periodStart: period.periodStart,
								periodEnd: period.periodEnd,
								data: [...period.data].filter((entry) => biggestSectors.includes(entry.id)),
							}
						})
					)
				)
			} else {
				// Custom supplier selections (DIY) mode
				setGraphDataCustom(constructData([...sortedGraphData]))
			}
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphDataStatus])

	const onChangeTab = (sectorId: any) => {
		onChangeInitialActiveTab(sectorId)
	}

	// Data construction for graph
	const constructData = (
		sourceData: {
			periodId: string
			periodEnd: string
			periodStart: string
			data: {
				id: string
				label: string
				total: number
			}[]
		}[]
	) => {
		return sourceData.map((period) => {
			let codeAsKey = period.data.reduce((acc, entry) => {
				return {
					[entry.id]: entry.total.toFixed(2),
					[`label-${entry.id}`]: entry.label, // Unused
					...acc,
				}
			}, {})

			return {
				name: `${dayjs(period.periodStart, 'DD/MM/YYYY').format('MM/YYYY')} - ${dayjs(
					period.periodEnd,
					'DD/MM/YYYY'
				).format('MM/YYYY')}`,
				...codeAsKey,
			}
		})
	}

	// Contrasting colours set
	const colourList = ['#CC6429', '#77E1AE', '#3278FF', '#FFFFFF', '#FFE387']

	return (
		<>
			{graphDataStatus === FetchStatus.loaded && (
				<>
					<div className='flex justify-between'>

						<h2 className='text-white text-xl font-bold mb-4'>
							Embedded CO₂e in Purchases by Sector
						</h2>
						{(graphDataStatus === FetchStatus.loaded || graphDataStatus === FetchStatus.error) && (
							<Button
								additionalClass='mt-4'
								type='Primary'
								size='sm'
								onClick={() => setModalOpen(true)}
								text='Select Sectors to Compare'
								icon='fa-regular fa-code-compare'
								hasLeadingIcon
							/>
						)}
					</div>
					<div className='relative pl-4 overflow-hidden'>
						{/* Custom Y axis label - sorry */}
						<div className='text-white text-sm absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-center'>
							<span className='-rotate-90 origin-top-left'>
								<span className='absolute -translate-x-1/2'>Embedded CO₂e (kg)</span>
							</span>
						</div>
						<ResponsiveContainer width='99.9%' aspect={1.778}>
							<LineChart
								width={500}
								height={300}
								data={customView ? graphDataCustom : graphDataFilteredByBiggest}
								margin={{
									top: 20,
									right: 30,
									left: 20,
									bottom: 5,
								}}

							>
								<XAxis
									dataKey='name'
									axisLine={false}
									tickLine={false}
									style={{ fontSize: '12px' }}
									tick={{ fill: '#FFF' }}
								/>
								<YAxis
									axisLine={false}
									tickLine={false}
									style={{ fontSize: '12px' }}
									tick={{ fill: '#FFF' }}
								/>

								<Tooltip
									content={({ active, payload, label }) => {
										if (active && payload && payload.length) {
											return (
												<div className='bg-charcoal-600 border-2 border-charcoal-700 text-white p-2'>
													<p>{label}</p>
													{payload.map((tooltipItem) => {
														return (
															<p key={tooltipItem.name} className='text-sm text-charcoal-100'>
																{typeof tooltipItem.name === 'string' && (
																	<>
																		<span
																			className='inline-block mr-2'
																			style={{
																				height: 10,
																				width: 14,
																				backgroundColor: tooltipItem.color,
																				flexShrink: 0,
																			}}
																		/>
																		{customView
																			? selectedSectors.filter(
																				(sector) => sector.code === tooltipItem.name
																			)[0].code
																			: tooltipItem.name}
																	</>
																)}{' '}
																- {tooltipItem.value} kg
															</p>
														)
													})}
												</div>
											)
										}

										return null
									}}
									cursor={{ fill: '#12171C' }}
								/>

								{customView
									? selectedSectors.map((sector: Sector, index: number) => {
										return (
											<Line
												activeDot={{ onClick: (event, payload) => onChangeTab(sector.code) }}
												connectNulls
												type='monotone'
												key={sector.code}
												dataKey={sector.code}
												stroke={colourList[index]}
												fill={colourList[index]}
											/>
										)
									})
									: biggestSectorsInLastPeriod?.map((key: string, index: number) => {
										return (
											<Line
												activeDot={{ onClick: (event, payload) => onChangeTab(key) }}
												connectNulls
												type='monotone'
												key={key}
												dataKey={key}
												stroke={colourList[index]}
												fill={colourList[index]}
											/>
										)
									})}
							</LineChart>
						</ResponsiveContainer>
					</div>

					<div className='pl-4'>
						<h3 className='text-white text-lg my-2'>Legend</h3>
						{customView
							? selectedSectors?.map((sector: Sector, index: number) => (
								<div key={sector.code} className='flex items-center mb-2'>
									<div
										style={{
											height: 10,
											width: 14,
											backgroundColor: colourList[index],
											flexShrink: 0,
										}}
									/>
									<p className='text-charcoal-100 ml-2'>
										{sector.code} - {sector.name}
									</p>
								</div>
							))
							: biggestSectorsInLastPeriod?.map((key: string, index: number) => (
								<div key={key} className='flex items-center mb-2'>
									<div
										style={{
											height: 10,
											width: 14,
											backgroundColor: colourList[index],
											flexShrink: 0,
										}}
									/>
									<p className='text-white ml-2'>
										{key} -{' '}
										<span className='text-charcoal-100'>
											{biggestSectorsInLastPeriodLabels[index]}
										</span>
									</p>
								</div>
							))}
					</div>
				</>
			)}

			{graphDataStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						Sorry, there was an error trying to fetch the sector data for comparison. Please check
						your supplier information is up to date and try again. If the problem persists, please
						contact the Neoni team.
					</p>
				</div>
			)}

			{/* Modal for searching and selecting custom sectors */}
			<Modal open={modalOpen} onClose={() => setModalOpen(false)} size='small'>
				<ModalSectorSearch
					onChange={(selections) => {
						if (selections !== void 0) {
							setSelectedSectors(selections)
						}

						setModalOpen(false)
						setCustomView(true)
					}}
					active={modalOpen}
				/>
			</Modal>

			{graphDataStatus === FetchStatus.loading && (
				<>
					<Skeleton additionalClass='h-80 mb-2' />
					<Skeleton additionalClass='mb-2' />
					<Skeleton additionalClass='mb-2' />
					<Skeleton additionalClass='mb-2' />
				</>
			)}
		</>
	)
}

export default ECO2SectorGraph
