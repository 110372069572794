import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invitation } from '../../types/Invitation'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type InvitationsState = {
	status: FetchStatus
	error?: string
	invitationsReceived: Array<Invitation>
}
const invitationsInitialState: InvitationsState = {
	status: FetchStatus.initial,
	error: undefined,
	invitationsReceived: [],
}

export const receivedInvitationsCompanySlice = createSlice({
	name: 'companyReceivedInvitations',
	initialState: invitationsInitialState,
	reducers: {
		RECEIVED_INVITATIONS_FETCHING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		RECEIVED_INVITATIONS_SUCCESS: (state, action: PayloadAction<Array<Invitation>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				invitationsReceived: action.payload,
			}
		},
		RECEIVED_INVITATIONS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		RECEIVED_INVITATIONS_RESET: () => {
			return invitationsInitialState
		},
		RECEIVED_INVITATIONS_INSERT: (state, action: PayloadAction<Invitation>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				invitationsReceived: [action.payload, ...state.invitationsReceived],
			}
		},
		RECEIVED_INVITATIONS_UPDATING: (state, action: PayloadAction<string>) => {
			// Flag invitation as updating, mark updated as incomplete
			return {
				...state,
				invitationsReceived: state.invitationsReceived.map((invitation) => ({
					...invitation,
					updating: action.payload === invitation.id ? true : invitation.updating,
					updated: action.payload === invitation.id ? false : invitation.updated,
				})),
			}
		},
		RECEIVED_INVITATIONS_UPDATED: (state, action: PayloadAction<Invitation>) => {
			// Update invitation status, remove updating flag, add updated flag
			return {
				...state,
				invitationsReceived: state.invitationsReceived.map((invitation) => ({
					...invitation,
					updating: action.payload.id === invitation.id ? false : invitation.updating,
					updated: action.payload.id === invitation.id ? true : invitation.updated,
					status: action.payload.id === invitation.id ? action.payload.status : invitation.status,
				})),
			}
		},
		RECEIVED_INVITATIONS_FAILURE_UPDATE: (
			state,
			action: PayloadAction<{ id: string; error: string }>
		) => {
			// Remove updating flag, populate error, add updated flag
			return {
				...state,
				invitationsReceived: state.invitationsReceived.map((invitation) => ({
					...invitation,
					updating: action.payload.id === invitation.id ? false : invitation.updating,
					updated: action.payload.id === invitation.id ? false : invitation.updated,
					error: action.payload.id === invitation.id ? action.payload.error : invitation.error,
				})),
			}
		},
	},
})

// Action types
export const {
	RECEIVED_INVITATIONS_FETCHING,
	RECEIVED_INVITATIONS_SUCCESS,
	RECEIVED_INVITATIONS_ERROR,
	RECEIVED_INVITATIONS_RESET,
	RECEIVED_INVITATIONS_INSERT,
	RECEIVED_INVITATIONS_UPDATING,
	RECEIVED_INVITATIONS_UPDATED,
	RECEIVED_INVITATIONS_FAILURE_UPDATE,
} = receivedInvitationsCompanySlice.actions

// Selectors
export const selectReceivedInvitationsStatus = (state: RootState) =>
	state.companyReceivedInvitations.status
export const selectReceivedInvicationsError = (state: RootState) =>
	state.companyReceivedInvitations.error
export const selectReceivedInvitations = (state: RootState) =>
	state.companyReceivedInvitations.invitationsReceived

export default receivedInvitationsCompanySlice.reducer
