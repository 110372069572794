import { useEffect, useState } from "react";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import { Button } from "../../components/BaseComponents";
import useApi from "../../hooks/useApi";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import { formatDate } from "../../lib/utils";
import Label from "../../components/BaseComponents/Label";
import JurisdictionSelect from "../../components/BaseComponents/JurisdictionSelect";
import usePagination from "../../hooks/usePagination";
import useDataSort from "../../hooks/useDataSort";
import useCleanup from "../../hooks/useCleanup";
import { generatePath, Link } from "react-router-dom";
import routes from "../../components/Router/routes";

const renderEmission = (key: string, data: any) => {
	return <span>{ data[key] } kgCO₂e</span>
}

const columns: TableColumn[] = [
    {
        id: 'companyName',
        label: 'Organisation',
		sortable: true,
		renderer: (key, data) => {
			return (
				<Link 
					className="underline"
					to={generatePath(routes.publicProfile.path, {companyId: data.companyId})}
				>
					{ data[key] }
				</Link>
			)
		}
    },
    {
        id: 'companyNumber',
        label: 'Organisation #',
		sortable: true,
    },
    {
        id: 'jurisdictionCode',
        label: 'Location',
		sortable: true,
    },
    {
        id: 'end_date',
        label: 'Period',
        renderer: (key, data) => (
			<span>
				{ formatDate(data.start_date) }
				{' - '}
				{ formatDate(data.end_date) }
			</span>
		),
		sortable: true,
    },
    {
        id: 'scope1_total',
        label: 'Scope 1',
		renderer: renderEmission,
		sortable: true,
    },
    {
        id: 'scope2_total',
        label: 'Scope 2',
		renderer: renderEmission,
		sortable: true,
    },
    {
        id: 'scope3_total',
        label: 'Scope 3',
		renderer: renderEmission,
		sortable: true,
    },
    {
        id: 'total_emissions',
        label: 'Total',
		renderer: renderEmission,
		sortable: true,
    },
    {
        id: 'intensity_ratio',
        label: 'Intenstity',
		renderer: (key, data) => (
			<span>
				{ Number(data[key]).toFixed(6) } kgC02e/EUR
			</span>
		),
		sortable: true,
    },
]

function PublicReports() {
    const api = useApi();
    const [data, setData] = useState([]);

	const pagination = usePagination(1, 20);
	const dataSort = useDataSort('companyName', 'asc');
	const [loading, setLoading] = useState(true);

	const [filters, setFilters] = useState({
		companyName: '',
		companyNumber: '',
		jurisdictionCode: '',
		year: '',
	})

    useEffect(() => {
        onSearch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, dataSort.params, pagination.params])

    const onSearch = useCleanup(() => {
		let cancelled = false;
		setLoading(true)
		const run = async () => {
			const qs = new URLSearchParams({
				...filters,
				...pagination.params,
				...dataSort.params,
			})
			const results = await api.get('secr/search?' + qs.toString());
			if(cancelled) {
				return;
			}
			setData(results.data.reports);
			pagination.readMeta(results.data.meta);
			setLoading(false);
		}
		run();
		return () => {
			cancelled = true;
		}
	});

	const startYear = 2010;
	const years = [];
	for(let i = startYear; i <= new Date().getFullYear(); i++) {
		years.unshift(i)
	}

    return (
        <PageTemplate>
            <PageHeadings title="Reports" />
			<div className="flex space-x-3">
				<div>
					<Label className="block">Organisation Name</Label>
					<input
						type="text"
						placeholder="iSumio..."
						className="rounded"
						value={filters.companyName}
						onChange={e => setFilters({...filters, companyName: e.target.value})}
					/>
				</div>
				<label>
					<Label className="block">Organisation Number</Label>
					<input
						type="text"
						placeholder="123..."
						className="rounded"
						value={filters.companyNumber}
						onChange={e => setFilters({...filters, companyNumber: e.target.value})}
					/>
				</label>

				<label>
					<Label className="block">Years</Label>
					<select
						className="rounded"
						value={filters.year}
						onChange={e => setFilters({...filters, year: e.target.value})}
					>
						<option value=''>Any</option>
						{ years.map(year => <option key={year} value={year}>{ year}</option> )}
					</select>
				</label>
			</div>
			<div className="flex space-x-3">
				<label>
					<Label className="block">Jurisdiction</Label>
					<JurisdictionSelect
						value={filters.jurisdictionCode}
						onChange={value => setFilters({...filters, jurisdictionCode: value})}
					/>
				</label>
			</div>

			<div>
				<Button
					type='Primary'
					text='Search'
					size='sm'
					hasTrailingIcon
					onClick={onSearch}
					additionalClass='mt-4 mb-2'
				/>
			</div>

            <SimpleSortTable
				isLoading={loading}
				additionalClass="mt-4"
                columns={columns}
                data={data}
				defaultSorting={dataSort.defaultSorting}
				onSortPress={dataSort.onChange}
            />
            <Pagination
				isLoading={loading}
                pagination={pagination.pagination}
                onPageChange={pagination.onPageChange}
            />
        </PageTemplate>
    )
}

export default PublicReports;
