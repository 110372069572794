import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts'
import { PeriodStatistic } from '../../../../../types/PeriodStatistic'

type ISupplierInsightsEmissionQualityGraph = {
	chartData: Array<PeriodStatistic>
}

const SupplierInsightsEmissionQualityGraph: FunctionComponent<ISupplierInsightsEmissionQualityGraph> = ({
	chartData,
}) => {
	const qualityChartData = chartData
		.map((period) => {
			return {
				name: `${dayjs(period.startdate).format('MM/YYYY')} - ${dayjs(period.endDate).format(
					'MM/YYYY'
				)}`,
				emissionQuality: Number(period.statistics.emissionQualityRate * 100).toFixed(2),
			}
		})
		.reverse()

	return (
		<>
			<div className='relative pl-4 overflow-hidden'>
				{/* Custom Y axis label - sorry */}
				<div className='text-white text-sm absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-center'>
					<span className='-rotate-90 origin-top-left'>
						<span className='absolute -translate-x-1/2'>
							Data Quality
						</span>
					</span>
				</div>
				<ResponsiveContainer width='100%' aspect={1.778}>
					<LineChart
						width={500}
						height={300}
						data={qualityChartData}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<XAxis
							dataKey='name'
							axisLine={false}
							tickLine={false}
							style={{ fontSize: '12px' }}
							tick={{ fill: '#FFF' }}
						/>
						<YAxis
							axisLine={false}
							tickLine={false}
							ticks={[0, 25, 50, 75, 100]}
							style={{ fontSize: '12px' }}
							tick={{ fill: '#FFF' }}
							tickFormatter={value => `${value}%`}
						/>
						<Tooltip
							contentStyle={{ backgroundColor: '#2E3A46', borderColor: '#252F38', borderWidth: 2 }}
							labelStyle={{ color: 'white' }}
							cursor={{ fill: '#12171C' }}
						/>
						<Line dataKey='emissionQuality' connectNulls fill='#E6EFFF' name='Data Quality' unit="%" />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</>
	)
}

export default SupplierInsightsEmissionQualityGraph
