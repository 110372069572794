import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, LoadingSpinner } from '../components/BaseComponents'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import {
	getAccountingPeriods,
	updateAccountingPeriodStage,
} from '../redux/actions/accountingPeriodsActions'
import { getScope1EmissionTypes } from '../redux/actions/emissionTypesActions'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	ACCOUNTINGPERIODS_UPDATE_RESET,
	selectAccountingPeriods,
	selectAccountingPeriodsError,
	selectAccountingPeriodsStatus,
	selectAccountingPeriodsUpdateError,
	selectAccountingPeriodsUpdateStatus,
} from '../redux/slices/accountingPeriodsSlice'
import {
	selectAllScope1EmissionTypes,
	selectEmissionTypesStatus,
} from '../redux/slices/emissionTypesSlice'
import { selectUpdateItemsStatus, UPDATEITEMS_RESET } from '../redux/slices/updateItemsSlice'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import { updateItemsEmissionsType } from '../redux/actions/updateItemsActions'
import { centsToCurrency } from '../lib/utils'
import { SortConfig } from '../components/BaseComponents/Datagrid/useSortableData'
import { PurchaseUploadState } from '../enums/PurchaseUploadState'
import EmissionTypeInput from '../components/BaseComponents/Forms/EmissionTypeInput'
import {
	GET_PURCHASES_CARBON_ACCOUNTS_LIMIT,
} from '../redux/actions/carbonAccountsPurchasesActions'
import SimpleSortTable, {
	TableColumn,
} from '../components/BaseComponents/SimpleSortTable/SimpleSortTable'
import Pagination from '../components/BaseComponents/Pagination/Pagination'
import { Scope1EmissionItem } from '../types/PurchaseCarbonData'
import { selectRefinementsScope1EmissionError, selectRefinementsScope1EmissionItems, selectRefinementsScope1EmissionStatus } from '../redux/slices/refinementsSlice'
import { PaginationMeta } from '../types/PaginationMeta'
import { getScope1EmissionItems } from '../redux/actions/refinementsActions'
import useCurrencyFormatter from '../hooks/useCurrencyFormatter'
import useApi from '../hooks/useApi'

const CompanyRefineEmissionsType = () => {
	const formatCurrency = useCurrencyFormatter();
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const api = useApi();

	const accountingPeriodsStatus = useAppSelector(selectAccountingPeriodsStatus)
	const accountingPeriodsError = useAppSelector(selectAccountingPeriodsError)
	const accountingPeriodUpdateStatus = useAppSelector(selectAccountingPeriodsUpdateStatus)
	const accountingPeriodUpdateError = useAppSelector(selectAccountingPeriodsUpdateError)
	const allAccountingPeriodData = useAppSelector(selectAccountingPeriods)
	const [errorNoPeriod, setErrorNoPeriod] = useState(false)

	const emissionTypesStatus = useAppSelector(selectEmissionTypesStatus)
	const allScope1EmissionTypes = useAppSelector(selectAllScope1EmissionTypes)

	const itemLoadStatus = useAppSelector(selectRefinementsScope1EmissionStatus)
	const itemError = useAppSelector(selectRefinementsScope1EmissionError)
	const itemData = useAppSelector(selectRefinementsScope1EmissionItems)
	const defaultPagination: PaginationMeta = {
		current_page: 1,
		first_page: 1,
		last_page: 1,
		per_page: 20,
		total: 0,
	}
	const { items, pagination } = itemData || { items: [], pagination: defaultPagination }

	const limit = GET_PURCHASES_CARBON_ACCOUNTS_LIMIT
	const [orderBy, setOrderBy] = useState<string>('itemName')
	const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
	const { accountingPeriodId } = useParams() as { accountingPeriodId: string }

	const [dirtyItems, setDirtyItems] = useState<{ itemId: string, emissionTypeId: string|null}[]>([])

	const updateItemsStatus = useAppSelector(selectUpdateItemsStatus)

	// Find the accounting period to match the url param
	const thisAccountingPeriod = allAccountingPeriodData.filter(
		(period) => period.id === accountingPeriodId
	)[0]

	// Data loading
	useEffect(() => {
		// Fetch accounting periods if they aren't in the store, we need to compare against the IDs
		if (accountingPeriodsStatus === FetchStatus.initial) {
			dispatch(getAccountingPeriods(api))
		}

		// Refetch purchases (regardless) as we need updated scope information
		if (accountingPeriodsStatus === FetchStatus.loaded) {
			dispatch(
				getScope1EmissionItems(api, accountingPeriodId, 1, orderBy, direction, limit)
			)

			if (typeof thisAccountingPeriod === 'undefined') {
				setErrorNoPeriod(true)
			}
		}

		// Fetch emission type dropdown options for scope 1
		if (emissionTypesStatus === FetchStatus.initial) {
			dispatch(getScope1EmissionTypes(api))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountingPeriodsStatus])

	// Navigate to next page on successful submission, mark flag complete
	useEffect(() => {
		if (updateItemsStatus === PostStatus.sent) {
			dispatch(
				updateAccountingPeriodStage(api, {
					accountingPeriodId: thisAccountingPeriod.id,
					uploadState: PurchaseUploadState.scope1Refined,
				})
			)
			dispatch({
				type: UPDATEITEMS_RESET,
			})
			dispatch({
				type: ACCOUNTINGPERIODS_UPDATE_RESET,
			})
			navigate(generatePath(routes.companyRefineElectricity.path, { accountingPeriodId: thisAccountingPeriod.id}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateItemsStatus, accountingPeriodUpdateStatus])

	// DataGrid Columns
	const columns: TableColumn[] = [
		{ id: 'itemName', label: 'Product', sortable: true },
		{ id: 'supplierName', label: 'Supplier', sortable: true },
		{
			id: 'totalQuantity',
			label: 'Quantity',
			sortable: true,
			renderer: (columnKey, rowData) => {
				return (
					<>
						<p className=' font-medium text-white '>
							{rowData.totalQuantity} {rowData.quantityUnit}
						</p>
						<p className=' font-medium text-charcoal-100 '>({formatCurrency(rowData.price)})</p>
					</>
				)
			},
		},
		{
			id: 'emissionType',
			label: 'Emission Type',
			renderer: (columnKey, rowData) => {
				const dirtyItem = dirtyItems.find((item) => item.itemId === rowData._id)
				return (
					<div>
						{emissionTypesStatus === FetchStatus.loaded && (
							<EmissionTypeInput
								emissionTypeData={allScope1EmissionTypes}
								initialValue={dirtyItem ? dirtyItem.emissionTypeId : rowData.emissionTypeId}
								onChange={(emissionId) => {
									const changes = dirtyItems.filter((p) => p.itemId !== rowData._id)
									const itemChange = {
										itemId: rowData._id,
										emissionTypeId: emissionId || null,
									}
									setDirtyItems([
										...changes,
										itemChange
									])
								}}
							/>
						)}
					</div>
				)
			},
		},
	]
	const defaultSorting: SortConfig = {
		key: 'itemName',
		direction: 'ascending',
	}

	// DataGrid data constructor
	const constructData = (dataSet: Scope1EmissionItem[]) => dataSet.map((item) => {
		return {
			_id: item.itemId,
			itemName: item.itemName,
			price: centsToCurrency(item.totalSpend),
			supplierName: item.supplierName,
			totalQuantity: item.totalQuantity,
			quantityUnit: item.quantityUnit,
			emissionTypeId: item.emissionTypeId
		}
	})
	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyRefineEmissionsGeneratedType.name}</title>
			</Helmet>
			<PageHeadings
				title={'Accounting periods'}
				subTitle={'Refine scope 1 data'}
				subSubTitle={'Emissions generated type'}
				routeBack={generatePath(routes.companyRefineEmissionsGenerated.path, { accountingPeriodId})}
			/>

			<>
				<div className='rounded-lg bg-sun-500 p-4 mt-5'>
					<p>Select the emission type for the purchases that generate emissions.</p>
				</div>
				<SimpleSortTable
					isLoading={
						accountingPeriodsStatus === FetchStatus.loading ||
						itemLoadStatus === FetchStatus.loading ||
						emissionTypesStatus === FetchStatus.loading
					}
					additionalClass='mt-4'
					data={constructData(items)}
					columns={columns}
					defaultSorting={defaultSorting}
					onSortPress={(key, direction) => {
						// Store current sorting values for pagination to reference in its API calls
						setOrderBy(key)
						setDirection(direction === 'ascending' ? 'asc' : 'desc')
						dispatch(
							getScope1EmissionItems(api, accountingPeriodId, 1, orderBy, direction === 'ascending' ? 'asc' : 'desc', limit)
						)
					}}
				/>
				<Pagination
					isLoading={
						accountingPeriodsStatus === FetchStatus.loading ||
						itemLoadStatus === FetchStatus.loading
					}
					pagination={pagination}
					onPageChange={(newPageNumber) => {
						dispatch(
							getScope1EmissionItems(api, accountingPeriodId, newPageNumber, orderBy, direction, limit)
						)
					}}
				/>

				<div className='rounded-lg bg-charcoal-600 p-2 mt-5'>
					<Button
						type={'Primary'}
						text='Next Step'
						icon='fa-regular fa-chevron-right'
						additionalClass='float-right'
						hasTrailingIcon
						onClick={() => {
							// Post items with selections for update
							dispatch(
								updateItemsEmissionsType(
									api,
									dirtyItems.map(dirtyItem => ({
										id: dirtyItem.itemId,
										emission_type: dirtyItem.emissionTypeId,
									}))
								)
							)
						}}
					/>
				</div>
			</>

			{/* Errors and loading states*/}
			{accountingPeriodsStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading accounting periods...' />
			)}
			{updateItemsStatus === PostStatus.sending && (
				<LoadingSpinner loadingText='Sending items...' />
			)}
			{accountingPeriodsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the accounting periods list. If this problem persists,
						please contact the Neoni team.
					</p>
					<p className='text-white'>{accountingPeriodsError}</p>
				</div>
			)}
			{itemLoadStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the purchases for this accounting period (
						{accountingPeriodId}). If this problem persists, please contact the Neoni team.
					</p>
					<p className='text-white'>{itemError}</p>
				</div>
			)}
			{accountingPeriodUpdateStatus === PostStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error updating the accounting periods list. If this problem persists,
						please contact the Neoni team.
					</p>
					<p className='text-white'>{accountingPeriodUpdateError}</p>
				</div>
			)}
			{updateItemsStatus === PostStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error updating those items' emission types. If this problem persists,
						please contact the Neoni team.
					</p>
				</div>
			)}
			{errorNoPeriod && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error whilst trying to find purchases for that accounting period ID (
						{accountingPeriodId}) in scope 1. If this problem persists, please contact the Neoni
						team.
					</p>
					<Button
						onClick={() => {
							navigate(generatePath(routes.companyRefineEmissionsGeneratedType.path, {accountingPeriodId}), {
								replace: true,
							})
						}}
						type='Primary'
						hasTrailingIcon={true}
						icon='fa-solid fa-refresh'
						text='Try again'
						additionalClass='mt-9 mr-5'
					/>
					<Button
						type='Outlined'
						text='Back to accounting periods'
						onClick={() => {
							navigate(routes.companyAddAccountingPeriods.path)
						}}
						additionalClass='mt-6 mr-5'
					/>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyRefineEmissionsType
