import { FunctionComponent } from 'react'
import ReactModal from 'react-modal'
import Button from '../Button/Button'
import './Modal.css'

interface IModal {
	open: boolean
	onClose: () => void
	onOpen?: () => void
	children?: React.ReactNode
	size?: 'small' | 'large'
}

const Modal: FunctionComponent<IModal> = ({ children, onOpen, onClose, open, size = 'large' }) => {
	return (
		<>
			<ReactModal
				isOpen={open}
				onAfterOpen={onOpen}
				onAfterClose={onClose}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				onRequestClose={() => {
					onClose()
				}}
				className={`isumio-modal bg-charcoal-500 rounded-lg p-8 ${
					size === 'small' ? 'isumio-modal--small' : 'isumio-modal--large'
				}`}
				overlayClassName='isumio-overlay bg-charcoal-500'
			>
				<Button
					type='Link'
					hasTrailingIcon={true}
					icon='fa-regular fa-times'
					additionalClass='isumio-closebutton'
					onClick={() => onClose()}
				/>
				{children}
			</ReactModal>
		</>
	)
}

export default Modal
