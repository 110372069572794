import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { PaginationMeta } from '../../types/PaginationMeta'
import { PurchaseItem } from '../../types/PurchaseItem'
import type { RootState } from '../store'

type ItemsSlice = {
	status: FetchStatus
	error?: string
	items: Array<PurchaseItem>
	pagination: PaginationMeta
	item?: PurchaseItem
}

const itemsInitialState: ItemsSlice = {
	status: FetchStatus.initial,
	error: undefined,
	items: [],
	pagination: {
		total: 0,
		per_page: 0,
		current_page: 1,
		last_page: 1,
		first_page: 1,
	},
	item: undefined,
}

export const itemsSlice = createSlice({
	name: 'Items',
	initialState: itemsInitialState,
	reducers: {
		ITEMS_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		ITEMS_LOADED: (
			state,
			action: PayloadAction<{
				data: Array<PurchaseItem>
				meta: any
			}>
		) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				items: action.payload.data,
				pagination: {
					total: action.payload.meta.total,
					per_page: action.payload.meta.per_page,
					current_page: action.payload.meta.current_page,
					last_page: action.payload.meta.last_page,
					first_page: action.payload.meta.first_page,
				},
			}
		},
		ITEMS_SINGLELOADED: (state, action: PayloadAction<PurchaseItem>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				item: action.payload,
			}
		},
		ITEMS_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		ITEMS_RESET: () => {
			return itemsInitialState
		},
	},
})

// Action types
export const { ITEMS_LOADING, ITEMS_LOADED, ITEMS_SINGLELOADED, ITEMS_ERROR, ITEMS_RESET } =
	itemsSlice.actions

// Selectors
export const selectItemsStatus = (state: RootState) => state.items.status
export const selectItemsError = (state: RootState) => state.items.error
export const selectAllItems = (state: RootState) => state.items.items
export const selectItemsPagination = (state: RootState) => state.items.pagination
export const selectNumberOfResults = (state: RootState) => state.items.items.length
export const selectIndividualItem = (state: RootState) => state.items.item

export type { PurchaseItem }
export default itemsSlice.reducer
