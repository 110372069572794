import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { NewCompany } from '../../types/Company'
import {
	CREATECOMPANY_ERROR,
	CREATECOMPANY_SENDING,
	CREATECOMPANY_SENT,
} from '../slices/createCompanySlice'
import { NeoniApi } from '../../lib/api'

export const createCompany = (api: NeoniApi, companyToCreate: NewCompany) => async (dispatch: Dispatch) => {
	dispatch({
		type: CREATECOMPANY_SENDING,
	})

	try {
		const csrf = await getCSRFToken()

		const body = await api.post(`/company`, {
				...companyToCreate,
				companyName: companyToCreate.name,
				companyNumber: companyToCreate.number,
				industryCodes: companyToCreate.industry_codes,
				jurisdictionCode: companyToCreate.jurisdiction_code,
				type: companyToCreate?.type,
				tier: companyToCreate.tier,
				_csrf: csrf,
		});

		if (body.success === true) {
			// Successfully created new company
			dispatch({
				type: CREATECOMPANY_SENT,
				payload: body.company,
			})
		} else {
			// Error
			dispatch({
				type: CREATECOMPANY_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: CREATECOMPANY_ERROR,
			payload: error,
		})
	}
}
