import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type SupplyChainGraphDataSlice = {
	status: FetchStatus
	error?: string
	graphData: Array<{
		periodId: string
		periodStart: string
		periodEnd: string
		data: Array<{
			id: string
			label: string
			total: number
		}>
	}>
}

const supplyChainGraphDataInitialState: SupplyChainGraphDataSlice = {
	status: FetchStatus.initial,
	error: undefined,
	graphData: [],
}

export const supplyChainGraphDataSlice = createSlice({
	name: 'supplyChainGraphData',
	initialState: supplyChainGraphDataInitialState,
	reducers: {
		SUPPLYCHAINGRAPHDATA_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		SUPPLYCHAINGRAPHDATA_LOADED: (state, action: PayloadAction<Array<any>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				graphData: action.payload,
			}
		},
		SUPPLYCHAINGRAPHDATA_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		SUPPLYCHAINGRAPHDATA_RESET: () => {
			return supplyChainGraphDataInitialState
		},
	},
})

// Action types
export const {
	SUPPLYCHAINGRAPHDATA_LOADING,
	SUPPLYCHAINGRAPHDATA_LOADED,
	SUPPLYCHAINGRAPHDATA_ERROR,
	SUPPLYCHAINGRAPHDATA_RESET,
} = supplyChainGraphDataSlice.actions

// Selectors
export const selectSupplyChainGraphDataStatus = (state: RootState) =>
	state.supplyChainGraphData.status
export const selectSupplyChainGraphDataSectorError = (state: RootState) =>
	state.supplyChainGraphData.error
export const selectAllSupplyChainGraphData = (state: RootState) =>
	state.supplyChainGraphData.graphData

export const selectSortedSupplyChainGraphData = (state: RootState) =>
	[...state.supplyChainGraphData.graphData]
		.sort((a, b) => dayjs(a.periodEnd).diff(dayjs(b.periodEnd)))
		.reverse()
		.map((period) => {
			return {
				periodId: period.periodId,
				periodEnd: period.periodEnd,
				periodStart: period.periodStart,
				data: [...period.data].sort((a, b) => b.total - a.total),
			}
		})

export default supplyChainGraphDataSlice.reducer
