import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmissionType } from '../../types/EmissionType'
import { FetchStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type EmissionTypesSlice = {
	status: FetchStatus
	error?: string
	emissionTypes: {
		scope1: Array<EmissionType>
	}
}

const EmissionTypesInitialState: EmissionTypesSlice = {
	status: FetchStatus.initial,
	error: undefined,
	emissionTypes: {
		scope1: [],
	},
}

export const emissionTypesSlice = createSlice({
	name: 'Emission Types',
	initialState: EmissionTypesInitialState,
	reducers: {
		EMISSIONTYPES_LOADING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		EMISSIONTYPES_LOADED: (state, action: PayloadAction<{ scope1: Array<EmissionType> }>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				error: undefined,
				emissionTypes: {
					scope1: [...action.payload.scope1],
				},
			}
		},
		EMISSIONTYPES_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		EMISSIONTYPES_RESET: () => {
			return EmissionTypesInitialState
		},
	},
})

// Action types
export const {
	EMISSIONTYPES_LOADING,
	EMISSIONTYPES_LOADED,
	EMISSIONTYPES_ERROR,
	EMISSIONTYPES_RESET,
} = emissionTypesSlice.actions

// Selectors
export const selectEmissionTypesStatus = (state: RootState) => state.emissonTypes.status
export const selectEmissionTypesError = (state: RootState) => state.emissonTypes.error
export const selectAllEmissionTypes = (state: RootState) => state.emissonTypes.emissionTypes
export const selectAllScope1EmissionTypes = (state: RootState) =>
	state.emissonTypes.emissionTypes.scope1

export default emissionTypesSlice.reducer
