import { useEffect, useState, Key } from 'react'
import { Combobox } from '@headlessui/react'
import { FieldError } from 'react-hook-form'
import classNames from 'classnames'
import './ISICSelector.css'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/reduxHooks'
import { getIsicCodes } from '../../../redux/actions/isicActions'
import { selectAllIsic, selectIsicStatus } from '../../../redux/slices/isicSlice'
import { ISICItem } from '../../../types/ISICItem'
import { FetchStatus } from '../../../types/LoadingStates'
import Fuse from 'fuse.js'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import useApi from '../../../hooks/useApi'

//TODO: Needs major refactor
//WARNING: Will cause infinite loops on hot reload due to useEffect losing reference/poor implementation

//TODO: STILL NEEDS A MAJOR REFACTOR!

export interface InterfaceISICSelector {
	updateHandler(selectedCodes: string[]): void
	initialQueries?: string[]
	defaultValue?: string[]
	fixedValues?: string[]
	value?: string[]
	error?: FieldError
	isDirty?: boolean
	min?: number
	max?: number
	darkMode?: boolean
}

// Prettier and ESLint get into a fight about indentation here
// prettier-ignore
export const validate =
	(min?: number, max?: number, extraValues?: string[]) =>
		(value: string[]): string | undefined => {
			extraValues = extraValues || []
			// 'extraValues' allows us to inject fixed values into the validation
			value = value.concat(extraValues)
			console.log('ISIC value', value)

			const minimumValidAmount = min || 1
			const maximumValidAmount = max || 4
			if (value.length < minimumValidAmount) return `Please select at least ${minimumValidAmount} ISIC code${
				minimumValidAmount === 1 ? '' : 's'
			}.`
			if (value.length > maximumValidAmount) return `The maximum amount of selectable ISIC codes is ${maximumValidAmount}.`
			return undefined
		}

export default function ISICSelector({
	updateHandler: onUpdate,
	defaultValue,
	fixedValues = [],
	error,
	isDirty,
	min,
	max,
	value,
	darkMode = false,
}: InterfaceISICSelector): JSX.Element {
	const api = useApi();
	const dispatch = useAppDispatch()
	const isicCodes = useAppSelector(selectAllIsic)
	const isicStatus = useAppSelector(selectIsicStatus)
	const [filteredIsicCodes, setFilteredIsicCodes] = useState<Fuse.FuseResult<ISICItem>[]>([])
	const [selectedIsicCodes, setSelectedIsicCodes] = useState<string[]>(defaultValue || [])
	const [errormsg, setErrorMsg] = useState('')

	// Fetch ISIC codes from database
	useEffect(() => {
		if (isicStatus !== FetchStatus.loaded && isicStatus !== FetchStatus.loading) {
			dispatch(getIsicCodes(api))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Fuzzy search library: Fuse.js (https://fusejs.io/)
	const fuse = new Fuse<ISICItem>(isicCodes, {
		keys: ['code', 'name', 'description'],
		threshold: 0.5, // Bump this towards 1 to get less strict
	})
	const fuzzySearch = (keyword: string) => {
		setFilteredIsicCodes(fuse.search(keyword))
	}

	//when we are not using form and want to validate directly
	const validateISIC = (min?: number, max?: number) => {
		const combinedCodesTotal = selectedIsicCodes.concat(fixedValues)

		const minval = min || 1
		const maxval = max || 4
		if (combinedCodesTotal?.length + 1 < minval) {
			setErrorMsg(`Please select at least ${minval} ISIC code${minval === 1 ? '' : 's'}.`)
			return errormsg
		} else if (combinedCodesTotal?.length + 1 > maxval) {
			// eslint-disable-next-line no-const-assign
			setErrorMsg(`The maximum amount of selectable ISIC codes is ${maxval}.`)
			return errormsg
		} else {
			setErrorMsg('')
			return undefined
		}
	}

	const showErrors = () => {
		if (error) {
			if (isDirty) {
				return true
			} else {
				return false
			}
		}
		return false
	}

	// Controlled input mode
	useEffect(() => {
		if (value && value.length) {
			setSelectedIsicCodes(value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	useEffect(() => {
		if (onUpdate) {
			onUpdate(selectedIsicCodes)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIsicCodes])

	// Any type used as Combobox component expects string setter but is actually receiving fuze result
	const addIsicToSelected = (isicToAdd: any) => {
		if (min !== null && max !== null) {
			let validateval = validateISIC(min, max)
			if (validateval === undefined) {
				setSelectedIsicCodes((prev) => [...prev, isicToAdd.item.code])
			}
		} else {
			if (!validate(1)) {
				return
			}
			setSelectedIsicCodes((prev) => [...prev, isicToAdd.item.code])
		}
	}
	const removeIsicFromSelected = (isicToRemove: string) => {
		setSelectedIsicCodes((prev) => prev.filter((item) => item !== isicToRemove))
		validate(-1)
		setErrorMsg('')
	}

	return (
		<>
			{isicStatus === FetchStatus.loading && <LoadingSpinner loadingText='Loading ISIC codes...' />}
			{isicStatus === FetchStatus.loaded && (
				<div>
					{/* `value` is an empty string because we never set this, it’s only unset after a selection */}
					<Combobox as='div' value={''} onChange={addIsicToSelected}>
						<div className='relative mt-1 mb-4'>
							<Combobox.Input
								className={classNames(
									'w-full rounded-md border bg-white py-2 pl-3 pr-10 focus:outline-none focus:ring-1',
									{
										' border-error-500 bg-error-050 text-error-800 focus:border-error-500 focus:ring-error-500':
											showErrors(),
										' border-charcoal-200 focus:border-algae-500 focus:ring-algae-500':
											!showErrors(),
									}
								)}
								onChange={(event) => {
									fuzzySearch(event.target.value)
								}}
							/>
							<Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
								<span
									className='fa-solid fa-search h-5 w-5 text-charcoal-500 pr-2 py-0.5'
									aria-hidden='true'
								></span>
							</Combobox.Button>

							{filteredIsicCodes.length > 0 && (
								<Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
									{filteredIsicCodes.map((resultOption) => {
										// Don’t display codes that have already been selected as options or are in the fixed options
										if (
											selectedIsicCodes.includes(resultOption.item.code) ||
											fixedValues?.includes(resultOption.item.code)
										) {
											return null
										}
										const result: ISICItem = isicCodes.find(
											(item) => item.code === resultOption.item.code
										) as ISICItem
										return (
											<Combobox.Option
												key={resultOption.item.id as Key}
												value={resultOption}
												className={({ active }) =>
													classNames(
														'relative cursor-default select-none py-2 pl-3 pr-9 text-charcoal-500',
														{ 'bg-algae-100': active }
													)
												}
											>
												{({ active, selected }) => (
													<>
														<div className='flex'>
															<span className={classNames({ 'font-semibold': selected })}>
																{resultOption.item.code}
															</span>
															<span className='px-2'>–</span>
															<span className='text-charcoal-500'>{result && result.name}</span>
														</div>

														{selected && (
															<span className='absolute inset-y-0 right-0 flex items-center pr-4'>
																<span
																	className='fa-solid fa-check h-5 w-5'
																	aria-hidden='true'
																></span>
															</span>
														)}
													</>
												)}
											</Combobox.Option>
										)
									})}
								</Combobox.Options>
							)}
						</div>
					</Combobox>
					{showErrors() && error && <p className='mt-2 text-sm text-error-700'>{error.message}</p>}
					{errormsg && (
						<p className={`mt-3 mb-3 text-sm ${darkMode ? 'text-error-400' : 'text-error-700'}`}>
							{errormsg}
						</p>
					)}
					<ul className='my-2'>
						{/* Fixed ISIC codes that can't be dismissed */}
						{fixedValues?.map((fixedValue: string) => {
							const result: ISICItem = isicCodes.find(
								(item) => item.code === fixedValue
							) as ISICItem
							return (
								<li key={fixedValue} className={`my-1.5 text-sm ${darkMode && 'text-white'}`}>
									<strong>{fixedValue}</strong>
									<span className='px-1'>–</span>
									{result && <span className='flex-auto'>{result.name}</span>}
								</li>
							)
						})}

						{/* Normal selectable and dismissible ISIC codes */}
						{selectedIsicCodes.map((selectedOption: string) => {
							const result: ISICItem = isicCodes.find(
								(item) => item.code === selectedOption
							) as ISICItem
							const optionProps = {
								key: selectedOption as Key,
								onClick: () => removeIsicFromSelected(selectedOption),
								className: `flex cursor-pointer my-1.5 text-sm ${darkMode && 'text-white'}`,
								onMouseOver: () => {},
								onMouseOut: () => {},
							}
							return (
								<li {...optionProps}>
									<strong>{selectedOption}</strong>
									<span className='px-1'>–</span>
									<span className='flex-auto'>{result && result.name}</span>
									<span
										className='fa-solid fa-remove h-5 w-5 pr-6 py-1.5'
										aria-hidden='true'
									></span>
								</li>
							)
						})}
					</ul>
				</div>
			)}
		</>
	)
}
