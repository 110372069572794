import { useParams } from "react-router-dom";
import CompanyProject from "./CompanyProject";
import CompanyProjectsList from "./CompanyProjectsList";

function CompanyProjects() {
    const { projectId } = useParams();
    if(projectId) {
        return <CompanyProject />
    } else {
        return <CompanyProjectsList />
    }
}

export default CompanyProjects;
