import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import AppRouter from './components/Router/Router'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { AuthProvider } from './context/AuthContext'
import { NotificationProvider } from './context/NotificationContext'
import { UserProvider } from './context/UserContext'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<Provider store={store}>
		<AuthProvider>
			<BrowserRouter>
				<NotificationProvider>
					<UserProvider>
						<AppRouter />
					</UserProvider>
				</NotificationProvider>
			</BrowserRouter>
		</AuthProvider>
	</Provider>
)
