import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { PeriodStatistic } from '../../../../../types/PeriodStatistic'
import { getCurrencySymbol } from '../../../../../lib/utils'
import useCurrency from '../../../../../hooks/useCurrency'

type ISupplierInsightsScope3Graph = {
	chartData: Array<PeriodStatistic>
}

const SupplierInsightsScope3Graph: FunctionComponent<ISupplierInsightsScope3Graph> = ({
	chartData,
}) => {

    const currencySymbol = getCurrencySymbol(useCurrency())
	// Get the latest year
	//		Sort it by size
	//			Grab the biggest 5 to be keys
	//			Also grab their labels
	let latestYearByCodeSorted = [...chartData[0].statistics.byIsicCode].sort((a, b) => b.sum - a.sum)
	let fiveBiggestKeys = latestYearByCodeSorted.slice(0, 5).reduce((arr, item) => {
		arr.push(item.code)
		return arr
	}, [] as Array<string>)
	let fiveBiggestKeysLabels = latestYearByCodeSorted.slice(0, 5).reduce((arr, item) => {
		arr.push(item.label)
		return arr
	}, [] as Array<string>)

	// Get every year
	//		Allow the biggest keys to be shown
	//			Store everything else in X
	let allYearsFiltered = chartData
		.map((period) => {
			let keysInBigFive = period.statistics.byIsicCode.filter((code) =>
				fiveBiggestKeys.includes(code.code)
			)

			keysInBigFive.push({
				code: 'X',
				label: 'Everything else',
				sum: Number(
					period.statistics.byIsicCode
						.filter((code) => !fiveBiggestKeys.includes(code.code))
						.reduce((acc, item) => acc + item.sum, 0)
						.toFixed(3)
				),
			})

			// Reformat the data to use the code as the key, and the sum as the value
			// Give the label a related key we can hook into later
			let codeAsKey = keysInBigFive.reduce((acc, entry) => {
				return {
					[entry.code]: entry.sum,
					[`label-${entry.code}`]: entry.label, // Unused
					...acc,
				}
			}, {})

			return {
				name: `${dayjs(period.startdate).format('MM/YYYY')} - ${dayjs(period.endDate).format(
					'MM/YYYY'
				)}`,
				...codeAsKey,
			}
		})
		.reverse() //We want the latest year on the right

	const colourList = ['#E6EFFF', '#CCDFFF', '#99BEFF', '#669EFF', '#337EFF', '#005EFF']

	return (
		<>
			<div className='relative pl-4 overflow-hidden'>
				{/* Custom Y axis label - sorry */}
				<div className='text-white text-sm absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-center'>
					<span className='-rotate-90 origin-top-left'>
						<span className='absolute -translate-x-1/2'>
							Intensity Ratio (kg CO₂e per {currencySymbol}1 Turnover)
						</span>
					</span>
				</div>
				<ResponsiveContainer width='100%' aspect={1.778}>
					<BarChart
						width={500}
						height={300}
						data={allYearsFiltered}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<XAxis
							dataKey='name'
							axisLine={false}
							tickLine={false}
							style={{ fontSize: '12px' }}
							tick={{ fill: '#FFF' }}
						/>
						<YAxis
							axisLine={false}
							tickLine={false}
							style={{ fontSize: '12px' }}
							tick={{ fill: '#FFF' }}
						/>
						<Tooltip
							contentStyle={{ backgroundColor: '#2E3A46', borderColor: '#252F38', borderWidth: 2 }}
							labelStyle={{ color: 'white' }}
							cursor={{ fill: '#12171C' }}
						/>

						<Bar
							dataKey={'X'}
							stackId='a'
							fill={colourList[0]}
							name='Everything else'
							label='Everything else'
							barSize={48}
						/>
						{fiveBiggestKeys.map((key, index) => {
							return (
								<Bar
									key={key}
									dataKey={key}
									stackId='a'
									fill={colourList[index + 1]}
									name={key}
									barSize={48}
								/>
							)
						})}
					</BarChart>
				</ResponsiveContainer>
			</div>
			<div className=''>
				<h3 className='text-white text-lg my-4'>Legend</h3>
				<div className='flex items-center mb-2'>
					<div style={{ height: 10, width: 14, backgroundColor: colourList[0], flexShrink: 0 }} />
					<p className='text-white ml-2'>Everything else</p>
				</div>
				{fiveBiggestKeys.map((key, index) => (
					<div key={key} className='flex items-center mb-2'>
						<div
							style={{
								height: 10,
								width: 14,
								backgroundColor: colourList[index + 1],
								flexShrink: 0,
							}}
						/>
						<p className='text-white ml-2'>
							{key} - <span className='text-charcoal-100'>{fiveBiggestKeysLabels[index]}</span>
						</p>
					</div>
				))}
			</div>
		</>
	)
}

export default SupplierInsightsScope3Graph
