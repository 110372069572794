import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'

type AlertProps = {
	type: 'error' | 'warning' | 'success' | 'info'
	title: string
	text?: string
}

const iconType = {
	success: 'text-success-500 fa-light fa-circle-check',
	info: 'text-algae-500 fa-light fa-circle-info',
	error: 'text-error-500 fa-light fa-circle-xmark',
	warning: 'text-warning-500 fa-light fa-circle-exclamation',
}

function Alert(props: AlertProps) {
	const [show, setShow] = useState(true)

	return (
		<div
			aria-live='assertive'
			className='pointer-events-none fixed inset-0 flex items-end px-4 py-6'
		>
			<div className='flex w-full flex-col items-end space-y-4'>
				<Transition
					show={show}
					as={Fragment}
					enter='transform ease-out duration-300 transition'
					enterFrom='translate-y-2 opacity-0'
					enterTo='translate-y-0 opacity-100'
					leave='transition ease-in duration-100'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-md bg-white ring-1 ring-black text-white ring-opacity-5'>
						<div className='p-4'>
							<div className='flex items-start justify-center'>
								<div className='flex-shrink-0'>
									<span className={`h-xl w-xl ${iconType[props.type]}`} aria-hidden='true'></span>
								</div>
								<div className='ml-3 w-0 flex-1 pt-0.5'>
									<p className='text-sm font-medium text-charcoal-900'>{props.title}</p>
									{props.text && <p className='mt-1 text-sm text-charcoal-100'>{props.text}</p>}
								</div>
								<div className='ml-4 flex flex-shrink-0'>
									<button
										type='button'
										className='inline-flex rounded-md bg-white text-charcoal-500'
										onClick={() => {
											setShow(false)
										}}
									>
										<span className='sr-only'>Close</span>
										<span className='fa-light fa-xmark h-5 w-5' aria-hidden='true'></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	)
}
export default Alert
