import { Helmet } from "react-helmet";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../../components/Router/routes";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import AdminTextSettings from "./AdminTextSettings";
import AdminUsers from "./AdminUsers";


function AdminSettings() {

return (
		<PageTemplate>
			<Helmet>
				<title>{routes.adminSettings.name}</title>
			</Helmet>
			<PageHeadings title="Admin Settings" />
            <AdminUsers />
            <div className="mt-8">
                <AdminTextSettings />
            </div>
        </PageTemplate>
    )
}

export default AdminSettings;
