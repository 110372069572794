import useApi from '../../hooks/useApi'
import { acceptRejectInvitationCompany } from '../../redux/actions/companyReceivedInvitationsActions'
import {
	resendInvitationCompany,
	revokeInvitationCompany,
} from '../../redux/actions/companySentInvitationsActions'
import { resendInvitation, revokeInvitation } from '../../redux/actions/invitationsActions'
import { useAppDispatch } from '../../redux/hooks/reduxHooks'
import { Invitation } from '../../types/Invitation'
import { Alert, Button, LoadingSpinner } from '../BaseComponents'

type Props = {
	invitations: Invitation[]
	type?: string
}

function InvitationsTable({ invitations, type }: Props) {
	const dispatch = useAppDispatch()
	const api = useApi();

	return (
		<div className='pt-4'>
			<table className='table-fixed w-full py-2 divide-y-1 divide-charcoal-050'>
				<thead className='border-t-2 border-b-2 border-charcoal-050 bg-charcoal-800'>
					<tr>
						<th scope='col' className='p-lg text-left text-sm font-semibold text-white leading-5'>
							Organisation Name
						</th>
						<th
							scope='col'
							className='p-lg w-20 text-left text-sm font-semibold text-white leading-5 '
						>
							Status
						</th>
						<th scope='col' className='p-lg text-left text-sm font-semibold text-white leading-5 '>
							Sent By
						</th>
						<th scope='col' className='p-lg text-left text-sm font-semibold text-white leading-5 '>
							Sent To
						</th>
						<th
							scope='col'
							className='p-lg w-36 text-left text-sm font-semibold text-white leading-5 '
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody className='divide-y divide-charcoal-050'>
					{invitations.map((invitation: Invitation) => {
						return (
							<tr key={invitation.id}>
								<td className='truncate p-lg text-sm text-white'>{invitation.companyName}</td>
								<td className='truncate p-lg text-sm text-white'>{invitation.status}</td>
								<td className='truncate p-lg text-sm text-white'>
									{invitation.sentBy === undefined ? '-' : invitation.sentBy}
								</td>
								<td className='truncate p-lg text-sm text-white'>{invitation.sendTo}</td>
								<td className='p-lg text-sm text-white'>
									{invitation.updating ? (
										<>
											{/* Loading spinner */}
											<LoadingSpinner inline={true} loadingText='Updating...' small={true} />
										</>
									) : (
										<>
											{invitation.error && (
												<>
													<p className='text-white mb-4'>
														Oops! There was an error updating this invitation:
													</p>
													<p className='text-white text-xs mb-4'>{invitation.error}</p>
												</>
											)}

											{invitation.updated && (
												<>
													<Alert
														title={`Updated invite for ${invitation.companyName}`}
														type='success'
													/>
												</>
											)}

											{invitation.status === 'open' && type !== 'CompanyReceivedInvitations' && (
												<>
													<Button
														type='Primary'
														text='Resend'
														size='xs'
														fullWidth
														additionalClass='mb-2'
														hasLeadingIcon
														icon='fa-regular fa-paper-plane-top'
														onClick={() => {
															// Resend
															if (type === 'Admin') {
																dispatch(resendInvitation(api, invitation.id))
															} else if (type === 'Company') {
																dispatch(resendInvitationCompany(api, invitation.id))
															}
														}}
													/>
													<Button
														type='Primary'
														text='Revoke'
														size='xs'
														fullWidth
														hasLeadingIcon
														icon='fa-regular fa-trash'
														onClick={() => {
															// Revoke
															if (type === 'Admin') {
																dispatch(revokeInvitation(api, invitation.id, 'revoked'))
															} else if (type === 'Company') {
																dispatch(revokeInvitationCompany(api, invitation.id, 'revoked'))
															}
														}}
													/>
												</>
											)}

											{invitation.status === 'open' && type === 'CompanyReceivedInvitations' && (
												<>
													<Button
														type='Primary'
														text='Accept'
														size='xs'
														fullWidth
														additionalClass='mb-2'
														hasLeadingIcon
														icon='fa-regular fa-paper-plane-top'
														onClick={() => {
															// Accept
															dispatch(acceptRejectInvitationCompany(api, invitation.id, true))
														}}
													/>
													<Button
														type='Primary'
														text='Reject'
														size='xs'
														fullWidth
														hasLeadingIcon
														icon='fa-regular fa-trash'
														onClick={() => {
															// Reject
															dispatch(acceptRejectInvitationCompany(api, invitation.id, false))
														}}
													/>
												</>
											)}
										</>
									)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
export default InvitationsTable
