import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Period } from '../../types/AccountingPeriods'
import { FetchStatus, PostStatus } from '../../types/LoadingStates'
import type { RootState } from '../store'

type AccountingPeriodsSlice = {
	status: FetchStatus
	error?: string
	status_creating: PostStatus
	error_creating?: string
	status_updating: PostStatus
	error_updating?: string
	periods: Array<Period>
	meta: Array<any>
	deleteStatus: PostStatus
	deleteError?: string
	status_editing: PostStatus
	error_editing?: string
	accountingPeriodName?: string
}
const accountingPeriodsInitialState: AccountingPeriodsSlice = {
	status: FetchStatus.initial,
	error: undefined,
	status_creating: PostStatus.initial,
	error_creating: undefined,
	status_updating: PostStatus.initial,
	error_updating: undefined,
	periods: [],
	meta: [],
	deleteStatus: PostStatus.initial,
	deleteError: undefined,
	status_editing: PostStatus.initial,
	error_editing: undefined,
	accountingPeriodName: undefined,
}

const dayjs = require('dayjs')

export const accountingPeriodsSlice = createSlice({
	name: 'accountingPeriods',
	initialState: accountingPeriodsInitialState,
	reducers: {
		ACCOUNTINGPERIODS_FETCH: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		ACCOUNTINGPERIODS_SUCCESS: (
			state,
			action: PayloadAction<{
				periods: Array<Period>
				meta: Array<any>
			}>
		) => {
			return {
				...state,
				status: FetchStatus.loaded,
				periods: action.payload.periods,
				meta: action.payload.meta,
			}
		},
		ACCOUNTINGPERIODS_FAILURE: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		ACCOUNTINGPERIODS_RESET: () => {
			return accountingPeriodsInitialState
		},
		ACCOUNTINGPERIODS_CREATE_SENDING: (state) => {
			return {
				...state,
				status_creating: PostStatus.sending,
			}
		},
		ACCOUNTINGPERIODS_CREATE_SENT: (state, action: PayloadAction<Period>) => {
			return {
				...state,
				status_creating: PostStatus.sent,
				periods: [
					...state.periods,
					{
						...action.payload,
						start_date: dayjs(action.payload.start_date).format('DD/MM/YY'),
						end_date: dayjs(action.payload.end_date).format('DD/MM/YY'),
					},
				],
			}
		},
		ACCOUNTINGPERIODS_CREATE_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status_creating: PostStatus.error,
				error_creating: action.payload,
			}
		},
		ACCOUNTINGPERIODS_CREATE_RESET: (state) => {
			return {
				...state,
				status_creating: PostStatus.initial,
			}
		},
		ACCOUNTINGPERIODS_UPDATE_SENDING: (state) => {
			return {
				...state,
				status_updating: PostStatus.sending,
			}
		},
		ACCOUNTINGPERIODS_UPDATE_SENT: (state, action: PayloadAction<Period>) => {
			return {
				...state,
				status_updating: PostStatus.sent,
				periods: [
					...state.periods.map((period) => {
						return {
							...period,
							upload_state:
								period.id === action.payload.id ? action.payload.upload_state : period.upload_state,
						}
					}),
				],
			}
		},
		ACCOUNTINGPERIODS_UPDATE_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status_updating: PostStatus.error,
				error_updating: action.payload,
			}
		},
		ACCOUNTINGPERIODS_UPDATE_RESET: (state) => {
			return {
				...state,
				status_updating: PostStatus.initial,
			}
		},
		DELETE_ACCOUNTINGPERIOD_SENDING: (state) => {
			return {
				...state,
				deleteStatus: PostStatus.sending,
			}
		},
		DELETE_ACCOUNTINGPERIOD_SENT: (state, action: PayloadAction<string>) => {
			return {
				...state,
				deleteStatus: PostStatus.sent,
				deleteError: undefined,
				periods: state.periods.filter((item) => item.id !== action.payload),
			}
		},
		DELETE_ACCOUNTINGPERIOD_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				deleteStatus: PostStatus.error,
				error: action.payload,
			}
		},
		DELETE_ACCOUNTINGPERIOD_RESET: (state) => {
			return {
				...state,
				deleteStatus: PostStatus.initial,
				deleteError: undefined,
			}
		},
		ACCOUNTINGPERIODS_EDITING_SENDING: (state) => {
			return {
				...state,
				status_editing: PostStatus.sending,
			}
		},
		ACCOUNTINGPERIODS_EDITING_SENT: (state, action: PayloadAction<Period>) => {
			return {
				...state,
				status_editing: PostStatus.sent,
				periods: [
					...state.periods.map((period) => {
						return {
							...period,
							start_date:
								period.id === action.payload.id
									? dayjs(action.payload.start_date).format('DD/MM/YY')
									: period.start_date,
							end_date:
								period.id === action.payload.id
									? dayjs(action.payload.end_date).format('DD/MM/YY')
									: period.end_date,
							total_turnover_amount:
								period.id === action.payload.id
									? action.payload.total_turnover_amount
									: period.total_turnover_amount,
							total_purchase_spent_target:
								period.id === action.payload.id
									? action.payload.total_purchase_spent_target
									: period.total_purchase_spent_target,
						}
					}),
				],
			}
		},
		ACCOUNTINGPERIODS_EDITING_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status_editing: PostStatus.error,
				error_editing: action.payload,
			}
		},
		ACCOUNTINGPERIODS_EDITING_RESET: (state) => {
			return {
				...state,
				status_editing: PostStatus.initial,
			}
		},
		ACCOUNTINGPERIODS_SET_NAME: (state, action: PayloadAction<string>) => {
			return {
				...state,
				accountingPeriodName: action.payload,
			}
		},
	},
})

// Action types
export const {
	ACCOUNTINGPERIODS_FETCH,
	ACCOUNTINGPERIODS_SUCCESS,
	ACCOUNTINGPERIODS_FAILURE,
	ACCOUNTINGPERIODS_RESET,
	ACCOUNTINGPERIODS_CREATE_SENDING,
	ACCOUNTINGPERIODS_CREATE_SENT,
	ACCOUNTINGPERIODS_CREATE_ERROR,
	ACCOUNTINGPERIODS_CREATE_RESET,
	ACCOUNTINGPERIODS_UPDATE_SENDING,
	ACCOUNTINGPERIODS_UPDATE_SENT,
	ACCOUNTINGPERIODS_UPDATE_ERROR,
	ACCOUNTINGPERIODS_UPDATE_RESET,
	DELETE_ACCOUNTINGPERIOD_SENDING,
	DELETE_ACCOUNTINGPERIOD_SENT,
	DELETE_ACCOUNTINGPERIOD_ERROR,
	DELETE_ACCOUNTINGPERIOD_RESET,
	ACCOUNTINGPERIODS_EDITING_SENDING,
	ACCOUNTINGPERIODS_EDITING_SENT,
	ACCOUNTINGPERIODS_EDITING_ERROR,
	ACCOUNTINGPERIODS_EDITING_RESET,
	ACCOUNTINGPERIODS_SET_NAME,
} = accountingPeriodsSlice.actions

// Selectors
export const selectAccountingPeriodsStatus = (state: RootState) => state.accountingPeriods.status
export const selectAccountingPeriodsError = (state: RootState) => state.accountingPeriods.error
export const selectAccountingPeriods = (state: RootState) => state.accountingPeriods.periods
export const selectAccountingPeriodsMeta = (state: RootState) => state.accountingPeriods.meta
export const selectAccountingPeriodsCreateStatus = (state: RootState) =>
	state.accountingPeriods.status_creating
export const selectAccountingPeriodsUpdateStatus = (state: RootState) =>
	state.accountingPeriods.status_updating
export const selectAccountingPeriodsUpdateError = (state: RootState) =>
	state.accountingPeriods.error_updating
export const selectAccountingPeriodsLastCreated = (state: RootState) =>
	state.accountingPeriods.periods[state.accountingPeriods.periods.length - 1]
export const deleteAccountingPeriodStatus = (state: RootState) =>
	state.accountingPeriods.deleteStatus
export const deleteAccountingPeriodError = (state: RootState) => state.accountingPeriods.deleteError
export const selectAccountingPeriodsEditStatus = (state: RootState) =>
	state.accountingPeriods.status_editing
export const selectAccountingPeriodsEditError = (state: RootState) =>
	state.accountingPeriods.error_editing
export const selectAccountingPeriodsName = (state: RootState) =>
	state.accountingPeriods.accountingPeriodName

export default accountingPeriodsSlice.reducer
