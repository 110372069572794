import { Dispatch } from 'redux'
import {
	SUPPLYCHAINGRAPHDATA_ERROR,
	SUPPLYCHAINGRAPHDATA_LOADED,
	SUPPLYCHAINGRAPHDATA_LOADING,
} from '../slices/supplyChainGraphDataSlice'
import { NeoniApi } from '../../lib/api'

export const getSectorECO2eData =
	(api: NeoniApi, sectorIds: string = '') =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: SUPPLYCHAINGRAPHDATA_LOADING,
		})
		try {
			const body = await api.get(`reports/highestEmissionSectors?` +
					new URLSearchParams({ sectorIds: sectorIds })
			)

			if (body.success === true) {
				// Successfully fetched sectors graph data
				dispatch({
					type: SUPPLYCHAINGRAPHDATA_LOADED,
					payload: body.data.data,
				})
			} else {
				// Error
				dispatch({
					type: SUPPLYCHAINGRAPHDATA_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: SUPPLYCHAINGRAPHDATA_ERROR,
				payload: error,
			})
		}
	}
