import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../types/LoadingStates'
import { OpenSearchCompany } from '../../types/OpenSearchCompany'
import type { RootState } from '../store'

type openCorporatesSliceType = {
	status: FetchStatus
	error?: string
	openCorporatesData: Array<OpenSearchCompany>
}
const openCorporatesInitialState: openCorporatesSliceType = {
	status: FetchStatus.initial,
	error: undefined,
	openCorporatesData: [],
}
export const openCorporatesSlice = createSlice({
	name: 'openCorporates',
	initialState: openCorporatesInitialState,
	reducers: {
		OPENCORPORATES_FETCHING: (state) => {
			return {
				...state,
				status: FetchStatus.loading,
			}
		},
		OPENCORPORATES_SUCCESS: (state, action: PayloadAction<Array<OpenSearchCompany>>) => {
			return {
				...state,
				status: FetchStatus.loaded,
				openCorporatesData: action.payload,
			}
		},
		OPENCORPORATES_ERROR: (state, action: PayloadAction<string>) => {
			return {
				...state,
				status: FetchStatus.error,
				error: action.payload,
			}
		},
		OPENCORPORATES_RESET: () => {
			return openCorporatesInitialState
		},
	},
})

// Action types
export const {
	OPENCORPORATES_FETCHING,
	OPENCORPORATES_SUCCESS,
	OPENCORPORATES_ERROR,
	OPENCORPORATES_RESET,
} = openCorporatesSlice.actions

// Selectors
export const selectOpenCorporatesStatus = (state: RootState) => state.openCorporates.status
export const selectOpenCorporatesError = (state: RootState) => state.openCorporates.error
export const selectOpenCorporates = (state: RootState) => state.openCorporates.openCorporatesData

export default openCorporatesSlice.reducer
