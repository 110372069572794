import PublicPageTemplate from "../components/BaseComponents/PageTemplate/PublicPageTemplate";
import { useEffect, useState } from "react";
import { formatDate } from "../lib/utils";
import { Link, useParams } from "react-router-dom";
import usePublicApi from "../hooks/usePublicApi";
import SecrRender from "../components/SECR/SecrRender";
import { LoadingSpinner } from "../components/BaseComponents";

function PublicReport() {
    const { reportId } = useParams();
    const [data, setData] = useState<any>();
    const [companyData, setCompanyData] = useState<any>();
    const [baselineData, setBaselineData] = useState<any>();
    const [alternatives, setAlternatives] = useState<any[]>([]);

    const publicApi = usePublicApi();

    useEffect(() => {
        let cancelled = false;
        const run = async () => {
            const [result, baselineResult] = await Promise.all([
                publicApi.get(`public/reports/${reportId}`),
                publicApi.get(`public/reports/${reportId}/baseline`),
            ]);

            if(cancelled) {
                return;
            }
            setData(result.data.report);
            setCompanyData(result.data.company)
            setAlternatives(result.data.alternatives);
            setBaselineData(baselineResult.data);
        }

        run();

        return () => {
            cancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    if(!data) {
        return (
            <LoadingSpinner />
        )
    }

    return(
        <PublicPageTemplate>
            <Link className="text-white underline" to={`/public/profiles/${companyData.id}`}>
                Return to {companyData.name}
            </Link>
            <SecrRender
                report={data}
                baselineReport={baselineData}
                companyReports={alternatives.map(report => {
                    return {
                        id: report.id,
                        label: formatDate(report.startDate) + ' - ' + formatDate(report.endDate)
                    }
                })}
                companyId={companyData.id}
            />
        </PublicPageTemplate>
    )
}

export default PublicReport;
