import { useContext, useEffect } from "react";
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate";
import { LoadingSpinner } from "../components/BaseComponents";
import usePublicApi from "../hooks/usePublicApi";
import { useNavigate } from "react-router-dom";
import routes from "../components/Router/routes";
import { getCSRFToken } from "../lib/utils";
import { userContext } from "../context/UserContext";

function Logout() {
    const publicApi = usePublicApi();
    const navigate = useNavigate();
    const { clearUserData } = useContext(userContext);

    useEffect(() => {
        const run = async () => {
            await publicApi.post('/user/logout', {
                _csrf: await getCSRFToken(),
            });
            clearUserData();
            navigate(routes.publicLogin.path);
        }
        run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PageTemplate>
            <LoadingSpinner />
        </PageTemplate>
    )
}

export default Logout;
