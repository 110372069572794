import { PropsWithChildren, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { getCSRFToken } from "../../lib/utils";

type BoltOnProps = PropsWithChildren<{
    label: string;
    featureKey: string;
    companyId: string;
}>

function BoltOn({label, companyId, featureKey, children}: BoltOnProps) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>()
    const [isAdmin, setIsAdmin] = useState(false);
    const api = useApi();

    useEffect(() => {
        setLoading(true);
        let cancelled = false;
        const run = async () => {
            const result = await api.get(`admin/company/${companyId}/boltOns/${featureKey}`)
            if(cancelled) {
                return;
            }
            setData(result.boltOn);
            setIsAdmin(result.isAdmin);
            setLoading(false);
        }
        run();

        return () => {
            cancelled = true;
        }
    }, [featureKey])

    const onSubmit = async (enabled: boolean) => {
        setLoading(true);
        const result = await api.put(`admin/company/${companyId}/boltOns/${featureKey}`, {
            boltOn: { enabled },
            _csrf: await getCSRFToken()
        })
        setData(result.boltOn);
        setLoading(false);
    }

    return (
        <div className="p-2 rounded text-white bg-charcoal-400">
            <div className="flex">
                <h4 className="w-40 text-white">{ label }</h4>
                { isAdmin ? (
                    <div>Organisation is Admin</div>
                ) : (
                    <BoltOnControl
                        isActive={data?.enabled}
                        onSubmit={onSubmit}
                        loading={loading}
                    />
                )}
            </div>
            <div className="mt-2">
                { children }
            </div>
        </div>
    );
}

type BoltOnControlProps = {
    isActive: boolean;
    onSubmit: (enabled: boolean) => void,
    loading?: boolean;
}

function BoltOnControl({isActive, onSubmit, loading}: BoltOnControlProps) {
    if((isActive)) {
        return (
            <div className="flex space-x-2">
                <div className="rounded py-2.5 px-4 text-charcoal-900 bg-algae-500 hover:bg-algae-600 focus:bg-algae-500 border-2 border-algae-500 hover:border-algae-600 focus:outline-none focus:ring focus:ring-algae-100" >
                    Enabled
                </div>
                <div
                    className="cursor-pointer rounded py-2.5 px-4 text-error-200 hover:text-charcoal-900 font-semibold border-2 border-error-200 hover:bg-error-100 hover:border-error-100 focus:outline-none focus:ring focus:ring-error-500"
                    onClick={() => onSubmit(false)}
                >
                    Disable
                </div>
            </div>
        );
    }

    return (
        <div className="flex space-x-2">
            <div
                className="cursor-pointer rounded py-2.5 px-4 text-algae-200 hover:text-charcoal-900 font-semibold border-2 border-algae-200 hover:bg-algae-100 hover:border-algae-100 focus:outline-none focus:ring focus:ring-algae-500"
                onClick={() => onSubmit(true)}
            >
                Enable
            </div>
            <div className="rounded py-2.5 px-4 text-charcoal-900 bg-error-500 hover:bg-error-600 focus:bg-error-500 border-2 border-error-500 hover:border-error-600 focus:outline-none focus:ring focus:ring-error-100" >
                Disabled
            </div>
        </div>
    );
}

type AdminCompanyBoltOnsProps = {
    companyId: string;
}

function AdminCompanyBoltOns({companyId}: AdminCompanyBoltOnsProps) {

    return (
        <div>
            <h3 className="text-white mb-3 mt-8">Bolt Ons</h3>
            <div className="rounded bg-charcoal-600 p-5 space-y-5">
                <BoltOn companyId={companyId} label="Portfolios" featureKey="portfolios">
                    <p>Curate portfolios.</p>
                </BoltOn>
                <BoltOn companyId={companyId} label="Client Accounting" featureKey="client_accounting">
                    <p>Manage carbon accounting data for clients companies.</p>
                </BoltOn>
                <BoltOn companyId={companyId} label="Projects" featureKey="projects">
                    Group emissions for projects.
                </BoltOn>
                <BoltOn companyId={companyId} label="Screen-scraping" featureKey="screen_scrape">
                    <p>Can select & copy data from reports.</p>
                </BoltOn>
            </div>
        </div>
    )
}

export default AdminCompanyBoltOns;
