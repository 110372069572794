import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, Input, LoadingSpinner } from '../components/BaseComponents'
import PageHeadings from '../components/BaseComponents/PageHeadings/PageHeadings'
import PageTemplate from '../components/BaseComponents/PageTemplate/PageTemplate'
import routes from '../components/Router/routes'
import { useAppDispatch, useAppSelector } from '../redux/hooks/reduxHooks'
import {
	ACCOUNTINGPERIODS_UPDATE_RESET,
	ACCOUNTINGPERIODS_UPDATE_SENT,
	selectAccountingPeriods,
	selectAccountingPeriodsError,
	selectAccountingPeriodsStatus,
	selectAccountingPeriodsUpdateStatus,
} from '../redux/slices/accountingPeriodsSlice'
import {
	getAccountingPeriods,
	updateAccountingPeriodStage,
} from '../redux/actions/accountingPeriodsActions'
import Select from 'react-select'
import { default as ISICSelector } from '../components/BaseComponents/ISICSelector/ISICSelector'
import { FetchStatus, PostStatus } from '../types/LoadingStates'
import { useEffect, useState } from 'react'
import { PurchaseUploadState } from '../enums/PurchaseUploadState'
import { countries } from '../assets/countryList'
import { centsToCurrency, getCSRFToken } from '../lib/utils'
import {
	GET_PURCHASES_SUPPLIERS_LIMIT_SCOPE3,
} from '../redux/actions/carbonAccountsSupplierActions'
import Pagination from '../components/BaseComponents/Pagination/Pagination'
import { useEffectWithoutInitialRender } from '../hooks/useEffectWithoutInitialRender'
import { refineSuppliers } from '../redux/actions/updateBulkSuppliersAction'
import {
	selectUpdateBulkSuppliersError,
	selectUpdateBulkSuppliersStatus,
	UPDATE_BULK_SUPPLIERS_RESET,
} from '../redux/slices/updateBulkSuppliersSlice'
import { getScope3Suppliers } from '../redux/actions/refinementsActions'
import { REFINEMENTS_SCOPE3_SUPPLIERS_RESET, selectRefinementsscope3SupplierItems, selectRefinementsscope3SuppliersError, selectRefinementsscope3SupplierStatus } from '../redux/slices/refinementsSlice'
import { PaginationMeta } from '../types/PaginationMeta'
import { SupplierRefinementUpdate } from '../types/PurchaseItem'
import useCurrencyFormatter from '../hooks/useCurrencyFormatter'
import useApi from '../hooks/useApi'

export const CompanyRefineSuppliers = () => {
	const formatCurrency = useCurrencyFormatter();
	const countryList = countries
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const api = useApi();
	const { accountingPeriodId } = useParams()

	const accountingPeriodsStatus = useAppSelector(selectAccountingPeriodsStatus)
	const accountingPeriodsError = useAppSelector(selectAccountingPeriodsError)
	const allAccountingPeriodData = useAppSelector(selectAccountingPeriods)

	const updateAccountingPeriodStatus = useAppSelector(selectAccountingPeriodsUpdateStatus);

	const suppliersLoadStatus = useAppSelector(selectRefinementsscope3SupplierStatus)
	const suppliersError = useAppSelector(selectRefinementsscope3SuppliersError)
	const [searchText, setSearchtext] = useState('')

	// Find the accounting period to match the url param
	const thisAccountingPeriod = allAccountingPeriodData.filter(
		(period) => period.id === accountingPeriodId
	)[0]
	const [errorNoPeriod, setErrorNoPeriod] = useState(false)

	const limit = GET_PURCHASES_SUPPLIERS_LIMIT_SCOPE3
	const [companyId, setCompanyId] = useState('')
	const [accountingPeriodIdVal, setAccountingPeriodIdVal] = useState('')
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false)

	const supplierData = useAppSelector(selectRefinementsscope3SupplierItems)
	const defaultPagination: PaginationMeta = {
		current_page: 1,
		first_page: 1,
		last_page: 1,
		per_page: 20,
		total: 0,
	}

	const { suppliers, pagination } = supplierData || { suppliers: [], pagination: defaultPagination }

	const [dirtySuppliers, setDirtySuppliers] = useState<SupplierRefinementUpdate[]>([])

	const updateSuppliersStatus = useAppSelector(selectUpdateBulkSuppliersStatus)
	const updateSuppliersError = useAppSelector(selectUpdateBulkSuppliersError)

	// Data loading
	useEffect(() => {
		// Fetch accounting periods if they aren't in the store, we need to compare against the IDs
		if (accountingPeriodsStatus === FetchStatus.initial) {
			dispatch(getAccountingPeriods(api))
		}

		// // Reset the purchases store if it's currently filled
		if (suppliersLoadStatus === FetchStatus.loaded) {
			dispatch({
				type: REFINEMENTS_SCOPE3_SUPPLIERS_RESET,
			})
		}

		if (accountingPeriodsStatus === FetchStatus.loaded) {
			// If there's no accounting period defined, the url parameter is probably wrong, show an error
			if (typeof thisAccountingPeriod !== 'undefined') {
				setCompanyId(thisAccountingPeriod.company_id)
				setAccountingPeriodIdVal(thisAccountingPeriod.id)
				dispatch(
					getScope3Suppliers(api, thisAccountingPeriod.id, pagination.first_page, 'totalSpend', 'desc', limit, searchText)
				)
			} else {
				setErrorNoPeriod(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountingPeriodsStatus])

	useEffect(() => {
		if (updateSuppliersStatus === PostStatus.sent) {
			dispatch({
				type: UPDATE_BULK_SUPPLIERS_RESET,
			})

			const run = async () => {

				const csrf = await getCSRFToken()

				const body = await api.post(`accountingPeriods/updateState`, {
					accountingPeriodId: thisAccountingPeriod.id,
					uploadState: PurchaseUploadState.scope3Refined,
					_csrf: csrf
				});

				if (body.success === true) {
					dispatch({
						type: ACCOUNTINGPERIODS_UPDATE_SENT,
						payload: body.data,
					})
				}
				navigate(
					generatePath(
						routes.companyCarbonAccounts.path,
						{accountingPeriodId: thisAccountingPeriod.id}
					)
				)

			}
			run();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateSuppliersStatus])

	const customFilter = (
		option: { data: { value: string; region: string; label: string } },
		searchText: string
	) => {
		return (
			option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.region.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.label.toLowerCase().includes(searchText.toLowerCase())
		)
	}
	// Timeout for search
	useEffectWithoutInitialRender(() => {
		if (searchText !== '') {
			setSearchInProgress(true)
		}

		let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
			//Do some stuff after 1 second delay
			if (companyId !== '' && accountingPeriodIdVal !== '') {
				dispatch(
					getScope3Suppliers(api, thisAccountingPeriod.id, pagination.first_page, 'totalSpend', 'desc', limit, searchText)
				)
			}
			setSearchInProgress(false)
		}, 1000)

		if (searchText !== '') {
			setSearchInProgress(true)
		} else {
			if (!searchInProgress) {
				clearTimeout(timer)
			}
		}

		return () => {
			clearTimeout(timer)
		}
	}, [searchText])
	const getIsicValue = (isicCode?: string | null) => {
		return isicCode ? [isicCode] : []
	}

	const updateSupplierJurisdictionCode = (id: string, val: any) => {
		let update = dirtySuppliers.find((dirty) => dirty.id === id)
		if(update) {
			update.jurisdictionCode = val.value;
		} else {
			update = {
				id: id,
				jurisdictionCode: val.value,
				isicCode: suppliers.find((suppler) => suppler.supplierId === id)?.industryCode || undefined,
			}
		}
		setDirtySuppliers([
			...dirtySuppliers.filter(supplier => supplier.id !== id),
			update,
		])
	}
	const updateSupplierIsicCode = (id: string, val: any) => {
		let update = dirtySuppliers.find((dirty) => dirty.id === id)
		if(update) {
			update.isicCode = val;
		} else {
			update = {
				id: id,
				isicCode: val,
				jurisdictionCode: suppliers.find((suppler) => suppler.supplierId === id)?.jurisdictionCode || undefined,
			}
		}
		setDirtySuppliers([
			...dirtySuppliers.filter(supplier => supplier.id !== id),
			update,
		])
	}

	return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companyRefineSuppliers.name}</title>
			</Helmet>
			<PageHeadings
				title={'Accounting periods'}
				subTitle={'Refine Suppliers'}
				subSubTitle={'Emissions generated'}
				routeBack={routes.companyAddAccountingPeriods.path}
			/>
			{/* Helpful information */}
			<div className='rounded-lg bg-sun-500 p-4 my-5'>
				<p className='mb-5'>Add an industry sector code to each of your suppliers.</p>
				<p className='mb-5'>
					This will give you the approximate CO₂e emissions figures for your purchases.
				</p>
				<p>You can also add codes later.</p>
			</div>

			<div className='items-start'>
				<div className='relative max-w-2xl'>
					<label htmlFor='searchPurchases' className='sr-only'>
						Search suppliers
					</label>
					<Input
						type='text'
						name='searchPurchases'
						placeholder='Search'
						value={searchText}
						onChange={(event: any) => {
							setSearchtext(event.target.value)
						}}
					/>
					<Button
						hasLeadingIcon
						type='White'
						size='sm'
						icon={` ${
							searchInProgress
								? 'fas fa-loader fa-spin'
								: searchText.length > 0
								? 'fa-solid fa-times'
								: 'fa-solid fa-search'
							}`}
						additionalClass='absolute top-1/2 transform -translate-y-1/2 right-1 text-charcoal-500'
						onClick={() => {
							setSearchtext('')
							dispatch(
								getScope3Suppliers(api, thisAccountingPeriod.id, pagination.first_page, 'totalSpend', 'desc', limit, '')
							)
						}}
					/>
				</div>
			</div>
			{suppliersLoadStatus === FetchStatus.loaded &&
				updateSuppliersStatus !== PostStatus.sending &&
				suppliers.length > 0 && (
					<>
						{/* data display */}
						<div>
							{suppliers.map((supplier) => {
								const dirtySupplier = dirtySuppliers.find(dirty => dirty.id === supplier.supplierId)
								return (
									<div className='bg-charcoal-700 my-4' key={supplier.supplierId}>
										<div className='flex justify-between items-center mb-4 bg-charcoal-800 p-2 px-3'>
											<h2 className='font-bold text-white text-lg'>{supplier.supplierName}</h2>
											<p className='text-white font-semibold text-sm ml-4'>
												Total Spend:{' '}
												{supplier.totalSpend ? formatCurrency(centsToCurrency(supplier.totalSpend) || 0) : ''}
											</p>
										</div>

										<div className='grid grid-cols-2 gap-5 p-2 px-3 pt-0'>
											<div>
												<p className='font-semibold mt-1 text-charcoal-100 mb-2'>
													Jurisdiction of this supplier:
												</p>
												<Select
													key={supplier.supplierId}
													id={supplier.supplierName}
													options={countryList}
													isMulti={false}
													isSearchable={true}
													filterOption={customFilter}
													isClearable={false}
													className='w-full'
													onChange={(value) => {
														updateSupplierJurisdictionCode(supplier.supplierId, value)
													}}
													value={countryList.filter(function (option) {
														return option.value === (dirtySupplier ? dirtySupplier.jurisdictionCode : supplier.jurisdictionCode)
													})}
												></Select>
											</div>

											<div>
												<p className='font-semibold text-charcoal-100 mb-2'>
													Nature of business - Sector Code (ISIC):
												</p>
												<ISICSelector
													darkMode
													defaultValue={getIsicValue(dirtySupplier ? dirtySupplier.isicCode : supplier.industryCode)}
													min={1}
													max={1}
													updateHandler={(value) => {
														if (value.length !== 0) {
															updateSupplierIsicCode(supplier.supplierId, value[0])
														}
													}}
												/>
											</div>
										</div>
									</div>
								)
							})}
						</div>
						<Pagination
							pagination={pagination}
							onPageChange={(newPageNumber) => {
								dispatch(
									getScope3Suppliers(api, thisAccountingPeriod.id, newPageNumber, 'totalSpend', 'desc', limit, searchText)
								)
							}}
						/>
						<div className='flex flex-row-reverse mt-2'>
							<Button
								type='Primary'
								text='Complete Import'
								hasTrailingIcon
								icon='fa-regular fa-chevron-right'
								onClick={() => {
									dispatch(
										refineSuppliers(
											api,
											dirtySuppliers,
											accountingPeriodIdVal
										)
									)
								}}
							/>
						</div>
					</>
				)}
			{suppliersLoadStatus === FetchStatus.loaded &&
				updateSuppliersStatus !== PostStatus.sending &&
				suppliers.length === 0 && (
					<>
						<div className='rounded-lg bg-charcoal-700 p-4 mt-4'>
							<p className='text-white'>No supplier found.</p>
							<Button
								type='Primary'
								text='Complete Import'
								hasTrailingIcon
								icon='fa-regular fa-chevron-right'
								additionalClass='mt-5 mb-2'
								onClick={() => {
									dispatch(
										refineSuppliers(
											api,
											dirtySuppliers,
											accountingPeriodIdVal
										)
									)
								}}
							/>
						</div>
					</>
				)}

			{/* Errors and loading states*/}
			{accountingPeriodsStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading accounting periods...' />
			)}
			{suppliersLoadStatus === FetchStatus.loading && (
				<LoadingSpinner loadingText='Loading purchases...' />
			)}
			{updateSuppliersStatus === PostStatus.sending && (
				<LoadingSpinner loadingText='Sending suppliers...' />
			)}
			{accountingPeriodsStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the accounting periods list. If this problem persists,
						please contact the Neoni team.
					</p>
					<p className='text-white'>{accountingPeriodsError}</p>
				</div>
			)}
			{suppliersLoadStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error fetching the purchases for this accounting period (
						{accountingPeriodId}). If this problem persists, please contact the Neoni team.
					</p>
					<p className='text-white'>{suppliersError}</p>
				</div>
			)}
			{updateSuppliersStatus === PostStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error updating the suppliers. If this problem persists, please contact the
						Neoni team.
					</p>
					<p className='text-white mb-5'>{updateSuppliersError}</p>
					<Button
						type='Primary'
						text='Retry'
						hasTrailingIcon
						icon='fa-regular fa-refresh'
						onClick={() => {
							dispatch(
								refineSuppliers(
									api,
									dirtySuppliers,
									accountingPeriodIdVal
								)
							)
						}}
					/>
				</div>
			)}
			{errorNoPeriod && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						There was an error whilst trying to find purchases for that accounting period ID (
						{accountingPeriodId}). If this problem persists, please contact the Neoni team.
					</p>
					<Button
						onClick={() => {
							navigate(generatePath(routes.companyRefineEmissionsGenerated.path, {accountingPeriodId}), {
								replace: true,
							})
						}}
						type='Primary'
						hasTrailingIcon={true}
						icon='fa-solid fa-refresh'
						text='Try again'
						additionalClass='mt-9 mr-5'
					/>
					<Button
						type='Outlined'
						text='Back to accounting periods'
						onClick={() => {
							navigate(routes.companyAddAccountingPeriods.path)
						}}
						additionalClass='mt-6 mr-5'
					/>
				</div>
			)}
		</PageTemplate>
	)
}

export default CompanyRefineSuppliers
