/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Button, Input, LoadingSpinner } from '../../BaseComponents'
import PageHeadings from '../../BaseComponents/PageHeadings/PageHeadings'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/reduxHooks'
import Modal from '../../BaseComponents/Modal/Modal'
import { PostStatus } from '../../../types/LoadingStates'
import Select from 'react-select'
import { countries } from '../../../assets/countryList'
import { createCompany } from '../../../redux/actions/createCompanyActions'
import { CREATECOMPANY_RESET, selectCreateCompanyStatus } from '../../../redux/slices/createCompanySlice'
import { getCompanyList  } from '../../../redux/actions/getCompanyListActions'
import useApi from '../../../hooks/useApi'

type AdminCreateCompanyProps = {
    onClose: () => void,
    open?: boolean,
}

function AdminCreateCompany({onClose, open = false}: AdminCreateCompanyProps) {
    const api = useApi();

	const optionlist = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	]

	const countryList = countries

	const [name, setName] = useState('')
	const [type, setType] = useState('')
	const [number, setNumber] = useState('')
	const [jurisdiction_code, setJurisdiction] = useState<string | undefined>('')
	const [address, setAddress] = useState('')
	const [isPublicBody, setIsPublicBody] = useState<boolean | undefined>(false)

	let industryCodesFiltered: string[] = []

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(getCompanyList(api))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const createCompanyStatus = useAppSelector(selectCreateCompanyStatus)

	const resetCompanyCreation = () => {
		dispatch({
			type: CREATECOMPANY_RESET,
		})

		// Reset local state stores used in form
		setName('')
		setType('')
		setNumber('')
		setJurisdiction('')
		setAddress('')
		setIsPublicBody(false)
	}
	const customFilter = (
		option: { data: { value: string; region: string; label: string } },
		searchText: string
	) => {
		if (
			option.data.value.includes(searchText) ||
			option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.region.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.label.toLowerCase().includes(searchText.toLowerCase())
		) {
			return true
		} else {
			return false
		}
	}

	return (
        <Modal
            open={open}
            onOpen={() => resetCompanyCreation()}
            onClose={onClose}
            size='small'
        >
            {createCompanyStatus === PostStatus.initial && (
                <>
                    <PageHeadings title='Add a new organisation' />

                    <div className='max-w-2xl'>
                        <Input
                            label='Name'
                            type='text'
                            name='name'
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            additionalClass='mt-2'
                        />
                        <Input
                            label='Description'
                            type='text'
                            name='type'
                            value={type}
                            onChange={(e) => {
                                setType(e.target.value)
                            }}
                            additionalClass='mt-2'
                        />
                        <Input
                            label='Number'
                            type='text'
                            name='number'
                            value={number}
                            onChange={(e) => {
                                setNumber(e.target.value)
                            }}
                            additionalClass='mt-2'
                        />
                        <label
                            htmlFor='Jurisdiction'
                            className='block text-sm font-medium text-charcoal-100 mt-2'
                        >
                            Jurisdiction
                        </label>
                        <Select
                            name='Jurisdiction'
                            options={countryList}
                            isMulti={false}
                            isSearchable={true}
                            filterOption={customFilter}
                            isClearable={false}
                            className='w-full'
                            onChange={(value) => {
                                setJurisdiction(value?.value)
                            }}
                            value={countryList.filter(function (option) {
                                return option.value === jurisdiction_code
                            })}
                        ></Select>
                        <Input
                            label='Address'
                            type='text'
                            name='address'
                            value={address}
                            onChange={(e) => {
                                setAddress(e.target.value)
                            }}
                            additionalClass='mt-2'
                        />
                        <label
                            htmlFor='Public'
                            className='block text-sm font-medium text-charcoal-100 mt-2'
                        >
                            Is Public Body?
                        </label>
                        <Select
                            name='Public'
                            options={optionlist}
                            isMulti={false}
                            isSearchable={true}
                            isClearable={false}
                            className='w-full'
                            onChange={(value) => {
                                setIsPublicBody(value?.value)
                            }}
                            value={optionlist.filter(function (option) {
                                return option.value === isPublicBody
                            })}
                        ></Select>
                    </div>

                    <Button
                        onClick={() => {
                            dispatch(
                                createCompany(api, {
                                    name: name,
                                    jurisdiction_code: jurisdiction_code !== undefined ? jurisdiction_code : '',
                                    number: number,
                                    address: address,
                                    isPublicBody: isPublicBody,
                                    industry_codes: industryCodesFiltered,
                                    type: type,
                                })
                            )
                        }}
                        type='Primary'
                        hasTrailingIcon={true}
                        icon='fa-solid fa-plus'
                        text='Add Organisation'
                        additionalClass='mt-9 mr-5'
                    />
                    <Button
                        type='Outlined'
                        text='Cancel'
                        onClick={() => {
                            resetCompanyCreation()
                            onClose();
                        }}
                    />
                </>
            )}
            {createCompanyStatus === PostStatus.sending && (
                <LoadingSpinner loadingText='Creating Organisation...' />
            )}

            {createCompanyStatus === PostStatus.sent && (
                <>
                    <PageHeadings title='Successfully created new organisation' />
                    <p className='text-white mt-6'>Your new organisation has been created:</p>

                    <div className='mt-4 bg-white rounded-lg p-4'>
                        <p className='text-lg'>
                            <strong>Name:</strong>
                        </p>
                        <p>{name}</p>
                        <p className='mt-4 text-lg'>
                            <strong>Description:</strong>
                        </p>
                        <p>{type}</p>
                        <p className='mt-4 text-lg'>
                            <strong>Organisation number:</strong>
                        </p>
                        <p>{number}</p>
                        <p className='mt-4 text-lg'>
                            <strong>Jurisdiction Code:</strong>
                        </p>
                        <p>
                            {countryList
                                .filter((item) => item.value === jurisdiction_code)
                                .map((item) => {
                                    return item.label
                                })}
                        </p>
                        <p className='mt-4 text-lg'>
                            <strong>Address:</strong>
                        </p>
                        <p>{address}</p>
                        <p className='mt-4 text-lg'>
                            <strong>Is Public Body?:</strong>
                        </p>
                        <p>{isPublicBody === true ? 'Yes' : 'No'}</p>
                    </div>

                    <Button
                        type='Primary'
                        text='Back to organisation list'
                        onClick={() => {
                            onClose();
                        }}
                        additionalClass='mt-6 mr-5'
                    />

                    <Button
                        type='Outlined'
                        text='Create another organisation'
                        icon='fa-regular fa-plus'
                        hasTrailingIcon
                        onClick={() => {
                            resetCompanyCreation()
                        }}
                        additionalClass='mt-6'
                    />
                </>
            )}

            {createCompanyStatus === PostStatus.error && (
                <>
                    <PageHeadings title='Error' />
                    <div className='rounded-lg bg-error-500 p-4 mt-4'>
                        <p className='text-white'>
                            There was an error whilst trying to create the new organisation. If this problem
                            persists, please contact the Neoni team.
                        </p>
                    </div>
                    <Button
                        onClick={() => {
                            dispatch(
                                createCompany(api, {
                                    name: name,
                                    jurisdiction_code: jurisdiction_code !== undefined ? jurisdiction_code : '',
                                    number: number,
                                    address: address,
                                    isPublicBody: isPublicBody,
                                    industry_codes: industryCodesFiltered,
                                    type: type,
                                })
                            )
                        }}
                        type='Primary'
                        hasTrailingIcon={true}
                        icon='fa-solid fa-arrow-right'
                        text='Retry'
                        additionalClass='mt-9 mr-5'
                    />
                    <Button
                        type='Outlined'
                        text='Cancel'
                        onClick={() => {
                            onClose();
                        }}
                        additionalClass='mt-6 mr-5'
                    />
                </>
            )}
        </Modal>
	)
}
export default AdminCreateCompany
