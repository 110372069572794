import { Helmet } from "react-helmet";
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate";
import routes from "../components/Router/routes";
import PageHeadings from "../components/BaseComponents/PageHeadings/PageHeadings";
import useUserData from "../hooks/useUserData";
import { useEffect, useState } from "react";
import { Button, LoadingSpinner } from "../components/BaseComponents";
import SimpleSortTable from "../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import useApi from "../hooks/useApi";
import { getCSRFToken } from "../lib/utils";
import useCleanup from "../hooks/useCleanup";

function CompanyUsers() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);

    const api = useApi();

    useEffect(() => {
        let cancelled = false;

        const run = async () => {
            const result = await api.get('company/users')
            if(cancelled) {
                return;
            }
            setData(result.data.users);
            setLoading(false);
        };
        run();
        return () => {
            cancelled = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(loading) {
        <LoadingSpinner />
    }

    const columns = [
        {
            id: 'email',
            // sortable: true,
            label: 'Email',
        },
        {
            id: 'fullName',
            // sortable: true,
            label: 'Name',
        },
        {
            id: 'role',
            // sortable: true,
            label: 'Role',
        },
    ]

    return (
        <div>
            <div className="mt-8">Users</div>
            <SimpleSortTable
                additionalClass='mt-4'
                data={data.filter(user => !user.locked)}
                columns={columns}
            />
            <div className="mt-8">Locked users</div>
            <SimpleSortTable
                additionalClass='mt-4'
                data={data.filter(user => user.locked)}
                columns={columns}
            />
        </div>
    )

}

function AdminEntryPermission() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>(null);
    const api = useApi();
    useEffect(() => {
        let cancelled = false;
        const run = async () => {
            const result = await api.get('/companySettings')
            if(cancelled) {
                return;
            }

            setData(result.data);
            setLoading(false);
        }

        run();

        return () => {
            cancelled = true;
        }
    }, []);

    const onChange = async (enabled: boolean) => {
        setLoading(true);
        const result = await api.put('/companySettings', {
            _csrf: await getCSRFToken(),
            settings: {
                adminEntryPermission: enabled,
            }
        })


        console.log(result.data)
        setData(result.data)
        setLoading(false);
    }

    const enabled = data?.adminEntryPermission;

    return (
        <div className="mt-4">
            <div>Permit Neoni Access</div>
            { loading ? (
                <div>Loading...</div>
            ) : (
                enabled ? (
                    <div className="flex space-x-2">
                        <div className="rounded py-2.5 px-4 text-charcoal-900 bg-algae-500 hover:bg-algae-600 focus:bg-algae-500 border-2 border-algae-500 hover:border-algae-600 focus:outline-none focus:ring focus:ring-algae-100" >
                            Enabled
                        </div>
                        <div
                            className="cursor-pointer rounded py-2.5 px-4 text-error-200 hover:text-charcoal-900 font-semibold border-2 border-error-200 hover:bg-error-100 hover:border-error-100 focus:outline-none focus:ring focus:ring-error-500"
                            onClick={() => onChange(false)}
                        >
                            Disable
                        </div>
                    </div>

                ): (
                    <div className="flex space-x-2">
                        <div
                            className="cursor-pointer rounded py-2.5 px-4 text-algae-200 hover:text-charcoal-900 font-semibold border-2 border-algae-200 hover:bg-algae-100 hover:border-algae-100 focus:outline-none focus:ring focus:ring-algae-500"
                            onClick={() => onChange(true)}
                        >
                            Enable
                        </div>
                        <div className="rounded py-2.5 px-4 text-charcoal-900 bg-error-500 hover:bg-error-600 focus:bg-error-500 border-2 border-error-500 hover:border-error-600 focus:outline-none focus:ring focus:ring-error-100" >
                            Disabled
                        </div>
                    </div>

                )
            )}
        </div>
    )

}

function CompanySettings() {
    const userData = useUserData();

    return (
		<PageTemplate>
			<Helmet>
				<title>{routes.companySupplyChainRequests.name}</title>
			</Helmet>
			<PageHeadings title={'Account Settings'} />
            <div className="mt-9 text-white">
                <div>
                    Currency:
                    { userData.currencyCode }
                </div>
                <AdminEntryPermission />
                <div>

                </div>
                <CompanyUsers />
            </div>
        </PageTemplate>
    )
}

export default CompanySettings;
