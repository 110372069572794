import { useEffect, useState } from "react";
import PageHeadings from "../../components/BaseComponents/PageHeadings/PageHeadings";
import PageTemplate from "../../components/BaseComponents/PageTemplate/PageTemplate";
import useApi from "../../hooks/useApi";
import SimpleSortTable, { TableColumn } from "../../components/BaseComponents/SimpleSortTable/SimpleSortTable";
import { Button } from "../../components/BaseComponents";
import CompanyProjectCreateModal from "./CompanyProjectCreateModal";
import { centsToCurrency, getCSRFToken } from "../../lib/utils";
import routes from "../../components/Router/routes";
import CompanyProjectEditModal from "./CompanyProjectEditModal";
import ConfirmationModal from "../../components/BaseComponents/ConfirmationModal";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";
import { generatePath } from "react-router-dom";
import usePagination from "../../hooks/usePagination";
import useDataSort from "../../hooks/useDataSort";
import Pagination from "../../components/BaseComponents/Pagination/Pagination";
import useCleanup from "../../hooks/useCleanup";

function CompanyProjectsList() {
    const api = useApi();
    const [data, setData] = useState([])

    const pagination = usePagination();
    const dataSort = useDataSort('name');

    const onDelete = async (projectId: string) =>  {
        await api.delete(`/projects/${projectId}`, {
            _csrf: await getCSRFToken()
        })
        setDeletionCandidate(null);
        loadData();
    }

    const [deletionCandidate, setDeletionCandidate] = useState<any>()

    const [editCandidate, setEditCandidate] = useState<any>(null);

    const formatCurrency = useCurrencyFormatter();

    const columns: TableColumn[] = [
        {
            id: 'name',
            label: 'Project name',
            sortable: true,
        },
        {
            id: 'code',
            label: 'Project code',
            columnClass: 'font-mono',
            sortable: true,
        },
        {
            id: 'totalTurnoverAmount',
            label: 'Project value',
			renderer: (key, data) => {
                const value = data[key]
				return (
                    <p className='text-white'>
                        {
                            value
                                ? formatCurrency(Number(centsToCurrency(value)))
                                : '-'
                        }
                    </p>
				)
			},
            textAlign: 'right',
            sortable: true,
        },
        {
            id: 'purchases_count',
            label: 'Purchases',
            sortable: true,
        },
        {
            id: 'actions',
            label: 'Actions',
            textAlign: 'center',
            renderer: (key, data) => {
                return (
                    <div className="flex justify-center space-x-2">
                        <Button
                            type="Primary"
                            icon='fa-regular fa-trash'
                            hasLeadingIcon
                            size="sm"
                            onClick={() => setDeletionCandidate(data)}
                        />
                        <Button
                            type="Primary"
                            icon='fa-regular fa-edit'
                            hasLeadingIcon
                            size="sm"
                            onClick={() => setEditCandidate(data)}
                        />
                    </div>
                )
            }
        },
        {
            id: 'id',
            label: 'View / refine data',
            renderer: (key, data) => {
                return (
                    <Button
                        text="View project"
                        type="Primary"
                        size="sm"
                        isLink
                        url={generatePath(routes.companyProjects.path, { projectId: data.id })}
                    />
                )
            }
        }
    ];


    const [createModalOpen, setCreateModelOpen] = useState(false);
    const [loading, setLoading] = useState(true)

    const loadData = useCleanup(() => {
        setLoading(true)
        let cancelled = false;
        const run = async () => {
            const qs = new URLSearchParams({
                ...pagination.params,
                ...dataSort.params,
            })
            const result = await api.get('/projects?' + qs);
            if(cancelled) {
                return;
            }
            setLoading(false);
            setData(result.data.rows);
            pagination.readMeta(result.data.meta);
        }
        run();
        return () => {
            cancelled = true;
        }
    })

    const onCreationModalClose = () => {
        setCreateModelOpen(false);
        loadData();
    }

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.params, dataSort.params])

    return (
        <PageTemplate>
            <PageHeadings title="Purchases" subTitle="Projects" />
            <div className="my-8">
                <Button
                    type="Primary"
                    text="Create project"
                    hasTrailingIcon
                    icon="fa-regular fa-plus"
                    size="sm"
                    onClick={() => setCreateModelOpen(true)}
                />
            </div>
            <SimpleSortTable
                data={data}
                columns={columns}
                defaultSorting={dataSort.defaultSorting}
                onSortPress={dataSort.onChange}
                isLoading={loading}
            />
            <Pagination
                isLoading={loading}
                pagination={pagination.pagination}
                onPageChange={pagination.onPageChange}
            />
            <CompanyProjectCreateModal
                open={createModalOpen}
                onClose={onCreationModalClose}
            />
            { editCandidate && (
                <CompanyProjectEditModal
                    open={!!editCandidate}
                    onClose={() => setEditCandidate(null)}
                    projectId={editCandidate.id}
                    projectName={editCandidate.name}
                    projectCode={editCandidate.code}
                    projectValue={editCandidate.totalTurnoverAmount}
                    onEdited={() => loadData()}
                />
            )}
            <ConfirmationModal
                open={!!deletionCandidate}
                onCancel={() => setDeletionCandidate(null)}
                onConfirm={() => deletionCandidate && onDelete(deletionCandidate.id)}
            >
                { deletionCandidate && (
                    <div>
                        <PageHeadings title="Delete project" />
                        <p className="my-8 text-white">Delete project {deletionCandidate.name}</p>
                    </div>
                )}
            </ConfirmationModal>
        </PageTemplate>
    )
}

export default CompanyProjectsList;
