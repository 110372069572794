/* eslint-disable @typescript-eslint/no-unused-vars */
// Graph: Emebedded CO2e in purchases by Supplier

import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Button } from '../../../../components/BaseComponents'
import Modal from '../../../../components/BaseComponents/Modal/Modal'
import Skeleton from '../../../../components/BaseComponents/Skeleton/Skeleton'
import { useEffectWithoutInitialRender } from '../../../../hooks/useEffectWithoutInitialRender'
import { getSupplierIntensityChangeData } from '../../../../redux/actions/IntensityChangeActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/reduxHooks'
import {
	selectSortedSupplyChainGraphData,
	selectSupplyChainGraphDataStatus,
	SUPPLYCHAINGRAPHDATA_RESET,
} from '../../../../redux/slices/supplyChainGraphDataSlice'
import { FetchStatus } from '../../../../types/LoadingStates'
import { Supplier } from '../../../../types/Supplier'
import { ModalSectorToSupplierSearch } from '../ModalSearch/ModalSectorToSupplierSearch'
import { ModalSupplierSearch } from '../ModalSearch/ModalSupplierSearch'
import useApi from '../../../../hooks/useApi'

// Dayjs extension required to format dates from UK format DD/MM/YYYY explicitly as thinks it is MM/DD/YYYY
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const WORST_SUPPLIER_LIMIT_QUANTITY = 5
type IIntensitySupplierGraph = {
	sectorIds?: number | string
	updateSectorId: () => void
}
const IntensitySupplierGraph = (props: IIntensitySupplierGraph) => {
	const { sectorIds, updateSectorId } = props
	const [sectorId, setSectorId] = useState(sectorIds)
	const dispatch = useAppDispatch()
	const api = useApi();

	const graphDataStatus = useAppSelector(selectSupplyChainGraphDataStatus)
	const sortedGraphData = useAppSelector(selectSortedSupplyChainGraphData)

	const [modalOpen, setModalOpen] = useState(false)
	const [modalOpenSector, setModalOpenSector] = useState(false)

	// Used to filter the sortedGraphData to get the five worst suppliers across time (default view)
	const [graphDataFilteredByBiggest, setGraphDataFilteredByBiggest] = useState<any>([])
	const [biggestSuppliersInLastPeriod, setBiggestSuppliersInLastPeriod] = useState<any>([])
	const [biggestSuppliersInLastPeriodLabels, setBiggestSuppliersInLastPeriodLabels] = useState<{
		[key: string]: string
	}>({})

	// Used for the custom view, sent to API to retreive specific suppliers
	const [customView, setCustomView] = useState(false)
	const [graphDataCustom, setGraphDataCustom] = useState<any>([])
	const [selectedSuppliers, setSelectedSuppliers] = useState<Array<Supplier>>([])
	const [showLoader, setShowLoader] = useState(sectorIds === undefined ? false : true)

	// Initial data loading stage
	useEffect(() => {
		dispatch({ type: SUPPLYCHAINGRAPHDATA_RESET })
		dispatch(getSupplierIntensityChangeData(api))
		if (sectorId !== null && sectorId !== undefined) {
			updateSectorId()
			setModalOpen(true)
			setCustomView(true)
			setShowLoader(false)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Custom data loading
	useEffect(() => {
		if (selectedSuppliers.length > 0) {
			dispatch({ type: SUPPLYCHAINGRAPHDATA_RESET })
			dispatch(getSupplierIntensityChangeData(api, selectedSuppliers.map((supplier) => supplier.id).join(',')))
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSuppliers])

	// Data processing stage (race condition with reset on first load)
	useEffectWithoutInitialRender(() => {
		if (graphDataStatus === FetchStatus.loaded) {
			// Default view mode (N worst suppliers)
			if (!customView && selectedSuppliers.length <= 0) {
				const biggestSuppliers = sortedGraphData[sortedGraphData.length - 1]?.data
					.filter((entry, index) => index < WORST_SUPPLIER_LIMIT_QUANTITY)
					.map((entry) => entry.id)

				// Store biggest keys and labels for later, slightly different for suppliers as we match via ID
				setBiggestSuppliersInLastPeriodLabels(
					sortedGraphData[sortedGraphData.length - 1]?.data
						.filter((entry, index) => index < WORST_SUPPLIER_LIMIT_QUANTITY)
						.reduce((acc, entry) => {
							return { [entry.id]: entry.label, ...acc }
						}, []) as any
				)

				setBiggestSuppliersInLastPeriod(biggestSuppliers)

				setGraphDataFilteredByBiggest(
					constructData(
						[...sortedGraphData].map((period) => {
							return {
								periodId: period.periodId,
								periodStart: period.periodStart,
								periodEnd: period.periodEnd,
								data: [...period.data].filter((entry) => biggestSuppliers.includes(entry.id)),
							}
						})
					)
				)
			} else {
				// Custom supplier selections (DIY) mode
				setGraphDataCustom(constructData([...sortedGraphData]))
			}
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphDataStatus, selectedSuppliers])

	// Data construction for graph
	const constructData = (
		sourceData: {
			periodId: string
			periodEnd: string
			periodStart: string
			data: {
				id: string
				label: string
				total: number
			}[]
		}[]
	) => {
		return sourceData.map((period) => {
			let codeAsKey = period.data.reduce((acc, entry) => {
				return {
					[entry.id]: entry.total.toFixed(2),
					[`label-${entry.id}`]: entry.label, // Unused
					...acc,
				}
			}, {})

			return {
				name: `${dayjs(period.periodStart, 'DD/MM/YYYY').format('MM/YYYY')} - ${dayjs(
					period.periodEnd,
					'DD/MM/YYYY'
				).format('MM/YYYY')}`,
				...codeAsKey,
			}
		})
	}

	// Contrasting colours set
	const colourList = ['#CC6429', '#77E1AE', '#3278FF', '#FFFFFF', '#FFE387']

	return (
		<>
			{graphDataStatus === FetchStatus.loaded && !showLoader && (
				<>
					<div className='flex justify-between'>
						<h2 className='text-white text-xl font-bold mb-4'>
							Intensity ratio by Supplier
						</h2>

						{(graphDataStatus === FetchStatus.loaded || graphDataStatus === FetchStatus.error) && (
							<div className='flex justify-between'>
								<Button
									additionalClass='mt-4 mr-2'
									type='Primary'
									size='sm'
									onClick={() => setModalOpenSector(true)}
									text='Select Sectors to Compare'
									icon='fa-regular fa-code-compare'
									hasLeadingIcon
								/>
								<Button
									additionalClass='mt-4'
									type='Primary'
									size='sm'
									onClick={() => setModalOpen(true)}
									text='Select Suppliers to Compare'
									icon='fa-regular fa-code-compare'
									hasLeadingIcon
								/>
							</div>
						)}
					</div>
					<div className='relative pl-4 overflow-hidden'>
						{/* Custom Y axis label - sorry */}
						<div className='text-white text-sm absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-center'>
							<span className='-rotate-90 origin-top-left'>
								<span className='absolute -translate-x-1/2'>Intensity Ratio</span>
							</span>
						</div>
						<ResponsiveContainer width='99.9%' aspect={1.778}>
							<LineChart
								width={500}
								height={300}
								data={customView ? graphDataCustom : graphDataFilteredByBiggest}
								margin={{
									top: 20,
									right: 30,
									left: 20,
									bottom: 5,
								}}
							>
								<XAxis
									dataKey='name'
									axisLine={false}
									tickLine={false}
									style={{ fontSize: '12px' }}
									tick={{ fill: '#FFF' }}
								/>
								<YAxis
									axisLine={false}
									tickLine={false}
									style={{ fontSize: '12px' }}
									tick={{ fill: '#FFF' }}
								/>
								<Tooltip
									content={({ active, payload, label }) => {
										if (active && payload && payload.length) {
											return (
												<div className='bg-charcoal-600 border-2 border-charcoal-700 text-white p-2'>
													<p>{label}</p>
													{payload.map((tooltipItem) => {
														return (
															<p key={tooltipItem.name} className='text-sm text-charcoal-100'>
																{typeof tooltipItem.name === 'string' && (
																	<>
																		<span
																			className='inline-block mr-2'
																			style={{
																				height: 10,
																				width: 14,
																				backgroundColor: tooltipItem.color,
																				flexShrink: 0,
																			}}
																		/>
																		{customView
																			? selectedSuppliers.filter(
																				(supplier) => supplier.id === tooltipItem.name
																			)[0].name
																			: biggestSuppliersInLastPeriodLabels[tooltipItem.name]}
																	</>
																)}
																- {tooltipItem.value}
															</p>
														)
													})}
												</div>
											)
										}

										return null
									}}
									cursor={{ fill: '#12171C' }}
								/>

								{customView
									? selectedSuppliers.map((supplier: Supplier, index: number) => {
										return (
											<Line
												connectNulls
												type='monotone'
												key={supplier.id}
												dataKey={supplier.id}
												stroke={colourList[index]}
												fill={colourList[index]}
											/>
										)
									})
									: biggestSuppliersInLastPeriod?.map((key: string, index: number) => {
										return (
											<Line
												connectNulls
												type='monotone'
												key={key}
												dataKey={key}
												stroke={colourList[index]}
												fill={colourList[index]}
											/>
										)
									})}
							</LineChart>
						</ResponsiveContainer>
					</div>
					<div className='pl-4'>
						<h3 className='text-white text-lg my-2'>Legend</h3>
						{customView
							? selectedSuppliers?.map((supplier: Supplier, index: number) => (
								<div key={supplier.id} className='flex items-center mb-2'>
									<div
										style={{
											height: 10,
											width: 14,
											backgroundColor: colourList[index],
											flexShrink: 0,
										}}
									/>
									<p className='text-charcoal-100 ml-2'>{supplier.name}</p>
								</div>
							))
							: biggestSuppliersInLastPeriod?.map((key: string, index: number) => (
								<div key={key} className='flex items-center mb-2'>
									<div
										style={{
											height: 10,
											width: 14,
											backgroundColor: colourList[index],
											flexShrink: 0,
										}}
									/>
									<p className='text-charcoal-100 ml-2'>
										{biggestSuppliersInLastPeriodLabels[key]}
									</p>
								</div>
							))}
					</div>
				</>
			)}

			{graphDataStatus === FetchStatus.error && (
				<div className='rounded-lg bg-error-500 p-4 mt-4'>
					<p className='text-white'>
						Sorry, there was an error trying to fetch the supplier data for comparison. Please check
						your supplier information is up to date and try again. If the problem persists, please
						contact the Neoni team.
					</p>
				</div>
			)}


			{/* Modal for searching and selecting custom suppliers */}
			<Modal open={modalOpen} onClose={() => setModalOpen(false)} size='small'>
				<ModalSupplierSearch
					sectorId={sectorId}
					onChange={(selections) => {
						if (selections !== void 0) {
							setSelectedSuppliers(selections.filter((entry, index) => index < WORST_SUPPLIER_LIMIT_QUANTITY))
						}
						setModalOpen(false)
						setSectorId(undefined)
						setCustomView(true)
					}}
					active={modalOpen}
				/>

			</Modal>
			<Modal open={modalOpenSector} onClose={() => setModalOpenSector(false)} size='small'>
				<ModalSectorToSupplierSearch
					onChange={(selections) => {
						if (selections !== void 0) {
							setSelectedSuppliers(selections.filter((entry, index) => index < WORST_SUPPLIER_LIMIT_QUANTITY))
						}
						setModalOpenSector(false)
						setSectorId(undefined)
						setCustomView(true)
					}}
					active={modalOpenSector}
				/>

			</Modal>

			{graphDataStatus === FetchStatus.loading || showLoader && (
				<>
					<Skeleton additionalClass='h-80 mb-2' />
					<Skeleton additionalClass='mb-2' />
					<Skeleton additionalClass='mb-2' />
					<Skeleton additionalClass='mb-2' />
				</>
			)}
		</>
	)
}

export default IntensitySupplierGraph
