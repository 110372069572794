import { Dispatch } from 'redux'
import { ApiPagination } from '../../types/PaginationApi'
import { ITEMS_ERROR, ITEMS_LOADED, ITEMS_LOADING, ITEMS_SINGLELOADED } from '../slices/itemsSlice'
import { NeoniApi } from '../../lib/api'

export const GET_ITEMS_LIMIT = 20

interface IGetItems extends Omit<ApiPagination, 'orderBy'> {
	orderBy?: 'name' | 'supplierName' | 'scope' | string
}

export const getItems =
	(api: NeoniApi, {
		page = 1,
		limit = GET_ITEMS_LIMIT,
		orderBy = 'name',
		direction = 'asc',
		q = '',
	}: IGetItems = {}) =>
	async (dispatch: Dispatch) => {
		dispatch({
			type: ITEMS_LOADING,
		})

		try {
			const body = await api.get(`/items?` +
				new URLSearchParams({
					page: page.toString(),
					limit: limit.toString(),
					orderBy,
					direction,
					q,
				})
			)

			if (body.success === true) {
				// Successfully fetched items
				dispatch({
					type: ITEMS_LOADED,
					payload: body.items,
				})
			} else {
				// Error
				dispatch({
					type: ITEMS_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: ITEMS_ERROR,
				payload: error,
			})
		}
	}

export const getItem = (api: NeoniApi, itemId?: string) => async (dispatch: Dispatch) => {
	dispatch({
		type: ITEMS_LOADING,
	})

	try {
		const body = await api.get(`items/` + itemId)

		if (body.success === true) {
			// Successfully fetched single item
			dispatch({
				type: ITEMS_SINGLELOADED,
				payload: body.item,
			})
		} else {
			// Error
			dispatch({
				type: ITEMS_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: ITEMS_ERROR,
			payload: error,
		})
	}
}
