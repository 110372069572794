import { Dispatch } from 'redux'
import { getCSRFToken } from '../../lib/utils'
import { Period } from '../../types/AccountingPeriods'
import {
	ACCOUNTINGPERIODS_CREATE_SENDING,
	ACCOUNTINGPERIODS_CREATE_SENT,
	ACCOUNTINGPERIODS_CREATE_ERROR,
	ACCOUNTINGPERIODS_FAILURE,
	ACCOUNTINGPERIODS_FETCH,
	ACCOUNTINGPERIODS_SUCCESS,
	ACCOUNTINGPERIODS_UPDATE_SENDING,
	ACCOUNTINGPERIODS_UPDATE_SENT,
	ACCOUNTINGPERIODS_UPDATE_ERROR,
	DELETE_ACCOUNTINGPERIOD_SENT,
	DELETE_ACCOUNTINGPERIOD_ERROR,
	DELETE_ACCOUNTINGPERIOD_SENDING,
	ACCOUNTINGPERIODS_EDITING_SENT,
	ACCOUNTINGPERIODS_EDITING_ERROR,
	ACCOUNTINGPERIODS_EDITING_SENDING,
} from '../slices/accountingPeriodsSlice'
import { NeoniApi } from '../../lib/api'

export const getAccountingPeriods = (api: NeoniApi) => async (dispatch: Dispatch) => {
	const dayjs = require('dayjs')

	dispatch({
		type: ACCOUNTINGPERIODS_FETCH,
	})

	try {
		console.log({api})
		const body = await api.get('accountingPeriods/')

		body.accountingPeriods.data.forEach((period: Period) => {
			period.created_at = dayjs(period.created_at).format('DD/MM/YY')
			period.start_date = dayjs(period.start_date).format('DD/MM/YY')
			period.end_date = dayjs(period.end_date).format('DD/MM/YY')
		})

		dispatch({
			type: ACCOUNTINGPERIODS_SUCCESS,
			payload: {
				periods: body.accountingPeriods.data,
				meta: body.accountingPeriods.meta,
			},
		})
	} catch (error) {
		dispatch({
			type: ACCOUNTINGPERIODS_FAILURE,
			payload: error,
		})
	}
}

type ICreateAccountingPeriod = {
	startDate: string
	endDate: string
	totalSpendCurrency?: string
	totalSpendAmount?: number
	totalTurnoverCurrency?: string
	totalTurnoverAmount?: number
	totalPurchaseSpentTarget?: number
	id?: string
}

export const createAccountingPeriod =
	(api: NeoniApi, periodToCreate: ICreateAccountingPeriod) => async (dispatch: Dispatch) => {
		const dayjs = require('dayjs')

		dispatch({
			type: ACCOUNTINGPERIODS_CREATE_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			const body = await api.post(`/accountingPeriods`, {
				...periodToCreate, _csrf: csrf
			});

			if (body.success === true) {
				// Successfully created new period
				dispatch({
					type: ACCOUNTINGPERIODS_CREATE_SENT,
					payload: {
						...body.accountingPeriod,
						created_at: dayjs(body.accountingPeriod.created_at).format('DD/MM/YY'),
					},
				})
			} else {
				// Error
				dispatch({
					type: ACCOUNTINGPERIODS_CREATE_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: ACCOUNTINGPERIODS_CREATE_ERROR,
				payload: error,
			})
		}
	}

type IUpdateAccountingPeriodStage = {
	accountingPeriodId: string
	uploadState: number
}

export const updateAccountingPeriodStage =
	(api: NeoniApi, periodToUpdate: IUpdateAccountingPeriodStage) => async (dispatch: Dispatch) => {
		dispatch({
			type: ACCOUNTINGPERIODS_UPDATE_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			const body = await api.post(`accountingPeriods/updateState`, {
				...periodToUpdate, _csrf: csrf
			});

			if (body.success === true) {
				// Successfully created new period
				dispatch({
					type: ACCOUNTINGPERIODS_UPDATE_SENT,
					payload: body.data,
				})
			} else {
				// Error
				dispatch({
					type: ACCOUNTINGPERIODS_UPDATE_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: ACCOUNTINGPERIODS_UPDATE_ERROR,
				payload: error,
			})
		}
	}

export const deleteAccountingPeriod = (api: NeoniApi, accountingPeriodId: any) => async (dispatch: Dispatch) => {
	dispatch({
		type: DELETE_ACCOUNTINGPERIOD_SENDING,
	})

	try {
		const csrf = await getCSRFToken()
		const body = await api.delete(`accountingPeriods/${accountingPeriodId}`, {
			_csrf: csrf
		})

		if (body.success === true) {
			// Successfully fetched items
			dispatch({
				type: DELETE_ACCOUNTINGPERIOD_SENT,
				payload: accountingPeriodId,
			})
		} else {
			// Error
			dispatch({
				type: DELETE_ACCOUNTINGPERIOD_ERROR,
				payload: body.message,
			})
		}
	} catch (error) {
		dispatch({
			type: DELETE_ACCOUNTINGPERIOD_ERROR,
			payload: error,
		})
	}
}

export const updateAccountingPeriods =
	(api: NeoniApi, periodToCreate: ICreateAccountingPeriod) => async (dispatch: Dispatch) => {
		const dayjs = require('dayjs')

		dispatch({
			type: ACCOUNTINGPERIODS_EDITING_SENDING,
		})

		try {
			const csrf = await getCSRFToken()

			const body = await api.put(`accountingPeriods/${periodToCreate.id}`, {
				...periodToCreate, _csrf: csrf
			});

			if (body.success === true) {
				// Successfully created new period
				dispatch({
					type: ACCOUNTINGPERIODS_EDITING_SENT,
					payload: {
						...body.accountingPeriod,
						created_at: dayjs(body.accountingPeriod.created_at).format('DD/MM/YY'),
					},
				})
			} else {
				// Error
				dispatch({
					type: ACCOUNTINGPERIODS_EDITING_ERROR,
					payload: body.message,
				})
			}
		} catch (error) {
			dispatch({
				type: ACCOUNTINGPERIODS_EDITING_ERROR,
				payload: error,
			})
		}
	}
