import { Helmet } from "react-helmet"
import { LoadingSpinner } from "../components/BaseComponents"
import PageTemplate from "../components/BaseComponents/PageTemplate/PageTemplate"
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "../context/UserContext";

function AssumeCompany() {
    const { setCompanyAssumption, userData } = useContext(userContext)
    const { companyId, assumptionType } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!setCompanyAssumption) {
            return;
        }
        if(!companyId || !assumptionType) {
            return;
        }
        setCompanyAssumption({
            companyId,
            type: assumptionType as any,
        })
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId])

    useEffect(() => {
        if(userData?.companyId === companyId) {
            navigate('/', { replace: true })
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

	return (
		<PageTemplate testid='add-accounting-periods-main'>
			<Helmet>
				<title>Loading</title>
			</Helmet>
            <LoadingSpinner />
        </PageTemplate>
	)
}

export default AssumeCompany
