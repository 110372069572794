import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, LoadingSpinner } from '../components/BaseComponents'
import { FormEvent, useEffect, useState } from 'react';
import usePublicApi from '../hooks/usePublicApi';
import { getCSRFToken } from '../lib/utils';
import Select from 'react-select'
import { countries } from '../assets/countryList'
import routes from '../components/Router/routes';
import useCleanup from '../hooks/useCleanup';

function SignUp() {
    const [searchParams]= useSearchParams();
    const inviteToken = searchParams.get('inviteToken');
    const [termsConditions, setTermsConditions] = useState('');

    const [loading, setLoading] = useState(!!inviteToken);
    const [inviteInfo, setInviteInfo] = useState<any>();

    const publicApi = usePublicApi();
    const navigate = useNavigate();

    const loadInviteInfo = useCleanup(() => {
        if(!inviteToken) {
            return;
        }

        let cancelled = false;
        const run = async () => {
            const result = await publicApi.get(`/user/inviteInfo/${inviteToken}`);
            if(cancelled) {
                return;
            }
            setInviteInfo(result.data);
        }
        run();
        return () =>{ 
            cancelled = true;
        }
    })

    const loadTermsConditions = async () => {
        const result = await publicApi.get('user/loginTermsConditions');
        setTermsConditions(result.data.termsConditions)
    }

    useEffect(() => {
        const run = async () => {
            await Promise.all([
                loadInviteInfo(),
                loadTermsConditions(),
            ])
            setLoading(false);
        }
        run();
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyNAme] = useState('');
	const [jurisdictionCode, setJurisdiction] = useState<string | undefined>('')
    const [password, setPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);

    const onFormSubmit = (e: FormEvent) => {
       e.preventDefault();
       onSubmit();
       return false; 
    }

    const onSubmit = async () => {
        if(!inviteInfo && !companyName) {
            setError('Please enter an organisation name');
            return;
        } else if(!inviteInfo && !jurisdictionCode) {
            setError('Please select a jurisdiction');
            return;
        } else if(!name) {
            setError('Please enter your full name');
            return;
        } else if(!email.match(/\w+@\w+\.\w+/)) {
            setError('Please enter a valid email address')
            return;
        } else if(password.length < 6) {
            setError('Your password must contain at least 6 characters')
            return;
        } else if(!termsAccepted) {
            setError('You must accept the terms and conditions to sign up.');
            return;
        }

        setSubmitting(true)
        setError('');

        try {
            await publicApi.post('/user/signup', {
                name,
                email,
                password,
                inviteToken,
                companyName,
                jurisdictionCode,
                termsConditions,
                _csrf: await getCSRFToken(),
            });
			navigate(
                routes.publicLogin.path,
                {
                    replace: true,
                    state: {
                        message: 'Your account was successfully created, please login below'
                    }
                }
            )

        } catch(error: any) {
            setSubmitting(false);
            console.warn(error)
			const { error: signUpError } = error.body || {};
            setError(signUpError)
        }
    }

    if(loading) {
        return <LoadingSpinner />
    }

    return (
        <div className="content w-[32rem] mx-auto mt-16">
            <div className="text-charcoal-100 font-bold text-2xl text-center">Welcome</div>
            { inviteInfo && (
                <div className="text-charcoal-100 font-bold text-2xl text-center">
                    Setting up account for { inviteInfo.companyName }
                </div>
            )}
            <div className="flex text-center mt-8 text-sm">
                <Link className="w-1/2 border-algae-500 p-2 border-b-2 text-white" to={routes.publicSignUp.path}>Create an account</Link>
                <Link className="w-1/2 text-charcoal-100 p-2" to={routes.publicLogin.path}>Log in</Link>
            </div>
            <form onSubmit={onFormSubmit}>
                { !inviteInfo && (
                    <>
                        <label className="block mt-8 text-sm">
                            <span className="text-charcoal-100">Organisation name</span>
                            <br />
                            <input
                                disabled={submitting}
                                name="name"
                                className="rounded w-full mt-1 text-sm"
                                type="text"
                                placeholder="Organisation name"
                                value={companyName}
                                onChange={e => setCompanyNAme(e.target.value)}
                            />
                        </label>
                        <label className="text-sm block 100 mt-8" >
                            <span className="text-charcoal-100">
                                Organisation jurisdiction
                            </span>
                            <Select
                                name='Jurisdiction'
                                options={countries}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={false}
                                className='w-full text-sm'
                                onChange={(value) => {
                                    setJurisdiction(value?.value)
                                }}
                                value={countries.filter(function (option) {
                                    return option.value === jurisdictionCode
                                })}
                            ></Select>
                        </label>
                    </>
                )}
                <label className="block mt-8 text-sm">
                    <span className="text-charcoal-100">Full name</span>
                    <br />
                    <input
                        disabled={submitting}
                        name="name"
                        className="rounded w-full mt-1 text-sm"
                        type="text"
                        placeholder="e.g. Jo Bloggs"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </label>
                <label className="block mt-8 text-sm">
                    <span className="text-charcoal-100">Organisation email address</span>
                    <br />
                    <input
                        disabled={submitting}
                        name="email"
                        className="rounded w-full mt-1 text-sm"
                        type="text"
                        placeholder="you@example.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </label>
                <label className="block mt-8 text-sm">
                    <span className="text-charcoal-100">Password</span>
                    <br />
                    <input
                        disabled={submitting}
                        name="password"
                        className="rounded w-full mt-1 text-sm"
                        type="password"
                        placeholder="Create a password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </label>

                <div className="text-charcoal-100 mt-8">Terms and Conditions</div>
                <div className="max-h-96 overflow-y-scroll rounded font-sm">
                    <div className="whitespace-pre-line text-charcoal-300">{ termsConditions }</div>
                </div>
                <label className="text-white mt-2 block">
                    <input
                        type="checkbox"
                        className="rounded-sm mr-2"
                        checked={termsAccepted}
                        onChange={e => setTermsAccepted(e.target.checked)}
                    />
                    I have read and agree to the Terms and Conditions.
                </label>

                { error && (
                    <div className='bg-error-400 rounded p-4 mt-6 text-sm'>
                        <span className='fa-regular fa-circle-exclamation pr-4' />
                        { error }
                    </div>
                )}
                <Button
                    disabled={submitting}
                    onClick={onSubmit}
                    type="Primary"
                    text="Create an account"
                    size="sm"
                    additionalClass='mt-6 w-full mb-8'
                />
                <button type="submit" value="Submit" className="hidden" />
            </form>
        </div>
    );
}

export default SignUp
